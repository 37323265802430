import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps";
import { useRef } from "react";
import { DepartmentModel } from '@/interface';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import styles from './styles.module.css';

const Footer = ({ activeDepartment }: { activeDepartment?: DepartmentModel }) => {
  const map: any = useRef();

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.leftBlock}>
          <div className={styles.blockItem}>
            <LocationOnIcon />
            <p>г.{activeDepartment?.code} {activeDepartment?.address}</p>
          </div>
          <div className={styles.blockItem}>
            <PhoneInTalkIcon />
            <div className={styles.phonesBlock}>
              <p>+7 (708) 573 69 29</p>
              <p>+7 (701) 666 43 98</p>
            </div>
          </div>
          <div className={styles.blockItem}>
            <CalendarMonthIcon />
            <p>График работы:</p>
            <div className={styles.graphickBlock}>
              <p>ПН - ВС</p>
              <p>с 9:00 до 20:00</p>
              <p>Без перерывов</p>
            </div>
          </div>
          {/* <div>
            <a
              rel="noreferrer"
              style={{ color: "white", textDecoration: "none" }}
              target="_blank"
              href={basePath + "/assets/documents/agent_contract.pdf"}
            >
              Договор агентского соглашения
            </a>
          </div> */}
        </div>
        <div className={styles.rightBlock}>
          <YMaps
            query={{
              apikey: "34a3447b-70be-4734-b74f-0268eefca581",
              ns: "use-load-option",
              load: "Map,Placemark,control.ZoomControl,control.FullscreenControl",
            }}>
            <Map
              instanceRef={map}
              // width={"100%"}
              // height={"100%"}
              state={{
                center: [43.23823495, 76.95711971],
                zoom: 16,
                controls: ["zoomControl", "fullscreenControl"],
              }}>
              <Placemark geometry={[43.23823495, 76.95711971]} />
            </Map>
          </YMaps>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import { api } from '@/api/Api';
import { DepartmentModel } from '@/interface';
import { loadDepartments } from '@/redux/modules/department/departmentActions';
import { selectActiveDepartment, selectDepartmentStatus, selectRegions } from '@/redux/modules/department/departmentSelectors';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pm from "@/core/helpers/ProcessManager";

export interface Log {
	logId: number
	depId: number
	actionDate: string
	initiator: string
	rateLogs: RateLog[]
}

export interface RateLog {
	id: string
	currencyCode: string
	buy: number
	sale: number
	limitFrom: number
	limitTo: number
}

export const useOrdersDocument = () => {
	const [dateRange, setDateRange] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: "selection",
		},
	]);
	const [logList, setLogList] = useState<Log[]>([]);
	const [departmentFromLoadedLogs, setDepartmentFromLoadedLogs] = useState<DepartmentModel | undefined>();
	const [datesFromloadedLogs, setDatesFromLoadedLogs] = useState<{ startDate: string | null, endDate: string | null }>({
		startDate: null,
		endDate: null,
	});

	const startDate = dateRange[0].startDate ? dateRange[0].startDate.toLocaleDateString() : null;

	const endDate = dateRange[0].endDate ? dateRange[0].endDate.toLocaleDateString() : null;

	const dispatch = useDispatch();

	const regions = useSelector(selectRegions);
	const activeDepartment = useSelector(selectActiveDepartment);
	const departmentStatus = useSelector(selectDepartmentStatus);

	useEffect(() => {
		dispatch(loadDepartments());
	}, []);

	const handleLoadLogsList = async () => {
		pm.openBackDrop();
		if (startDate && endDate) {
			await api.logs.loadLogsByDate({
				startDate: `${startDate.split('.')[2]}-${startDate.split('.')[1]}-${startDate.split('.')[0]}`,
				endDate: `${endDate.split('.')[2]}-${endDate.split('.')[1]}-${endDate.split('.')[0]}`,
				depId: activeDepartment?.id || ""
			})
				.then(res => {
					if (res.value.length === 0) {
						pm.openSnackBar("Распоряжений за данный период не найдено", { severity: "error" });
					} else {
						setLogList(res.value.reverse());
						setDepartmentFromLoadedLogs(activeDepartment);
						setDatesFromLoadedLogs({ startDate, endDate });
					}
				});
		}
		pm.closeBackDrop();
	};

	return {
		regions,
		setDateRange,
		dateRange,
		handleLoadLogsList,
		logList,
		departmentFromLoadedLogs,
		datesFromloadedLogs,
		departmentStatus
	};
};
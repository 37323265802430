import React, { useState } from "react";

import pm from "@/core/helpers/ProcessManager";

import { ModalCashActionProps } from './ModalCashAction.props';

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

import NumberFormat from 'react-number-format';
import pdfMake from "pdfmake/build/pdfmake";

import styles from "./modalCashAction.module.css";
import { consumptionCashOrderDocument } from './DynamicDocuments/cashOrderDocument';
import { Autocomplete, IconButton, Tooltip } from '@mui/material';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import actOfSurplusAndShortage from './DynamicDocuments/actOfSurplusAndShortage';
import { formatNumberRu } from '@/core/helpers/PageHelpers';
import { CurrencyModel, KassaModel } from '@/interface';
import { OperationType } from '../../useBalancePage';

export const ModalCashAction = ({
	openWindow,
	handleCloseWindow,
	addCashBody,
	setAddCashBody,
	loadUpdateCash,
	loadAddCash,
	isMinus,
	handleCloseKassa,
	currencyList,
	kassa,
	user,
	department,
	handleTransferCashBetweenKassa
}: ModalCashActionProps): JSX.Element => {
	const [number, setNumber] = useState(0);
	const [closeStep, setCloseStep] = useState(0);
	const [overOrShortageMoneyList, setOverOrShortageMoneyList] = useState<{ factBalance: number, systemBalance: number, currencyCode: string, }[]>([{ factBalance: 0, systemBalance: 0, currencyCode: "" }]);
	return (
		<Dialog
			open={openWindow !== null}
			onClose={handleCloseWindow}
			maxWidth={closeStep === 2 ? "md" : "xs"}
		>
			{openWindow === "updateCash"
				&& (
					<>
						<DialogContent>
							<DialogContentText className={styles.title}>
								{isMinus ? "Снятие" : "Приход"}:
							</DialogContentText>
						</DialogContent>
						<div style={{ padding: "0 20px", display: "grid", gap: "10px" }}>
							<Autocomplete
								disableClearable
								options={department.currencies?.sort((a, b) => a.code !== "KZT" ? 1 : -1)}
								getOptionLabel={(currency: CurrencyModel) => currency.code}
								value={department.currencies.find(cur => cur.currencyId === addCashBody.currencyId)}
								onChange={(_, newValue: CurrencyModel) => {
									const foundCurrencyCash = kassa.cash?.find(item => item.currencyCode === newValue.code);
									setAddCashBody((state: any) => ({
										...state,
										currencyCode: newValue?.code,
										balance: foundCurrencyCash?.balance || 0,
										currencyId: newValue?.currencyId || null,
										cashId: foundCurrencyCash?.cashId || null
									}));
								}}
								renderInput={(params) => <TextField label="Валюта" {...params} />}
							/>
							{addCashBody.currencyCode && <p style={{ fontSize: "19px", height: "35px" }}>Баланс: {formatNumberRu((addCashBody.balance || 0).toFixed(2))}</p>}
							<NumberFormat
								customInput={TextField}
								//Свойства TextField Mui5
								autoComplete={"off"}
								fullWidth
								value={number ? number : ""}
								label={isMinus ? "Сумма снятия" : "Сумма прихода"}
								variant="outlined"
								className={styles.textField}
								onChange={(e) => {
									const num = +(e.target.value.replace(/\s/g, ""));
									if (isMinus && num > addCashBody.balance) {
										pm.openSnackBar("Сумма расходов превышает фактический остаток на кассе", { severity: "error" });
									}
									setNumber(num);
								}}
								inputProps={{
									inputmode: "numeric",
								}}
								//Свойства NumberFormat
								thousandSeparator={" "}
								allowNegative={false}
								decimalScale={2}
								fixedDecimalScale={true}
								disabled={!addCashBody.currencyCode}
							/>
							<Autocomplete
								disableClearable
								options={isMinus ? ["Выдано в подотчет"] : ["Принято из банка",  "Взнос от учередителя"]}
								value={addCashBody.operationType}
								onChange={(_, newValue: string | OperationType) => {
									if (newValue === "Передача наличности в другую кассу" && department.kassa.length < 1) {
										pm.openSnackBar('В данном отделении открыта только одна касса', { severity: "error" });
									}
									setAddCashBody((state: any) => ({
										...state,
										operationType: newValue,
										toKassaId: newValue !== "Передача наличности в другую кассу" ? 0 : state.toKassaId
									}));
								}}
								renderInput={(params) => <TextField label="Операция" {...params} />}
							/>
							{addCashBody.operationType === "Передача наличности в другую кассу" && (
								<Autocomplete
									disableClearable
									options={department.kassa.filter(kassa => kassa.id !== addCashBody.kassaId)}
									getOptionLabel={(kassa: KassaModel) => '№' + kassa.code}
									value={department.kassa.find(kassa => kassa.id === addCashBody.toKassaId)}
									onChange={(_, newValue: KassaModel) => {
										setAddCashBody((state: any) => ({
											...state,
											toKassaId: newValue.id
										}));
									}}
									renderInput={(params) => <TextField label="Касса пополнения" {...params} />}
								/>
							)}
						</div>
						<DialogActions className={styles.btn_group}>
							<Button
								variant="contained"
								fullWidth
								size="large"
								startIcon={<ClearIcon />}
								sx={{
									background: "#b70000",
									"&:hover": {
										background: "#7f0101",
									},
								}}
								onClick={handleCloseWindow}
							>
								Отмена
							</Button>
							<Button
								variant="contained"
								fullWidth
								size="large"
								startIcon={<CheckIcon />}
								disabled={(isMinus && number > addCashBody.balance) || !(addCashBody.currencyCode && addCashBody.operationType && number && (addCashBody.operationType === "Передача наличности в другую кассу" ? addCashBody.toKassaId : true))}
								sx={{
									background: "#037703",
									marginLeft: "none",
									"&:hover": {
										background: "#005f00",
									},
								}}
								onClick={async () => {
									// const doc = isMinus ? consumptionCashOrderDocument(kassa, addCashBody, number) : comingCashOrderDocument(isMinus, number);
									const doc = consumptionCashOrderDocument(kassa, addCashBody, number, isMinus, department);
									pdfMake.createPdf(doc).print();
									if (addCashBody.operationType === "Передача наличности в другую кассу") {
										handleTransferCashBetweenKassa(number);
									} else {
										if (addCashBody.cashId) {
											loadUpdateCash(isMinus ? 0 - number : 0 + number);
										} else {
											loadAddCash({ balance: number, currencyCode: addCashBody.currencyCode, kassaId: addCashBody.kassaId });
										}
									}
								}}
							>
								Подтвердить
							</Button>
						</DialogActions>
					</>
				)}
			{openWindow === "closeKassa"
				&& (
					<>
						{
							closeStep === 0
							&& (
								<>
									<DialogContent>
										<DialogContentText className={styles.title}>
											Закрыть кассу?
										</DialogContentText>
									</DialogContent>
									<DialogActions className={styles.btn_group}>
										<Button
											variant="contained"
											fullWidth
											size="large"
											startIcon={<ClearIcon />}
											sx={{
												background: "#b70000",
												"&:hover": {
													background: "#7f0101",
												},
											}}
											onClick={handleCloseWindow}
										>
											Нет
										</Button>
										<Button
											variant="contained"
											fullWidth
											size="large"
											startIcon={<CheckIcon />}
											disabled={isMinus && number > addCashBody.balance}
											sx={{
												background: "#037703",
												marginLeft: "none",
												"&:hover": {
													background: "#005f00",
												},
											}}
											onClick={async () => setCloseStep(1)}
										>
											Да
										</Button>
									</DialogActions>
								</>
							)
						}
						{
							closeStep === 1
							&& (
								<>
									<DialogContent>
										<DialogContentText className={styles.title}>
											Закрыть кассу с излишками/недостачами?
										</DialogContentText>
									</DialogContent>
									<DialogActions className={styles.btn_group}>
										<Button
											variant="contained"
											fullWidth
											size="large"
											startIcon={<ClearIcon />}
											sx={{
												background: "#b70000",
												"&:hover": {
													background: "#7f0101",
												},
											}}
											onClick={handleCloseKassa}
										>
											Нет
										</Button>
										<Button
											variant="contained"
											fullWidth
											size="large"
											startIcon={<CheckIcon />}
											disabled={isMinus && number > addCashBody.balance}
											sx={{
												background: "#037703",
												marginLeft: "none",
												"&:hover": {
													background: "#005f00",
												},
											}}
											onClick={async () => setCloseStep(2)}
										>
											Да
										</Button>
									</DialogActions>
								</>
							)
						}
						{
							closeStep === 2
							&& (
								<>
									<DialogContent>
										<DialogContentText className={styles.title}>
											Выберите валюты по которым имеются излишки/недостачи
										</DialogContentText>
									</DialogContent>
									<div style={{ padding: "0 20px", display: "grid", gap: "10px", justifyContent: "center" }}>
										{currencyList && overOrShortageMoneyList.map((item, index) => (
											<div key={item.currencyCode} style={{ display: "grid", gridTemplateColumns: "1fr 1fr 50px", gap: "10px", alignItems: "center" }}>
												<Autocomplete
													disableClearable
													fullWidth
													options={currencyList.filter(currency => {
														let bool = true;
														for (const item of overOrShortageMoneyList) {
															if (currency.code === item.currencyCode) {
																bool = false;
																break;
															}
														}
														return bool;
													}).map(currency => currency.code)}
													value={item.currencyCode}
													onChange={(e, newValue: string) => setOverOrShortageMoneyList((state) => {
														const newState = [...state];
														newState[index] = { currencyCode: newValue, factBalance: 0, systemBalance: currencyList.find(cur => cur.code === newValue)?.cash?.balance || 0 };
														return newState;
													})}
													renderInput={(params) => <TextField label={`Выберите валюту`} required {...params} />}
													className={styles.textField}
													sx={{
														background: "white",
														borderRadius: "4px",
														height: "100%",
														"& .MuiFormControl-root": {
															height: "100%"
														},
														"& .MuiInputBase-root": {
															height: "100%"
														},
														"& input": {
															textAlign: "center",
															fontSize: "15px",
														},
														"& fieldset": {
															textAlign: "center",
														},
														"& label": {
															width: "100%",
															textAlign: "center",
															transformOrigin: "top center",
															left: "-13px",
														},
														"& .Mui-disabled": {
															color: "black !important",
														},
														"& .MuiOutlinedInput-root": {
															padding: "3px !important",
														},
													}}
												/>
												<NumberFormat
													customInput={TextField}
													//Свойства TextField Mui5
													autoComplete={"off"}
													fullWidth
													disabled={!item.currencyCode}
													value={item.factBalance ? item.factBalance : ""}
													label={"Укажите фактическую сумму на кассе по выбранной валюте"}
													variant="outlined"
													className={styles.textField}
													onChange={(e) => {
														const num = +(e.target.value.replace(/\s/g, ""));
														setOverOrShortageMoneyList((state) => {
															const newState = [...state];
															newState[index] = { ...state[index], factBalance: num };
															return newState;
														});
													}}
													inputProps={{
														inputmode: "numeric",
													}}
													//Свойства NumberFormat
													thousandSeparator={" "}
													allowNegative={false}
													decimalScale={2}
													fixedDecimalScale={true}
												/>
												<Tooltip title="Удалить валюту" placement="top">
													<IconButton
														className={styles.deleteBtn + " " + styles.iconBtn}
													>
														<DeleteIcon
															onClick={() => setOverOrShortageMoneyList(state => [...state.filter(i => i.currencyCode !== item.currencyCode)])}
														/>
													</IconButton>
												</Tooltip>
											</div>
										))}
										{currencyList && overOrShortageMoneyList.length < currencyList?.length &&
											<Tooltip title="Добавить валюту" placement="top">
												<IconButton
													className={styles.addBtn + " " + styles.iconBtn}
													onClick={() => setOverOrShortageMoneyList(state => [...state, { currencyCode: "", factBalance: 0, systemBalance: 0 }])}
												>
													<AddCircleOutlineIcon />
												</IconButton>
											</Tooltip>
										}
									</div>
									<DialogActions className={styles.btn_group}>
										<Button
											variant="contained"
											fullWidth
											size="large"
											startIcon={<ClearIcon />}
											sx={{
												background: "#b70000",
												"&:hover": {
													background: "#7f0101",
												},
											}}
											onClick={() => setCloseStep(1)}
										>
											Нет
										</Button>
										<Button
											variant="contained"
											fullWidth
											size="large"
											startIcon={<CheckIcon />}
											disabled={isMinus && number > addCashBody.balance}
											sx={{
												background: "#037703",
												marginLeft: "none",
												"&:hover": {
													background: "#005f00",
												},
											}}
											onClick={async () => {
												if (kassa) {
													const doc = actOfSurplusAndShortage(overOrShortageMoneyList, kassa, user, department);
													pdfMake.createPdf(doc).open();
												}
											}}
										>
											Да
										</Button>
									</DialogActions>
								</>
							)
						}
					</>
				)}
		</Dialog>
	);
};
import React from "react";

import { Htag } from '@/components';
import orderDocument from './DynamicDocuments/OrderDocument';
import { OrdersTableProps } from "./OrdersTable.props";
import { Button } from '@mui/material';
import pdfMake from "pdfmake/build/pdfmake";
import styles from "./ordersTable.module.css";

export const OrdersTable = ({
	activeDepartment,
	dates,
	logList,
	className,
	...props
}: OrdersTableProps): JSX.Element => (
	<div
		className={className + " " + styles.container}
		{...props}
	>
		<Htag tag='h2'>
			Распоряжения из отделения {activeDepartment?.code}, {activeDepartment?.address} за период {dates?.startDate} - {dates?.endDate}
		</Htag>

		<div style={{ display: "flex", justifyContent: "center" }}>
			<Button
				variant={"contained"}
				onClick={() => {
					const doc = orderDocument(logList, activeDepartment);
					pdfMake.createPdf(doc).open();
				}}
				sx={{
					marginBottom: "10px",
				}}>
				Распечатать расспоряжения
			</Button>
		</div>

		<div>
			<div className={styles.table_row}>
				<p >№</p>
				<p>Время</p>
			</div>

			{
				logList.map(log => (
					<div
						key={log.logId}
						className={styles.table_row}
						onClick={() => {
							const doc = orderDocument(logList.filter(item => log.logId === item.logId), activeDepartment);
							pdfMake.createPdf(doc).open();
						}}
					>
						<p>{log.logId}</p>
						<p>{new Date(log.actionDate).toLocaleString()}</p>
					</div>
				))
			}
		</div>
	</div>
);
import { User } from "@/api/CamundaController";
import { generateUUID } from "@/core/helpers/Utils";
import {
    Avatar,
    Button,
    Card,
    Stack,
    TextField
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CommentModel } from "./Comment";

interface AddReplyProps {
    onAddReply: (model: CommentModel) => void;
    me: User;
    parent: CommentModel;
}

const AddReply = (props: AddReplyProps) => {
    const { t } = useTranslation();

    const { onAddReply, me, parent } = props;

    const [replyText, setReplyText] = useState("");

    const handleAdd = () => {
        onAddReply({
            id: generateUUID(),
            parentId: parent.id,
            parentUser: parent.who,
            text: replyText,
            date: new Date().toISOString(),
            who: { login: me.login, fullName: me.fullName },
            process: parent.process,
            isPublic: parent.isPublic
        });
    };

    const avatar =
        me?.photo?.data && `data:image/*;base64, ${me?.photo?.data}`;

    return (
        <Card>
            <Box sx={{ p: "15px" }}>
                <Stack direction="row" spacing={2} alignItems="flex-start">
                    <Avatar
                        src={avatar}
                        variant="rounded"
                        alt="user-avatar"
                    />
                    <TextField
                        multiline
                        fullWidth
                        minRows={4}
                        id="outlined-multilined"
                        placeholder={t("comment.addcom") || ''}
                        value={replyText}
                        onChange={(e) => {
                            setReplyText(e.target.value);
                        }}
                    />
                    <Button
                        size="large"
                        sx={{
                            bgcolor: "custom.moderateBlue",
                            color: "neutral.white",
                            p: "8px 25px",
                            "&:hover": {
                                bgcolor: "custom.lightGrayishBlue",
                            },
                        }}
                        onClick={(e) => {
                            !replyText.trim() ?
                                e.preventDefault() :
                                handleAdd();
                            setReplyText("");
                        }}
                    >
                        {t("comment.reply")}
                    </Button>
                </Stack>
            </Box>
        </Card>
    );
};

export default AddReply;
import authorizedLayout from "@/components/AuthorizedLayout";
import { Routes, Route } from "react-router-dom";
import CalendarList from "./CalendarList";
import CalendarCreate from "./CalendarCreate";

const Calendars = () => (
    <Routes>
        <Route
            path=''
            element={authorizedLayout(() => CalendarList())}
        />
        <Route
            path='create'
            element={authorizedLayout((props: any) => CalendarCreate(props))}
        />
        <Route
            path=':id'
            element={authorizedLayout((props: any) => CalendarCreate(props && { formState: "read" }))}
        />
    </Routes>
);

export default Calendars;

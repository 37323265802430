import { User } from "@/api/CamundaController";
import { Stack } from "@mui/material";
import AddReply from "./AddReply";
import { CommentModel } from "./Comment";
import Reply from "./Reply";

interface RepliesSection {
    replies: CommentModel[];
    parent: CommentModel;
    clicked: boolean;
    me: User;
    readonly: boolean;
    onDelete: (id: string) => void;
    onAddReply: (data: CommentModel) => void;
}

const RepliesSection = (props: RepliesSection) => {
    const { replies, clicked, me, parent, readonly, onDelete, onAddReply } = props;

    return (
        <Stack spacing={2} alignSelf="flex-end">
            {replies.map((rep) => <Reply reply={rep} me={me} readonly={readonly} onDelete={onDelete} />)}
            {clicked && !readonly && <AddReply onAddReply={onAddReply} me={me} parent={parent} />}
        </Stack>
    );
};

export default RepliesSection;
import React from "react";
import { Routes, Route } from "react-router-dom";
import RequestListPage from "./RequestListPage/RequestListPage";
import { wizard } from '@/hoc';

const RequestList = () => (
  <Routes>
    <Route path="" element={wizard(RequestListPage, 'История операций')} />
  </Routes>
);

export default RequestList;

import { ProcessDefinitionInfo, TaskFormComponent } from "@/api/CamundaController";
import { Button, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import DynamicAdd from "./DynamicAdd";
import processManager from '@/core/helpers/ProcessManager';
import ComponentsGen from "./ComponentsGen";
import { useTranslation } from "react-i18next";

interface ProcessFormGenProps {
    process?: ProcessDefinitionInfo;
}


const ProcessFormGen = (props: ProcessFormGenProps) => {
    const { t } = useTranslation();
    const { process } = props;
    const form = process?.form;
    const [components, setComponents] = useState<Array<TaskFormComponent>>(form?.components || []);

    const startProcess = () => {
        processManager.start({
            processDefinitionKey: process?.key,
            variables: processManager.getTaskModelVariables()
        });
    };

    return (
        <Paper sx={{ width: '100%', mb: 2, pb: 2 }}>
            <Grid sx={{ p: 2 }}>
                <Typography component="h2" variant="h6">
                    {process?.name}
                </Typography>
                <Box component="form" onSubmit={() => startProcess()} sx={{ mt: 3, mb: 3 }}>
                    <Grid container spacing={2}>
                        <ComponentsGen components={components} setComponents={setComponents} />
                        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start" sx={{ mt: 4 }}>
                            <Stack spacing={2} direction={'row'}>
                                <React.Fragment>
                                    <Button variant='contained' type="submit">{t("form.create")}</Button>
                                </React.Fragment>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
                <Divider />
                <DynamicAdd components={components} setComponents={setComponents} />
            </Grid>
        </Paper >);
};

export default ProcessFormGen;
import React from "react";

import { Htag } from '@/components';

import { useBalancePage } from './useBalancePage';
import { DepartmentCash, ModalCashAction, CashierExchangePage } from './components';
import { Button } from '@mui/material';

const BalancePage = () => {

  const {
    variables,
    handleOpenWindow,
    openWindow,
    handleCloseWindow,
    addCashBody,
    setAddCashBody,
    loadUpdateCash,
    isMinus,
    handleCloseKassa,
    user,
    loadAddCash,
    handleTransferCashBetweenKassa,
  } = useBalancePage();

  return (
    <>
      {openWindow &&
        <ModalCashAction
          openWindow={openWindow}
          handleCloseWindow={handleCloseWindow}
          addCashBody={addCashBody}
          setAddCashBody={setAddCashBody}
          loadUpdateCash={loadUpdateCash}
          isMinus={isMinus}
          handleCloseKassa={handleCloseKassa}
          currencyList={variables.department?.currencies}
          kassa={variables.kassa}
          department={variables.department}
          user={user}
          loadAddCash={loadAddCash}
          handleTransferCashBetweenKassa={handleTransferCashBetweenKassa}
        />
      }
      <Htag tag={'h2'} style={{ textAlign: "center", gridColumn: "span 2", fontWeight: "bold", fontSize: "20px" }}>г.{variables.department?.code} {variables.department?.address} касса №{variables.kassa.name}</Htag>

      <DepartmentCash
        key={variables.kassa?.id}
        kassa={variables.kassa}
        handleOpenWindow={handleOpenWindow}
      />

      <CashierExchangePage
        activeKassa={variables.kassa}
        activeDepartment={variables.department}
        loadAddCash={loadAddCash}
      />

      <Button
        variant={"contained"}
        sx={{
          display: "block",
          margin: "20px auto",

          background: "#b90202",
          "&:hover": {
            backgroundColor: "#920000",
          },
        }}
        onClick={() => handleOpenWindow(variables.kassa, true, "closeKassa")}
      >
        Закрыть кассу
      </Button>
    </>
  );
};

export default BalancePage;
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from "@mui/material/styles";

const BtnOrange = styled(Button)<ButtonProps>(() => ({
	backgroundColor: "#fd5f00",
	fontWeight: "bold",
	'&:hover': {
		backgroundColor: "#005792",
		color: "white"
	},
}));

export default BtnOrange;
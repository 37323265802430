import { server } from '../core/server/axios';
import { Pagination } from './CamundaController';

const wenv = process.env;

export class SlaMeasure {
  id = '';
  name = '';
  process = '';
  starts: Array<SlaEvent> = [];
  ends: Array<SlaEvent> = [];
  expectations: Array<SlaExpectation> = new Array(new SlaExpectation());
}

export class SlaEvent {
  event = '';
  activity = '';
}

export class SlaExpectation {
  filter?: Object;
  calendarId = '';
  expectation = '';
}

export class SlaVariable {
  id = '';
  name = '';
  company = '';
  startTime = '';
  endTime = '';
  actualEndTime?: string;
}

export class SlaController {

  async create(calendar: SlaMeasure): Promise<any> {
    return await server.post(`/sonata/api/sla`, calendar, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async get(id?: string): Promise<SlaMeasure> {
    return await server.get(`/sonata/api/sla/${id}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async delete(id?: string) {
    return await server.delete(`/sonata/api/sla/${id}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async getSlaContains(
    pageNumber: number,
    pageSize: number,
    name: string,
    code: string,
    process: string
  ): Promise<Pagination<SlaMeasure>> {
    return server.get(
      `/sonata/api/sla/${pageNumber}/${pageSize}?name=${name}&code=${code}&process=${process}`,
      {
        baseURL: wenv.REACT_APP_BACKEND
      }
    );
  }
}

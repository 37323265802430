import authorizedLayout from "@/components/AuthorizedLayout";
import { Routes, Route } from "react-router-dom";
import QueuesList from "./QueuesList";
import QueueCreate from "./QueueCreate";

const Queues = () => (
    <Routes>
        <Route
            path=''
            element={authorizedLayout(() => QueuesList())}
        />
        <Route
            path='create/:ord'
            element={authorizedLayout((props: any) => QueueCreate(props))}
        />
        <Route
            path=':id'
            element={authorizedLayout((props: any) => QueueCreate(props && { formState: "read" }))}
        />
    </Routes>
);

export default Queues;

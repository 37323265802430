import { BatchController } from "./BatchController";
import { CalendarController } from "./CalendarController";
import { CamnundaController } from "./CamundaController";
import { KassaController } from './KassaController';
import { CurrencyController } from './CurrencyController';
import { FileController } from "./FileController";
import { DepartmentCotroller } from './DepartmentCotroller';
import { HistoryController } from "./HistoryController";
import { QueryController } from "./QueryController";
import { ReportController } from "./ReportController";
import { SlaController } from "./SlaController";
import { ParsingController } from './ParsingController';
import { RateController } from './RateController';
import { LogsController } from './LogsController';
import { RequestController } from './RequestController';


export class Api {
  camunda = new CamnundaController();
  file = new FileController();
  calendar = new CalendarController();
  sla = new SlaController();
  history = new HistoryController();
  batch = new BatchController();
  report = new ReportController();
  query = new QueryController();

  department = new DepartmentCotroller();
  currency = new CurrencyController();
  kassa = new KassaController();
  parsing = new ParsingController();
  rate = new RateController();
  logs = new LogsController();
  request = new RequestController();
}

export const api = new Api();

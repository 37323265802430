import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Box, Button, FormControlLabel, IconButton, Input, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { EventAvailable, Search } from '@mui/icons-material';
import { api } from '@/api/Api';
import React, { useEffect, useState } from 'react';
import { Delete } from '@mui/icons-material';
import processManager from '@/core/helpers/ProcessManager';
import { Calendar } from '@/api/CalendarController';
import { useTranslation } from 'react-i18next';


const CalendarList = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [content, setContent] = useState([] as Calendar[]);
    const [pageSize, setPageSize] = useState(10);
    const showMoreSize = 10;
    const [lastPage, setLastPage] = useState(true);
    const [name, setName] = useState("");
    const [dense, setDense] = React.useState(true);

    const onShowMore = () => {
        api.calendar.getCalendarContains(0, pageSize + showMoreSize, name, name).then((m) => {
            setContent(m.content);
            setLastPage(m.last);
            setPageSize(pageSize + showMoreSize);
        });
    };

    const deleteCalendar = (calendar: Calendar) => {
        processManager.openConfirm(() => {
            api.calendar.delete(calendar.id).then(() => {
                setContent(content.filter((m) => m.id !== calendar.id));
                processManager.openSnackBar(t("cal.list.deletesuccess"));
            });
        }, `${t("cal.list.deleteconfirm")} ${calendar.name}?`);
    };

    useEffect(() => {
        api.calendar.getCalendarContains(0, pageSize, name, name).then((m) => {
            setContent(m.content);
            setLastPage(m.last);
        });
    }, [name]);

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const onClick = (calendar: Calendar) => {
        navigate(`/calendars/${calendar.id}`);
    };

    return (
        <Grid container spacing={2}>
            <Grid>
                <Toolbar>
                    <Button variant="contained" component={Link} to='/calendars/create' startIcon={<EventAvailable />}>{t("cal.list.create")}</Button>
                </Toolbar>
            </Grid>
            {/* Chart */}
            <Grid item xs={12} md={12} lg={12}>
                <React.Fragment>
                    <Box sx={{ width: '100%' }}>
                        <Paper sx={{ width: '100%', mb: 2 }}>
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Id
                                            </TableCell>
                                            <TableCell>
                                                <Input
                                                    type="search"
                                                    placeholder={t("cal.list.name") || ''}
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    size='small'
                                                />
                                                <Search />
                                            </TableCell>
                                            <TableCell>#</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {content.map(((cont) => (
                                            <TableRow key={cont.id} onClick={() => onClick(cont)}>
                                                <TableCell>{cont.id}</TableCell>
                                                <TableCell>{cont.name}</TableCell>
                                                <TableCell style={{ width: "88px" }}>
                                                    <IconButton aria-label="delete"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            deleteCalendar(cont);
                                                        }}>
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br />
                            {!lastPage && (
                                <div
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => onShowMore()}>
                                    <b>
                                        <u>{t("cal.list.showmore")}</u>
                                    </b>
                                </div>
                            )}
                        </Paper>
                        <FormControlLabel
                            control={<Switch checked={dense} onChange={handleChangeDense} />}
                            label={t("cal.list.showmore")}
                        />
                    </Box>
                </React.Fragment>
            </Grid>
        </Grid>);
};

export default CalendarList;
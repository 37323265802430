import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Box, Button, Card, Chip, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Toolbar, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { Add, AddCircle, Clear, HistoryToggleOff, PlaylistAdd } from '@mui/icons-material';
import { api } from '@/api/Api';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import processManager from '@/core/helpers/ProcessManager';
import { SlaExpectation, SlaMeasure } from '@/api/SlaController';
import iassign from 'immutable-assign';
import { CodeValue, ProcessDefinitionInfo } from '@/api/CamundaController';
import { Stack } from '@mui/system';
import { CalendarPicker } from '@/components';
import { useTranslation } from 'react-i18next';


const SlaCreate = (props: any) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [sla, setSla] = useState(new SlaMeasure());
    const [bpList, setBpList] = useState<Array<ProcessDefinitionInfo>>([]);
    const [endEvent, setEndEvent] = useState('');
    const [endActivity, setEndActivity] = useState('');
    const [startEvent, setStartEvent] = useState('');
    const [startActivity, setStartActivity] = useState('');
    const [code, setCode] = useState('');
    const [value, setValue] = useState('');

    const { formState } = props;

    useEffect(() => {
        api.camunda.getFullBpList().then(list => setBpList(list));

        if (formState && id) {
            api.sla.get(id).then((c) => {
                setSla(c);
            });
        }
    }, []);

    const enumarate = (exp: SlaExpectation) => {
        var keyVal: Array<CodeValue> = [];
        for (const key in exp.filter) {
            if (exp.filter.hasOwnProperty(key)) {
                keyVal.push({ code: key, value: exp.filter[key as keyof typeof exp.filter].toString() });
            }
        }

        return keyVal.map((k, i) => <Chip key={k.code} label={`${k.code}: ${k.value}`} onDelete={() =>
            setSla(
                iassign(
                    sla,
                    s => s.expectations,
                    s => s.map((ee) => {
                        if (ee == exp) {
                            var newE = { ...ee };
                            newE.filter = keyVal.filter((kv, kvi) => kvi != i).reduce((result, item) => {
                                result = Object.assign(result, { [item.code]: item.value });
                                return result;
                            }, {});
                            return newE;
                        }
                        return ee;
                    })
                )
            )
        } />);
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        api.sla.create(sla).then(() => {
            processManager.openSnackBar(formState ? t("sla.create.successupdate") : t("sla.create.successcreate"));
            navigate('/slas');
        });
    };

    return (
        <Grid container spacing={2}>
            <Grid xs={12} md={8} lg={8}>
                <Toolbar>
                    <Button variant="contained" component={Link} to='/slas' startIcon={<HistoryToggleOff />}>{t("sla.create.cancel")}</Button>
                </Toolbar>
            </Grid>
            {/* Chart */}
            <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <Grid sx={{ p: 2 }}>
                        <Typography component="h2" variant="h6">
                            {formState ? t("sla.create.slaupdate") : t("sla.create.slacreate")}
                        </Typography>
                        <br />
                        <Box component="form" onSubmit={onSubmit} sx={{ width: '100%' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                    <TextField
                                        fullWidth
                                        required
                                        size='small'
                                        label={t("sla.create.name")}
                                        placeholder={t("sla.create.namehelper") || ''}
                                        value={sla.name}
                                        onChange={(e) =>
                                            setSla({ ...sla, name: e.target.value })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={3} sm={3}>
                                    <FormControl fullWidth size='small' required>
                                        <InputLabel id="process-label">{t("sla.create.process")}</InputLabel>
                                        <Select
                                            size='small'
                                            labelId="process-label"
                                            id="process-select"
                                            value={sla.process}
                                            label={t("sla.create.process")}
                                            onChange={(e) =>
                                                setSla({ ...sla, process: e.target.value })
                                            }
                                        >
                                            {bpList.map((p) => <MenuItem key={p.key} value={p.key}>{p.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                            <Card sx={{ p: 2 }} variant="outlined">
                                <Typography component="h2" variant="h6">
                                    {t("sla.create.bpmn")}
                                </Typography>
                                <br />
                                <Grid container spacing={2} justifyItems="flex-start" alignItems="flex-start">
                                    <Grid item container spacing={2} xs={6} sm={6}>
                                        <Grid item xs={12} sm={12}>
                                            <Typography>
                                                {t("sla.create.start")}
                                            </Typography>
                                        </Grid>
                                        {sla.starts.map((e, i) =>
                                            <Fragment>
                                                <Grid item xs={10} sm={10}>
                                                    <TextField
                                                        fullWidth
                                                        key={i}
                                                        size='small'
                                                        value={`${e.event}: ${e.activity}`}
                                                        inputProps={{ "aria-readonly": true }}
                                                    />
                                                </Grid>
                                                <Grid item xs={2} sm={2}>
                                                    <IconButton onClick={() => setSla(
                                                        iassign(
                                                            sla,
                                                            s => s.starts,
                                                            s => s.filter((e, ei) => i != ei)
                                                        ))}>
                                                        <Clear color='error' />
                                                    </IconButton>
                                                </Grid>
                                            </Fragment>
                                        )}
                                        <Grid item xs={5} sm={5}>
                                            <FormControl fullWidth size='small' required={sla.ends.length == 0}>
                                                <InputLabel id="lang-select-label">{t("sla.create.startevent")}</InputLabel>
                                                <Select
                                                    size='small'
                                                    labelId="lang-select-label"
                                                    id="lang-select"
                                                    value={startEvent}
                                                    label={t("sla.create.startevent")}
                                                    onChange={(e) => setStartEvent(e.target.value)}
                                                >
                                                    <MenuItem value={'start'}>{'Start: NonUserTask'}</MenuItem>
                                                    <MenuItem value={'end'}>{'End: NonUserTask'}</MenuItem>
                                                    <MenuItem value={'create'}>{'Create: UserTask'}</MenuItem>
                                                    <MenuItem value={'assignment'}>{'Assignment: UserTask'}</MenuItem>
                                                    <MenuItem value={'complete'}>{'Complete: UserTask'}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={5} sm={5}>
                                            <TextField
                                                fullWidth
                                                required={sla.ends.length == 0}
                                                size='small'
                                                label={t("sla.create.startactivity")}
                                                helperText={t("sla.create.starthelper")}
                                                value={startActivity}
                                                onChange={(e) =>
                                                    setStartActivity(e.target.value)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={2} sm={2}>
                                            <IconButton onClick={() => {
                                                if (startEvent.length > 0 && startActivity.length > 0) {
                                                    setSla(
                                                        iassign(
                                                            sla,
                                                            s => s.starts,
                                                            s => s.concat([{ event: startEvent, activity: startActivity }])
                                                        ));
                                                    setEndEvent('');
                                                    setEndActivity('');
                                                }
                                            }}>
                                                <Add />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid item container spacing={2} xs={6} sm={6}>
                                        <Grid item xs={12} sm={12}>
                                            <Typography>
                                                {t("sla.create.end")}
                                            </Typography>
                                        </Grid>
                                        {sla.ends.map((e, i) =>
                                            <Fragment>
                                                <Grid item xs={10} sm={10}>
                                                    <TextField
                                                        fullWidth
                                                        key={i}
                                                        size='small'
                                                        value={`${e.event}: ${e.activity}`}
                                                        inputProps={{ "aria-readonly": true }}
                                                    />
                                                </Grid>
                                                <Grid item xs={2} sm={2}>
                                                    <IconButton onClick={() => setSla(
                                                        iassign(
                                                            sla,
                                                            s => s.ends,
                                                            s => s.filter((e, ei) => i != ei)
                                                        ))}>
                                                        <Clear color='error' />
                                                    </IconButton>
                                                </Grid>
                                            </Fragment>
                                        )}
                                        <Grid item xs={5} sm={5}>
                                            <FormControl fullWidth size='small' required={sla.ends.length == 0}>
                                                <InputLabel id="lang-select-label">{t("sla.create.endevent")}</InputLabel>
                                                <Select
                                                    size='small'
                                                    labelId="lang-select-label"
                                                    id="lang-select"
                                                    value={endEvent}
                                                    label={t("sla.create.endevent")}
                                                    onChange={(e) => setEndEvent(e.target.value)}
                                                >
                                                    <MenuItem value={'start'}>{'Start: NonUserTask'}</MenuItem>
                                                    <MenuItem value={'end'}>{'End: NonUserTask'}</MenuItem>
                                                    <MenuItem value={'create'}>{'Create: UserTask'}</MenuItem>
                                                    <MenuItem value={'assignment'}>{'Assignment: UserTask'}</MenuItem>
                                                    <MenuItem value={'complete'}>{'Complete: UserTask'}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={5} sm={5}>
                                            <TextField
                                                fullWidth
                                                required={sla.ends.length == 0}
                                                size='small'
                                                label={t("sla.create.endevent")}
                                                helperText={t("sla.create.endhelper")}
                                                value={endActivity}
                                                onChange={(e) =>
                                                    setEndActivity(e.target.value)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={2} sm={2}>
                                            <IconButton onClick={() => {
                                                if (endEvent.length > 0 && endActivity.length > 0) {
                                                    setSla(
                                                        iassign(
                                                            sla,
                                                            s => s.ends,
                                                            s => s.concat([{ event: endEvent, activity: endActivity }])
                                                        ));
                                                    setEndEvent('');
                                                    setEndActivity('');
                                                }
                                            }}>
                                                <Add />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                            <br />
                            <Card sx={{ p: 2 }} variant="outlined">
                                <Typography component="h2" variant="h6">
                                    {t("sla.create.expectation")}<IconButton
                                        onClick={() =>
                                            setSla(
                                                iassign(
                                                    sla,
                                                    s => s.expectations,
                                                    s => s.concat([new SlaExpectation()])
                                                )
                                            )
                                        }><AddCircle /></IconButton>
                                </Typography>
                                <br />

                                {sla.expectations.map((exp, i) =>
                                    <Card sx={{ p: 2, mb: 2 }} key={i} variant='outlined'>
                                        <Grid container spacing={2} justifyItems="flex-start" alignItems="flex-start">
                                            <Grid item xs={6} sm={6}>
                                                <Card sx={{ p: 2 }} variant='outlined'>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={12}>
                                                            <Typography>
                                                                {t("sla.create.filter")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12}>
                                                            <Stack direction='row' spacing={0} sx={{ flexWrap: 'wrap', gap: 1 }}>
                                                                {enumarate(exp)}
                                                            </Stack>
                                                        </Grid>
                                                        <Grid item xs={5} sm={5}>
                                                            <TextField
                                                                size='small'
                                                                value={code}
                                                                label={t("sla.create.key")}
                                                                onChange={(e) => setCode(e.target.value)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={5} sm={5}>
                                                            <TextField
                                                                fullWidth
                                                                size='small'
                                                                value={value}
                                                                label={t("sla.create.value")}
                                                                onChange={(e) => setValue(e.target.value)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2} sm={2}>
                                                            <IconButton onClick={() => {
                                                                setSla(
                                                                    iassign(
                                                                        sla,
                                                                        s => s.expectations,
                                                                        s => s.map((ee, ei) => {
                                                                            if (ei == i) {
                                                                                var newE = { ...ee };
                                                                                newE.filter = Object.assign({}, newE.filter, { [code]: value });
                                                                                return newE;
                                                                            }
                                                                            return ee;
                                                                        })
                                                                    )
                                                                );
                                                            }}>
                                                                <PlaylistAdd />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={3} sm={3}>
                                                <CalendarPicker defaultValue={exp.calendarId} onSelectedItem={(c) =>
                                                    setSla(
                                                        iassign(
                                                            sla,
                                                            s => s.expectations,
                                                            s => s.map((ee, ei) => {
                                                                if (ei == i) {
                                                                    var newE = { ...ee };
                                                                    newE.calendarId = c.id || '';
                                                                    return newE;
                                                                }
                                                                return ee;
                                                            })
                                                        )
                                                    )} />
                                            </Grid>
                                            <Grid item xs={3} sm={3}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    size='small'
                                                    label={t("sla.create.exp")}
                                                    placeholder={t("sla.create.expsample") || ''}
                                                    helperText={t("sla.create.expstandard")}
                                                    value={exp.expectation}
                                                    onChange={(e) =>
                                                        setSla(
                                                            iassign(
                                                                sla,
                                                                s => s.expectations,
                                                                s => s.map((ee, ei) => {
                                                                    if (ei == i) {
                                                                        var newE = { ...ee };
                                                                        newE.expectation = e.target.value;
                                                                        return newE;
                                                                    }
                                                                    return ee;
                                                                })
                                                            )
                                                        )
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={3} sm={3}>
                                                <Button color='primary' onClick={() => {
                                                    setSla(
                                                        iassign(
                                                            sla,
                                                            s => s.expectations,
                                                            s => s.map((ee, ei) => {
                                                                if (ei == i) {
                                                                    return new SlaExpectation();
                                                                }
                                                                return ee;
                                                            })
                                                        )
                                                    );
                                                }}>{t("sla.create.reset")}</Button>
                                                <Button color='error' onClick={() => {
                                                    setSla(
                                                        iassign(
                                                            sla,
                                                            s => s.expectations,
                                                            s => s.filter((f, fi) => fi != i)
                                                        )
                                                    );
                                                }}>{t("sla.create.delete")}</Button>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                )}

                            </Card>
                            <br />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {t("sla.create.save")}
                            </Button>
                        </Box>
                    </Grid>
                </Paper>
            </Grid >
        </Grid >);
};

export default SlaCreate;
import React, { useMemo } from "react";

import { SelectDepartments } from '@/components';

import { DepartmentCash } from '../../components';
import { useCashListPage } from './useCashListPage';
import styles from './CashListPage.module.css';

const CashListPage = () => {

	const {
		activeKassa,
		kassaIsOpen,
		handleOpenKassa,
		departmentStatus,
		regions,
	} = useCashListPage();

	const filtredCashboxById = useMemo(() => (
		// activeDepartment && activeDepartment.deps.map(kassa => (
		activeKassa && (
			<DepartmentCash
				key={activeKassa?.id}
				handleOpenKassa={handleOpenKassa}
			/>
		)
		// ))
	), [activeKassa?.id, kassaIsOpen]);

	return (
		<>
			{departmentStatus === "resolve" && (
				<div className={styles.container}>
					<SelectDepartments regions={regions} className={styles.row} />
					{filtredCashboxById}
				</div>
			)}
		</>
	);
};

export default CashListPage;
import { api } from "@/api/Api";
import { ProcessDefinitionInfo } from "@/api/CamundaController";
import { updateTaskVariables } from "@/redux/modules/Process";
import { Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

interface ProcessListProps {
    selectedProcess: ProcessDefinitionInfo;
    handleProcessSelect: (process?: ProcessDefinitionInfo) => void;
}

type Var = { [key: string]: any };

const ProcessList = (props: ProcessListProps) => {

    const { handleProcessSelect, selectedProcess: selecttedProcess } = props;
    const [selected, setSelected] = useState(false);
    const dispatch = useDispatch();

    const [processList, setProcessList] = useState([] as ProcessDefinitionInfo[]);

    useEffect(() => {
        api.camunda
            .getBpList()
            .then(
                (procs) => {
                    setProcessList(procs);
                },
                (error) => {
                    console.log(error);
                }
            )
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        if (selecttedProcess.form) {
            const variables: Var = {};
            selecttedProcess.form.components?.forEach(c => {
                if (c.type === 'checklist') {
                    variables[c.key!] = c.values?.map((v) => ({ key: v.value!, val: false })) || [];
                } else {
                    variables[c.key!] = c.defaultValue;
                }
            });
            dispatch(updateTaskVariables((g) => g, (p) => Object.assign(p, variables)));
        }
    }, [selecttedProcess]);

    return (
        <Stack spacing={2} direction="row">
            {processList?.map((row, index) => (
                <Button
                    key={index}
                    variant={
                        row.id == selecttedProcess.id
                            ? 'contained'
                            : 'outlined'
                    }
                    onClick={() => {
                        if (!selected) {
                            handleProcessSelect(row);
                            setSelected(true);
                        } else {
                            handleProcessSelect();
                            setSelected(false);
                        }
                    }}
                >
                    {row?.name}
                </Button>
            ))}
        </Stack>
    );
};

export default ProcessList;
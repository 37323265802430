import { FlowButton, Queue, Task, User } from "@/api/CamundaController";
import { MoreVert } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { alpha } from '@mui/material/styles';
import processManager from "@/core/helpers/ProcessManager";
import { api } from "@/api/Api";
import AuthUtil from "@/core/helpers/AuthUtil";
import { useTranslation } from "react-i18next";

interface TaskTableButtonsProps {
    selectedUserQueue?: Queue
    selected: string[];
    me: User;
}

const TaskTableButtons = (props: TaskTableButtonsProps) => {
    const { t } = useTranslation();
    const {
        selected,
        selectedUserQueue,
        me
    } = props;

    const numSelected = selected.length;
    const [taskId, setTaskId] = useState(numSelected > 0 ? selected[0].split(':')[1] : undefined);
    const [task, setTask] = useState<Task>();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (taskId) {
            api.camunda.getTask(taskId)
                .then(t => setTask(t))
                .catch(e => console.log("TaskTableButtons", e));
        }
    }, [taskId]);

    useEffect(() => {
        const t = numSelected > 0 ? selected[0].split(':')[1] : undefined;
        if (t != taskId) {
            setTaskId(t);
        }
    }, [selected]);

    const deleteProcs = () => {
        processManager.openConfirm(() => {
            api.batch.batchCancel({ processInstanceIds: selected.map(s => s.split(':')[0]) }).then(batch => {
                processManager.openSnackBar(`${t("task.deletesuccess")} ${batch.id}`);
                window.location.assign("/app/queue");
            });

        }, t("task.deleteconfirm", { numSelected: numSelected }));
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (btn: FlowButton) => {
        const buttons = task?.buttons || [];
        processManager.openConfirm(() => {
            let obj = new Object();
            buttons?.forEach((value) => {
                obj = Object.assign(obj, { [value.id!]: value.id === btn.id });
            });
            api.batch.batchTransitionVariables({
                processDefinitionKey: task?.processDefinitionId?.split(':')[0] || '',
                processInstanceIds: selected.map(s => s.split(':')[0]),
                startTransition: btn.id || '',
                cancelActivity: task?.definitionKey || '',
                variables: Object.assign({}, { button: obj }, { assignee: me.login })
            }).then(() => {
                handleClose();
                processManager.openSnackBar(t("task.updatesuccess"));
                window.location.assign("/app/queue");
            });
        }, t("task.updateconfirm"), btn.label || '');
    };

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {t(selectedUserQueue?.name || '')}
                </Typography>
            )}
            {numSelected > 0 ? (
                <Fragment>
                    {AuthUtil.hasRole("ADMIN") &&
                        <Tooltip title="Delete" >
                            <IconButton onClick={deleteProcs}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    <Tooltip title="Delete" >
                        <Fragment>
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? 'long-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                            >
                                <MoreVert />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    style: {
                                        maxHeight: 48 * 4.5,
                                        width: '20ch',
                                    },
                                }}
                            >
                                {task?.buttons?.map((option, oi) => (
                                    <MenuItem key={oi} onClick={() => handleSelect(option)}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Fragment>
                    </Tooltip>
                </Fragment>
            ) : (
                <React.Fragment>
                    {/* <Tooltip title="Filter list">
                        <IconButton>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip> */}
                </React.Fragment>
            )}
        </Toolbar>
    );
};

export default TaskTableButtons;
import authorizedLayout from "@/components/AuthorizedLayout";
import { Routes, Route } from "react-router-dom";
import OpenHistoryTask from "./OpenHistoryTask";
import ProcList from "./ProcessHistoryList";
import TaskHistoryList from "./TaskHistoryList";

const Roles = () => (
    <Routes>
        <Route
            path=''
            element={authorizedLayout(() => ProcList())}
        />
        <Route
            path=':businessKey'
            element={authorizedLayout(() => TaskHistoryList())}
        />
        <Route
            path='task/:id'
            element={authorizedLayout(() => OpenHistoryTask())}
        />
    </Routes>
);

export default Roles;

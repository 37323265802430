import { Queue } from "@/api/CamundaController";
import {
  Badge,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useEffect } from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "@/redux/modules/Header";
import processManager from "@/core/helpers/ProcessManager";
import { AssignmentLate } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface QueueListProps {
  mode?: "queue" | "started";
  onSelectQueue?: (queue: Queue) => void;
}
const QueueList = (props: QueueListProps) => {
  const { t } = useTranslation();
  const { mode, onSelectQueue } = props;

  const dispatch = useDispatch();
  const header = useSelector((state: any) => state?.header) || {};
  const selectedUserQueue = header.selectedQueueMenu || new Queue();
  const userQueueList = header.userQueue || new Array<Queue>();

  const handleChangeQueue = (q: Queue, fromMenu: boolean) => {
    if (fromMenu)
      dispatch(
        actions.selectMenu({ title: t("menu.queue"), path: "/queue", index: 2 })
      );
    dispatch(actions.selectQueueMenu(q));
    if (onSelectQueue) {
      onSelectQueue(q);
    }
  };

  useEffect(() => {
    if (userQueueList.length == 0) {
      processManager.fetchQueueData();
    }
  }, []);

  useEffect(() => {
    if (mode) {
      const modeQueue = userQueueList.find((q: any) => q.process === mode);
      if (modeQueue) {
        handleChangeQueue(modeQueue, false);
      }
    }
  }, [userQueueList]);

  return (
    <React.Fragment>
      {mode == undefined &&
        userQueueList?.map((row: any, index: number) => (
          <ListItemButton
            dense={true}
            key={index}
            selected={row.id == selectedUserQueue.id}
            onClick={() => {
              handleChangeQueue(row, true);
            }}>
            <ListItemIcon>
              <Badge
                badgeContent={row.queueResponse.totalResults}
                color="primary">
                {row?.readOnly ? <AssignmentLate /> : <AssignmentIcon />}
              </Badge>
            </ListItemIcon>
            <ListItemText secondary={t(row?.name)} />
          </ListItemButton>
        ))}
    </React.Fragment>
  );
};

export default QueueList;

import pdfMake from "pdfmake/build/pdfmake";
import { printDocument } from "./printDocument";

export const useApplication14Document = () => {
	const handlePrintApplication14Document = () => {
		const doc = printDocument();
		pdfMake.createPdf(doc).open();
	};

	return {
		handlePrintApplication14Document,
	};
};
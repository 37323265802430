import React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MainListItems } from './ListItems';
import { HeaderReducer, actions, SelectedMenu } from '@/redux/modules/Header';
import { connect, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

const drawerWidth = 280;

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const LeftMenu = (props: HeaderReducer) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSelectMenu = (menu: SelectedMenu) => {
        if (menu.path == '/queue') {
            dispatch(actions.openQueueMenuBar(!props.queueMenuBarOpen));
        }
        // if (props.selectedMenu?.path != menu.path) {
        dispatch(actions.selectMenu(menu));
        dispatch(actions.openQueueMenuBar(false));
        navigate(menu.path);
        // }
    };

    return (
        <Drawer variant="permanent" open={props.menuBarOpen} anchor="left">
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    px: [1],
                }}
            >
                <IconButton onClick={() => dispatch(actions.openMenuBar(!props.menuBarOpen))}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
                <MainListItems selectedMenu={props.selectedMenu} onSelectMenu={onSelectMenu} roles={props.userContext?.user?.roles} />
            </List>
            {/* <Divider sx={{ my: 1 }} />
            <List component="nav">
                {SecondaryListItems}
            </List> */}
        </Drawer>
    );
};

function mapStateToProps(state: any) {
    const header: HeaderReducer = state.header || {};

    return {
        userContext: header.userContext,
        menuBarOpen: header.menuBarOpen,
        selectedMenu: header.selectedMenu,
        queueMenuBarOpen: header.queueMenuBarOpen
    };
}

export default connect(mapStateToProps, null)(LeftMenu);
import { server } from '../core/server/axios';

const wenv = process.env;

export interface CheckDayIsOpenVariables {
	login: string;
	kassaId: string | number;
}

export interface AddKassaVariables {
	name: string;
	code: string;
	address: string,
	adviser: string,
	depId: string | number //id филлиала
}

export interface OpenKassaDayVariables {
	login: string;
	kassaId: string | number; //id кассы
}

export class KassaController {
	async checkDayIsOpen(request: CheckDayIsOpenVariables) {
		return await server.get(`/CashierReport/checkIsDayOpen/${request.login}/${request.kassaId}`, {
			baseURL: wenv.REACT_APP_API_URL
		});
	}

	async addKassa(request: AddKassaVariables) {
		return await server.post('/Cashbox/newCashbox', request, {
			baseURL: wenv.REACT_APP_API_URL
		});
	}

	async loadBalanceList(request: string | number) {
		return await server.get(`Cash/balanceList?depId=${request}`, {
			baseURL: wenv.REACT_APP_API_URL
		});
	}

	async openKassaDay({ login, kassaId }: OpenKassaDayVariables) {
		return await server.post(`/CashierReport/openDay?login=${login}&kassaId=${kassaId}`, {}, {
			baseURL: wenv.REACT_APP_API_URL
		});
	}

	async closeKassaDay({ login, kassaId }: OpenKassaDayVariables) {
		return await server.get(`/CashierReport/closeDay?login=${login}&kassaId=${kassaId}`, {
			baseURL: wenv.REACT_APP_API_URL
		});
	}

	async checkKassaIsOpen({ login, kassaId }: OpenKassaDayVariables) {
		return await server.get(`CashierReport/checkIsDayOpen/${login}/${kassaId}`, {
			baseURL: wenv.REACT_APP_API_URL
		});
	}
}

import styles from './DepartmentOpenPage.module.css';
import { useDepartmentOpen } from './useDepartmentOpen';
import AddIcon from '@mui/icons-material/Add';
import { AddDepartmentWindow } from '../../components/AddDepartmentWindow';
import { DepartmentInfoWindow } from '../../components/DepartmentInfoWindow';
import { IconButton, Tooltip } from '@mui/material';
import ModeIcon from '@mui/icons-material/Mode';

const DepartmentOpenPage = () => {
	const {
		departmentList,
		openWindow,
		handleCloseWindow,
		city,
		handleChangeCity,
		handleOpenWindow,
		address,
		handleChangeAddress,
		departmentName,
		handleChangeDepartmentName,
		handleAddDepartment,
		activeDepartment,
		handleChangeBin,
		bin,
		handleOpenDepartmentInfoWindow,
		handleCloseDepartmentInfoWindow,
		openDepartmentInfoWindow,
		handleAddKassa,
		handleOpenEditWindow,
		handleCloseEditWindow,
		openEditWindow,
		handleUpdateDepartment
	} = useDepartmentOpen();

	return (
		<>
			<div className={styles.departmentsList}>
				{departmentList.map(department => (
					<>
						<div key={department.id} className={styles.department} onClick={() => handleOpenDepartmentInfoWindow(department.id)}>
							<h5>{department.name}</h5>
							<div>г.{department.code}, {department.address}</div>
							<p>{department.bin}</p>
						</div>

						<div>
							<Tooltip title="Изменить данные отделения" placement="top">
								<IconButton onClick={() => handleOpenEditWindow(department)}>
									<ModeIcon />
								</IconButton>
							</Tooltip>
						</div>
					</>
				))}
				{openDepartmentInfoWindow && activeDepartment && (
					<DepartmentInfoWindow
						activeDepartment={activeDepartment}
						handleCloseWindow={handleCloseDepartmentInfoWindow}
						openWindow={openDepartmentInfoWindow}
						handleAddKassa={handleAddKassa}
					/>
				)}
				<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					<div className={styles.plus_department} onClick={handleOpenWindow}><AddIcon /></div>
					{(openWindow || openEditWindow) && (
						<AddDepartmentWindow
							openWindow={openWindow || openEditWindow}
							handleCloseWindow={openWindow ? handleCloseWindow : handleCloseEditWindow}
							city={city}
							handleChangeCity={handleChangeCity}
							handleChangeAddress={handleChangeAddress}
							address={address}
							departmentName={departmentName}
							handleChangeDepartmentName={handleChangeDepartmentName}
							handleAddDepartment={handleAddDepartment}
							handleUpdateDepartment={() => activeDepartment && handleUpdateDepartment(activeDepartment)}
							handleChangeBin={handleChangeBin}
							bin={bin}
							isEditMode={openEditWindow}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default DepartmentOpenPage;
import authorizedLayout from "@/components/AuthorizedLayout";
import { Routes, Route } from "react-router-dom";
import RoleCreate from "./RoleCreate";
import RolesList from "./RolesList";

const Users = () => (
    <Routes>
        <Route
            path=''
            element={authorizedLayout(() => RolesList())}
        />
        <Route
            path='create'
            element={authorizedLayout((props: any) => RoleCreate(props))}
        />
        <Route
            path=':code'
            element={authorizedLayout((props: any) => RoleCreate(props && { formState: "read" }))}
        />
    </Routes>
);

export default Users;

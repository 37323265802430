import { api } from '@/api/Api';
import { Role } from '@/api/CamundaController';
import { Regions } from '@/interface';
import { setActiveDepartmentId, setActiveKassaId, setActiveRegion } from '@/redux/modules/department/departmentActions';
import { InitialStateProps } from '@/redux/modules/department/departmentReducer';
import { selectActiveDepartment, selectActiveKassa, selectActiveRegion, selectDepartmentList, selectKassaIsOpen } from '@/redux/modules/department/departmentSelectors';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useSelectDepartments = () => {
	const [haveOpenKassa, setHaveOpenKassa] = useState(false);

	const dispatch = useDispatch();

	const departmentList = useSelector(selectDepartmentList);
	const activeRegion = useSelector(selectActiveRegion);
	const activeDepartment = useSelector(selectActiveDepartment);
	const activeKassa = useSelector((state: { departmentReducer: InitialStateProps }) => selectActiveKassa(state, activeDepartment));
	const kassaIsOpen = useSelector(selectKassaIsOpen);

	const userLogin: string = useSelector((state: any) => state?.header?.userContext?.user?.login || {});
	const roles: Role[] = useSelector((state: any) => state?.header?.userContext?.user?.roles || []);

	const role = useMemo(() => {
		let code: number | null = null;
		for (const role of roles) {
			if (role.code === "ADMIN") {
				code = null;
				break;
			} else if (role.code === "Cashier_Almaty_Dostyk") {
				code = 6;
				break;
			} else if (role.code === "Cashier_Astana_Aeroport") {
				code = 9;
				break;
			} else if (role.code === "Cashier_Almaty_GrandPark") {
				code = 8;
				break;
			} else if (role.code === "Cashier_Astana_Saryarka") {
				code = 10;
				break;
			} else if (role.code === "Cashier_Astana_Hanshatyr") {
				code = 7;
				break;
			} else if (role.code === "Cashier_Almaty_Rixos") {
				code = 11;
				break;
			}
		}
		return code;
	}, []);

	useEffect(() => {
		if (!activeDepartment) {
			const currentDepartment = departmentList.find(department => department.code === activeRegion);
			currentDepartment && dispatch(setActiveDepartmentId(currentDepartment.id));
		}

		if (!activeKassa) {
			if (activeDepartment) {
				dispatch(setActiveKassaId(activeDepartment.kassa[0].id));
			} else {
				const currentDepartment = departmentList.find(department => department.code === activeRegion);
				currentDepartment && dispatch(setActiveKassaId(currentDepartment.kassa[0].id));
			}
		}
	}, [departmentList]);

	const checkHaveOpenedKassaByLogin = async () => {
		if (activeDepartment?.kassa && Array.isArray(activeDepartment?.kassa)) {
			let kassaIsOpened = false;
			for (const kassa of activeDepartment.kassa) {
				await api.kassa.checkKassaIsOpen({ login: userLogin, kassaId: kassa.id })
					.then(res => {
						if (res.status === 1) {
							kassaIsOpened = true;
							setHaveOpenKassa(true);
							dispatch(setActiveKassaId(kassa.id));
						} else {
							setHaveOpenKassa(false);
						}
					});

				if (kassaIsOpened) {
					break;
				}
			}
			return kassaIsOpened;
		} else {
			setHaveOpenKassa(false);
			return false;
		}
	};

	useEffect(() => {
		if (role === null) {
			handleSelectRegion(activeRegion !== "Все" ? activeRegion : "Алматы");
		} else {
			handleSelectActiveDepartment(role);
			checkHaveOpenedKassaByLogin();
		}
	}, []);

	useEffect(() => {
		if (role !== null) {
			checkHaveOpenedKassaByLogin();
		}
	}, [kassaIsOpen]);

	// const handleCheckKassaIsOpen = (kassaId: string | number) => {
	// 	api.kassa.checkKassaIsOpen({ login: userLogin, kassaId: kassaId })
	// 		.then(res => {
	// 			dispatch(setKassaIsOpen(res.status === 1));
	// 		});
	// };

	const handleSelectRegion = (region: Regions | "Все") => {
		if (region !== activeRegion) {
			dispatch(setActiveRegion(region));
			const foundDepartment = departmentList.find(department => department.code === region);
			foundDepartment?.id && dispatch(setActiveDepartmentId(foundDepartment?.id));
			foundDepartment?.kassa[0].id && dispatch(setActiveKassaId(foundDepartment?.kassa[0].id));
			// foundDepartment?.kassa[0].id && handleCheckKassaIsOpen(foundDepartment?.kassa[0].id);
		} else {
			// activeKassa?.id && handleCheckKassaIsOpen(activeKassa.id);
		}
	};

	const handleSelectActiveDepartment = (id: string | number) => {
		if (id !== activeDepartment?.id) {
			dispatch(setActiveDepartmentId(id));
			const foundKassa = departmentList?.find(department => department.id === id)?.kassa[0];
			foundKassa?.id && dispatch(setActiveKassaId(foundKassa.id));
			// foundKassa && handleCheckKassaIsOpen(foundKassa?.id);
		} else {
			// activeKassa?.id && handleCheckKassaIsOpen(activeKassa.id);
		}
	};

	const handleSelectActiveKassa = (kassaId: string | number) => {
		dispatch(setActiveKassaId(kassaId));
		// handleCheckKassaIsOpen(kassaId);
	};

	return {
		handleSelectRegion,
		handleSelectActiveDepartment,
		handleSelectActiveKassa,
		departmentList,
		activeRegion,
		activeDepartment,
		activeKassa,
		role,
		haveOpenKassa,
	};
};
import pdfMake from "pdfmake/build/pdfmake";
import { printDocument } from "./printDocument";

export const useApplication10Document = () => {
	const handlePrintApplication10Document = () => {
		const doc = printDocument();
		pdfMake.createPdf(doc).open();
	};

	return {
		handlePrintApplication10Document,
	};
};
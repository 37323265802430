import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from "@mui/material/styles";

const BtnDarkBlue = styled(Button)<ButtonProps>(() => ({
	backgroundColor: "#005792",
	fontWeight: "bold",
	color: "white",
	'&:hover': {
		backgroundColor: "#13334c",
	},
}));

export default BtnDarkBlue;
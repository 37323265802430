import React, { DetailedHTMLProps, HTMLAttributes, useEffect, useState } from "react";

import LockClockIcon from "@mui/icons-material/LockClock";

import styles from "./timer.module.css";

export const Timer = ({ className, ...props }: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {

	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);

	const getTimeRemaining = () => {
		const date = new Date();
		let endTime = new Date(
			date.getFullYear(),
			date.getMonth(),
			date.getDate(),
			20
		);

		if (endTime < date) {
			endTime = new Date(
				date.getFullYear(),
				date.getMonth(),
				date.getDate() + 1,
				20
			);
		}

		const timeLeft = +endTime - +date;

		setHours(Math.floor((timeLeft / (1000 * 60 * 60)) % 24));
		setMinutes(Math.floor((timeLeft / (1000 * 60)) % 60));
		setSeconds(Math.floor((timeLeft / 1000) % 60));
	};

	useEffect(() => {
		const interval = setInterval(() => getTimeRemaining(), 1000);

		return () => {
			clearInterval(interval);
		};
	}, []);



	const withZero = (num: number) => {
		if (num >= 0 && num < 10) {
			return `0${num}`;
		} else {
			return num;
		}
	};

	return (
		<div className={styles.container + " " + className} {...props}>
			<LockClockIcon className={styles.icon} />
			Бронирование курса действует {withZero(hours)}:{withZero(minutes)}:{withZero(seconds)}
		</div>
	);
};
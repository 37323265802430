import { useEffect, useState } from 'react';
import pm from "@/core/helpers/ProcessManager";

export enum DocumentsEnum {
	Order = "Распоряжение",
	DailyReport = "Ежедневный отчет",
	Application10 = "Приложение 10",
	Application14 = "Приложение 14",
}

export const documentsArray = [
	{ type: DocumentsEnum.Order },
	{ type: DocumentsEnum.DailyReport },
	{ type: DocumentsEnum.Application10 },
	{ type: DocumentsEnum.Application14 },
];

export const useDocumentsPage = () => {
	const [activeDocumentType, setActiveDocumentType] = useState<DocumentsEnum>();

	const handleSelectDocument = (documentType: DocumentsEnum) => {
		setActiveDocumentType(documentType);
	};

	useEffect(() => {
		pm.closeBackDrop();
	}, []);

	return {
		activeDocumentType,
		handleSelectDocument
	};
};
import React, { useState } from "react";

import { DepartmentCashierExchangeProps } from "./DepartmentCashierExchange.props";

import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import styles from "./DepartmentCashierExchange.module.css";
import { formatNumberRu } from '@/core/helpers/PageHelpers';

const basePath = process.env.PUBLIC_URL;

export const DepartmentCashierExchange = ({
	department,
	kassa,
	handleOpenWindow,
	showAllId,
	setShowAll,
	kztCurrency,
	className,
	...props
}: DepartmentCashierExchangeProps): JSX.Element => {
	const [isOptom, setIsOptom] = useState(false);

	const showAll = showAllId === kassa.depId;

	return (
		<div className={className + " " + styles.container} {...props} >
			<div className={styles.kzt_currency}>
				<p>
					<img src={`${basePath}/assets/images/Flags/KZT.png`} />
					KZT
				</p>
				<p>
					{kztCurrency?.balance ? formatNumberRu(kztCurrency?.balance) : formatNumberRu(0)} тг
				</p>
			</div>

			<div className={styles.currency_list}>
				<div className={styles.list_header}>
					<p></p>
					<p style={{ textAlign: "center" }}>Покупка</p>
					<p style={{ textAlign: "center" }}>Валюта</p>
					<p style={{ textAlign: "center" }}>Остаток</p>
					<p style={{ textAlign: "center" }}>Продажа</p>
					<p style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
						Оптом
						<Switch
							checked={isOptom}
							onChange={() => setIsOptom(!isOptom)}
						/>
					</p>
				</div>
				{department.currencies.filter(currency => currency.code !== "KZT").map((currency, index) => {
					if ((!showAll && index <= 2) || showAll) {
						return (
							<div
								key={currency.currencyId}
								className={styles.currency_row}
							>
								<Button
									variant={"contained"}
									sx={{
										background: "#da793f",
										"&:hover": {
											backgroundColor: "#b76534",
										},
									}}
									onClick={() => {
										handleOpenWindow(
											currency,
											currency.code,
											"KZT",
											currency.rates[0].limitFrom === 0
												? +currency.rates[0].buy.toFixed(2)
												: +currency.rates[1].buy.toFixed(2),
											kassa.id,
											department?.id || ""
										);
									}}
								>
									Покупаем
								</Button>
								<p style={{ justifyContent: "center" }}>
									{!isOptom &&
										(currency.rates[0].limitFrom === 0
											? currency.rates[0].buy.toFixed(2)
											: currency.rates[1].buy.toFixed(2))}
									{isOptom &&
										(currency.rates[0].limitFrom !== 0
											? currency.rates[0].buy.toFixed(2)
											: currency.rates[1].buy.toFixed(2))}
								</p>
								<p>
									<img
										src={`${basePath}/assets/images/Flags/${currency.code}.png`}
									/>
									<div>
										<p>{currency.code}</p>
										<p style={{ fontSize: "12px", fontWeight: 400 }}>{currency.description}</p>
									</div>
								</p>
								<p style={{ justifyContent: "center" }}>
									{formatNumberRu(kassa.cash?.find(item => item.currencyCode === currency.code)?.balance || 0)}
								</p>
								<p style={{ justifyContent: "center" }}>
									{!isOptom &&
										(currency.rates[0].limitFrom === 0
											? currency.rates[0].sale.toFixed(2)
											: currency.rates[1].sale.toFixed(2))}
									{isOptom &&
										(currency.rates[0].limitFrom !== 0
											? currency.rates[0].sale.toFixed(2)
											: currency.rates[1].sale.toFixed(2))}
								</p>
								<Button
									variant={"contained"}
									sx={{
										background: "#da793f",
										"&:hover": {
											backgroundColor: "#b76534",
										},
									}}
									onClick={() =>
										handleOpenWindow(
											currency,
											"KZT",
											currency.code,
											currency.rates[0].limitFrom === 0
												? +currency.rates[0].sale.toFixed(2)
												: +currency.rates[1].sale.toFixed(2),
											kassa.id,
											department?.id || ""
										)
									}>
									Продаем
								</Button>
							</div>
						);
					}
				})}
			</div>

			{department.currencies.filter(currency => currency.code !== "KZT").length > 3 &&
				(
					showAll
						? <div
							className={styles.showAll}
							onClick={() => setShowAll(null)}
						>
							Скрыть <KeyboardArrowUpIcon />
						</div>
						: <div
							className={styles.showAll}
							onClick={() => setShowAll(kassa.depId ? kassa.depId : null)}
						>
							Eще <KeyboardArrowDownIcon />
						</div>
				)
			}
		</div>
	);
};
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { User, VariableFilter } from '@/api/CamundaController';
import { Box, FormControlLabel, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { api } from '@/api/Api';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HistoricProcessInstance } from '@/api/HistoryController';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import TaskTableToolbar from '@/components/TaskBoard/TaskTableToolbar';
import i18n from '@/i18nextConf';
import { useTranslation } from 'react-i18next';

const basics = () => [
    { code: "businessKey", value: i18n.t("history.businessKey") },
    { code: "clientCode", value: i18n.t("history.clientCode") },
    { code: "currentActivityId", value: i18n.t("history.currentActivityId") },
    { code: "processDefinitionKey", value: i18n.t("history.processDefinitionKey") }
];

const ProcessHistoryList = () => {
    const { t } = useTranslation();
    const me = useSelector((state: any) => state?.header?.userContext?.user as User);
    const navigate = useNavigate();
    const [procs, setProcs] = useState([] as HistoricProcessInstance[]);
    const [pageSize, setPageSize] = useState(10);
    const [searchFilter, setSearchFilter] = useState<Array<VariableFilter>>([]);
    const [lastPage, setLastPage] = useState(true);
    const showMoreSize = 10;
    const [dense, setDense] = React.useState(true);

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const onShowMore = () => {
        api.history
            .getProcessInstances(0, pageSize + showMoreSize, searchFilter)
            .then((m) => {
                setProcs(m);
                setLastPage(pageSize + showMoreSize > m.length);
                setPageSize(pageSize + showMoreSize);
            });
    };

    const onClickSearch = (e: any) => {
        e.preventDefault();
        handleSearchQueue();
    };

    const onClearSearch = () => {
        setSearchFilter([]);
        handleSearchQueue();
    };

    const handleSearchQueue = () => {
        api.history
            .getProcessInstances(0, pageSize, searchFilter)
            .then((m) => {
                setProcs(m);
                setLastPage(pageSize > m.length);
            });
    };

    const onClick = (proc: HistoricProcessInstance) => {
        navigate(`/history/${proc.businessKey}`);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
                <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        <TaskTableToolbar
                            onClickSearch={onClickSearch}
                            setSearchFilter={setSearchFilter}
                            onClearSearch={onClearSearch}
                            searchFilter={searchFilter}
                            items={basics()}
                            queueId={999999}
                            me={me}
                        />
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("history.processname")}</TableCell>
                                        <TableCell>{t("history.defkey")}</TableCell>
                                        <TableCell>{t("history.businessKey")}</TableCell>
                                        <TableCell>{t("history.status")}</TableCell>
                                        <TableCell>{t("history.initiator")}</TableCell>
                                        <TableCell>{t("history.when")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {procs.map((proc, i) => (
                                        <TableRow key={i} onClick={() => onClick(proc)}>
                                            <TableCell>{proc.processDefinitionName}</TableCell>
                                            <TableCell>{proc.processDefinitionKey}</TableCell>
                                            <TableCell>{proc.businessKey}</TableCell>
                                            <TableCell>{proc.state}</TableCell>
                                            <TableCell>{proc.startUserId}</TableCell>
                                            <TableCell>{moment.tz(proc.startTime, me.gmt || '').toLocaleString()}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br />
                        {!lastPage && (
                            <div
                                style={{
                                    width: "100%",
                                    textAlign: "center",
                                    cursor: "pointer",
                                }}
                                onClick={() => onShowMore()}
                            >
                                <b>
                                    <u>{t("history.showmore")}</u>
                                </b>
                            </div>
                        )}
                    </Paper>
                    <FormControlLabel
                        control={<Switch checked={dense} onChange={handleChangeDense} />}
                        label={t("history.tight")}
                    />
                </Box>
            </Grid>
        </Grid>);
};

export default ProcessHistoryList;
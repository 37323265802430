import { Report } from '@/api/ReportController';
import { Queue, Role, User, UserContext } from '../../api/CamundaController';

const LOGIN_SUCCESS = 'Header/Login/LOGIN_SUCCESS';
const LOGIN_FAILURE = 'Header/Login/LOGIN_FAILURE';
const LOGOUT = 'Header/Login/LOGOUT';
const UPDATE_USER_PHOTO = 'Header/User/UPDATE_USER_PHOTO';
const UPDATE_USER_ROLES = 'Header/User/UPDATE_USER_ROLES';
const OPEN_MENUBAR = 'Header/Menu/OPEN_MENUBAR';
const SELECT_MENU = 'Header/Menu/SELECT_MENU';
const SELECT_REPORT = 'Header/Report/SELECT_REPORT';
const OPEN_QUEUE_MENUBAR = 'Header/Menu/OPEN_QUEUE_MENUBAR';
const SELECT_QUEUE_MENU = 'Header/Menu/SELECT_QUEUE_MENU';
const UPDATE_QUEUE_MENU = 'Header/Menu/UPDATE_QUEUE_MENU';
const UPDATE_USER_QUEUE = 'Header/User/UPDATE_USER_QUEUE';
const UPDATE_USER = 'Header/User/UPDATE_USER';

export interface SelectedMenu {
  path: string;
  title: string;
  index: number;
}

const userContext = JSON.parse(localStorage.getItem('userContext') || '{}');

export class HeaderReducer {
  userContext?: UserContext = userContext;
  menuBarOpen?: boolean = false;
  selectedMenu?: SelectedMenu;
  selectedReport?: Report;
  queueMenuBarOpen?: boolean = false;
  selectedQueueMenu?: Queue;
  userQueue?: Array<Queue>;
}

const initialState = new HeaderReducer();

export default function header(state: HeaderReducer = initialState, action: any = {}) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, userContext: action.payload };
    case LOGOUT:
      return {};
    case OPEN_MENUBAR:
      return {
        ...state,
        menuBarOpen: action.payload
      };
    case SELECT_MENU:
      return {
        ...state,
        selectedMenu: action.payload
      };
    case SELECT_REPORT:
      return {
        ...state,
        selectedReport: action.payload
      };
    case OPEN_QUEUE_MENUBAR:
      return {
        ...state,
        queueMenuBarOpen: action.payload
      };
    case SELECT_QUEUE_MENU:
      return {
        ...state,
        selectedQueueMenu: action.payload
      };
    case UPDATE_QUEUE_MENU:
      return {
        ...state,
        selectedQueueMenu: (action.payload as Queue[]).find(q => q.id == state.selectedQueueMenu?.id)
      };
    case UPDATE_USER:
      return {
        ...state,
        userContext: {
          ...state.userContext,
          user: {
            ...state.userContext?.user,
            ...action.payload
          }
        }
      };
    case UPDATE_USER_PHOTO:
      return {
        ...state,
        userContext: {
          ...state.userContext,
          user: {
            ...state.userContext?.user,
            photo: {
              ...state.userContext?.user?.photo,
              data: action.payload
            }
          }
        }
      };
    case UPDATE_USER_ROLES:
      return {
        ...state,
        userContext: {
          ...state.userContext,
          user: {
            ...state.userContext?.user,
            roles: action.payload
          }
        }
      };
    case UPDATE_USER_QUEUE:
      return {
        ...state,
        userQueue: action.payload
      };
    default:
      return state;
  }
}

const success = (userContext: any) => ({
  type: LOGIN_SUCCESS,
  payload: userContext,
});

const failure = (error: any) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

const logout = () => ({
  type: LOGOUT,
  payload: null
});

const openMenuBar = (state: boolean) => ({
  type: OPEN_MENUBAR,
  payload: state
});

const selectMenu = (menu: SelectedMenu) => ({
  type: SELECT_MENU,
  payload: menu
});

const selectReport = (report: Report) => ({
  type: SELECT_REPORT,
  payload: report
});

const openQueueMenuBar = (state: boolean) => ({
  type: OPEN_QUEUE_MENUBAR,
  payload: state
});

const selectQueueMenu = (menu?: Queue) => ({
  type: SELECT_QUEUE_MENU,
  payload: menu
});

const updateQueueMenu = (queues: Queue[]) => ({
  type: UPDATE_QUEUE_MENU,
  payload: queues
});

const updateUser = (user: Partial<User>) => ({
  type: UPDATE_USER,
  payload: user
});

const updateUserPhoto = (photoBase64: string) => ({
  type: UPDATE_USER_PHOTO,
  payload: photoBase64
});

const updateUserRoles = (roles?: Role[]) => ({
  type: UPDATE_USER_ROLES,
  payload: roles
});

const updateUserQueue = (userQueue?: Array<Queue>) => ({
  type: UPDATE_USER_QUEUE,
  payload: userQueue
});

export const actions = {
  success,
  failure,
  logout,
  updateUser,
  updateUserPhoto,
  updateUserRoles,
  openMenuBar,
  selectMenu,
  selectReport,
  openQueueMenuBar,
  selectQueueMenu,
  updateQueueMenu,
  updateUserQueue
};

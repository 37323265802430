import { api } from '@/api/Api';
import { HeaderReducer } from '@/redux/modules/Header';
import { loadDepartments, setKassaIsOpen } from '@/redux/modules/department/departmentActions';
import { InitialStateProps } from '@/redux/modules/department/departmentReducer';
import { selectActiveDepartment, selectActiveKassa, selectDepartmentStatus, selectKassaIsOpen, selectRegions } from '@/redux/modules/department/departmentSelectors';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pm from "@/core/helpers/ProcessManager";
import { Queue } from '@/api/CamundaController';

export interface AddCashBodyVariables {
	balance: number,
	currencyCode: string,
	kassaId: number,
	currencyId: number | null,
	cashId: number | null,
	operationType: string,
	toKassaId: number,
}

export const useCashListPage = () => {

	const dispatch = useDispatch();

	const departmentStatus = useSelector(selectDepartmentStatus);

	const regions = useSelector(selectRegions);

	const activeDepartment = useSelector(selectActiveDepartment);
	const activeKassa = useSelector((state: { departmentReducer: InitialStateProps }) => selectActiveKassa(state, activeDepartment));
	const kassaIsOpen = useSelector(selectKassaIsOpen);

	const userLogin: string = useSelector((state: { header: HeaderReducer }) => state?.header?.userContext?.user?.login || "");

	const header = useSelector((state: any) => state?.header) || {};
	const userQueueList = header.userQueue || new Array<Queue>();
	const kassaQueueList = userQueueList.find((queue: any) => queue.id === 5);

	useEffect(() => {
		handleGetDepartmentList();

		const currentProcess = kassaQueueList?.queueResponse?.queue?.find((queueRes: any) => queueRes.Логин === userLogin);
		if (currentProcess) {
			pm.claimTask(currentProcess.taskId!);
		}
	}, []);

	const handleGetDepartmentList = () => {
		dispatch(loadDepartments());
	};

	const handleOpenKassa = () => {
		activeKassa?.id && api.kassa.openKassaDay({ login: userLogin, kassaId: activeKassa?.id })
			.then((res: any) => {
				handleCheckKassaIsOpen(activeKassa?.id);
				if (res.status === 2) {
					pm.openSnackBar(res.text, { severity: "error" });
				}
				pm.start({
					processDefinitionKey: "kassa_day",
					// processDefinitionKey: "kassa_day_prod",
					variables: {
						login: userLogin,
						model: res.model,
						department: activeDepartment,
						kassa: activeKassa
					},
				});
			})
			.catch(() => pm.openSnackBar("Произошла ошибка: Не удалось открыть кассу", { severity: "error" }));
	};

	const handleCheckKassaIsOpen = (kassaId: string | number) => {
		api.kassa.checkKassaIsOpen({ login: userLogin, kassaId: kassaId })
			.then(res => {
				dispatch(setKassaIsOpen(res.status === 1));
			});
	};

	return {
		activeKassa,
		kassaIsOpen,
		handleOpenKassa,
		departmentStatus,
		regions,
	};
};
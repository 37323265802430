import React from "react";
import { Routes, Route } from "react-router-dom";
import { DocumetsPage } from './pages';
import { wizard } from '@/hoc';

const DocumentPage = () => (
  <Routes>
    <Route path="/" element={wizard(DocumetsPage, 'Документы')} />
  </Routes>
);

export default DocumentPage;

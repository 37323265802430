import { TaskFormComponent } from "@/api/CamundaController";
import { updateTaskVariables } from "@/redux/modules/Process";
import { Grid, Checkbox, FormGroup, FormControlLabel, FormHelperText, FormControl, Stack } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

interface CheckboxGenProps {
    component: TaskFormComponent;
    iconButton?: ReactNode;
}
const CheckboxGen = (props: CheckboxGenProps) => {
    const { component, iconButton } = props;
    const dispatch = useDispatch();
    const variables = useSelector((state: any) => state?.process?.taskModel?.variables) || {};
    useEffect(() => {
        if (variables[component.key!] == undefined && component.defaultValue) {
            dispatch(
                updateTaskVariables(
                    (g) => g,
                    (p) => Object.assign(p, { [component.key!]: component.defaultValue })
                ));
        }
    }, []);
    const value = variables[component.key!] || false;
    return (
        <Grid item xs={12} {...component.properties}>
            <Stack direction='row' justifyContent="flex-start"
                alignItems="flex-start">
                <FormControl
                    required={component.validate?.required}
                    error={false}
                >
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                size='small'
                                required={component.validate?.required}
                                id={component.id}
                                placeholder={component.description}
                                name={component.id}
                                checked={value}
                                onChange={() => dispatch(
                                    updateTaskVariables(
                                        (g) => g,
                                        (p) => Object.assign(p, { [component.key!]: !value })
                                    ))}
                                disabled={component.disabled}
                                inputProps={component.properties}
                            />} label={component.label} />
                        <FormHelperText>{component.description}</FormHelperText>
                    </FormGroup>
                </FormControl>
                {component.dynamic && iconButton}
            </Stack>
        </Grid >);
};

export default CheckboxGen;
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { Paper, Grid, Typography, TextField, Container, Button } from "@mui/material";

import { BtnDarkBlue } from "@/components/MuiComponent";

import pm from "@/core/helpers/ProcessManager";

import pdfMake from "pdfmake/build/pdfmake";

import CheckDocument from './CheckDocument/CheckDocument';
import { formatNumberRu } from '@/core/helpers/PageHelpers';
import axios from 'axios';
import InputMask from "react-input-mask";
import { actions as rateExchangeActions } from '@/redux/modules/RateExchange';
import AuthUtil from '@/core/helpers/AuthUtil';
import { updateTaskVariables } from '@/redux/modules/Process';
import { api } from '@/api/Api';
import styles from "./styles.module.css";

const wenv = process.env;

const LastPage = () => {
  const dispatch = useDispatch();

  const variables = useSelector(
    (state: any) => state?.process?.taskModel?.variables
  );
  const isLoadingProcess = useSelector((state: any) => state.process.isLoading);

  const [isSendOtp, setIsSendOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpCount, setOtpCount] = useState(3);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(30);
  const [docCode, setDocCode] = useState("");

  useEffect(() => {
    if (!variables?.updateResponce) {
      dispatch(
        updateTaskVariables(
          (c) => c.updateResponce,
          () => ({ text: "" })
        )
      );
    }
  }, []);

  useEffect(() => {
    if (variables?.requestError) {
      pm.openSnackBar('Ошибка при создании заявки', { severity: "error", autoHideDuration: 60000 });
    }
  }, [variables?.requestError]);

  useEffect(() => {
    if (variables?.ofdError) {
      pm.openSnackBar('Ошибка с созданием чека на стороне ОФД, срочно обратитесь к ответственному за сервис', { severity: "error", autoHideDuration: 60000 });
    }
  }, [variables?.ofdError]);

  useEffect(() => {
    const timeOut = setInterval(() => {
      if (timer !== 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    return () => clearInterval(timeOut);
  }, [timer]);

  return (
    <Paper
      sx={{
        padding: "15px 15px 40px",
        marginTop: "40px",
        background: "white",
      }}
    >
      {!variables?.ofdSmsError && !variables?.completed && (
        <>
          <Container
            maxWidth="md"
            sx={{ padding: "40px 20px", margin: "20px auto" }}
            className={styles.reserveContainer}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                display="flex"
                justifyContent="space-between"
              >
                <div>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}>
                    КЛИЕНТ ПРОДАЕТ
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "2.1rem",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}>
                    {formatNumberRu(variables.buyAmount)}{" "}
                    {variables.buyCurrencyCode}
                  </Typography>
                </div>
                <div>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}>
                    КУРС
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "2.1rem",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}>
                    {formatNumberRu((+variables.currentRate).toFixed(2))}
                  </Typography>
                </div>
                <div>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      textAlign: "end",
                    }}>
                    КЛИЕНТ ПОЛУЧАЕТ
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "2.1rem",
                      fontWeight: "bold",
                      textAlign: "end",
                    }}>
                    {formatNumberRu(variables.saleAmount)}{" "}
                    {variables.saleCurrencyCode}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Container>

          <Container maxWidth="xs">
            <Grid container spacing={2}>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                display="grid"
                justifyContent="center"
                gridTemplateColumns={"1fr"}
              >
                {
                  !isSendOtp
                  && <Button
                    fullWidth
                    size="large"
                    variant="outlined"
                    sx={{ fontWeight: "bold" }}
                    disabled={isLoading || isLoadingProcess}
                    onClick={() => {
                      setIsLoading(true);

                      variables?.updateResponce?.text !== "success" ? api.request.newRequest({
                        amount: +`${variables.buyCurrencyCode !== "KZT" ? variables.saleAmount : variables.buyAmount}`,
                        businessKey: variables.businessKey,
                        currencyFrom: variables.buyCurrencyCode,
                        currencyTo: variables.saleCurrencyCode,
                        depId: variables.depsId,
                        fullName: variables.fullName,
                        iin: variables.iin,
                        isDelivery: 0,
                        isReserve: 0,
                        kassaId: variables.kassaId,
                        phone: variables.phone,
                        rate: variables.currentRate,
                        value: +`${variables.buyCurrencyCode !== "KZT" ? variables.buyAmount : variables.saleAmount}`,
                      })
                        .then((res) => {
                          const auth = AuthUtil.getAuth();
                          if (res?.requestId >= 0) {
                            dispatch(rateExchangeActions.updateRequestId(res.requestId));
                            dispatch(
                              updateTaskVariables(
                                (c) => c.requestId,
                                () => res.requestId
                              )
                            );
                            if (variables?.phone.length > 1) {
                              const doc = CheckDocument(
                                variables.iin,
                                res.date,
                                res.requestId,
                                variables.saleCurrencyCode,
                                variables.saleAmount,
                                variables.currentRate,
                                variables.buyCurrencyCode,
                                variables.buyAmount,
                              );
                              const document = pdfMake.createPdf(doc);
                              document.getBlob((blob) => {
                                const formData = new FormData();

                                formData.append("file", blob, "document.pdf");

                                axios
                                  .post(
                                    `${wenv.REACT_APP_API_URL}/Application/uploadApplication/${res.requestId}`,
                                    formData,
                                    {
                                      headers: {
                                        "Content-Type": "multipart/form-data",
                                        "Authorization": `Bearer ${auth.token?.accessToken}`
                                      },
                                    }
                                  )
                                  .then((res) => {
                                    if (res.data.status === 1) {
                                      setDocCode(res?.data?.code);
                                      axios
                                        .post(
                                          `${wenv.REACT_APP_API_URL}/Otp/send`,
                                          {
                                            phone: variables.phone,
                                            text: `Check https://ecash.kz/receipt/${res.data.code}`,
                                            digits: 4
                                          },
                                        )
                                        .then(() => {
                                          setIsSendOtp(true);
                                          setTimer(30);
                                          setOtpCount(otpCount - 1);
                                        })
                                        .catch(() => pm.openSnackBar("Произошла ошибка: не удалось отправить смс-чек", { severity: "error" }))
                                        .finally(() => setIsLoading(false));
                                    }
                                  })
                                  .catch(() => {
                                    pm.openSnackBar("Произошла ошибка: не удалось отправить смс-чек", { severity: "error" });
                                    setIsLoading(false);
                                  });
                              });
                            } else {
                              setIsLoading(false);
                              pm.goForth();
                            }
                          }
                        })
                        .catch(() => {
                          pm.openSnackBar("Произошла ошибка: не удалось закрыть заявку", { severity: "error" });
                          setIsLoading(false);
                        }) : function () {
                          pm.goForth();
                          setIsLoading(false);
                        }();
                    }}
                  >
                    Подтвердить заявку
                  </Button>
                }

                {isSendOtp &&
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "black",
                        textAlign: "center",
                      }}
                    >
                      Введите смс-код
                    </div>
                    <InputMask
                      mask="9 9 9 9"
                      value={otp}
                      onChange={(e) => {
                        setOtp(e.target.value.replace(/[_\s]/g, ""));
                      }}
                    >
                      <TextField
                        sx={{
                          textAlign: "center",
                          fontSize: "25px",
                          fontWeight: "bold",
                          background: "white"
                        }}
                        autoComplete={"off"}
                        label="Введите смс-код"
                        variant="outlined"
                        fullWidth
                      />
                    </InputMask>
                    {
                      otpCount !== 0 ?
                        timer !== 0
                          ?
                          <div> Отправить повторно смс через {timer} секунд (Осталось попыток: {otpCount})</div>
                          :
                          <div
                            className={styles.sendOtpAgain}
                            onClick={() => {
                              axios
                                .post(
                                  `${wenv.REACT_APP_API_URL}/Otp/send`,
                                  {
                                    phone: variables.phone,
                                    text: `Check https://ecash.kz/receipt/${docCode}`,
                                    digits: 4
                                  },
                                )
                                .then(() => {
                                  setIsSendOtp(true);
                                  setIsLoading(false);
                                  setTimer(30);
                                  setOtpCount(otpCount - 1);
                                })
                                .catch(() => { setIsLoading(false); });
                            }}
                          >
                            Отправить смс повторно (Осталось попыток: {otpCount})
                          </div>
                        : <div>Попытки закончились</div>
                    }
                    <BtnDarkBlue
                      fullWidth
                      variant="contained"
                      size="large"
                      type={"submit"}
                      disabled={otp.replace(/[_\s]/g, "").length !== 4}
                      sx={{ marginTop: "10px" }}
                      onClick={() => {
                        axios
                          .post(
                            `${wenv.REACT_APP_API_URL}/Otp/confirm`,
                            {
                              phone: variables.phone,
                              otp: otp,
                            },
                          )
                          .then((res: any) => {
                            if (res.data.isCorrect) {
                              pm.goForth();
                            } else {
                              pm.openSnackBar("Неверный код", { severity: "error" });
                            }
                          })
                          .catch(() => { setIsLoading(false); });
                      }}
                    >
                      Исполнить
                    </BtnDarkBlue>
                  </form>
                }
              </Grid>
            </Grid>
          </Container>
        </>
      )}
      {variables?.ofdSmsError && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h2>
              Операция завершена, чек сформирован.
            </h2>
          </Grid>
          <Grid item xs={12}>
            <h3>
              ПРОИЗОШЛА ОШИБКА ПРИ ОТПРАВКЕ СМС КЛИЕНТУ
            </h3>
          </Grid>
          <Grid item xs={12}>
            <BtnDarkBlue
              fullWidth
              variant="contained"
              size="large"
              type={"submit"}
              sx={{ marginTop: "10px" }}
              onClick={() => pm.goForth()}
            >
              Отправить СМС-чек повторно
            </BtnDarkBlue>
          </Grid>
        </Grid>
      )}
      {variables?.completed && (
        <>
          <Container
            maxWidth="md"
            sx={{ padding: "15px 10px", margin: "20px auto", borderRadius: "5px", backgroundColor: "rgb(62 174 68 / 87%)", color: "white" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h3 style={{ textAlign: "center" }}>Операция успешно завершена</h3>
              </Grid>
            </Grid>
          </Container>

          <Container
            maxWidth="md"
            sx={{ padding: "40px 20px", margin: "20px auto" }}
            className={styles.reserveContainer}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                display="flex"
                justifyContent="space-between"
              >
                <div>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}>
                    КЛИЕНТ ПРОДАЕТ
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "2.1rem",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}>
                    {formatNumberRu(variables.buyAmount)}{" "}
                    {variables.buyCurrencyCode}
                  </Typography>
                </div>
                <div>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}>
                    КУРС
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "2.1rem",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}>
                    {formatNumberRu((+variables.currentRate).toFixed(2))}
                  </Typography>
                </div>
                <div>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      textAlign: "end",
                    }}>
                    КЛИЕНТ ПОЛУЧАЕТ
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "2.1rem",
                      fontWeight: "bold",
                      textAlign: "end",
                    }}>
                    {formatNumberRu(variables.saleAmount)}{" "}
                    {variables.saleCurrencyCode}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Container>

          <Container maxWidth="md" >
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  size="large"
                  variant="outlined"
                  sx={{ margin: "10px auto 0", display: "block" }}
                  onClick={() => {
                    const userResponse = confirm("Вы уверены, что хотите отменить операцию?");

                    if (userResponse) {
                      pm.retry();
                    }

                  }}
                >
                  Отменить операцию
                </Button>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <BtnDarkBlue
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{ margin: "10px auto 0", display: "block" }}
                  onClick={() => pm.exit()}
                >
                  Следующий клиент
                </BtnDarkBlue>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </Paper >
  );
};

export default LastPage;

import Grid from '@mui/material/Grid';
import { User } from '@/api/CamundaController';
import { TaskFormGen } from '@/components/FormGen';
import { useDispatch, useSelector } from 'react-redux';
import { Stack } from '@mui/system';
import { Button, Container, Paper, Typography } from '@mui/material';
import { FileDropzone, Sla } from '@/components';
import ButtonsFlow from '@/components/FormGen/ButtonsFlow';
import CommentList from '@/components/Comments/CommentList';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '@/api/Api';
import { updateTaskModel } from '@/redux/modules/Process';
import { ExitToApp } from '@mui/icons-material';
import { history as bh } from "@/core/helpers/history";

const OpenHistoryTask = () => {

    const formRef = useRef();
    const dispatch = useDispatch();
    const { id } = useParams();

    const me = useSelector((state: any) => state?.header?.userContext?.user as User);

    const task = useSelector((state: any) => state?.process?.taskModel);
    const itsMe = task.assignee === me?.login;
    const variables = task.variables || {};
    const sla = (variables.sla || []).filter((s: any) => s.company === me.company);
    const comments = variables?.comments || [];

    useEffect(() => {
        if (id != null) {
            api.history.getTask(id)
                .then(t => dispatch(updateTaskModel(t)))
                .catch(e => console.log(e));
        }
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={1}>
                <Button variant='outlined' onClick={() => bh.back()}>
                    <ExitToApp />
                </Button>
            </Grid>
            <Grid item xs={11}>
                {itsMe && <ButtonsFlow formRef={formRef} buttons={task.buttons} />}
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
                <TaskFormGen formRef={formRef} task={task} />
                <FileDropzone readonly={true} />
                <CommentList itsMe={itsMe} me={me} comments={comments} readonly={true} process={task.processDefinitionKey} />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
                <Paper sx={{ width: '100%', mb: 2, pb: 2 }}>
                    <Container sx={{ pt: 2 }}>
                        <Stack spacing={2} direction="column">
                            <Grid item xs={12}>
                                <Typography>
                                    {task.businessKey}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    {task.assignee}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Sla slas={sla} gmt={me.gmt || 'Asia/Almaty'} />
                            </Grid>
                        </Stack>
                    </Container>
                </Paper>
            </Grid>
        </Grid>);
};

export default OpenHistoryTask;
import ReactDOM from 'react-dom/client';
import './index.css';
import { ThemeProvider, createTheme } from '@mui/material';
import { Provider } from 'react-redux';
import routes from './routes';

import { store } from './core/helpers/store';
import processManager from './core/helpers/ProcessManager';

const theme = createTheme({
  palette: {
    primary: {
      light: '#fff',
      main: '#808B96',
      dark: '#000'
    },
    secondary: {
      main: '#f44336'
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
processManager.restoreTaskModelAfterRefreshingPage(() =>
  root.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>{routes()}</ThemeProvider>
    </Provider>
  )
);

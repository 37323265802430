import { server } from '../core/server/axios';

const wenv = process.env;

interface NewRequestVariables {
	iin: string
	phone: string
	fullName: string
	currencyFrom: string
	currencyTo: string
	value: string | number
	rate: string | number
	amount: string | number
	isIndividual?: boolean
	kassaId: number
	depId: number
	isReserve: 1 | 0
	isDelivery: 0
	businessKey: string
}

export class RequestController {
	async newRequest(request: NewRequestVariables): Promise<any> {
		return await server.post('/Application/NewRequest', request, {
			baseURL: wenv.REACT_APP_API_URL
		});
	}
}
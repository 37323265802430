import React from "react";

import { FillialCashProps } from "./DepartmentCash.props";

import styles from "./DepartmentCash.module.css";
import { Button } from '@mui/material';

export const DepartmentCash = ({
	handleOpenKassa,
	className,
	...props
}: FillialCashProps): JSX.Element => (
	<div
		className={className + " " + styles.container}
		{...props}
	>

		<Button
			variant={"contained"}
			sx={{
				display: "block",
				margin: "auto",

				background: "#da793f",
				"&:hover": {
					backgroundColor: "#b76534",
				},
			}}
			onClick={() => handleOpenKassa()}
		>
			Открыть кассу
		</Button>
	</div>
);
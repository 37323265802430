import { Routes, Route } from "react-router-dom";
import LastPage from "./LastPage/LastPage";

const CashierRequest = () => (
  <Routes>
    <Route
      path="/last-page"
      element={<LastPage />}
    />
  </Routes>
);

export default CashierRequest;

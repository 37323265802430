import { SlaVariable } from '@/api/SlaController';
import { Clear, Done, WatchLaterOutlined } from '@mui/icons-material';
import { Chip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack } from '@mui/system';
import { Fragment, useState } from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

interface SlaPopupProps {
    sla: SlaVariable
    open: boolean;
    setOpen: (value: boolean) => void;
    tz?: string;
}

const SlaPopup = (props: SlaPopupProps) => {
    const { sla, open, setOpen, tz } = props;
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {sla.name}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography><b>{t("sla.view.start")}</b> {moment.tz(sla.startTime, tz || '').format('DD.MM.YYYY H:mm:ss')}</Typography>
                    <Typography><b>{t("sla.view.wait")}</b>  {moment.tz(sla.endTime, tz || '').format('DD.MM.YYYY H:mm:ss')}</Typography>
                    <Typography><b>{t("sla.view.done")}</b>  {sla.actualEndTime ? moment.tz(sla.actualEndTime, tz || '').format('DD.MM.YYYY H:mm:ss') : 'Нет'}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} autoFocus>
                    Ок
                </Button>
            </DialogActions>
        </Dialog>
    );
};

interface SlaProps {
    slas: SlaVariable[];
    gmt: string
}

const Sla = (props: SlaProps) => {

    const { slas, gmt } = props;
    const [sla, setSla] = useState(new SlaVariable());
    const [open, setOpen] = useState(false);

    const isAfterNow = (s: SlaVariable): boolean => moment.tz(s.endTime, gmt).toDate() > moment.tz(Date.now(), gmt).toDate();

    const isAfterActual = (s: SlaVariable): boolean => moment.tz(s.endTime, gmt).toDate() > moment.tz(s.actualEndTime, gmt).toDate();

    const formatDiff = (s: SlaVariable): string => {
        const dateOne = s.actualEndTime ? moment(s.actualEndTime).tz(gmt) : moment().tz(gmt);
        const dateTwo = moment(s.endTime).tz(gmt);
        const duration = dateOne > dateTwo ? moment.duration(dateOne.diff(dateTwo)) : moment.duration(dateTwo.diff(dateOne));

        //Get Days and subtract from duration
        var days = Math.floor(duration.asDays());
        duration.subtract(moment.duration(days, 'days'));

        //Get hours and subtract from duration
        var hours = duration.hours();
        duration.subtract(moment.duration(hours, 'hours'));

        //Get Minutes and subtract from duration
        var minutes = duration.minutes();
        duration.subtract(moment.duration(minutes, 'minutes'));

        const daySection = days > 0 ? `${days}d ` : '';

        return daySection + `${hours}h ${minutes}m`;
    };


    const onSelectSla = (sla: SlaVariable) => {
        setSla(sla);
        setOpen(true);
    };

    return (
        <Fragment>
            <SlaPopup sla={sla} open={open} setOpen={setOpen} tz={gmt} />
            <Stack spacing={2} direction="column">
                <Typography variant='h6' component='h4'>SLA</Typography>
                {slas.map((s: any, i: number) =>
                    <Chip
                        key={i}
                        label={`${s.name} - ${formatDiff(s)}`}
                        onClick={() => onSelectSla(s)}
                        color={s.actualEndTime ? isAfterActual(s) ? "success" : "error" : isAfterNow(s) ? "primary" : "error"}
                        variant="outlined"
                        deleteIcon={s.actualEndTime ? isAfterActual(s) ? <Done /> : <Clear /> : <WatchLaterOutlined />}

                    />
                )}
            </Stack>
        </Fragment>
    );
};

export default Sla;
import { useApplication14Document } from "./useApplication14Document";
import styles from "./Application14Document.module.css";

export const Application14Document = () => {
	const { handlePrintApplication14Document } = useApplication14Document();

	return (
		<div style={{
			marginTop: "30px",
			background: "white",
			padding: "20px",
			borderRadius: "10px",
		}}
		>
			<div>
				<div className={styles.table_row}>
					<p>Филиал</p>
					<p style={{ justifySelf: "center" }}>Период</p>
				</div>

				<div
					className={styles.table_row}
					onClick={() => handlePrintApplication14Document()}
				>
					<p>Ecash</p>
					<p style={{ justifySelf: "center" }}>Июнь</p>
				</div>
				<div
					className={styles.table_row}
					onClick={() => handlePrintApplication14Document()}
				>
					<p>Ecash</p>
					<p style={{ justifySelf: "center" }}>Май</p>
				</div>
				<div
					className={styles.table_row}
					onClick={() => handlePrintApplication14Document()}
				>
					<p>Ecash</p>
					<p style={{ justifySelf: "center" }}>Апрель</p>
				</div>
			</div>
		</div>
	);
};
import { api } from '@/api/Api';
import { User } from '@/api/CamundaController';
import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface UserPickerProps {
    onSelectedItem: (user: User) => void;
    mode: 'all' | 'colleagues';
    label?: string;
    readonly?: boolean;
    defaultValue?: string;
}

const UserPicker = (props: UserPickerProps) => {
    const { t } = useTranslation();
    const me = useSelector((state: any) => state?.header?.userContext?.user as User);
    const { mode, onSelectedItem, label, readonly, defaultValue } = props;
    const [searchText, setSearchText] = useState("");
    const [selectedItem, setSelectedItem] = useState<User | null>();

    const [users, setUsers] = useState([] as User[]);

    useEffect(() => {
        if (defaultValue) {
            api.camunda.getUser(defaultValue, true).then((m) => {
                setSelectedItem(m);
            });
        }
        return () => setSelectedItem(null);
    }, [defaultValue]);

    useEffect(() => {
        if (readonly == true) {
            return;
        } else {
            if (mode == 'all') {
                api.camunda.getUsersContains(0, 10, searchText, true).then((m) => {
                    setUsers(m.content);
                });
            } else {
                api.camunda.getUsersColleagues(0, 10, searchText, true).then((m) => {
                    setUsers(m.content);
                });
            }
        }
    }, [searchText]);

    return (
        <Autocomplete
            fullWidth
            size='small'
            disablePortal
            id="combo-box-demo"
            options={readonly == true ? [] : users}
            getOptionLabel={u => u.login == me.login ? u.fullName ? `${u.fullName} (${t("you")})` : t("you") : u.fullName || ''}
            isOptionEqualToValue={(o, v) => o.login == v.login}
            value={selectedItem || (defaultValue ? { login: defaultValue } : null)}
            onChange={(e, value) => {
                if (value) {
                    setSelectedItem(value);
                    onSelectedItem(value);
                }
            }}
            renderInput={(params) => <TextField {...params} label={label || t("chooseuser")} value={searchText} onChange={(e) => setSearchText(e.target.value)} />}
        />
    );
};

export default UserPicker;
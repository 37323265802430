import React, { useLayoutEffect, useState } from "react";
import { BrowserHistory } from "history";
import { Router } from "react-router-dom";

interface CustomRouterProps {
    basename?: string,
    children?: React.ReactNode,
    history: BrowserHistory
}

const CustomRouter = ({ history, ...props }: CustomRouterProps) => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router
            {...props}
            location={state.location}
            navigationType={state.action}
            navigator={history}
        />
    );
};

export default CustomRouter;
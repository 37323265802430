import { api } from "@/api/Api";
import { updateTaskVariables } from "@/redux/modules/Process";
import { CloudUpload, Delete } from "@mui/icons-material";
import { Button, Grid, IconButton, Paper } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";

const wenv = process.env;

const FileDropzone = (props: { readonly: boolean }) => {
    const dispatch = useDispatch();

    const variables =
        useSelector((state: any) => state?.process?.task?.variables) || {};
    const files = variables?.files || [];

    const onDrop = useCallback((acceptedFiles: any) => {
        api.file.upload(acceptedFiles[0]).then((file) => {
            console.log("files", files);
            console.log("concat", files.concat([file]));
            dispatch(
                updateTaskVariables(
                    (g) => g.files,
                    (p) => (p || []).map((f: any) => f).concat([file])
                ));
        });
    }, []);

    const onDelete = (id: string) => {
        api.file.delete(id).then(() => {
            dispatch(
                updateTaskVariables(
                    (g) => g.files,
                    (p) => p.filter((f: any) => f.id !== id)
                ));
        });
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false });
    const fileList = files.map((file: any) => (
        <Stack key={file.name} direction={'row'}>
            <Button size="small" href={`${wenv.REACT_APP_BACKEND}/sonata/api/file/download/${file.id}`} target='_blank'>
                {file.name} - {file.size} bytes
            </Button>
            <IconButton aria-label="delete" size="small" onClick={() => onDelete(file.id)}>
                <Delete />
            </IconButton>
        </Stack>
    ));
    return (
        <Paper sx={{ p: 2, color: "grey", mb: 2 }}>
            {!props.readonly &&
                <Box sx={{ p: 2, border: '1px dashed grey', cursor: 'pointer' }}>
                    <Grid container direction="column" justifyContent="center" alignItems="center" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <CloudUpload color="primary" />
                        <p>Перетащите файл сюда или нажмите, чтобы выбрать файл</p>
                    </Grid>
                </Box>
            }
            <aside>
                <h4>Файлы</h4>
                <Stack
                    direction={'column'}
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    {fileList}
                </Stack>
            </aside>
        </Paper>);
};

export default FileDropzone;
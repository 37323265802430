import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { InputLabel, Select, MenuItem, Divider, Chip } from '@mui/material';
import { api } from '@/api/Api';
import { Toolbar } from '@mui/material';
import { CancelPresentation } from '@mui/icons-material';
import { Paper } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { Role, User } from '@/api/CamundaController';
import { Stack } from '@mui/system';
import { RolePicker } from '@/components';
import iassign from 'immutable-assign';
import processManager from '@/core/helpers/ProcessManager';
import { useTranslation } from 'react-i18next';

export const mediumRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})");
export const emailRegex = new RegExp("([!# - '*+/-9=?A-Z^-~-]+(\.[!#-' * +/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");
export const loginRegex = new RegExp("^[a-zA-Z0-9_]+([_.][a-zA-Z0-9]+)*$");

export const gmtList = [
    { code: "Etc/GMT+12", value: "(GMT-12:00) International Date Line West" },
    { code: "Pacific/Midway", value: "(GMT-11:00) Midway Island, Samoa" },
    { code: "Pacific/Honolulu", value: "(GMT-10:00) Hawaii" },
    { code: "US/Alaska", value: "(GMT-09:00) Alaska" },
    { code: "America/Los_Angeles", value: "(GMT-08:00) Pacific Time (US & Canada)" },
    { code: "America/Tijuana", value: "(GMT-08:00) Tijuana, Baja California" },
    { code: "US/Arizona", value: "(GMT-07:00) Arizona" },
    { code: "America/Chihuahua", value: "(GMT-07:00) Chihuahua, La Paz, Mazatlan" },
    { code: "US/Mountain", value: "(GMT-07:00) Mountain Time (US & Canada)" },
    { code: "America/Managua", value: "(GMT-06:00) Central America" },
    { code: "US/Central", value: "(GMT-06:00) Central Time (US & Canada)" },
    { code: "America/Mexico_City", value: "(GMT-06:00) Guadalajara, Mexico City, Monterrey" },
    { code: "Canada/Saskatchewan", value: "(GMT-06:00) Saskatchewan" },
    { code: "America/Bogota", value: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco" },
    { code: "US/Eastern", value: "(GMT-05:00) Eastern Time (US & Canada)" },
    { code: "US/East-Indiana", value: "(GMT-05:00) Indiana (East)" },
    { code: "Canada/Atlantic", value: "(GMT-04:00) Atlantic Time (Canada)" },
    { code: "America/Caracas", value: "(GMT-04:00) Caracas, La Paz" },
    { code: "America/Manaus", value: "(GMT-04:00) Manaus" },
    { code: "America/Santiago", value: "(GMT-04:00) Santiago" },
    { code: "Canada/Newfoundland", value: "(GMT-03:30) Newfoundland" },
    { code: "America/Sao_Paulo", value: "(GMT-03:00) Brasilia" },
    { code: "America/Argentina/Buenos_Aires", value: "(GMT-03:00) Buenos Aires, Georgetown" },
    { code: "America/Godthab", value: "(GMT-03:00) Greenland" },
    { code: "America/Montevideo", value: "(GMT-03:00) Montevideo" },
    { code: "America/Noronha", value: "(GMT-02:00) Mid-Atlantic" },
    { code: "Atlantic/Cape_Verde", value: "(GMT-01:00) Cape Verde Is." },
    { code: "Atlantic/Azores", value: "(GMT-01:00) Azores" },
    { code: "Africa/Casablanca", value: "(GMT+00:00) Casablanca, Monrovia, Reykjavik" },
    { code: "Etc/Greenwich", value: "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London" },
    { code: "Europe/Amsterdam", value: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
    { code: "Europe/Belgrade", value: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
    { code: "Europe/Brussels", value: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris" },
    { code: "Europe/Sarajevo", value: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb" },
    { code: "Africa/Lagos", value: "(GMT+01:00) West Central Africa" },
    { code: "Asia/Amman", value: "(GMT+02:00) Amman" },
    { code: "Europe/Athens", value: "(GMT+02:00) Athens, Bucharest, Istanbul" },
    { code: "Asia/Beirut", value: "(GMT+02:00) Beirut" },
    { code: "Africa/Cairo", value: "(GMT+02:00) Cairo" },
    { code: "Africa/Harare", value: "(GMT+02:00) Harare, Pretoria" },
    { code: "Europe/Helsinki", value: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
    { code: "Asia/Jerusalem", value: "(GMT+02:00) Jerusalem" },
    { code: "Europe/Minsk", value: "(GMT+02:00) Minsk" },
    { code: "Africa/Windhoek", value: "(GMT+02:00) Windhoek" },
    { code: "Asia/Kuwait", value: "(GMT+03:00) Kuwait, Riyadh, Baghdad" },
    { code: "Europe/Moscow", value: "(GMT+03:00) Moscow, St. Petersburg, Volgograd" },
    { code: "Africa/Nairobi", value: "(GMT+03:00) Nairobi" },
    { code: "Asia/Tbilisi", value: "(GMT+03:00) Tbilisi" },
    { code: "Asia/Tehran", value: "(GMT+03:30) Tehran" },
    { code: "Asia/Muscat", value: "(GMT+04:00) Abu Dhabi, Muscat" },
    { code: "Asia/Baku", value: "(GMT+04:00) Baku" },
    { code: "Asia/Yerevan", value: "(GMT+04:00) Yerevan" },
    { code: "Asia/Kabul", value: "(GMT+04:30) Kabul" },
    { code: "Asia/Yekaterinburg", value: "(GMT+05:00) Yekaterinburg" },
    { code: "Asia/Karachi", value: "(GMT+05:00) Islamabad, Karachi, Tashkent" },
    { code: "Asia/Calcutta", value: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi" },
    { code: "Asia/Calcutta", value: "(GMT+05:30) Sri Jayawardenapura" },
    { code: "Asia/Katmandu", value: "(GMT+05:45) Kathmandu" },
    { code: "Asia/Almaty", value: "(GMT+06:00) Almaty, Novosibirsk" },
    { code: "Asia/Dhaka", value: "(GMT+06:00) Astana, Dhaka" },
    { code: "Asia/Rangoon", value: "(GMT+06:30) Yangon (Rangoon)" },
    { code: "Asia/Bangkok", value: "(GMT+07:00) Bangkok, Hanoi, Jakarta" },
    { code: "Asia/Krasnoyarsk", value: "(GMT+07:00) Krasnoyarsk" },
    { code: "Asia/Hong_Kong", value: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi" },
    { code: "Asia/Kuala_Lumpur", value: "(GMT+08:00) Kuala Lumpur, Singapore" },
    { code: "Asia/Irkutsk", value: "(GMT+08:00) Irkutsk, Ulaan Bataar" },
    { code: "Australia/Perth", value: "(GMT+08:00) Perth" },
    { code: "Asia/Taipei", value: "(GMT+08:00) Taipei" },
    { code: "Asia/Tokyo", value: "(GMT+09:00) Osaka, Sapporo, Tokyo" },
    { code: "Asia/Seoul", value: "(GMT+09:00) Seoul" },
    { code: "Asia/Yakutsk", value: "(GMT+09:00) Yakutsk" },
    { code: "Australia/Adelaide", value: "(GMT+09:30) Adelaide" },
    { code: "Australia/Darwin", value: "(GMT+09:30) Darwin" },
    { code: "Australia/Brisbane", value: "(GMT+10:00) Brisbane" },
    { code: "Australia/Canberra", value: "(GMT+10:00) Canberra, Melbourne, Sydney" },
    { code: "Australia/Hobart", value: "(GMT+10:00) Hobart" },
    { code: "Pacific/Guam", value: "(GMT+10:00) Guam, Port Moresby" },
    { code: "Asia/Vladivostok", value: "(GMT+10:00) Vladivostok" },
    { code: "Asia/Magadan", value: "(GMT+11:00) Magadan, Solomon Is., New Caledonia" },
    { code: "Pacific/Auckland", value: "(GMT+12:00) Auckland, Wellington" },
    { code: "Pacific/Fiji", value: "(GMT+12:00) Fiji, Kamchatka, Marshall Is." },
    { code: "Pacific/Tongatapu", value: "(GMT+13:00) Nuku'alofa" }
];

export const langList = [
    { code: 'RUS', value: "Русский" },
    { code: 'KAZ', value: "Казахский" },
    { code: 'ENG', value: "Английский" },
];

const UsersCreate = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const { id } = useParams();

    const [user, setUser] = useState<User>(new User());

    useEffect(() => {
        if (id != null) {
            api.camunda.getUser(id).then(result => {
                result.firstName = result.fullName?.split(' ')[0];
                result.lastName = result.fullName?.split(' ')[1];
                setUser(result);
            });
        }
    }, []);

    const [emailError, setEmailError] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [isSetPassword, setIsSetPassword] = useState(id == null);
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [password2Error, setPassword2Error] = useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setEmailError(!emailRegex.test(user.email || ''));
        setLoginError(!loginRegex.test(user.login || ''));
        setPasswordError(!mediumRegex.test(password));
        setPassword2Error(password !== password2);
        if (!emailRegex.test(user.email || '') || !loginRegex.test(user.login || '') ||
            isSetPassword && (!mediumRegex.test(password) || password !== password2)) {
            return;
        } else {
            api.camunda.newUser({
                login: user.login,
                email: user.email,
                fullName: user.firstName + ' ' + user.lastName,
                company: user.company,
                lang: user.lang,
                gmt: user.gmt
            })
                .then(() => {
                    if (isSetPassword) {
                        api.camunda.newPassword(user.login, password)
                            .then(() => {
                                navigate('/users');
                            })
                            .catch(error => console.log(error));
                    } else {
                        navigate('/users');
                    }
                }
                )
                .catch(error => console.log(error));

        }
    };

    const bindRole = (role?: Role) => {
        if (role) {
            var roles = user.roles || [];
            if (!roles.some(r => r.code == role.code)) {
                roles?.push(role);
                api.camunda.bindUserRole(user.login || '', role.code).then(() => {
                    processManager.openSnackBar(t("user.create.bindsuccess"));
                    setUser(
                        iassign(
                            user,
                            c => c.roles,
                            () => roles
                        )
                    );
                });
            }
        }
    };

    const unbindRole = (role: Role) => {
        api.camunda.unbindUserRole(user.login + '', role.code).then(() => {
            processManager.openSnackBar(t("user.create.unbindsuccess"));
            setUser(
                iassign(
                    user,
                    c => c.roles,
                    p => p?.filter(m => m.code !== role.code)
                )
            );
        });
    };

    return (
        <Grid container spacing={2}>
            <Grid xs={12} md={8} lg={8}>
                <Toolbar>
                    <Button variant="contained" component={Link} to='/users' startIcon={<CancelPresentation />}>{t("user.create.cancel")}</Button>
                </Toolbar>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <Grid sx={{ p: 2 }}>
                        <Typography component="h2" variant="h6">
                            {id ? t("user.create.edit") : t("user.create.new")}
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2} sx={{ pb: 2 }}>
                                <Grid item xs={12}>
                                    <TextField

                                        size='small'
                                        id="code"
                                        value={user.company}
                                        label={t("user.create.company")}
                                        onChange={(e) => setUser({ ...user, company: e.target.value })}
                                        autoFocus
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        size='small'
                                        autoComplete="given-name"
                                        name="firstName"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label={t("user.create.name")}
                                        value={user.firstName}
                                        onChange={(e) => setUser({ ...user, firstName: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        size='small'
                                        required
                                        fullWidth
                                        id="lastName"
                                        label={t("user.create.lastname")}
                                        name="lastName"
                                        autoComplete="family-name"
                                        value={user.lastName}
                                        onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        size='small'
                                        required
                                        fullWidth
                                        id="email"
                                        label={t("user.create.email")}
                                        name="email"
                                        autoComplete="email"
                                        value={user.email}
                                        onChange={(e) => setUser({ ...user, email: e.target.value })}
                                        error={emailError}
                                        helperText={emailError ? t("user.create.emailerror") : ""}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl required fullWidth size='small'>
                                        <InputLabel id="lang-select-label">{t("user.create.lang")}</InputLabel>
                                        <Select
                                            size='small'
                                            labelId="lang-select-label"
                                            id="lang-select"
                                            value={user.lang}
                                            label={t("user.create.lang")}
                                            onChange={(e) => setUser({ ...user, lang: e.target.value })}
                                        >
                                            {langList.map((g, i) => <MenuItem key={i} value={g.code}>{g.value}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl required fullWidth size='small'>
                                        <InputLabel id="gmt-select-label">{t("user.create.gmt")}</InputLabel>
                                        <Select
                                            size='small'
                                            labelId="gmt-select-label"
                                            id="gmt-select"
                                            value={user.gmt}
                                            label={t("user.create.gmt")}
                                            onChange={(e) => setUser({ ...user, gmt: e.target.value })}
                                        >
                                            {gmtList.map((g, i) => <MenuItem key={i} value={g.code}>{g.value}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        disabled={id != null}
                                        size='small'
                                        required
                                        fullWidth
                                        id="login"
                                        label={t("user.create.login")}
                                        name="login"
                                        autoComplete="login"
                                        error={loginError}
                                        helperText={loginError ? t("user.create.loginerror") : ""}
                                        value={user.login}
                                        onChange={(e) => setUser({ ...user, login: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox value="allowExtraEmails" color="primary" checked={isSetPassword} onChange={(e) => setIsSetPassword(e.target.checked)} />}
                                        label={t("user.create.setpassword")}
                                    />
                                </Grid>
                                {isSetPassword &&
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            size='small'
                                            required
                                            fullWidth
                                            name="password"
                                            label={t("user.create.password")}
                                            type="password"
                                            id="password"
                                            autoComplete="new-password"
                                            error={passwordError}
                                            helperText={t("user.create.passwordhelper")}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </Grid>
                                }
                                {isSetPassword &&
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            size='small'
                                            required
                                            fullWidth
                                            name="password2"
                                            label={t("user.create.password2")}
                                            type="password"
                                            id="password2"
                                            autoComplete="new-password"
                                            error={password2Error}
                                            helperText={password2Error ? t("user.create.passwordhelper2") : ""}
                                            value={password2}
                                            onChange={(e) => setPassword2(e.target.value)}
                                        />
                                    </Grid>
                                }
                            </Grid>
                            {id &&
                                <Fragment>
                                    <Divider />
                                    <Grid xs={12} sm={12} item sx={{ pt: 2, pb: 2 }}>
                                        <b>{t("user.create.bindroles")}: </b>
                                        <Stack direction="row" spacing={0} sx={{ flexWrap: 'wrap', gap: 1 }}>
                                            {user.roles?.map((r, i) => <Chip key={i} label={r?.description} variant="outlined" onDelete={() => unbindRole(r)} />)}
                                        </Stack>
                                    </Grid>
                                    <Divider />
                                    <Grid xs={12} sm={6} item sx={{ pt: 2 }}>
                                        <RolePicker onSelectedItem={bindRole} />
                                    </Grid>
                                </Fragment>
                            }
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {t("user.create.save")}
                            </Button>
                        </Box>
                    </Grid>
                </Paper>
            </Grid>
        </Grid >
    );
};

export default UsersCreate;
import { server } from '../core/server/axios';

const wenv = process.env;
export class ProcessDefinitionInfo {
  id?: string;
  name?: string;
  key?: string;
  description?: string;
  form?: TaskFormData;
}

export class StartProcess {
  processDefinitionKey?: string;
  variables?: any;
}

export class Process {
  task?: Task;
}

export class Task {
  id?: string;
  processInstanceId?: string;
  processDefinitionId?: string;
  processDefinitionKey?: string;
  processName?: string;
  businessKey?: string;
  definitionKey?: string;
  name?: string;
  created?: string;
  due?: any;
  assignee?: string;
  variables?: any;
  form?: TaskFormData;
  buttons?: Array<FlowButton>;
}

export class FlowButton {
  id?: string;
  label?: string;
}

export class TaskFormData {
  components?: Array<TaskFormComponent>;
}

export class TaskFormComponent {
  label?: string;
  type?: string;
  id?: string;
  key?: string;
  description?: string;
  defaultValue?: any;
  values?: Array<LabelValue>;
  text?: string;
  disabled?: boolean;
  validate?: {
    required?: boolean;
    minLength?: number;
    maxLength?: number;
    pattern?: string;
  };
  properties?: any;
  dynamic?: boolean;
}

export class LabelValue {
  label?: string;
  value?: string;
}

export class CompleteTask {
  taskId?: string;
  processInstanceId?: string;
  variables?: any;
}

export class HistoricProcessAndTaskInstances {
  process: HistoryProcIns = new HistoryProcIns();
  tasks: Array<TaskHistory> = [];
}

export class TaskHistory {
  deleteReason?: string;
  processBusinessKey?: string;
  taskDefinitionKey?: string;
  name?: string;
  processInstanceId?: string;
  processDefinitionKey?: string;
  duration?: number;
  startTime?: string;
  endTime?: string;
  assignee?: string;
  owner?: string;
  description?: string;
}

export class HistoryProcIns {
  processDefinitionName?: string;
  startUserId?: string;
  processInstanceId?: string;
  processDefinitionKey?: string;
  startTime = '';
  endTime?: string;
  state?: string;
  processInstanceBusinessKey?: string;
  processDefinitionId?: string;
  businessKey?: string;
}

export class Role {
  code = "";
  description = "";
  comments = "";
  authority = "";
  queues?: Queue[];
}

export class Group {
  code = "";
  description = "";
  roles?: Role[] = [];
}

export class UserContext {
  token?: Token;
  user?: User;
  auth2Required?: boolean;
}

export class Token {
  accessToken?: string;
  refreshToken?: string;
}

export class User {
  login?: string = '';
  email?: string = '';
  firstName?: string = '';
  lastName?: string = '';
  fullName?: string = '';
  company?: string = '';
  lang?: string = '';
  gmt?: string = '';
  roles?: Role[];
  rolesString?: string[];
  photo?: Photo;
}

export class Photo {
  id?: string;
  name?: string;
  type?: string;
  data?: string;
}

export interface PhotoUploadResponse {
  name?: string;
  size?: number;
  type?: string;
  uri?: string;
}

export interface Pagination<T> {
  totalElements: number;
  totalPages: number;
  last: boolean;
  size: number;
  number: number;
  content: T[];
}

export interface Content<T> {
  totalElements: number;
  totalPages: number;
  last: boolean;
  size: number;
  number: number;
  content: T[];
}

export class Queue {
  id = 0;
  name = "";
  process = "";
  filter: Array<VariableFilter> = [];
  view: Array<VariableView> = [];
  roles?: Role[] = [];
  users?: User[] = [];
  distributedAssign = false;
  distributedTask = false;
  distributedAdmin = false;
  readOnly = false;
  showAssignee = false;
  showSla = false;
  showHistory = false;
  showComments = false;
  ord = 0;
  queueResponse: QueueResponse = new QueueResponse();
  public constructor(init?: Partial<Queue>) {
    Object.assign(this, init);
  }
}

export class QueueResponse {
  name = "";
  queue: Array<any> = [];
  pageNum = 1;
  pages = 5;
  totalResults = 0;
}

export class VariableFilter {
  key = "";
  value = "";
  operator: "Equals" | "NotEquals" | "GreaterThan" | "GreaterThanOrEquals" | "LessThan" | "LessThanOrEquals" | "Like" | "NotLike" = "Equals";
}

export class VariableView {
  title = "";
  key = "";
  builtIn = true;
  public constructor(init?: Partial<VariableView>) {
    Object.assign(this, init);
  }
}

export class CamundaProcess {
  id?: string;
  name = "";
  key = "";
  description = "";
  ceaModuleCode = "";
}

export class CodeValue {
  code = "";
  value = "";
}

export class CamnundaController {

  async reassignTask(taskId: string, user: string) {
    return await server.get(`/sonata/api/task/assign/${taskId}/to/${user}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async getUserRoles(login: string) {
    return await server.get(`/sonata/api/role/getUserRoles/${login}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }
  async getRoleQueues(role: string) {
    return await server.get(`/sonata/api/queue/roleQueues/${role}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async getRole(code: string): Promise<Role> {
    return server.get(`/sonata/api/role/${code}`, {
      baseURL: wenv.REACT_APP_BACKEND,
    });
  }

  async deleteRole(code: string): Promise<void> {
    return server.delete(`/sonata/api/role/${code}`, {
      baseURL: wenv.REACT_APP_BACKEND,
    });
  }

  async getRoles(
    pageNumber: number,
    pageSize: number,
    code?: string,
    description?: string
  ): Promise<Content<Role>> {
    return server.get(
      `/sonata/api/role/${pageNumber}/${pageSize}?code=${code ?? ""}&desc=${description ?? ""
      }`,
      {
        baseURL: wenv.REACT_APP_BACKEND,
      }
    );
  }

  async registerRole(role: Role): Promise<void> {
    return server.post(`/sonata/api/role`, role, {
      baseURL: wenv.REACT_APP_BACKEND,
    });
  }

  async bindUserRole(username: string, role: string): Promise<void> {
    return server.post(
      `/sonata/api/role/bind`,
      { username, role },
      {
        baseURL: wenv.REACT_APP_BACKEND,
      }
    );
  }

  async unbindUserRole(username: string, role: string): Promise<void> {
    return server.post(
      `/sonata/api/role/unbind`,
      { username, role },
      {
        baseURL: wenv.REACT_APP_BACKEND,
      }
    );
  }

  async getTaskList(page: number, size: number) {
    return await server.post(
      `/sonata/api/task/myTasks/${page}/${size}`,
      {},
      {
        baseURL: wenv.REACT_APP_BACKEND
      }
    );
  }

  async getUserQueues(): Promise<Queue[]> {
    return await server.get(`/sonata/api/queue/userQueues/`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async getQueues(page: number, size: number): Promise<Queue[]> {
    return await server.get(`/sonata/api/queue/${page}/${size}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async getQueueList(id: number, page: number, size: number, searchFilter?: Object): Promise<QueueResponse> {
    return await server.post(
      `/sonata/api/queue/${id}/${page}/${size}`,
      searchFilter,
      {
        baseURL: wenv.REACT_APP_BACKEND
      }
    );
  }

  async registerQueue(queue: Queue): Promise<void> {
    return server.post(`/sonata/api/queue`, queue, {
      baseURL: wenv.REACT_APP_BACKEND,
    });
  }

  async deleteQueue(id: number): Promise<void> {
    return server.delete(`/sonata/api/queue/${id}`, {
      baseURL: wenv.REACT_APP_BACKEND,
    });
  }

  async getQueuesName(
    pageNumber: number,
    pageSize: number,
    name: string
  ): Promise<Content<Queue>> {
    return server.get(
      `/sonata/api/queue/${pageNumber}/${pageSize}?name=${name}`,
      {
        baseURL: wenv.REACT_APP_BACKEND,
      }
    );
  }

  async getQueuesProcess(
    pageNumber: number,
    pageSize: number,
    process: string
  ): Promise<Content<Queue>> {
    return server.get(
      `/sonata/api/queue/${pageNumber}/${pageSize}?process=${process}`,
      {
        baseURL: wenv.REACT_APP_BACKEND,
      }
    );
  }

  async getQueue(id: number) {
    return server.get(`/sonata/api/queue/${id}`, {
      baseURL: wenv.REACT_APP_BACKEND,
    });
  }

  async bindQueueRole(queueId: number, role: string): Promise<void> {
    return server.post(
      `/sonata/api/queue/bind-role`,
      { queueId, role },
      {
        baseURL: wenv.REACT_APP_BACKEND,
      }
    );
  }
  async unbindQueueRole(queueId: number, role: string): Promise<void> {
    return server.post(
      `/sonata/api/queue/unbind-role`,
      { queueId, role },
      {
        baseURL: wenv.REACT_APP_BACKEND,
      }
    );
  }
  async bindQueueUser(queueId: number, user?: string): Promise<void> {
    return server.post(
      `sonata/api/queue/bind-user`,
      { queueId, user },
      {
        baseURL: wenv.REACT_APP_BACKEND,
      }
    );
  }
  async unbindQueueUser(queueId: number, user?: string): Promise<void> {
    return server.post(
      `/sonata/api/queue/unbind-user`,
      { queueId, user },
      {
        baseURL: wenv.REACT_APP_BACKEND,
      }
    );
  }

  async changeOrder(queueId1: number, queueId2: number): Promise<void> {
    return server.get(
      `/sonata/api/queue/change-order/${queueId1}/${queueId2}`,
      {
        baseURL: wenv.REACT_APP_BACKEND,
      }
    );
  }

  async getBpList(): Promise<Array<ProcessDefinitionInfo>> {
    return await server.get(`/sonata/api/process/bplist/`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async getFullBpList(): Promise<Array<ProcessDefinitionInfo>> {
    return await server.get(`/sonata/api/process/fullbplist/`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async start(request: StartProcess): Promise<Process> {
    return await server.post(`/sonata/api/process/start`, request, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async startPublic(request: StartProcess): Promise<Process> {
    return await server.postPublic(`/sonata/api/process/start`, request, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async completeTask(request: CompleteTask): Promise<Task> {
    return await server.post(`/sonata/api/task/complete`, request,

      {
        // headers: {
        //   'token': 'Bearer ' + AuthUtil.getToken().accessToken
        // },
        baseURL: wenv.REACT_APP_BACKEND
      })
      ;
  }

  async getTask(taskId?: string): Promise<Task> {
    return await server.get(`/sonata/api/task/get/${taskId}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async claimTask(taskId?: string): Promise<Task> {
    return await server.get(`/sonata/api/task/claim/${taskId}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async getDistributedTask(queueId?: number): Promise<Task> {
    return await server.get(`/sonata/api/task/claim/distributed/${queueId}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async getTaskBusinessKey(businessKey?: string): Promise<Task> {
    return await server.get(`/sonata/api/task/claim/businessKey/${businessKey}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async assignTask(taskId: string, username: string): Promise<Task> {
    return await server.get(`/sonata/api/task/assign/${taskId}/to/${username}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async login(login?: string, password?: string, keepLoggedIn?: boolean): Promise<UserContext> {
    return await server.token(
      `/sonata/api/auth/login`,
      {
        login: login,
        password: password,
        keepLoggedIn: keepLoggedIn
      },
      {
        baseURL: wenv.REACT_APP_BACKEND
      }
    );
  }

  async loginAuth2(passcode?: string, keepLoggedIn?: boolean): Promise<{ token: string }> {
    return await server.post(
      `/sonata/api/auth/login/auth2`,
      {
        passcode: passcode,
        keepLoggedIn: keepLoggedIn
      },
      {
        baseURL: wenv.REACT_APP_BACKEND
      }
    );
  }

  async refresh(token: { refreshToken: string }): Promise<UserContext> {
    return await server.post(`/sonata/api/auth/refresh`, token, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async uploadPhoto(file: File): Promise<PhotoUploadResponse> {
    const formData = new FormData();
    formData.append('file', file);
    return await server.post(`/sonata/api/user/uploadFile`, formData, {
      headers: {
        'Content-Type': `multipart/form-data;`
      },
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async downloadPhoto(uri?: string) {
    return await server.get(`${uri}`);
  }

  async getUsersContains(
    pageNumber: number,
    pageSize: number,
    text: string,
    cache?: boolean
  ): Promise<Pagination<User>> {
    return server.get(
      `/sonata/api/user/contains/${pageNumber}/${pageSize}?text=${text}`,
      {
        baseURL: wenv.REACT_APP_BACKEND,
        headers: {
          cache: cache
        }
      }
    );
  }

  async getUsersColleagues(
    pageNumber: number,
    pageSize: number,
    text: string,
    cache?: boolean
  ): Promise<Pagination<User>> {
    return server.get(
      `/sonata/api/user/colleagues/${pageNumber}/${pageSize}?text=${text}`,
      {
        baseURL: wenv.REACT_APP_BACKEND,
        headers: {
          cache: cache
        }
      }
    );
  }

  async getUsers(text: string): Promise<any> {
    return await server.get(`/sonata/api/user/contains/0/20?text=${text}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async getUser(login: string, cache?: boolean): Promise<User> {
    return await server.get(`/sonata/api/user/${login}`, {
      baseURL: wenv.REACT_APP_BACKEND,
      headers: {
        cache: cache
      }
    });
  }

  async getPasscode(): Promise<any> {
    return await server.post(
      `/sonata/api/user/passcode`,
      {},
      {
        baseURL: wenv.REACT_APP_BACKEND
      }
    );
  }

  async newUser(user: User) {
    return await server.post(
      `/sonata/api/user/`,
      user,
      {
        baseURL: wenv.REACT_APP_BACKEND
      }
    );
  }

  async updateUser(user: User) {
    return await server.post(
      `/sonata/api/user/own/update`,
      user,
      {
        baseURL: wenv.REACT_APP_BACKEND
      }
    );
  }

  async deleteUser(login: string): Promise<void> {
    return server.delete(`/sonata/api/user/${login}`, {
      baseURL: wenv.REACT_APP_BACKEND,
    });
  }

  async resetPassword(login?: string, password?: string) {
    return await server.post(
      `/sonata/api/user/newpassword`,
      {
        login: login,
        password: password
      },
      {
        baseURL: wenv.REACT_APP_BACKEND
      }
    );
  }

  async newPassword(login?: string, password?: string) {
    return await server.post(
      `/sonata/api/user/newpassword`,
      {
        login: login,
        password: password
      },
      {
        baseURL: wenv.REACT_APP_BACKEND
      }
    );
  }

  async ownNewPassword(password: string, newPassword: string) {
    return await server.post(
      `/sonata/api/user/own/newpassword`,
      {
        login: "",
        password: password,
        newPassword: newPassword
      },
      {
        baseURL: wenv.REACT_APP_BACKEND
      }
    );
  }

  async newPasswordAdmin(login?: string, password?: string) {
    return await server.post(
      `/sonata/api/user/admin/newpassword`,
      {
        login: login,
        password: password
      },
      {
        baseURL: wenv.REACT_APP_BACKEND
      }
    );
  }

  async restart(
    processDefinitionKey: string,
    businessKey: string,
    activityId: string,
    variables: []
  ): Promise<any> {
    return await server.post(
      `/sonata/api/process/restart`,
      {
        processDefinitionKey: processDefinitionKey,
        businessKey: businessKey,
        activityId: activityId,
        variables: variables
      },
      {
        baseURL: wenv.REACT_APP_BACKEND
      }
    );
  }

  async getTaskHistory(processInstanceId?: string): Promise<HistoricProcessAndTaskInstances> {
    return await server.get(`/sonata/api/task/history/${processInstanceId}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async getTaskStatus(processInstanceBusinessKey?: string): Promise<any[]> {
    return await server.get(`/sonata/api/history/process-tasks/${processInstanceBusinessKey}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async getHistoryProcInst(filter: string): Promise<HistoryProcIns[]> {
    return await server.get(
      `rest/history/process-instance?${filter}`, //finishedBefore
      {
        baseURL: wenv.REACT_APP_BACKEND
      }
    );
  }
}

import * as React from "react";
import Box from "@mui/material/Box";
import { ProcessReducer } from "@/redux/modules/Process";
import { useSelector } from "react-redux";
import PopupSnackBar from "./PopupSnackBar";
import PopupBackDrop from "./PopupBackDrop";
import pm from "@/core/helpers/ProcessManager";
import { LinearProgress } from "@mui/material";

const invisibleLayout = (Component: React.FC) => (
  <Layout>
    <Component />
  </Layout>
);

export const Layout = (props?: any) => {
  const process: ProcessReducer = useSelector((state: any) => state.process);

  const { isLoading, snackBar, backdrop } = process;

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <PopupSnackBar onClose={() => pm.closeSnackBar()} {...snackBar} />
      <PopupBackDrop {...backdrop} />
      <div style={isLoading ? { display: "block" } : { display: "none" }}>
        <LinearProgress />
      </div>
      <React.Fragment>{props.children}</React.Fragment>
    </Box>
  );
};

export default invisibleLayout;

import { createStore as _createStore, applyMiddleware, compose } from 'redux';

import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import promiseMiddleware from 'redux-promise-middleware';
import loggerMiddleware from './middleware/logger';

declare const module: any;
declare const window: any;

export default function createStore(history: any, client: any, data: any) {
  const reduxRouterMiddleware = routerMiddleware(history);

  const middleware = [reduxRouterMiddleware, promiseMiddleware, thunk, loggerMiddleware];

  let finalCreateStore;

  if (process.env.REACT_APP_DEVELOPMENT && process.env.REACT_APP_CLIENT) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    finalCreateStore = composeEnhancers(applyMiddleware(...middleware))(_createStore);
  } else {
    finalCreateStore = applyMiddleware(...middleware)(_createStore);
  }

  const reducer = require('./modules/reducer').default;

  const store = finalCreateStore(reducer, data);

  if (process.env.REACT_APP_DEVELOPMENT && module.hot) {
    module.hot.accept('./modules/reducer', () => {
      store.replaceReducer(require('./modules/reducer').default);
    });
  }

  return store;
}


import React from "react";
import { Routes, Route } from "react-router-dom";
import { CashListPage } from './pages';
import { wizard } from '@/hoc';

const CashBalance = () => (
	<Routes>
		<Route path="/" element={wizard(CashListPage, 'Отрытие кассы')} />
	</Routes>
);

export default CashBalance;

import { useApplication10Document } from "./useApplication10Document";
import styles from "./Application10Document.module.css";

export const Application10Document = () => {
	const { handlePrintApplication10Document } = useApplication10Document();

	return (
		<div style={{
			marginTop: "30px",
			background: "white",
			padding: "20px",
			borderRadius: "10px",
		}}
		>
			<div>
				<div className={styles.table_row}>
					<p>Филиал</p>
					<p style={{ justifySelf: "center" }}>Период</p>
				</div>

				<div
					className={styles.table_row}
					onClick={() => handlePrintApplication10Document()}
				>
					<p>Ecash</p>
					<p style={{ justifySelf: "center" }}>Июнь</p>
				</div>
				<div
					className={styles.table_row}
					onClick={() => handlePrintApplication10Document()}
				>
					<p>Ecash</p>
					<p style={{ justifySelf: "center" }}>Май</p>
				</div>
				<div
					className={styles.table_row}
					onClick={() => handlePrintApplication10Document()}
				>
					<p>Ecash</p>
					<p style={{ justifySelf: "center" }}>Апрель</p>
				</div>
				<div
					className={styles.table_row}
					onClick={() => handlePrintApplication10Document()}
				>
					<p>Ecash</p>
					<p style={{ justifySelf: "center" }}>Март</p>
				</div>
			</div>
		</div>
	);
};
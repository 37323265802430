import React, { useState } from "react";

import { Htag } from '@/components';

import styles from "./receipt.module.css";
import { Button } from '@mui/material';

import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type PDFFile = string | File | null;

const wenv = process.env;

const HomePage = (): JSX.Element => {

	const currentPath = window.location.pathname;

	const [file] = useState<PDFFile>(`${wenv.REACT_APP_API_URL}/Application/downloadApplication/${currentPath.slice(9)}`);

	return (
		<div className={styles.background_color}>
			<div className={styles.container}>
				<Htag tag={'h1'}>Скачать чек</Htag>
				<div style={{ maxWidth: "500px", margin: "auto", overflowX: "scroll", border: '1px solid #0000008c' }}>
					<Document file={file} onLoadSuccess={console.error}>
						<Page
							className={styles.pdfDoc}
							renderTextLayer={false}
							renderAnnotationLayer={false}
							pageNumber={1}
							width={500}
						/>
					</Document>
				</div>
				<a rel={"noreferrer"} href={`${wenv.REACT_APP_API_URL}/Application/downloadApplication/${currentPath.slice(9)}`} target='_blank'>
					<Button
						sx={{
							backgroundColor: "#005792",
							marginTop: "10px",
							'&:hover': {
								backgroundColor: "#13334c",
							},
						}}
						fullWidth
						variant="contained"
						size="large"
					>
						Скачать чек
					</Button>
				</a>
			</div>
		</div>
	);
};

export default HomePage;
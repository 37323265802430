import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import { ArrowRight, Logout } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { HeaderReducer, actions } from '@/redux/modules/Header';
import { connect, useDispatch } from "react-redux";
import { LinearProgress } from '@mui/material';

const drawerWidth = 280;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const bull = (
    <IconButton color="inherit">
        <ArrowRight />
    </IconButton>
);

const basePath = process.env.PUBLIC_URL;

const Header = (props: HeaderReducer & { isLoading: boolean }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userContext = props.userContext || {};
    const user = userContext.user || {};
    const avatar =
        user?.photo?.data && `data:image/*;base64, ${user?.photo?.data}`;


    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickProfile = () => {
        dispatch(actions.selectMenu({ path: '/profile', title: 'Мой профиль', index: 10 }));
        dispatch(actions.openQueueMenuBar(false));
        navigate('/profile');
    };

    const handleLogout = () => {
        localStorage.removeItem("userContext");
        (window as any).location.href = "/";
        dispatch(actions.logout());
    };

    return (
        <AppBar position="fixed" open={props.menuBarOpen}>
            <div style={props.isLoading ? { display: "block" } : { visibility: "hidden" }}>
                <LinearProgress />
            </div>
            <Toolbar
                sx={{
                    height: 50,
                    position: "relative",
                    overflow: "hidden",
                    background: "#EEEEEE",
                    color: "rgba(0, 0, 0, 0.54)",
                    pr: '24px', // keep right padding when drawer closed
                }}
            >
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => dispatch(actions.openMenuBar(!props.menuBarOpen))}
                    sx={{
                        marginRight: '36px',
                        ...(props.menuBarOpen && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <IconButton href='/'>
                    <img src={`${basePath}/LOGOSVG1.webp`} style={{
                        display: "inline-block",
                        width: 156,
                    }} />
                </IconButton>
                <Typography
                    variant="h6"
                    color="inherit"
                    sx={{ flexGrow: 1, fontWeight: "bold" }}
                    component="div"
                >
                    {props.selectedMenu && bull} {props.selectedMenu?.title} {props.selectedQueueMenu && props.selectedMenu?.path == '/queue' && bull} {props.selectedMenu?.path == '/queue' && props.selectedQueueMenu?.name}
                </Typography>

                <IconButton color="inherit">
                    <Badge badgeContent={props.selectedQueueMenu?.queueResponse?.totalResults} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <IconButton onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}>
                    <Avatar alt="Cindy Baker" src={avatar || "/smtava.jpeg"} />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={handleClickProfile} >
                        <Avatar /> Профиль
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

function mapStateToProps(state: any) {
    const header: HeaderReducer = state.header;

    return {
        userContext: header.userContext,
        menuBarOpen: header.menuBarOpen,
        selectedMenu: header.selectedMenu,
        selectedQueueMenu: header.selectedQueueMenu
    };
}

export default connect(mapStateToProps, null)(Header);
import { User } from '@/api/CamundaController';
import { formatNumberRu } from '@/core/helpers/PageHelpers';
import { PdfmakeFonts } from '@/core/helpers/PdfmakeFonts';
import { DepartmentModel, KassaModel } from '@/interface';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = PdfmakeFonts;

const actOfSurplusAndShortage = (currencyList: { currencyCode: string, factBalance: number, systemBalance: number }[], kassa: KassaModel, user: User, department: DepartmentModel) => {
	const tableArray = [
		[
			{ text: "Вид валюты", alignment: "center" },
			{ text: "Сумма в системе", alignment: "center" },
			{ text: "Фактическая сумма", alignment: "center" },
			{ text: "Излишки", alignment: "center" },
			{ text: "Недостачи", alignment: "center" },
		],
	];

	currencyList
		.map((cur) => {
			tableArray.push([
				{ text: cur.currencyCode, alignment: "center" },
				{ text: formatNumberRu(cur.systemBalance), alignment: "center" },
				{ text: formatNumberRu(cur.factBalance), alignment: "center" },
				{ text: cur.systemBalance < cur.factBalance ? formatNumberRu(cur.factBalance - cur.systemBalance) : "", alignment: "center" },
				{ text: cur.systemBalance > cur.factBalance ? formatNumberRu(cur.systemBalance - cur.factBalance) : "", alignment: "center" },
			]);
		});

	const showing: any = {
		pageMargins: [60, 40, 60, 40],
		content: [
			{
				stack: [
					{ text: "Акт" },
					{ text: "о недостаче/излишке банкнот/монет в пачках/мешках/упаковке" },
				],
				alignment: "center",
				bold: true,
			},
			{
				text: "\n"
			},
			{
				stack: [
					{
						text: `ТОО "${department.name}", ${department.bin} «${(new Date()).getDate()}» ${(new Date()).toLocaleString('default', { month: 'long' })} ${(new Date()).getFullYear()}г. составлен в ${user.fullName?.split(" ").reverse().join(" ")} в г.${department.code} в том, что сего числа при вскрытии и пересчете в кассе, комнате пересчета банкнот кассиром ${user.fullName?.split(" ").reverse().join(" ")} в присутствии________________________________________________________________`
					},
					{
						text: "_______________________________________________________________________________________________________"
					},
					{
						text: "(указать кассира и № бригады, № клише, № пломбира, в упаковке которого установлена недостача/излишек)",
						alignment: "center",
						fontSize: 9
					},
					{
						text: `Была обнаружена с целой/нарушенной клише/пломбой с целыми/нарушенными поясками/бандеролями недостачи/излишки (количество) банкнот/монет на сумму ${currencyList.map((cur, i) => i === 0 ? cur.systemBalance - cur.factBalance + " " + cur.currencyCode : " " + (cur.systemBalance - cur.factBalance) + " " + cur.currencyCode)}.`,
						margin: [0, 10, 0, 0]
					}
				],
				alignment: "justify"
			},
			{
				table: {
					widths: ["*", "*", "*", "*", "*"],
					body: tableArray,
				},
				margin: [0, 10, 0, 0]
			},
			{
				text: "Подписи (фамилии, инициалы) лиц, проводивших пересчет и присутствовавших при пересчете:",
				margin: [0, 10, 0, 0]
			},
			{
				text: `Кассир ${user.fullName?.split(" ").reverse().join(" ")}`,
				margin: [0, 10, 0, 0]
			},
			{
				text: `Контролер______________________`,
				margin: [0, 10, 0, 0]
			}
		],
		defaultStyle: {
			fontSize: 12,
			// font: "TimesNewRoman",
			font: "Roboto",
			lineHeight: 1.08
		},
	};

	return showing;
};

export default actOfSurplusAndShortage;

import React from "react";

import { SelectDepartments } from '@/components';

import { Button } from '@mui/material';

import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import styles from "./OrdersDocument.module.css";
import { useOrdersDocument } from './useOrdersDocument';
import { OrdersTable } from './OrdersTable/OrdersTable';

export const OrdersDocument = () => {
  const {
    regions,
    setDateRange,
    dateRange,
    handleLoadLogsList,
    logList,
    departmentFromLoadedLogs,
    datesFromloadedLogs,
    departmentStatus,
  } = useOrdersDocument();
  return (
    <>
      {departmentStatus === "resolve" && (
        <>
          <SelectDepartments regions={regions} isSelectKassa={false} />
        </>
      )}

      <div className={styles.justify_center}>
        <DateRange
          editableDateInputs={true}
          onChange={(item: any) => setDateRange([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={dateRange}
          className={styles.dateRange}
        />
      </div>

      <Button
        variant={"contained"}
        onClick={handleLoadLogsList}
        className={styles.load_btn}
      >
        Выгрузить расспоряжения
      </Button>

      {
        logList.length > 0 && departmentFromLoadedLogs
        &&
        <OrdersTable
          activeDepartment={departmentFromLoadedLogs}
          dates={datesFromloadedLogs}
          logList={logList}
        />
      }
    </>
  );
};
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";

import ClientForm from "../../../ClientForm";

import { Htag, ReserveCurrencyInfo, Timer } from '@/components';
import { closeBackDrop, openBackDrop, updateTaskVariables } from "@/redux/modules/Process";
import axios from "axios";
import {
  actions as clientInfoActions,
} from "@/redux/modules/ClientInfo";

import styles from "./successReserve.module.css";

const SuccessReserve = () => {

  const dispatch = useDispatch();
  const variables = useSelector(
    (state: any) => state?.process?.taskModel?.variables
  );
  const user = useSelector((state: any) => state?.header?.userContext?.user);

  const [openWindow, setOpenWindow] = useState(false);

  useEffect(() => {
    const phone = variables.phone.replace(/\s/g, "%20").replace(/\+/g, "%2B");
    dispatch(
      updateTaskVariables(
        (c) => c,
        (p) => {
          p.apiPhone = phone;

          return p;
        }
      )
    );
  }, []);

  return (
    <div className={styles.background_color}>
      <div className={styles.container}>
        <Htag tag={'h1'}>Курс успешно забронирован</Htag>
        <ReserveCurrencyInfo
          rateExchange={{
            buyAmount: variables.buyAmount,
            buyCurrencyCode: variables.buyCurrencyCode,
            depsId: variables.depsId,
            rate: variables.currentRate,
            saleAmount: variables.saleAmount,
            saleCurrencyCode: variables.saleCurrencyCode,
            requestId: null,
            isIndividual: false,
            kassaId: variables.kassaId
          }}
        />
        <Timer />
        <p className={styles.reserveCode}>
          Сообщите номер бронирования: {variables.businessKey}
        </p>

        {user?.roles?.find((item: any) => item.code.indexOf("Cashier") >= 0) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px"
            }}
          >
            <Button
              sx={{
                backgroundColor: "#005792",
                '&:hover': {
                  backgroundColor: "#13334c",
                },
              }}
              variant="contained"
              size="large"
              onClick={() => {
                dispatch(openBackDrop());
                axios
                  .post(
                    `https://api.ecash.kz/Client/SearchClient?phone=${variables.phone
                      .replace(/\s/g, "")
                      .replace(/-/g, "")
                      .replace(/\+/g, "")}`,
                    {
                      headers: {
                        accept: "*/*",
                      },
                    }
                  )
                  .then((res: any) => {
                    dispatch(
                      clientInfoActions.updateClientInfo(
                        (c) => c,
                        (p) => {
                          if (res.data.model !== null) {
                            p.phone = res.data.model.phone;
                            p.firstName = res.data.model.firstName;
                            p.middleName = res.data.model.middleName;
                            p.lastName = res.data.model.lastName;
                            p.iin = res.data.model.iin;
                            p.citizen = res.data.model.citizen;
                            p.birthday = new Date(+res.data.model.birthDay.slice(0, 4), +res.data.model.birthDay.slice(5, 7) - 1, +res.data.model.birthDay.slice(8, 10));
                            p.doctype =
                              res.data.model.documents[0].docType;
                            p.docNumber =
                              res.data.model.documents[0].docNumber;
                            p.issuePlace =
                              res.data.model.documents[0].issuePlace;
                            p.issueDate = new Date(+res.data.model.documents[0].issueDate.slice(0, 4), +res.data.model.documents[0].issueDate.slice(5, 7) - 1, +res.data.model.documents[0].issueDate.slice(8, 10));
                            p.validDate = new Date(+res.data.model.documents[0].validDate.slice(0, 4), +res.data.model.documents[0].validDate.slice(5, 7) - 1, +res.data.model.documents[0].validDate.slice(8, 10));
                            p.photoImg = res.data.model.documents[0].content;
                            p.signImg = res.data.model.documents[0].client;
                            p.clientId = res.data.model.clientId;
                            p.docId = res.data.model.documents[0].docId;
                            p.adrId = res.data.model.clientAdress[0]?.adrId || null;
                            p.country = res.data.model.clientAdress[0]?.country || "";
                            p.region = res.data.model.clientAdress[0]?.region || "";
                            p.locality = res.data.model.clientAdress[0]?.locality || "";
                            p.street = res.data.model.clientAdress[0]?.street || "";
                            p.number = res.data.model.clientAdress[0]?.number || "";
                            p.flat = res.data.model.clientAdress[0]?.flat || "";
                          } else {
                            p.firstName = "";
                            p.middleName = "";
                            p.lastName = "";
                            p.iin = "";
                            p.citizen = "Республика Казахстан";
                            p.birthday = "";
                            p.doctype = "";
                            p.docNumber = "";
                            p.issuePlace = "";
                            p.issueDate = "";
                            p.validDate = "";
                            p.photoImg = "";
                            p.signImg = "";
                            p.clientId = 0;
                            p.adrId = 0;
                            p.country = "";
                            p.region = "";
                            p.locality = "";
                            p.street = "";
                            p.number = "";
                            p.flat = "";
                          }

                          return p;
                        }
                      )
                    );
                    dispatch(closeBackDrop());
                    setOpenWindow(true);
                  })
                  .catch((e) => {
                    console.log(e);
                    dispatch(closeBackDrop());
                  });
              }}>
              Проверить на наличие анкеты
            </Button>

            {/* <Dialog
              open={openWindow}
              onClose={() => setOpenWindow(false)}
              maxWidth={"lg"}
              fullWidth>
              <ClientForm type={"client"} />
            </Dialog> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default SuccessReserve;

import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
// import {
//   Error404,
//   Login,
//   Reports,
//   StartProcess,
//   MyTasks,
//   Users,
//   Roles,
//   UserProfile,
//   TaskList,
//   Queues,
//   Calendars,
//   History,
//   ClientExchange,
//   Treasure,
//   DocumentPage,
//   CashBalance,
//   RequestList,
//   Receipt,
//   DepartmentOpen
// } from "./pages";
import authorizedLayout from "@/components/AuthorizedLayout";
import invisibleLayout from "./components/InvisibleLayout";
import { history } from "./core/helpers/history";
import CustomBrowserRouter from "./CustomBrowserRouter";
import Slas from "./pages/Slas";
import Processes from "./pages/Processes";
import MainPageLayout from './components/MainPageLayout/MainPageLayout';
import { Loading } from "@/components";
import { ClientExchange } from './pages';

const Error404 = React.lazy(() => import("./pages").then(module => ({ default: module.Error404 })));
const Login = React.lazy(() => import("./pages").then(module => ({ default: module.Login })));
const Reports = React.lazy(() => import("./pages").then(module => ({ default: module.Reports })));
const StartProcess = React.lazy(() => import("./pages").then(module => ({ default: module.StartProcess })));
const MyTasks = React.lazy(() => import("./pages").then(module => ({ default: module.MyTasks })));
const Users = React.lazy(() => import("./pages").then(module => ({ default: module.Users })));
const Roles = React.lazy(() => import("./pages").then(module => ({ default: module.Roles })));
const UserProfile = React.lazy(() => import("./pages").then(module => ({ default: module.UserProfile })));
const TaskList = React.lazy(() => import("./pages").then(module => ({ default: module.TaskList })));
const Queues = React.lazy(() => import("./pages").then(module => ({ default: module.Queues })));
const Calendars = React.lazy(() => import("./pages").then(module => ({ default: module.Calendars })));
const History = React.lazy(() => import("./pages").then(module => ({ default: module.History })));
// const ClientExchange = React.lazy(() => import("./pages").then(module => ({ default: module.ClientExchange })));
const Treasure = React.lazy(() => import("./pages").then(module => ({ default: module.Treasure })));
const DocumentPage = React.lazy(() => import("./pages").then(module => ({ default: module.DocumentPage })));
const CashBalance = React.lazy(() => import("./pages").then(module => ({ default: module.CashBalance })));
const RequestList = React.lazy(() => import("./pages").then(module => ({ default: module.RequestList })));
const Receipt = React.lazy(() => import("./pages").then(module => ({ default: module.Receipt })));
const DepartmentOpen = React.lazy(() => import("./pages").then(module => ({ default: module.DepartmentOpen })));

const RoutesComponent = () => (
  <React.StrictMode>
    <CustomBrowserRouter history={history}>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route
            path="login"
            element={invisibleLayout(Login)}
            errorElement={<Error404 />}
          />

          <Route
            path="startProcess"
            element={authorizedLayout(StartProcess)}
            errorElement={<Error404 />}
          />
          <Route
            path="task/:id/*"
            element={<Processes />}
            errorElement={<Error404 />}
          />
          <Route
            path="mytasks"
            element={authorizedLayout(MyTasks)}
            errorElement={<Error404 />}
          />
          <Route
            path="queue"
            element={authorizedLayout(TaskList)}
            errorElement={<Error404 />}
          />
          <Route
            path="history/*"
            element={<History />}
            errorElement={<Error404 />}
          />
          <Route
            path="reports"
            element={authorizedLayout(Reports)}
            errorElement={<Error404 />}
          />
          <Route
            path="profile"
            element={authorizedLayout(UserProfile)}
            errorElement={<Error404 />}
          />
          <Route path="users/*" element={<Users />} errorElement={<Error404 />} />
          <Route path="roles/*" element={<Roles />} errorElement={<Error404 />} />
          <Route
            path="queues/*"
            element={<Queues />}
            errorElement={<Error404 />}
          />
          <Route
            path="calendars/*"
            element={<Calendars />}
            errorElement={<Error404 />}
          />
          <Route path="slas/*" element={<Slas />} errorElement={<Error404 />} />
          <Route
            path="/"
            element={MainPageLayout(ClientExchange)}
            errorElement={<Error404 />}
          />
          <Route
            path="department-open/*"
            element={authorizedLayout(DepartmentOpen)}
            errorElement={<Error404 />}
          />
          <Route
            path="treasurer-rates/*"
            element={authorizedLayout(Treasure)}
            errorElement={<Error404 />}
          />
          <Route
            path="request-list/*"
            element={authorizedLayout(RequestList)}
            errorElement={<Error404 />}
          />
          <Route
            path="/documents/*"
            element={authorizedLayout(DocumentPage)}
            errorElement={<Error404 />}
          />
          <Route
            path="/cash-balance/*"
            element={authorizedLayout(CashBalance)}
            errorElement={<Error404 />}
          />
          <Route
            path="/receipt/*"
            element={MainPageLayout(Receipt)}
            errorElement={<Error404 />}
          />
        </Routes>
      </Suspense>
    </CustomBrowserRouter>
  </React.StrictMode>
);

export default RoutesComponent;
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pm from "@/core/helpers/ProcessManager";
import { selectActiveDepartment, selectDepartmentList } from '@/redux/modules/department/departmentSelectors';
import { loadDepartments, setActiveDepartmentId } from '@/redux/modules/department/departmentActions';
import { api } from '@/api/Api';
import { DepartmentModel } from '@/interface';

export const useDepartmentOpen = () => {
	const [openWindow, setOpenWindow] = useState(false);
	const [openEditWindow, setOpenEditWindow] = useState(false);
	const [openDepartmentInfoWindow, setOpenDepartmentInfoWindow] = useState(false);
	const [city, setCity] = useState<string | null>("");
	const [address, setAddress] = useState("");
	const [departmentName, setDepartmentName] = useState("");
	const [bin, setBin] = useState("");

	const dispatch = useDispatch();

	const departmentList = useSelector(selectDepartmentList);
	const activeDepartment = useSelector(selectActiveDepartment);

	useEffect(() => {
		handleGetDepartmentList();
	}, []);

	const handleGetDepartmentList = () => {
		dispatch(loadDepartments());
	};

	const handleChangeCity = (e: any, newValue: string | null) => {
		setCity(newValue);
	};

	const clearDepartmentParams = () => {
		setCity('');
		setAddress('');
		setDepartmentName('');
		setBin('');
	};

	const handleCloseWindow = () => {
		setOpenWindow(false);
		clearDepartmentParams();
	};
	const handleOpenWindow = () => setOpenWindow(true);

	const handleOpenEditWindow = (department: DepartmentModel) => {
		dispatch(setActiveDepartmentId(department.id));
		setOpenEditWindow(true);
		setCity(department.code);
		setAddress(department.address);
		setDepartmentName(department.name);
		setBin(department.bin);
	};
	const handleCloseEditWindow = () => {
		setOpenEditWindow(false);
		clearDepartmentParams();
	};

	const handleOpenDepartmentInfoWindow = (id: string | number) => {
		dispatch(setActiveDepartmentId(id));
		setOpenDepartmentInfoWindow(true);
	};
	const handleCloseDepartmentInfoWindow = () => setOpenDepartmentInfoWindow(false);

	const handleChangeAddress = (e: any) => setAddress(e.target.value);

	const handleChangeDepartmentName = (e: any) => setDepartmentName(e.target.value);

	const handleChangeBin = (e: any) => {
		let string = e.target.value.replace(/\D/g, "");
		string = string.slice(0, 12);
		setBin(string);
	};

	const handleAddDepartment = () => {
		pm.openBackDrop();
		api.department.addDepartment({ name: departmentName, code: city || "", address, bin, adviser: "Ержан" })
			.then((res: any) => {
				const department = res.value;
				api.currency.addCurrency({
					code: "KZT",
					description: "",
					country: "",
					spread: 0,
					margin: 0,
					isActive: true,
					depId: department.depsId,
					rates: [],
					fromParse: false,
					parseBuy: "",
					parseSale: "",
					parseSite: "",
					step: "",
				}).then(() => {
					handleCloseWindow();
					handleGetDepartmentList();
					clearDepartmentParams();
					pm.openSnackBar("Отделение добавлено", { severity: "success" });
				}).catch(() => pm.openSnackBar('Произошла ошибка: Не удалось добавить валюту "KZT - тенге" в новое отделение: обратитесь к IT-специалистам', { severity: "error" }));
			}).catch(() => pm.openSnackBar("Произошла ошибка: Не удалось получить данные по отеделениям", { severity: "error" }))
			.finally(() => pm.closeBackDrop());
	};

	const handleAddKassa = (id: string | number, kassaNum: string) => {
		pm.openBackDrop();
		api.kassa.addKassa({ depId: id, name: kassaNum, code: kassaNum, address: '', adviser: '' })
			.then(res => {
				if (res.value.depId >= 0) {
					handleGetDepartmentList();
				}
			})
			.catch(() => pm.openSnackBar("Произошла ошибка: Не удалось добавить кассу", { severity: "error" }))
			.finally(() => pm.closeBackDrop());
	};

	const handleUpdateDepartment = (department: DepartmentModel) => {
		pm.openBackDrop();
		api.department.updateDepartment({ id: department.id, name: departmentName, address: address, adviser: '', bin: bin, code: city || '' })
			.then(() => {
				handleGetDepartmentList();
				handleCloseEditWindow();
			})
			.catch(() => pm.openSnackBar("Произошла ошибка: Не удалось обновить данные по отеделению", { severity: "error" }))
			.finally(() => pm.closeBackDrop());
	};

	return {
		departmentList,
		city,
		handleChangeCity,
		openWindow,
		handleCloseWindow,
		handleOpenWindow,
		address,
		handleChangeAddress,
		departmentName,
		handleChangeDepartmentName,
		handleAddDepartment,
		activeDepartment,
		handleChangeBin,
		bin,
		handleOpenDepartmentInfoWindow,
		handleCloseDepartmentInfoWindow,
		openDepartmentInfoWindow,
		handleAddKassa,
		handleOpenEditWindow,
		openEditWindow,
		handleCloseEditWindow,
		handleUpdateDepartment
	};
};
import React from "react";

import { ModalDeleteCurrencyProps } from './ModalDeleteCurrency.props';

import ClearIcon from "@mui/icons-material/Clear";
import { Button } from '@mui/material';

import { ModalWindow } from '@/components';
import DeleteIcon from "@mui/icons-material/Delete";

import styles from "./ModalDeleteCurrency.module.css";

export const ModalDeleteCurrency = ({
	openWindow,
	handleCloseWindow,
	activeCurrency,
	handleDeleteCurrency
}: ModalDeleteCurrencyProps): JSX.Element => (
	<ModalWindow
		title={`Удалить валюту ${activeCurrency.code}`}
		open={openWindow}
		onClose={handleCloseWindow}
	>
		<form
			onSubmit={(e) => {
				e.preventDefault();
				activeCurrency?.currencyId && handleDeleteCurrency(activeCurrency?.currencyId);
			}}
		>
			<div className={styles.btn_group}>
				<Button
					variant="contained"
					size="large"
					startIcon={<ClearIcon />}
					sx={{
						background: "#b70000",
						"&:hover": {
							background: "#7f0101",
						},
					}}
					onClick={handleCloseWindow}
				>
					Отмена
				</Button>
				<Button
					variant="contained"
					size="large"
					startIcon={<DeleteIcon />}
					sx={{
						background: "#037703",
						"&:hover": {
							background: "#005f00",
						},
					}}
					type={"submit"}
				>
					Удалить
				</Button>
			</div>
		</form>
	</ModalWindow>
);
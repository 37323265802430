import { Grid, Typography, Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React from "react";
import { useTranslation } from "react-i18next";

export interface ModalFileUploadProps {
    open: boolean;
    text?: string;
    onClose?: () => void;
    onFileUpload: (file: File) => void;
    accept?: string
}

export default function ModalFileUpload(props: ModalFileUploadProps) {
    const { t } = useTranslation();

    const { open, text, onFileUpload, onClose, accept } = props;

    const [file, setFile] = React.useState<File>();

    const handleChooseFile = (e: any) => {
        setFile(e.target.files[0]);
    };

    const onFormSubmit = (e: any) => {
        e.preventDefault();
        onFileUpload(file ?? File.prototype);
    };
    return (
        <Dialog onClose={onClose} open={open} maxWidth={"lg"}>
            <DialogContent>
                <form onSubmit={onFormSubmit}>
                    <Grid container>
                        <Grid item>
                            <Typography>{text}</Typography>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container>
                        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                            <input type="file" onChange={handleChooseFile} accept={accept} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <Button type="submit" disabled={!file}>
                                {t("user.profile.uploadbutton")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
}

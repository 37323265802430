import { CurrencyModel } from '@/interface';
import { server } from '../core/server/axios';

const wenv = process.env;

export interface AddCurrencyCashVariables {
	balance: number;
	currencyCode: string;
	kassaId: string | number;
	currencyId: string | number;
	reserved: number;
}

export interface UpdateCurrencyCashVariables {
	cashId: number;
	balance: number;
	login: string;
}

export interface TransferCashBetweenKassaVariables {
	fromId: string | number;
	toId: string | number;
	currencyId: string | number;
	currencyCode: string;
	amount: number;
	initiator: string;
}

export class CurrencyController {
	async addCurrency(currency: CurrencyModel) {
		return await server.post('/Currency/addCurrency', currency, {
			baseURL: wenv.REACT_APP_API_URL
		});
	}

	async updateCurrency(currency: CurrencyModel) {
		return await server.patch('/Currency/updateCurrency', currency, {
			baseURL: wenv.REACT_APP_API_URL
		});
	}

	async deleteCurrency(currencyId: string | number) {
		return await server.delete(`/Currency/removeCurrency/${currencyId}`, {
			baseURL: wenv.REACT_APP_API_URL
		});
	}

	async addCurrencyCash(request: AddCurrencyCashVariables) {
		return await server.patch('/Cash/balance', request, {
			baseURL: wenv.REACT_APP_API_URL
		});
	}

	async updateCurrencyCash({ cashId, balance, login }: UpdateCurrencyCashVariables) {
		return await server.post(`/Cash/balanceUpdate?CashId=${cashId}&Balance=${balance}&Reserved=0&initiator=${login}`, {}, {
			baseURL: wenv.REACT_APP_API_URL
		});
	}

	async transferCashBetweenKassa(request: TransferCashBetweenKassaVariables) {
		return await server.post('/Department/cashTransfer', request, {
			baseURL: wenv.REACT_APP_API_URL
		});
	}

	async ofdCash({ operation, sum }: { operation: "ins" | "exp", sum: number }) {
		return await server.post(`EcashCore/moneyPlacement?kkmid=200620&operation=${operation}&sum=${sum}`, {}, {
			baseURL: wenv.REACT_APP_API_URL
		});
	}
}
import styles from './SelectDepartments.module.css';
import { SelectDepartmentsProps } from './SelectDepartments.props';
import { useSelectDepartments } from './useSelectDepartments';
import { Htag } from '../Htag/Htag';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const SelectDepartments = ({
	regions,
	isSelectKassa = true,
	className,
	...props
}: SelectDepartmentsProps) => {
	const {
		departmentList,
		activeRegion,
		activeDepartment,
		activeKassa,
		role,
		handleSelectRegion,
		handleSelectActiveDepartment,
		handleSelectActiveKassa,
		haveOpenKassa
	} = useSelectDepartments();

	const isViewAllDepartments = activeRegion === 'Все';

	return (
		<div className={styles.wrapper + " " + (role !== null && className)} style={isViewAllDepartments ? { gridTemplateColumns: "1fr" } : {}} {...props}>
			{role === null && (
				<FormControl
					sx={{
						justifySelf: "center",
						'& fieldset': {
							border: 0,
						},
						'& .MuiOutlinedInput-notchedOutline': {
							borderWidth: "0px !important"
						},
						'& .MuiOutlinedInput-input': {
							padding: "5px 14px !important",
							paddingRight: "32px !important"
						}
					}}
				>
					<Select
						sx={{
							fontWeight: 'bold',
							fontSize: '23px',
							color: '#e25500',
							'& svg': {
								color: '#e25500',
							}
						}}
						value={activeRegion}
						onChange={(e: any) => handleSelectRegion(e.target.value)}
					>
						{regions.map(region => (
							<MenuItem key={region} value={region}>{region}</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
			{
				!isViewAllDepartments && (
					<>
						{role === null ? (
							<FormControl
								sx={{
									justifySelf: "center",
									'& fieldset': {
										border: 0,
									},
									'& .MuiOutlinedInput-notchedOutline': {
										borderWidth: "0px !important"
									},
									'& .MuiOutlinedInput-input': {
										padding: "5px 14px !important",
										paddingRight: "32px !important"
									}
								}}
							>
								<Select
									sx={{
										fontWeight: 'bold',
										fontSize: '23px',
										color: '#e25500',
										'& svg': {
											color: '#e25500',
										}
									}}
									value={activeDepartment}
									onChange={(e: any) => handleSelectActiveDepartment(e.target.value.id)}
								>
									{departmentList.filter(department => department.code === activeRegion).map((department: any) => (
										<MenuItem key={department.id} value={department}>{department.address}</MenuItem>
									))}
								</Select>
							</FormControl>
						) : (
							<Htag tag={'h2'} style={{ textAlign: "center", fontWeight: "bold", fontSize: "20px" }}>г.{activeDepartment?.code} {activeDepartment?.address}</Htag>
						)}
						{isSelectKassa && (!haveOpenKassa ? (
							<FormControl
								sx={{
									justifySelf: "center",
									'& fieldset': {
										border: 0,
									},
									'& .MuiOutlinedInput-notchedOutline': {
										borderWidth: "0px !important"
									},
									'& .MuiOutlinedInput-input': {
										padding: "5px 14px !important",
										paddingRight: "32px !important"
									}
								}}
							>
								<Select
									sx={{
										fontWeight: 'bold',
										fontSize: '23px',
										color: '#e25500',
										'& svg': {
											color: '#e25500',
										}
									}}
									value={activeKassa}
									onChange={(e: any) => handleSelectActiveKassa(e.target.value.id)}
								>
									{activeDepartment?.kassa.map((kassa: any) => (
										<MenuItem key={kassa.id} value={kassa}>Касса №{kassa.name}</MenuItem>
									))}
								</Select>
							</FormControl>
						) : (
							<h3 style={{ gridColumn: 'span 2', textAlign: 'center' }}>Касса №{activeKassa?.name}</h3>
						))}
					</>
				)
			}
		</div>
	);
};
import { server } from '../core/server/axios';

const wenv = process.env;

export interface AddDepartmentVariables {
	name: string,
	code: string,
	address: string,
	bin: string,
	adviser: string
}

export interface UpdateDepartmentVariables {
	id: number,
	name?: string,
	code?: string,
	address?: string,
	bin?: string,
	adviser?: string,
	isActive?: 0 | 1
}

export class DepartmentCotroller {
	async addDepartment(request: AddDepartmentVariables): Promise<any> {
		return await server.post('/Department/newDepartment', request, {
			baseURL: wenv.REACT_APP_API_URL
		});
	}

	async getDepartments(): Promise<any> {
		return await server.get('/Department/getDepartmentList', {
			baseURL: wenv.REACT_APP_API_URL
		});
	}

	async updateDepartment(request: UpdateDepartmentVariables): Promise<any> {
		return await server.post('Department/updateDepartment', request, {
			baseURL: wenv.REACT_APP_API_URL
		});
	}
}
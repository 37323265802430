import { UserContext, Role, User } from '../../api/CamundaController';

export default class AuthUtil {
  public static isAuth(): boolean {
    const ctx = localStorage.getItem('userContext') || '{}';
    const auth = JSON.parse(ctx);

    return auth && auth.user;
  }

  public static getLogin(): string {
    const ctx = localStorage.getItem('userContext') || '{}';
    const auth = JSON.parse(ctx) as UserContext;

    return (auth && auth.user && auth.user.login) || '';
  }

  public static getToken(): {
    accessToken?: string;
    refreshToken?: string;
    auth2Token?: string;
  } {
    const ctx = localStorage.getItem('userContext') || '{}';
    const auth = JSON.parse(ctx);

    return auth && auth.token;
  }

  public static getAuth(): UserContext {
    const ctx = localStorage.getItem('userContext') || '{}';
    const auth = JSON.parse(ctx) as UserContext;
    return auth;
  }

  public static getRoles(): Role[] {
    const ctx = localStorage.getItem('userContext') || '{}';
    const auth = JSON.parse(ctx) as UserContext;

    return (auth && auth.user && auth.user.roles) || [];
  }

  public static hasRole(code: string): boolean {
    return this.getRoles().some(r => r.code == code);
  }

  public static getFullName(): string {
    const ctx = localStorage.getItem('userContext') || '{}';
    const auth = JSON.parse(ctx) as UserContext;
    const user = auth && auth.user && auth.user;
    return `${user?.lastName} ${user?.firstName}`;
  }

  public static getEmail(): string {
    const ctx = localStorage.getItem('userContext') || '{}';
    const auth = JSON.parse(ctx) as UserContext;
    const user = auth && auth.user && auth.user;
    return user?.email || '';
  }

  public static getUser(): User | undefined {
    const ctx = localStorage.getItem('userContext') || '{}';
    const auth = JSON.parse(ctx) as UserContext;
    const user = auth && auth.user && auth.user;
    return user;
  }
}

import { Grid, Stack } from "@mui/material";
import Comment, { CommentModel } from "./Comment";
import AddComment from "./AddComment";
import { useDispatch } from "react-redux";
import { updateTaskVariables } from "@/redux/modules/Process";
import { User } from "@/api/CamundaController";

interface CommentListProps {
    itsMe: boolean;
    me: User;
    process: string;
    readonly: boolean;
    comments: any;
}

const CommentList = (props: CommentListProps) => {

    const { itsMe, me, process, readonly, comments } = props;
    const dispatch = useDispatch();

    const onAdd = (c: CommentModel) => {
        dispatch(
            updateTaskVariables(
                (g) => g.comments,
                (p) => (p || []).map((f: any) => f).concat([c])
            ));
    };

    const onDelete = (id: string) => {
        dispatch(
            updateTaskVariables(
                (g) => g.comments,
                (p) => (p || []).filter((c: CommentModel) => c.id !== id)
            ));
    };

    const renderTopComments = comments
        .filter((r: any) => r.parentId == undefined)
        .filter((r: any) => r.isPublic || (!r.isPublic && r.process == process));
    return (
        <Grid container direction="column">
            <Stack spacing={2}>
                {renderTopComments.map((c: any) => <Comment
                    key={c.id}
                    me={me}
                    comment={c}
                    readonly={readonly}
                    replies={comments.filter((r: any) => r.parentId == c.id)}
                    onDelete={onDelete}
                    onAddReply={onAdd}
                />)}
                {itsMe && !readonly && <AddComment process={process} onAdd={onAdd} me={me} />}
            </Stack>
        </Grid>
    );
};

export default CommentList;
import { server } from '../core/server/axios';
import { VariableFilter } from './CamundaController';
import i18n from '@/i18nextConf';

const wenv = process.env;

export class QuerySave {
  id = 0;
  queueId = 0;
  name: string = i18n.t("newrequest");
  username = "";
  filter: Array<VariableFilter> = [];
  public constructor(init?: Partial<QuerySave>) {
    Object.assign(this, init);
  }
}

export class QueryController {

  async create(query: QuerySave): Promise<any> {
    return await server.post(`/sonata/api/query`, query, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async get(id: number): Promise<QuerySave> {
    return await server.get(`/sonata/api/query/${id}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async delete(id: number) {
    return await server.delete(`/sonata/api/query/${id}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async getUserQueries(
    username: string,
    queueId: number
  ): Promise<Array<QuerySave>> {
    return server.get(
      `/sonata/api/query/user/${username}/queue/${queueId}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }
}

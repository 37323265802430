import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import { pechat221240028699, pechat230840009643, pechat231140017364, pechat231040040937, podpis, pechat220240011027 } from "./Base64";
import { Log } from '@/pages/DocumentsPage/components/OrdersDocument/useOrdersDocument';
import { DepartmentModel } from '@/interface';
import { PdfmakeFonts } from '@/core/helpers/PdfmakeFonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = PdfmakeFonts;

const orderDocument = (logs: Log[], activeFillial: DepartmentModel) => {

  const logsContent = logs.map((log, index) => {
    const pechatImg = () => {
      if (activeFillial?.bin === "221240028699") {
        return {
          image: `${pechat221240028699}`,
          width: 150,
          height: 150,
          pageBreak: index === logs.length - 1 ? '' : 'after'
        };
      } else if (activeFillial?.bin === "230840009643") {
        return {
          image: `${pechat230840009643}`,
          width: 150,
          height: 150,
          pageBreak: index === logs.length - 1 ? '' : 'after'
        };
      } else if (activeFillial?.bin === "231140017364") {
        return {
          image: `${pechat231140017364}`,
          width: 150,
          height: 150,
          pageBreak: index === logs.length - 1 ? '' : 'after'
        };
      } else if (activeFillial?.bin === "231040040937") {
        return {
          image: `${pechat231040040937}`,
          width: 150,
          height: 150,
          pageBreak: index === logs.length - 1 ? '' : 'after'
        };
      } else if (activeFillial?.bin === "220240011027") {
        return {
          image: `${pechat220240011027}`,
          width: 150,
          height: 150,
          pageBreak: index === logs.length - 1 ? '' : 'after'
        };
      } else {
        return {
          text: ""
        };
      }
    };
    const tableArray = [
      [
        "Наименование валюты",
        { text: "Покупка", alignment: "right" },

        { text: "Продажа", alignment: "right" },
      ],
    ];

    log.rateLogs
      .filter((rate) => rate.currencyCode !== "KZT")
      .sort((a, b) => {
        if (a.currencyCode > b.currencyCode) return 1;

        if (a.currencyCode < b.currencyCode) return -1;

        if (a.currencyCode === b.currencyCode) {
          if (a.limitTo < b.limitTo) return 1;

          if (a.limitTo > b.limitTo) return -1;
        }

        return 0;
      })
      .map((rateLog) => {
        tableArray.push([
          rateLog.currencyCode + "  " + (rateLog.limitFrom === 0 ? "менее " + rateLog.limitTo : "более " + rateLog.limitFrom),
          { text: rateLog.buy.toFixed(2), alignment: "right" },
          { text: rateLog.sale.toFixed(2), alignment: "right" },
        ]);
      });
    return [
      {
        stack: [
          {
            text: 'ТОО "Ecash"',
          },
          {
            text: activeFillial?.address,
          },
          {
            text: `БИН: ${activeFillial?.bin}\n\n`,
          },
        ],
        alignment: "center",
      },
      {
        stack: [
          {
            text: `Распоряжение №${log.logId}`,
          },
          {
            text: "об установлении курсов покупки, продажи",
          },
          {
            text: "наличной иностранной валюты",
          },
          {
            text: new Date(log.actionDate).toLocaleString().slice(0, 10),
          },
          {
            text: `${new Date(log.actionDate).toLocaleString().slice(12, 20)}\n\n`,
          },
        ],
        alignment: "center",
      },
      {
        table: {
          widths: ["*", "*", "*"],
          body: tableArray,
        },
      },
      {
        text: "\n"
      },
      {
        columns: [
          {
            text: '\n\n\nДиректор ТОО "Ecash"',
          },
          {
            image: `${podpis}`,
            width: 100,
            height: 100,
            pageBreak: index === logs.length - 1 ? '' : 'after'
            // relativePositions: { x: -500, y: -5000 }
          },
          {
            text: '\n\n\nАхметгалиев. Е. М-Х.',
          }
        ]
      },
      pechatImg(),
    ];
  });

  const showing: any = {
    content: [
      ...logsContent,
    ],
    defaultStyle: {
      fontSize: 14,
      font: "Roboto"
    },
  };

  return showing;
};

export default orderDocument;

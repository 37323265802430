import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { User } from '@/api/CamundaController';
import { Box, Button, FormControlLabel, IconButton, Input, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import { PersonAdd, Search } from '@mui/icons-material';
import { api } from '@/api/Api';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Delete } from '@mui/icons-material';
import processManager from '@/core/helpers/ProcessManager';
import { useTranslation } from 'react-i18next';


const UsersList = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [users, setUsers] = useState([] as User[]);
    const [login, setLogin] = useState("");
    const [pageSize, setPageSize] = useState(10);
    const showMoreSize = 10;
    const [lastPage, setLastPage] = useState(true);
    const [dense, setDense] = React.useState(true);

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const onShowMore = () => {
        api.camunda
            .getUsersContains(0, pageSize + showMoreSize, login)
            .then((m) => {
                setUsers(m.content);
                setLastPage(m.last);
                setPageSize(pageSize + showMoreSize);
            });
    };

    const deleteUser = (user: User) => {
        processManager.openConfirm(() => {
            api.camunda.deleteUser(user.login || '').then(() => {
                setUsers(users.filter((m) => m.login != user.login));
                processManager.openSnackBar(t("user.list.deletesuccess"));
            });
        }, t("user.list.deleteconfirm") + user.fullName + "?");
    };

    useEffect(() => {
        api.camunda.getUsersContains(0, pageSize, login).then((m) => {
            setUsers(m.content);
            setLastPage(m.last);
        });
    }, [login]);

    const onClick = (user: User) => {
        navigate(`/users/${user.login}`);
    };

    return (
        <Grid container spacing={2}>
            <Grid>
                <Toolbar>
                    <Button variant="contained" component={Link} to='/users/create' startIcon={<PersonAdd />}>{t("user.list.create")}</Button>
                </Toolbar>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <React.Fragment>
                    <Box sx={{ width: '100%' }}>
                        <Paper sx={{ width: '100%', mb: 2 }}>
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Input
                                                    type="search"
                                                    placeholder={t("user.list.login") || ''}
                                                    value={login}
                                                    onChange={(e: any) => setLogin(e.target.value)}
                                                    size='small'
                                                />
                                                <Search />
                                            </TableCell>
                                            <TableCell>
                                                <Input
                                                    type="search"
                                                    placeholder={t("user.list.fullname") || ''}
                                                    value={login}
                                                    onChange={(e: any) => setLogin(e.target.value)}
                                                />
                                                <Search />
                                            </TableCell>
                                            <TableCell>{t("user.list.compnay")}</TableCell>
                                            <TableCell>{t("user.list.roles")}</TableCell>
                                            <TableCell>{t("user.list.email")}</TableCell>
                                            <TableCell>{t("user.list.delete")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {users.map((user) => (
                                            <TableRow key={user.login} onClick={() => onClick(user)}>
                                                <TableCell>{user.login}</TableCell>
                                                <TableCell>{user.fullName}</TableCell>
                                                <TableCell>{user.company}</TableCell>
                                                <TableCell>{user.roles?.map(r => r.code).join(', ')}</TableCell>
                                                <TableCell>{user.email}</TableCell>
                                                <TableCell style={{ width: "88px" }}>
                                                    <IconButton aria-label="delete"
                                                        onClick={(e: any) => {
                                                            e.stopPropagation();
                                                            deleteUser(user);
                                                        }}>
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br />
                            {!lastPage && (
                                <div
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => onShowMore()}
                                >
                                    <b>
                                        <u>{t("user.list.showmore")}</u>
                                    </b>
                                </div>
                            )}
                        </Paper>
                        <FormControlLabel
                            control={<Switch checked={dense} onChange={handleChangeDense} />}
                            label={t("user.list.tight")}
                        />
                    </Box>
                </React.Fragment>
            </Grid>
        </Grid>);
};

export default UsersList;
import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Header from "./Header";
import Footer from "./Footer";
import { ProcessReducer } from "@/redux/modules/Process";
import { useSelector } from "react-redux";
import PopupSnackBar from "../PopupSnackBar";
import PopupBackDrop from "../PopupBackDrop";
import pm from "@/core/helpers/ProcessManager";
import PopupConfirm from "../PopupConfirm";
import { selectActiveDepartment } from '@/redux/modules/department/departmentSelectors';

const ClientLayout = (Component: React.FC) => (
  <MainLayout>
    <Component />
  </MainLayout>
);

const mdTheme = createTheme({});

export const MainLayout = (props?: any) => {
  const process: ProcessReducer = useSelector((state: any) => state.process);

  const activeDepartment = useSelector(selectActiveDepartment);

  const { isLoading, snackBar, backdrop, confirm } = process;

  return (
    <ThemeProvider theme={mdTheme}>
      <Box>
        <PopupSnackBar onClose={() => pm.closeSnackBar()} {...snackBar} />
        <PopupBackDrop {...backdrop} />
        <PopupConfirm
          handleDisagreeOrClose={() => pm.closeSnackBar()}
          {...confirm}
        />
        <CssBaseline />
        <Toolbar />
        <Header isLoading={isLoading} />
        {props.children}
        <Footer activeDepartment={activeDepartment} />
      </Box>
    </ThemeProvider>
  );
};

export default ClientLayout;

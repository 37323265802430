import React from "react";
import { Routes, Route } from "react-router-dom";

const HomePage = React.lazy(() => import("./pages").then(module => ({ default: module.HomePage })));

const ClientExchange = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
  </Routes>
);

export default ClientExchange;

import { User } from "@/api/CamundaController";
import { generateUUID } from "@/core/helpers/Utils";
import {
    Avatar,
    Button,
    Card,
    Stack,
    TextField
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CommentModel } from "./Comment";

interface AddCommentProps {
    onAdd: (model: CommentModel) => void;
    me: User;
    process: string;
}

const AddComment = (props: AddCommentProps) => {

    const { t } = useTranslation();

    const { onAdd, me, process } = props;

    const [commentTxt, setCommentTxt] = useState("");
    const [isPublic] = useState(true);

    const handleAdd = () => {
        onAdd({
            id: generateUUID(),
            text: commentTxt,
            date: new Date().toISOString(),
            who: { login: me.login, fullName: me.fullName },
            process: process,
            isPublic: isPublic
        });
    };

    const avatar =
        me?.photo?.data && `data:image/*;base64, ${me?.photo?.data}`;

    return (
        <Card>
            <Box sx={{ p: "15px" }}>
                <Stack direction="row" spacing={2} alignItems="flex-start">
                    <Avatar
                        src={avatar}
                        variant="rounded"
                        alt="user-avatar"
                    />
                    <TextField
                        multiline
                        fullWidth
                        minRows={4}
                        id="outlined-multilined"
                        placeholder={t("comment.addcom") || ''}
                        value={commentTxt}
                        onChange={(e) => {
                            setCommentTxt(e.target.value);
                        }}
                    />
                    <Stack direction='column'>
                        {/* <FormControlLabel control={<Switch checked={isPublic} onChange={() => setIsPublic(!isPublic)} />} label={t("comment.public")} /> */}
                        <Button

                            size="small"
                            sx={{
                                bgcolor: "custom.moderateBlue",
                                color: "neutral.white",
                                p: "8px 25px",
                                "&:hover": {
                                    bgcolor: "custom.lightGrayishBlue",
                                },
                            }}
                            onClick={(e) => {
                                !commentTxt.trim()
                                    ? e.preventDefault()
                                    : handleAdd();
                                setCommentTxt("");
                            }}
                        >
                            {t("comment.add")}
                        </Button>

                    </Stack>
                </Stack>
            </Box>
        </Card>
    );
};

export default AddComment;
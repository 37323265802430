import Grid from '@mui/material/Grid';
import { HistoricProcessAndTaskInstances, User } from '@/api/CamundaController';
import { TaskFormGen } from '@/components/FormGen';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/system';
import { Button, Container, Paper, Typography } from '@mui/material';
import { FileDropzone, Sla, TaskHistoryTimeline, UserPicker } from '@/components';
import processManager from '@/core/helpers/ProcessManager';
import ButtonsFlow from '@/components/FormGen/ButtonsFlow';
import CommentList from '@/components/Comments/CommentList';
import { useRef } from 'react';
import { ExitToApp } from '@mui/icons-material';
import { history as bh } from "@/core/helpers/history";
import { useTranslation } from 'react-i18next';


const OpenTask = () => {

    const formRef = useRef();
    const { t } = useTranslation();

    const me = useSelector((state: any) => state?.header?.userContext?.user as User);
    const task = useSelector((state: any) => state?.process?.taskModel) || {};
    const history = useSelector((state: any) => state?.process?.history as HistoricProcessAndTaskInstances);
    const selectedUserQueue = useSelector((state: any) => state?.header?.selectedQueueMenu);

    const itsMe = task.assignee === me?.login;
    const variables = task.variables || {};
    const sla = (variables.sla || []).filter((s: any) => s.process === task.processDefinitionKey);
    const comments = variables?.comments || [];

    const onSelectedUser = (user: User) => {
        processManager.assignTask(task.id || '', user.login || '');
    };



    return (
        <Grid container spacing={2}>
            <Grid item xs={1}>
                <Button variant='outlined' onClick={() => bh.back()}>
                    <ExitToApp />
                </Button>
            </Grid>
            <Grid item xs={11}>
                {itsMe && !selectedUserQueue?.readOnly && <ButtonsFlow formRef={formRef} buttons={task.buttons} />}
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
                <TaskFormGen formRef={formRef} task={task} />
                <FileDropzone readonly={selectedUserQueue?.readOnly} />
                <CommentList itsMe={itsMe} me={me} comments={comments} readonly={task?.assignee == undefined || selectedUserQueue?.readOnly} process={task.processDefinitionKey} />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
                <Paper sx={{ width: '100%', mb: 2, pb: 2 }}>
                    <Container sx={{ pt: 2 }}>
                        <Stack spacing={2} direction="column">
                            <Grid item xs={12}>
                                <Typography>
                                    {task.businessKey}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                {task.assignee && <Button>{t("task.assigned")}</Button>}
                                {!itsMe && !selectedUserQueue?.readOnly && <Button onClick={() => onSelectedUser(me)}>{t("task.assignme")}</Button>}
                            </Grid>
                            <Grid item xs={12}>
                                <UserPicker
                                    readonly={selectedUserQueue?.readOnly}
                                    mode='colleagues'
                                    onSelectedItem={onSelectedUser}
                                    label={t("assignee") || ''}
                                    defaultValue={task.assignee} />
                            </Grid>
                            <Grid item xs={12}>
                                <Sla slas={sla} gmt={me.gmt || 'Asia/Almaty'} />
                            </Grid>
                            <Grid item xs={12}>
                                <TaskHistoryTimeline me={me} history={history} task={task} />
                            </Grid>
                        </Stack>
                    </Container>
                </Paper>
            </Grid>
        </Grid>);
};

export default OpenTask;
import { TaskFormComponent } from "@/api/CamundaController";
import { RemoveCircle } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import CheckboxGen from "./CheckboxGen";
import ChecklistGen from "./ChecklistGen";
import NumberGen from "./NumberGen";
import RadioGen from "./RadioGen";
import SelectGen from "./SelectGen";
import TaglistGen from "./TaglistGen";
import TextFieldGen from "./TextFieldGen";
import TextGen from "./TextGen";

interface ComponentsGenProps {
    components: Array<TaskFormComponent>;
    setComponents: (comps: Array<TaskFormComponent>) => void;
}

const ComponentsGen = (props: ComponentsGenProps) => {
    const { components, setComponents } = props;

    const onRemoveDynamicComponent = (index: number) => {
        setComponents(components.filter((v, i) => i !== index));
    };

    return (
        <React.Fragment>
            {components?.map((component, index) => (
                <React.Fragment key={index}>
                    {component.type == 'textfield' && <TextFieldGen key={index} component={component}
                        iconButton={
                            <IconButton onClick={() => onRemoveDynamicComponent(index)}>
                                <RemoveCircle />
                            </IconButton>
                        } />}
                    {component.type == 'number' && <NumberGen key={index} component={component}
                        iconButton={
                            <IconButton onClick={() => onRemoveDynamicComponent(index)}>
                                <RemoveCircle />
                            </IconButton>
                        } />}
                    {component.type == 'checkbox' && <CheckboxGen key={index} component={component}
                        iconButton={
                            <IconButton onClick={() => onRemoveDynamicComponent(index)}>
                                <RemoveCircle />
                            </IconButton>
                        } />}
                    {component.type == 'checklist' && <ChecklistGen key={index} component={component} />}
                    {component.type == 'select' && <SelectGen key={index} component={component} />}
                    {component.type == 'radio' && <RadioGen key={index} component={component} />}
                    {component.type == 'text' && <TextGen key={index} component={component} />}
                    {component.type == 'taglist' && <TaglistGen key={index} component={component} />}
                </React.Fragment>
            ))}
        </React.Fragment>
    );
};

export default ComponentsGen;
import { api } from '@/api/Api';
import { User } from '@/api/CamundaController';
import { CashModel, CurrencyModel, DepartmentModel, KassaModel } from '@/interface';
import { HeaderReducer } from '@/redux/modules/Header';
import { updateTaskVariables } from '@/redux/modules/Process';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pm from "@/core/helpers/ProcessManager";

export type OperationType = "Выдано в другой ОП" | "Для сдачи в банк" | "Выдано в кассу бухгалтерии" | "Выдано в подотчет" | "Передача наличности в другую кассу";

export interface AddCashBodyVariables {
	balance: number,
	currencyCode: string,
	kassaId: number,
	currencyId: number | null,
	cashId: number | null,
	operationType: string,
	toKassaId: number,
}

export const useBalancePage = () => {
	const [openWindow, setOpenWindow] = useState<"closeKassa" | "updateCash" | null>(null);
	const [addCashBody, setAddCashBody] = useState<AddCashBodyVariables>({
		balance: 0,
		currencyCode: "",
		kassaId: 0,
		currencyId: 0,
		cashId: 0,
		operationType: "",
		toKassaId: 0,
	});
	const [isMinus, setIsMinus] = useState(false);

	const dispatch = useDispatch();

	const variables = useSelector(
		(state: any) => state?.process?.taskModel?.variables
	);

	const userLogin: string = useSelector((state: { header: HeaderReducer }) => state?.header?.userContext?.user?.login || "");
	const user: User = useSelector((state: { header: HeaderReducer }) => state?.header?.userContext?.user || {});

	useEffect(() => {
		handleGetDepartmentList();
	}, []);

	const handleGetDepartmentList = async () => {
		pm.openBackDrop();
		await api.department.getDepartments()
			.then((res: any) => {
				const currentDepartment = res.value?.find((dep: DepartmentModel) => dep.id === variables.department.id);
				currentDepartment.currencies.sort(
					(a: CurrencyModel, b: CurrencyModel) => {
						if (a.code === 'CNY' && b.code !== 'USD' && b.code !== 'EUR' && b.code !== 'RUB') {
							return -1;
						} else if (a.code === 'EUR' && b.code !== 'USD' && b.code !== 'RUB') {
							return -1;
						} else if (a.code === 'RUB' && b.code !== 'USD') {
							return -1;
						} else if (a.code === 'USD') {
							return -1;
						} else {
							return 0;
						}
					}
				);
				const currentKassa = currentDepartment?.kassa?.find((kassa: KassaModel) => kassa.id === variables.kassa.id);
				dispatch(updateTaskVariables(
					(c) => c,
					(p) => {
						p.department = currentDepartment;
						p.kassa = currentKassa;

						return p;
					}
				));
			})
			.catch(() => pm.openSnackBar("Произошла ошибка: Не удалось получить данные по отеделениям", { severity: "error" }))
			.finally(() => pm.closeBackDrop());
	};

	const handleOpenWindow = (kassa: KassaModel, minus: boolean, windowType: "updateCash" | "closeKassa") => {
		setOpenWindow(windowType);
		setIsMinus(minus);
		setAddCashBody({
			balance: 0,
			currencyCode: "",
			kassaId: kassa.id,
			currencyId: 0,
			cashId: 0,
			operationType: "",
			toKassaId: 0
		});
	};

	const handleCloseWindow = () => {
		setOpenWindow(null);
		setAddCashBody({
			balance: 0,
			currencyCode: "",
			kassaId: 0,
			currencyId: 0,
			cashId: 0,
			operationType: "",
			toKassaId: 0
		});
	};

	const loadAddCash = ({ balance, currencyCode, kassaId }: { balance: number, currencyCode: string, kassaId: string | number }) => {
		pm.openBackDrop();
		const currentCurrency = variables.department?.currencies.find((cur: CurrencyModel) => cur.code === currencyCode);

		api.currency.addCurrencyCash({ balance: balance, currencyCode: currencyCode, currencyId: currentCurrency?.currencyId + '', kassaId, reserved: 0 })
			.then(() => {
				api.currency.ofdCash({ operation: "ins", sum: balance });
				handleGetDepartmentList();
				setOpenWindow(null);
			})
			.catch(() => pm.openSnackBar("Произошла ошибка: Не удалось получить данные по отеделениям", { severity: "error" }))
			.finally(() => pm.closeBackDrop());
	};

	const loadUpdateCash = (balance: number) => {
		pm.openBackDrop();
		addCashBody.cashId && api.currency.updateCurrencyCash({ cashId: addCashBody.cashId, balance: addCashBody.balance + balance, login: userLogin })
			.then(() => {
				api.currency.ofdCash({ operation: balance < 0 ? "exp" : "ins", sum: Math.abs(balance) });
				setOpenWindow(null);
				handleGetDepartmentList();
			})
			.catch(() => pm.openSnackBar("Произошла ошибка: Не удалось обновить баланс", { severity: "error" }))
			.finally(() => pm.closeBackDrop());
	};

	const handleCloseKassa = () => {
		setOpenWindow(null);
		pm.goForth();
	};

	const handleTransferCashBetweenKassa = (sum: number) => {
		pm.openBackDrop();

		const toKassa = variables.department.kassa.find((k: KassaModel) => k.id === addCashBody.toKassaId);
		const toCurrency = toKassa?.cash?.find((c: CashModel) => c.currencyCode === addCashBody.currencyCode);

		if (toCurrency) {
			api.currency.transferCashBetweenKassa({ fromId: addCashBody.kassaId, toId: addCashBody.toKassaId, currencyId: addCashBody?.currencyId || -1, currencyCode: addCashBody.currencyCode, amount: sum, initiator: userLogin })
				.then(() => {
					handleGetDepartmentList();
					setOpenWindow(null);
				})
				.catch(() => pm.openSnackBar('Произошла ошибка: Операция "Перевод наличности между кассами не прошел"'))
				.finally(() => pm.closeBackDrop());
		} else {
			api.currency.addCurrencyCash({ balance: 0, currencyCode: addCashBody.currencyCode, kassaId: toKassa.id, currencyId: addCashBody.currencyId || "9999999", reserved: 0 })
				.then(() => {
					api.currency.transferCashBetweenKassa({ fromId: addCashBody.kassaId, toId: addCashBody.toKassaId, currencyId: addCashBody?.currencyId || -1, currencyCode: addCashBody.currencyCode, amount: sum, initiator: userLogin })
						.then(() => {
							handleGetDepartmentList();
							setOpenWindow(null);
						})
						.catch(() => pm.openSnackBar('Произошла ошибка: Операция "Перевод наличности между кассами не прошел"'));
				})
				.catch(() => pm.openSnackBar('Произошла ошибка: не удалось добавить баланс валюты в кассу направления'))
				.finally(() => pm.closeBackDrop());
		}
	};

	return {
		variables,
		handleOpenWindow,
		openWindow,
		handleCloseWindow,
		addCashBody,
		setAddCashBody,
		loadUpdateCash,
		isMinus,
		handleCloseKassa,
		user,
		loadAddCash,
		handleTransferCashBetweenKassa,
	};
};
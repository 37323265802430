import { Fragment, useEffect, useState } from "react";
import {
    Avatar,
    Button,
    Card,
    Stack,
    Typography,
    TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Delete, Edit, Reply } from "@mui/icons-material";
import YouTag from "./YouTag";
import { User } from "@/api/CamundaController";
import { api } from "@/api/Api";
import moment from "moment-timezone";
import RepliesSection from "./RepliesSection";
import PubligTag from "./PubligTag";
import { useTranslation } from "react-i18next";

export interface CommentModel {
    id: string;
    parentId?: string;
    parentUser?: User;
    who: User;
    date: string;
    text: string;
    process: string;
    isPublic: boolean;
}

interface CommentProps {
    me: User;
    comment: CommentModel;
    replies: CommentModel[];
    onDelete: (id: string) => void;
    onAddReply: (c: CommentModel) => void;
    readonly: boolean;
}

const Comment = (props: CommentProps) => {
    const { t } = useTranslation();
    const { comment, onDelete, me, readonly, replies, onAddReply } = props;
    const itsMe = me.login == comment.who.login;
    const [clicked, setClicked] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [commentText, setCommentText] = useState(comment.text);
    const [user, setUser] = useState<User>(new User());

    const avatar =
        user?.photo?.data && `data:image/*;base64, ${user?.photo?.data}`;

    useEffect(() => {
        api.camunda.getUser(comment.who.login || '', true).then(result => {
            result.firstName = result.fullName?.split(' ')[0];
            result.lastName = result.fullName?.split(' ')[1];
            setUser(result);
        });
    }, []);

    return (
        <Fragment>
            <Card>
                <Box sx={{ p: "15px" }}>
                    <Stack spacing={2} direction="row">
                        <Box sx={{ width: "100%", maxWidth: "100%" }}>
                            <Stack
                                spacing={2}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Stack spacing={2} direction="row" alignItems="center">
                                    <Avatar src={avatar}></Avatar>
                                    <Typography fontWeight="bold">
                                        {comment.who.login}
                                    </Typography>
                                    {itsMe && <YouTag />}
                                    {comment.isPublic && <PubligTag />}
                                    <Typography sx={{ fontSize: '14px' }}>
                                        {moment.tz(comment.date, me.gmt || '').toLocaleString()}
                                    </Typography>
                                </Stack>
                                {!readonly && <Fragment>
                                    {itsMe ? (
                                        <Stack direction="row" spacing={1}>
                                            <Button
                                                startIcon={<Delete />}
                                                sx={{
                                                    fontWeight: 500,
                                                    textTransform: "capitalize",
                                                }}
                                                onClick={() => onDelete(comment.id)}
                                            >
                                                {t("comment.delete")}
                                            </Button>
                                            <Button
                                                variant="text"
                                                disabled={isEditing}
                                                sx={{
                                                    textTransform: "capitalize",
                                                    color: "custom.moderateBlue",
                                                }}
                                                startIcon={<Edit />}
                                                onClick={() => setIsEditing(!isEditing)}
                                            >
                                                {t("comment.edit")}
                                            </Button>
                                        </Stack>
                                    ) : (
                                        <Button
                                            onClick={() => {
                                                setClicked(!clicked);
                                            }}
                                            variant="text"
                                            sx={{
                                                fontWeight: 500,
                                                textTransform: "capitalize",
                                                color: "custom.moderateBlue",
                                            }}
                                            startIcon={<Reply />}
                                        >
                                            {t("comment.reply")}
                                        </Button>
                                    )}
                                </Fragment>}
                            </Stack>
                            {isEditing ? (
                                <>
                                    <TextField
                                        sx={{ p: "20px 0" }}
                                        multiline
                                        fullWidth
                                        minRows={4}
                                        id="outlined-multilined"
                                        placeholder={t("comment.noempty") || ''}
                                        value={commentText}
                                        onChange={(e) => {
                                            setCommentText(e.target.value);
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            !commentText.trim()
                                                ? alert(
                                                    t("comment.ifyou")
                                                )
                                                : setIsEditing(!isEditing);
                                        }}
                                    >
                                        {t("comment.update")}
                                    </Button>
                                </>
                            ) : (
                                <Typography sx={{ color: "neutral.grayishBlue", p: "20px 0" }}>
                                    {commentText}
                                </Typography>
                            )}
                        </Box>
                    </Stack>
                </Box>
            </Card>
            {
                replies && (
                    <RepliesSection
                        onAddReply={onAddReply}
                        onDelete={onDelete}
                        replies={replies}
                        clicked={clicked}
                        me={me}
                        parent={comment}
                        readonly={readonly}
                    />
                )
            }
        </Fragment>
    );
};

export default Comment;
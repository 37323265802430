import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { formatNumberRu } from '@/core/helpers/PageHelpers';
import { DepartmentModel } from '@/interface';
import { PdfmakeFonts } from '@/core/helpers/PdfmakeFonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = PdfmakeFonts;

export const printDocument = () => {
	const showing: any = {
		pageOrientation: 'landscape',
		content: [
			{
				stack: [
					"Приложение 10",
					"к постановлению Правления",
					"Национального Банка",
					"Республики Казахстан",
					"от 31 декабря 2019 года № 265",
				],
				alignment: "right"
			},
			{ text: "\n" },
			{
				stack: [
					"Приложение 11",
					"к Правилам осуществления обменных",
					"операций с наличной иностранной валютой",
					"в Республике Казахстан",
				],
				alignment: "right"
			},
			{ text: "\n" },
			{ text: "Форма", alignment: "right" },
			{ text: "\n" },
			{ text: "\n" },
			{ text: "\n" },
			{ text: "\n" },
			{ text: "\n" },
			{
				stack: [
					"ЖУРНАЛ",
					"реестров купленной и проданной",
					"наличной иностраной валюты",
				],
				alignment: "center",
				bold: true,
				fontSize: 11
			},
			{ text: "\n" },
			{ text: "\n" },
			{
				text: "Наименование юридического лица, имеющего право на осуществление обменных операций, или его филиала",
				alignment: "center",
				fontSize: 9,
			},
			{ text: "\n" },
			{ text: 'ТОО "Ecash"', alignment: "center", fontSize: 9, decoration: "underline" },
			{ text: "\n" },
			{ text: "\n" },
			{
				text: 'Номер приложения к лицензии уполномоченной организации/свидетельства обменного пункта уполномоченного банка (после получения уполномоченным банком такого свидетельства)',
				alignment: "center",
				fontSize: 9,
				decoration: "underline"
			},
			{ text: "\n" },
			{
				text: "No 7523006/7523011 от 14.04.2023 года",
				alignment: "center",
				fontSize: 9,
				decoration: "underline",
				pageBreak: "after",
			},
			"1 июня 2024 г.",
			{ text: "\n" },
			{ text: "\n" },
			"Остаток в операционной кассе",
			{
				layout: 'lightHorizontalLines',
				table: {
					widths: [245, 70, 70],
					body: [
						[{ text: "обменного пункта на начало дня *", border: [false, false, false, false], }, "62 702 375,00", "тенге"],
						[{ text: "", border: [false, false, false, false], }, "7 010,00", "USD"],
						[{ text: "", border: [false, false, false, false], }, "100,00", "EUR"],
						[{ text: "", border: [false, false, false, false], }, "3 414 650,00", "RUB"],
						[{ text: "", border: [false, false, false, false], }, "2 643,00", "CNY"],
						[{ text: "", border: [false, false, false, false], }, "200,00", "CAD"],
						[{ text: "", border: [false, false, false, false], }, "38 740,00", "KGS"],
						[{ text: "", border: [false, false, false, false], }, "500 000,00", "VND"],
						[{ text: "", border: [false, false, false, false], }, "640,00", "GBP"],
						[{ text: "", border: [false, false, false, false], }, "3 890,00", "TRY"],
						[{ text: "", border: [false, false, false, false], }, "8 727 000,00", "UZS"],
						[{ text: "", border: [false, false, false, false], }, "15 000,00", "JPY"],
						[{ text: "", border: [false, false, false, false], }, "18 005,00", "AED"],
						[{ text: "", border: [false, false, false, false], }, "75,00", "GEL"],
						[{ text: "", border: [false, false, false, false], }, "990,00", "CHF"],
						[{ text: "", border: [false, false, false, false], }, "4 650,00", "AUD"],
					]
				}
			},
			{ text: "\n" },
			"Остаток в операционной кассе",
			{
				layout: 'lightHorizontalLines',
				table: {
					widths: [245, 70, 70],
					body: [
						[{ text: "обменного пункта на конец дня *", border: [false, false, false, false], }, "61 980 863,00", "тенге"],
						[{ text: "", border: [false, false, false, false], }, "33 770,00", "USD"],
						[{ text: "", border: [false, false, false, false], }, "100,00", "EUR"],
						[{ text: "", border: [false, false, false, false], }, "1 085 770,00", "RUB"],
						[{ text: "", border: [false, false, false, false], }, "2 643,00", "CNY"],
						[{ text: "", border: [false, false, false, false], }, "200,00", "CAD"],
						[{ text: "", border: [false, false, false, false], }, "32 720,00", "KGS"],
						[{ text: "", border: [false, false, false, false], }, "500 000,00", "VND"],
						[{ text: "", border: [false, false, false, false], }, "140,00", "GBP"],
						[{ text: "", border: [false, false, false, false], }, "3 890,00", "TRY"],
						[{ text: "", border: [false, false, false, false], }, "8 727 000,00", "UZS"],
						[{ text: "", border: [false, false, false, false], }, "15 000,00", "JPY"],
						[{ text: "", border: [false, false, false, false], }, "18 005,00", "AED"],
						[{ text: "", border: [false, false, false, false], }, "75,00", "GEL"],
						[{ text: "", border: [false, false, false, false], }, "990,00", "CHF"],
						[{ text: "", border: [false, false, false, false], }, "4 650,00", "AUD"],
					]
				}
			},
			{ text: "\n" },
			{ text: "* Не заполняется  при совмещении обменных операций с иными банковскими операциями", margin: [70, 0, 0, 0], pageBreak: "after", },

			{
				alignment: "center",
				layout: {
					hLineWidth: function () {
						return .1;
					},
					vLineWidth: function () {
						return .1;
					},
				},
				table: {
					widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*",],
					body: [
						[
							{ text: "Дата и время начала действия распоряжения", colSpan: 3, rowSpan: 2 },
							{},
							{},
							{ text: "Номер и дата распоряжения руководителя", colSpan: 2, rowSpan: 2 },
							{},
							{ text: "USD", colSpan: 2, },
							{},
							{ text: "EUR", colSpan: 2, },
							{},
							{ text: "RUB", colSpan: 2, },
							{},
							{ text: "CNY", colSpan: 2, },
							{},
							{ text: "CAD", colSpan: 2, },
							{},
							{ text: "KGS", colSpan: 2, },
							{},
							{ text: "VND", colSpan: 2, },
							{},
							{ text: "GBP", colSpan: 2, },
							{},
						],
						[
							{},
							{},
							{},
							{},
							{},
							"Покупка",
							"Продажа",
							"Покупка",
							"Продажа",
							"Покупка",
							"Продажа",
							"Покупка",
							"Продажа",
							"Покупка",
							"Продажа",
							"Покупка",
							"Продажа",
							"Покупка",
							"Продажа",
							"Покупка",
							"Продажа",
						],
						[
							{ text: "01.09.2023 9:01:55", colSpan: 3 },
							{},
							{},
							{ text: "№1 473, 01.09.2023", colSpan: 3 },
							{},
							"457,7",
							"461",
							"497,3",
							"502",
							"4,65",
							"4,8",
							"61,5",
							"64,5",
							"300",
							"350",
							"5,1",
							"5,35",
							"0,01",
							"0,02",
							"581",
							"601",
						],
						[
							{ text: "01.09.2023 9:08:37", colSpan: 3 },
							{},
							{},
							{ text: "№1 474, 01.09.2023", colSpan: 3 },
							{},
							"457,7",
							"461",
							"496,5",
							"501,5",
							"4,65",
							"4,8",
							"61,5",
							"64,5",
							"300",
							"350",
							"5,1",
							"5,35",
							"0,01",
							"0,02",
							"581",
							"601",
						],
						[
							{ text: "01.09.2023 9:08:53", colSpan: 3 },
							{},
							{},
							{ text: "№1 475, 01.09.2023", colSpan: 3 },
							{},
							"457,7",
							"461",
							"496,5",
							"501,5",
							"4,71",
							"4,93",
							"61,5",
							"64,5",
							"300",
							"350",
							"5,1",
							"5,35",
							"0,01",
							"0,02",
							"581",
							"601",
						],
						[
							{ text: "01.09.2023 10:16:23", colSpan: 3 },
							{},
							{},
							{ text: "№1 476, 01.09.2023", colSpan: 3 },
							{},
							"458",
							"461",
							"496,5",
							"501,5",
							"4,71",
							"4,93",
							"61,5",
							"64,5",
							"300",
							"350",
							"5,1",
							"5,35",
							"0,01",
							"0,02",
							"581",
							"601",
						],
						[
							{ text: "01.09.2023 10:17:36", colSpan: 3 },
							{},
							{},
							{ text: "№1 477, 01.09.2023", colSpan: 3 },
							{},
							"458",
							"461",
							"497,2",
							"501",
							"4,71",
							"4,85",
							"61,5",
							"63,5",
							"300",
							"350",
							"5,1",
							"5,35",
							"0,01",
							"0,02",
							"581",
							"601",
						],
						[
							{ text: "01.09.2023 11:07:09", colSpan: 3 },
							{},
							{},
							{ text: "№1 478, 01.09.2023", colSpan: 3 },
							{},
							"456",
							"459",
							"494",
							"499",
							"4,71",
							"4,85",
							"61",
							"63,5",
							"300",
							"350",
							"5,1",
							"5,35",
							"0,01",
							"0,02",
							"577",
							"597",
						],
						[
							{ text: "01.09.2023 12:31:58", colSpan: 3 },
							{},
							{},
							{ text: "№1 480, 01.09.2023", colSpan: 3 },
							{},
							"457",
							"460",
							"495",
							"498",
							"4,71",
							"4,85",
							"61",
							"63,5",
							"300",
							"350",
							"5,1",
							"5,35",
							"0,01",
							"0,02",
							"577",
							"597",
						],
						[
							{ text: "01.09.2023 14:36:58", colSpan: 3 },
							{},
							{},
							{ text: "№1 481, 01.09.2023", colSpan: 3 },
							{},
							"457,5",
							"460",
							"495",
							"498",
							"4,71",
							"4,85",
							"61",
							"63,5",
							"300",
							"350",
							"5,1",
							"5,35",
							"0,01",
							"0,02",
							"577",
							"597",
						],
						[
							{ text: "01.09.2023 14:46:14", colSpan: 3 },
							{},
							{},
							{ text: "№1 482, 01.09.2023", colSpan: 3 },
							{},
							"457,5",
							"459",
							"495",
							"498",
							"4,71",
							"4,85",
							"61",
							"63,5",
							"300",
							"350",
							"5,1",
							"5,35",
							"0,01",
							"0,02",
							"577",
							"597",
						],
						[
							{ text: "01.09.2023 14:48:48", colSpan: 3 },
							{},
							{},
							{ text: "№1 483, 01.09.2023", colSpan: 3 },
							{},
							"457",
							"459",
							"495",
							"498",
							"4,71",
							"4,85",
							"61",
							"63,5",
							"300",
							"350",
							"5,1",
							"5,35",
							"0,01",
							"0,02",
							"577",
							"597",
						],
						[
							{ text: "01.09.2023 15:33:46", colSpan: 3 },
							{},
							{},
							{ text: "№1 484, 01.09.2023", colSpan: 3 },
							{},
							"457",
							"459",
							"495",
							"498",
							"4,71",
							"4,77",
							"61",
							"63,5",
							"300",
							"350",
							"5,1",
							"5,35",
							"0,01",
							"0,02",
							"577",
							"597",
						],
						[
							{ text: "01.09.2023 15:35:24", colSpan: 3 },
							{},
							{},
							{ text: "№1 485, 01.09.2023", colSpan: 3 },
							{},
							"457",
							"459",
							"495",
							"498",
							"4,71",
							"4,85",
							"61",
							"63,5",
							"300",
							"350",
							"5,1",
							"5,35",
							"0,01",
							"0,02",
							"577",
							"597",
						],
						[
							{ text: "01.09.2023 15:47:57", colSpan: 3 },
							{},
							{},
							{ text: "№1 486, 01.09.2023", colSpan: 3 },
							{},
							"457,5",
							"459,5",
							"495,5",
							"498,5",
							"4,71",
							"4,85",
							"61",
							"63,5",
							"300",
							"350",
							"5,1",
							"5,35",
							"0,01",
							"0,02",
							"577",
							"597",
						],
						[
							{ text: "01.09.2023 16:41:13", colSpan: 3 },
							{},
							{},
							{ text: "№1 487, 01.09.2023", colSpan: 3 },
							{},
							"457,5",
							"459,5",
							"495,5",
							"498,5",
							"4,65",
							"4,85",
							"61",
							"63,5",
							"300",
							"350",
							"5,1",
							"5,35",
							"0,01",
							"0,02",
							"577",
							"597",
						],
						[
							{ text: "01.09.2023 16:43:06", colSpan: 3 },
							{},
							{},
							{ text: "№1 488, 01.09.2023", colSpan: 3 },
							{},
							"457,5",
							"459,5",
							"495,5",
							"498,5",
							"4,7",
							"4,85",
							"61",
							"63,5",
							"300",
							"350",
							"5,1",
							"5,35",
							"0,01",
							"0,02",
							"577",
							"597",
						],
						[
							{ text: "01.09.2023 18:01:13", colSpan: 3 },
							{},
							{},
							{ text: "№1 489, 01.09.2023", colSpan: 3 },
							{},
							"458",
							"461,5",
							"495,5",
							"500",
							"4,7",
							"4,85",
							"61",
							"63,5",
							"300",
							"350",
							"5,1",
							"5,35",
							"0,01",
							"0,02",
							"577",
							"597",
						],
					]
				}
			},
			{ text: "\n" },
			{ text: "\n" },
			{ text: "\n" },
			{ text: "Кассир: Шадыман Айдана_________________страница 1", alignment: "center" },
			{ text: "(фамилия, имя и отчество (при его наличии)     (подпись)", alignment: "center", italics: true, fontSize: 4 },
			{ text: "\n" },
			{ text: "\n" },
			{
				alignment: "center",
				bold: true,
				stack: [
					"РЕЕСТР",
					"купленной и проданной наличной иностранной валюты",
					"за 1 июня 2023 г."
				]
			},
			{ text: "\n" },
			{
				layout: {
					hLineWidth: function () {
						return .1;
					},
					vLineWidth: function () {
						return .1;
					},
				},
				table: {
					widths: [15, 15, 31.5, 31.5, 31.5, 31.5, 31.5, 31.5, 31.5, 31.5, 31.5, 31.5, 31.5, 31.5, 31.5, 31.5, 31.5, 31.5, 31.5, 31.5,],
					body: [
						[
							{ text: "№п/п", alignment: "center", rowSpan: 3 },
							{ text: "Признак резидентства*", alignment: "center", rowSpan: 3 },
							{ text: "Данные документа, удостоверяющего личность клиента**", alignment: "center", rowSpan: 3, colSpan: 3 },
							{},
							{},
							{ text: "Индивидуальный идентификационный номер клиента", alignment: "center", rowSpan: 3 },
							{ text: "Юридический адрес клиента", alignment: "center", rowSpan: 3, colSpan: 2 },
							{},
							{ text: "Наименование иностранной валюты", alignment: "center", rowSpan: 3, colSpan: 2 },
							{},
							{ text: "Сумма валюты", alignment: "center", colSpan: 8, },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Время проведения операции (в часах и минутах)*****", alignment: "center", rowSpan: 3, colSpan: 2 },
							{},
						],
						[
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Куплено", alignment: "center", colSpan: 4 },
							{},
							{},
							{},
							{ text: "Продано", alignment: "center", colSpan: 4 },
							{},
							{},
							{},
							{},
							{},
						],
						[
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "в иностранной валюте", alignment: "center", colSpan: 2 },
							{},
							{ text: "тенге***", alignment: "center", colSpan: 2 },
							{},
							{ text: "в иностранной валюте", alignment: "center", colSpan: 2 },
							{},
							{ text: "тенге****", alignment: "center", colSpan: 2 },
							{},
							{},
							{},
						],
						[
							{ text: "1", alignment: "center" },
							{ text: "2", alignment: "center" },
							{ text: "3", alignment: "center", colSpan: 3 },
							{},
							{},
							{ text: "4", alignment: "center" },
							{ text: "5", alignment: "center", colSpan: 2 },
							{},
							{ text: "6", alignment: "center", colSpan: 2 },
							{},
							{ text: "7", alignment: "center", colSpan: 2 },
							{},
							{ text: "8", alignment: "center", colSpan: 2 },
							{},
							{ text: "9", alignment: "center", colSpan: 2 },
							{},
							{ text: "10", alignment: "center", colSpan: 2 },
							{},
							{ text: "11", alignment: "center", colSpan: 2 },
							{},
						],
						[
							{ text: "1", alignment: "right" },
							{ text: "1", alignment: "center" },
							{ text: "Киселёв Иван Михайлович; Удостоверение личности; № 047242197; выдан: МВД РК; дата выдачи: 20.10.2020; дата окончания: 19.10.2030", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "911010399016", fontSize: 4 },
							{ text: "Казахстан, ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "KGS", colSpan: 2 },
							{},
							{ text: "2 980,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "15 198,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "09.04", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "Изменение курса", bold: true, fontSize: 5, colSpan: 3, border: [false, true, false, false] },
							{},
							{},
							{ text: "01.06.2024 9:04:31", bold: true, fontSize: 5, colSpan: 7, border: [false, true, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Итого по:", bold: true, fontSize: 5, colSpan: 10, border: [false, true, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 8, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "KGS", alignment: "right", bold: true, fontSize: 5, colSpan: 2, border: [false, false, false, false] },
							{},
							{ text: "", colSpan: 10, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 9, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Куплено по курсу", colSpan: 3, border: [false, false, false, false] },
							{},
							{},
							{ text: "5,100", colSpan: 4, alignment: "right", border: [false, false, false, false] },
							{},
							{},
							{},
							{ text: "2 980,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
							{ text: "T15 198,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
						],
						[
							{ text: "", colSpan: 20, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],



						[
							{ text: "2", alignment: "right" },
							{ text: "1", alignment: "center" },
							{ text: "Ағзам Алмас Мұратұлы; Удостоверение личности; № 042052785; выдан: МВД РК; дата выдачи: 04.09.2017; дата окончания: 03.09.2027", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "840403300302", fontSize: 4 },
							{ text: "Казахстан, ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "USD", colSpan: 2 },
							{},
							{ text: "300,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "136 800,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "11.37", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "3", alignment: "right" },
							{ text: "2", alignment: "center" },
							{ text: "Аветисан Гурген Арамович; Заграничный паспорт; № 75 2888666; дата выдачи: 02.03.2016; дата окончания: 02.03.2026", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "", fontSize: 4 },
							{ text: ", ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "EUR", colSpan: 2 },
							{},
							{ text: "200,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "98 800,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "12.08", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "4", alignment: "right" },
							{ text: "2", alignment: "center" },
							{ text: "Трофимов Андрей; Заграничный паспорт; № 760959789; дата выдачи: 26.05.2019; дата окончания: 26.05.2029", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "", fontSize: 4 },
							{ text: ", ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "USD", colSpan: 2 },
							{},
							{ text: "500,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "228 000,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "12.11", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "Изменение курса", bold: true, fontSize: 5, colSpan: 3, border: [false, true, false, false] },
							{},
							{},
							{ text: "01.09.2023 12:11:14", bold: true, fontSize: 5, colSpan: 7, border: [false, true, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Итого по:", bold: true, fontSize: 5, colSpan: 10, border: [false, true, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 8, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "USD", alignment: "right", bold: true, fontSize: 5, colSpan: 2, border: [false, false, false, false] },
							{},
							{ text: "", colSpan: 10, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 9, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Куплено по курсу", colSpan: 3, border: [false, false, false, false] },
							{},
							{},
							{ text: "456,000", colSpan: 4, alignment: "right", border: [false, false, false, false] },
							{},
							{},
							{},
							{ text: "800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
							{ text: "T364 800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
						],
						[
							{ text: "", colSpan: 8, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "EUR", alignment: "right", bold: true, fontSize: 5, colSpan: 2, border: [false, false, false, false] },
							{},
							{ text: "", colSpan: 10, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 9, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Куплено по курсу", colSpan: 3, border: [false, false, false, false] },
							{},
							{},
							{ text: "494,000", colSpan: 4, alignment: "right", border: [false, false, false, false] },
							{},
							{},
							{},
							{ text: "200,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
							{ text: "T98 800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
						],
						[
							{ text: "", colSpan: 20, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],



						[
							{ text: "2", alignment: "right" },
							{ text: "1", alignment: "center" },
							{ text: "Ағзам Алмас Мұратұлы; Удостоверение личности; № 042052785; выдан: МВД РК; дата выдачи: 04.09.2017; дата окончания: 03.09.2027", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "840403300302", fontSize: 4 },
							{ text: "Казахстан, ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "USD", colSpan: 2 },
							{},
							{ text: "300,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "136 800,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "11.37", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "3", alignment: "right" },
							{ text: "2", alignment: "center" },
							{ text: "Аветисан Гурген Арамович; Заграничный паспорт; № 75 2888666; дата выдачи: 02.03.2016; дата окончания: 02.03.2026", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "", fontSize: 4 },
							{ text: ", ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "EUR", colSpan: 2 },
							{},
							{ text: "200,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "98 800,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "12.08", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "4", alignment: "right" },
							{ text: "2", alignment: "center" },
							{ text: "Трофимов Андрей; Заграничный паспорт; № 760959789; дата выдачи: 26.05.2019; дата окончания: 26.05.2029", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "", fontSize: 4 },
							{ text: ", ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "USD", colSpan: 2 },
							{},
							{ text: "500,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "228 000,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "12.11", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "Изменение курса", bold: true, fontSize: 5, colSpan: 3, border: [false, true, false, false] },
							{},
							{},
							{ text: "01.09.2023 12:11:14", bold: true, fontSize: 5, colSpan: 7, border: [false, true, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Итого по:", bold: true, fontSize: 5, colSpan: 10, border: [false, true, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 8, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "USD", alignment: "right", bold: true, fontSize: 5, colSpan: 2, border: [false, false, false, false] },
							{},
							{ text: "", colSpan: 10, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 9, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Куплено по курсу", colSpan: 3, border: [false, false, false, false] },
							{},
							{},
							{ text: "456,000", colSpan: 4, alignment: "right", border: [false, false, false, false] },
							{},
							{},
							{},
							{ text: "800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
							{ text: "T364 800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
						],
						[
							{ text: "", colSpan: 8, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "EUR", alignment: "right", bold: true, fontSize: 5, colSpan: 2, border: [false, false, false, false] },
							{},
							{ text: "", colSpan: 10, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 9, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Куплено по курсу", colSpan: 3, border: [false, false, false, false] },
							{},
							{},
							{ text: "494,000", colSpan: 4, alignment: "right", border: [false, false, false, false] },
							{},
							{},
							{},
							{ text: "200,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
							{ text: "T98 800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
						],
						[
							{ text: "", colSpan: 20, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],



						[
							{ text: "2", alignment: "right" },
							{ text: "1", alignment: "center" },
							{ text: "Ағзам Алмас Мұратұлы; Удостоверение личности; № 042052785; выдан: МВД РК; дата выдачи: 04.09.2017; дата окончания: 03.09.2027", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "840403300302", fontSize: 4 },
							{ text: "Казахстан, ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "USD", colSpan: 2 },
							{},
							{ text: "300,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "136 800,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "11.37", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "3", alignment: "right" },
							{ text: "2", alignment: "center" },
							{ text: "Аветисан Гурген Арамович; Заграничный паспорт; № 75 2888666; дата выдачи: 02.03.2016; дата окончания: 02.03.2026", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "", fontSize: 4 },
							{ text: ", ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "EUR", colSpan: 2 },
							{},
							{ text: "200,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "98 800,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "12.08", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "4", alignment: "right" },
							{ text: "2", alignment: "center" },
							{ text: "Трофимов Андрей; Заграничный паспорт; № 760959789; дата выдачи: 26.05.2019; дата окончания: 26.05.2029", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "", fontSize: 4 },
							{ text: ", ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "USD", colSpan: 2 },
							{},
							{ text: "500,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "228 000,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "12.11", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "Изменение курса", bold: true, fontSize: 5, colSpan: 3, border: [false, true, false, false] },
							{},
							{},
							{ text: "01.09.2023 12:11:14", bold: true, fontSize: 5, colSpan: 7, border: [false, true, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Итого по:", bold: true, fontSize: 5, colSpan: 10, border: [false, true, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 8, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "USD", alignment: "right", bold: true, fontSize: 5, colSpan: 2, border: [false, false, false, false] },
							{},
							{ text: "", colSpan: 10, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 9, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Куплено по курсу", colSpan: 3, border: [false, false, false, false] },
							{},
							{},
							{ text: "456,000", colSpan: 4, alignment: "right", border: [false, false, false, false] },
							{},
							{},
							{},
							{ text: "800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
							{ text: "T364 800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
						],
						[
							{ text: "", colSpan: 8, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "EUR", alignment: "right", bold: true, fontSize: 5, colSpan: 2, border: [false, false, false, false] },
							{},
							{ text: "", colSpan: 10, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 9, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Куплено по курсу", colSpan: 3, border: [false, false, false, false] },
							{},
							{},
							{ text: "494,000", colSpan: 4, alignment: "right", border: [false, false, false, false] },
							{},
							{},
							{},
							{ text: "200,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
							{ text: "T98 800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
						],
						[
							{ text: "", colSpan: 20, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],



						[
							{ text: "2", alignment: "right" },
							{ text: "1", alignment: "center" },
							{ text: "Ағзам Алмас Мұратұлы; Удостоверение личности; № 042052785; выдан: МВД РК; дата выдачи: 04.09.2017; дата окончания: 03.09.2027", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "840403300302", fontSize: 4 },
							{ text: "Казахстан, ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "USD", colSpan: 2 },
							{},
							{ text: "300,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "136 800,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "11.37", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "3", alignment: "right" },
							{ text: "2", alignment: "center" },
							{ text: "Аветисан Гурген Арамович; Заграничный паспорт; № 75 2888666; дата выдачи: 02.03.2016; дата окончания: 02.03.2026", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "", fontSize: 4 },
							{ text: ", ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "EUR", colSpan: 2 },
							{},
							{ text: "200,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "98 800,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "12.08", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "4", alignment: "right" },
							{ text: "2", alignment: "center" },
							{ text: "Трофимов Андрей; Заграничный паспорт; № 760959789; дата выдачи: 26.05.2019; дата окончания: 26.05.2029", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "", fontSize: 4 },
							{ text: ", ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "USD", colSpan: 2 },
							{},
							{ text: "500,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "228 000,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "12.11", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "Изменение курса", bold: true, fontSize: 5, colSpan: 3, border: [false, true, false, false] },
							{},
							{},
							{ text: "01.09.2023 12:11:14", bold: true, fontSize: 5, colSpan: 7, border: [false, true, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Итого по:", bold: true, fontSize: 5, colSpan: 10, border: [false, true, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 8, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "USD", alignment: "right", bold: true, fontSize: 5, colSpan: 2, border: [false, false, false, false] },
							{},
							{ text: "", colSpan: 10, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 9, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Куплено по курсу", colSpan: 3, border: [false, false, false, false] },
							{},
							{},
							{ text: "456,000", colSpan: 4, alignment: "right", border: [false, false, false, false] },
							{},
							{},
							{},
							{ text: "800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
							{ text: "T364 800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
						],
						[
							{ text: "", colSpan: 8, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "EUR", alignment: "right", bold: true, fontSize: 5, colSpan: 2, border: [false, false, false, false] },
							{},
							{ text: "", colSpan: 10, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 9, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Куплено по курсу", colSpan: 3, border: [false, false, false, false] },
							{},
							{},
							{ text: "494,000", colSpan: 4, alignment: "right", border: [false, false, false, false] },
							{},
							{},
							{},
							{ text: "200,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
							{ text: "T98 800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
						],
						[
							{ text: "", colSpan: 20, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],



						[
							{ text: "2", alignment: "right" },
							{ text: "1", alignment: "center" },
							{ text: "Ағзам Алмас Мұратұлы; Удостоверение личности; № 042052785; выдан: МВД РК; дата выдачи: 04.09.2017; дата окончания: 03.09.2027", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "840403300302", fontSize: 4 },
							{ text: "Казахстан, ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "USD", colSpan: 2 },
							{},
							{ text: "300,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "136 800,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "11.37", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "3", alignment: "right" },
							{ text: "2", alignment: "center" },
							{ text: "Аветисан Гурген Арамович; Заграничный паспорт; № 75 2888666; дата выдачи: 02.03.2016; дата окончания: 02.03.2026", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "", fontSize: 4 },
							{ text: ", ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "EUR", colSpan: 2 },
							{},
							{ text: "200,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "98 800,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "12.08", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "4", alignment: "right" },
							{ text: "2", alignment: "center" },
							{ text: "Трофимов Андрей; Заграничный паспорт; № 760959789; дата выдачи: 26.05.2019; дата окончания: 26.05.2029", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "", fontSize: 4 },
							{ text: ", ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "USD", colSpan: 2 },
							{},
							{ text: "500,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "228 000,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "12.11", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "Изменение курса", bold: true, fontSize: 5, colSpan: 3, border: [false, true, false, false] },
							{},
							{},
							{ text: "01.09.2023 12:11:14", bold: true, fontSize: 5, colSpan: 7, border: [false, true, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Итого по:", bold: true, fontSize: 5, colSpan: 10, border: [false, true, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 8, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "USD", alignment: "right", bold: true, fontSize: 5, colSpan: 2, border: [false, false, false, false] },
							{},
							{ text: "", colSpan: 10, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 9, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Куплено по курсу", colSpan: 3, border: [false, false, false, false] },
							{},
							{},
							{ text: "456,000", colSpan: 4, alignment: "right", border: [false, false, false, false] },
							{},
							{},
							{},
							{ text: "800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
							{ text: "T364 800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
						],
						[
							{ text: "", colSpan: 8, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "EUR", alignment: "right", bold: true, fontSize: 5, colSpan: 2, border: [false, false, false, false] },
							{},
							{ text: "", colSpan: 10, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 9, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Куплено по курсу", colSpan: 3, border: [false, false, false, false] },
							{},
							{},
							{ text: "494,000", colSpan: 4, alignment: "right", border: [false, false, false, false] },
							{},
							{},
							{},
							{ text: "200,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
							{ text: "T98 800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
						],
						[
							{ text: "", colSpan: 20, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],



						[
							{ text: "2", alignment: "right" },
							{ text: "1", alignment: "center" },
							{ text: "Ағзам Алмас Мұратұлы; Удостоверение личности; № 042052785; выдан: МВД РК; дата выдачи: 04.09.2017; дата окончания: 03.09.2027", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "840403300302", fontSize: 4 },
							{ text: "Казахстан, ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "USD", colSpan: 2 },
							{},
							{ text: "300,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "136 800,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "11.37", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "3", alignment: "right" },
							{ text: "2", alignment: "center" },
							{ text: "Аветисан Гурген Арамович; Заграничный паспорт; № 75 2888666; дата выдачи: 02.03.2016; дата окончания: 02.03.2026", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "", fontSize: 4 },
							{ text: ", ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "EUR", colSpan: 2 },
							{},
							{ text: "200,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "98 800,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "12.08", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "4", alignment: "right" },
							{ text: "2", alignment: "center" },
							{ text: "Трофимов Андрей; Заграничный паспорт; № 760959789; дата выдачи: 26.05.2019; дата окончания: 26.05.2029", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "", fontSize: 4 },
							{ text: ", ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "USD", colSpan: 2 },
							{},
							{ text: "500,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "228 000,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "12.11", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "Изменение курса", bold: true, fontSize: 5, colSpan: 3, border: [false, true, false, false] },
							{},
							{},
							{ text: "01.09.2023 12:11:14", bold: true, fontSize: 5, colSpan: 7, border: [false, true, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Итого по:", bold: true, fontSize: 5, colSpan: 10, border: [false, true, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 8, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "USD", alignment: "right", bold: true, fontSize: 5, colSpan: 2, border: [false, false, false, false] },
							{},
							{ text: "", colSpan: 10, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 9, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Куплено по курсу", colSpan: 3, border: [false, false, false, false] },
							{},
							{},
							{ text: "456,000", colSpan: 4, alignment: "right", border: [false, false, false, false] },
							{},
							{},
							{},
							{ text: "800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
							{ text: "T364 800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
						],
						[
							{ text: "", colSpan: 8, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "EUR", alignment: "right", bold: true, fontSize: 5, colSpan: 2, border: [false, false, false, false] },
							{},
							{ text: "", colSpan: 10, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 9, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Куплено по курсу", colSpan: 3, border: [false, false, false, false] },
							{},
							{},
							{ text: "494,000", colSpan: 4, alignment: "right", border: [false, false, false, false] },
							{},
							{},
							{},
							{ text: "200,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
							{ text: "T98 800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
						],
						[
							{ text: "", colSpan: 20, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],



						[
							{ text: "2", alignment: "right" },
							{ text: "1", alignment: "center" },
							{ text: "Ағзам Алмас Мұратұлы; Удостоверение личности; № 042052785; выдан: МВД РК; дата выдачи: 04.09.2017; дата окончания: 03.09.2027", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "840403300302", fontSize: 4 },
							{ text: "Казахстан, ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "USD", colSpan: 2 },
							{},
							{ text: "300,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "136 800,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "11.37", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "3", alignment: "right" },
							{ text: "2", alignment: "center" },
							{ text: "Аветисан Гурген Арамович; Заграничный паспорт; № 75 2888666; дата выдачи: 02.03.2016; дата окончания: 02.03.2026", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "", fontSize: 4 },
							{ text: ", ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "EUR", colSpan: 2 },
							{},
							{ text: "200,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "98 800,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "12.08", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "4", alignment: "right" },
							{ text: "2", alignment: "center" },
							{ text: "Трофимов Андрей; Заграничный паспорт; № 760959789; дата выдачи: 26.05.2019; дата окончания: 26.05.2029", colSpan: 3, fontSize: 4 },
							{},
							{},
							{ text: "", fontSize: 4 },
							{ text: ", ", colSpan: 2, fontSize: 4 },
							{},
							{ text: "USD", colSpan: 2 },
							{},
							{ text: "500,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "228 000,00", alignment: "right", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "", alignment: "center", colSpan: 2 },
							{},
							{ text: "12.11", alignment: "right", colSpan: 2 },
							{},
						],
						[
							{ text: "Изменение курса", bold: true, fontSize: 5, colSpan: 3, border: [false, true, false, false] },
							{},
							{},
							{ text: "01.09.2023 12:11:14", bold: true, fontSize: 5, colSpan: 7, border: [false, true, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Итого по:", bold: true, fontSize: 5, colSpan: 10, border: [false, true, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 8, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "USD", alignment: "right", bold: true, fontSize: 5, colSpan: 2, border: [false, false, false, false] },
							{},
							{ text: "", colSpan: 10, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 9, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Куплено по курсу", colSpan: 3, border: [false, false, false, false] },
							{},
							{},
							{ text: "456,000", colSpan: 4, alignment: "right", border: [false, false, false, false] },
							{},
							{},
							{},
							{ text: "800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
							{ text: "T364 800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
						],
						[
							{ text: "", colSpan: 8, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "EUR", alignment: "right", bold: true, fontSize: 5, colSpan: 2, border: [false, false, false, false] },
							{},
							{ text: "", colSpan: 10, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
						[
							{ text: "", colSpan: 9, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{ text: "Куплено по курсу", colSpan: 3, border: [false, false, false, false] },
							{},
							{},
							{ text: "494,000", colSpan: 4, alignment: "right", border: [false, false, false, false] },
							{},
							{},
							{},
							{ text: "200,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
							{ text: "T98 800,00", colSpan: 2, alignment: "right", border: [false, false, false, false] },
							{},
						],
						[
							{ text: "", colSpan: 20, border: [false, false, false, false] },
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
							{},
						],
					]
				}
			},
		],
		defaultStyle: {
			fontSize: 6,
			font: "Roboto"
		},
	};

	return showing;
};
import { Routes, Route } from "react-router-dom";
import BalancePage from './BalancePage/BalancePage';
import { wizard } from '@/hoc';

const KassaDay = () => (
  <Routes>
    <Route
      path="/balance_page"
      element={wizard(BalancePage)}
    />
  </Routes>
);

export default KassaDay;

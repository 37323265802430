import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Box, Button, Card, Chip, Divider, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, Stack, Switch, Table, TableBody, TableCell, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { AddCircle, EventBusy } from '@mui/icons-material';
import { api } from '@/api/Api';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import processManager from '@/core/helpers/ProcessManager';
import { Calendar } from '@/api/CalendarController';
import i18n from '@/i18nextConf';
import { useTranslation } from 'react-i18next';

const months = () => [
    { key: "JANUARY", value: i18n.t("static.month.JANUARY") },
    { key: "FEBRUARY", value: i18n.t("static.month.FEBRUARY") },
    { key: "MARCH", value: i18n.t("static.month.MARCH") },
    { key: "APRIL", value: i18n.t("static.month.APRIL") },
    { key: "MAY", value: i18n.t("static.month.MAY") },
    { key: "JUNE", value: i18n.t("static.month.JUNE") },
    { key: "JULY", value: i18n.t("static.month.JULY") },
    { key: "AUGUST", value: i18n.t("static.month.AUGUST") },
    { key: "SEPTEMBER", value: i18n.t("static.month.SEPTEMBER") },
    { key: "OCTOBER", value: i18n.t("static.month.OCTOBER") },
    { key: "NOVEMBER", value: i18n.t("static.month.NOVEMBER") },
    { key: "DECEMBER", value: i18n.t("static.month.DECEMBER") }
];

const weekDays = () => [
    { key: "MONDAY", value: i18n.t("static.weekday.MONDAY") },
    { key: "TUESDAY", value: i18n.t("static.weekday.TUESDAY") },
    { key: "WEDNESDAY", value: i18n.t("static.weekday.WEDNESDAY") },
    { key: "THURSDAY", value: i18n.t("static.weekday.THURSDAY") },
    { key: "FRIDAY", value: i18n.t("static.weekday.FRIDAY") },
    { key: "SATURDAY", value: i18n.t("static.weekday.SATURDAY") },
    { key: "SUNDAY", value: i18n.t("static.weekday.SUNDAY") }
];

const hours = [
    { key: 0, value: '00' },
    { key: 1, value: '01' },
    { key: 2, value: '02' },
    { key: 3, value: '03' },
    { key: 4, value: '04' },
    { key: 5, value: '05' },
    { key: 6, value: '06' },
    { key: 7, value: '07' },
    { key: 8, value: '08' },
    { key: 9, value: '09' },
    { key: 10, value: '10' },
    { key: 11, value: '11' },
    { key: 12, value: '12' },
    { key: 13, value: '13' },
    { key: 14, value: '14' },
    { key: 15, value: '15' },
    { key: 16, value: '16' },
    { key: 17, value: '17' },
    { key: 18, value: '18' },
    { key: 19, value: '19' },
    { key: 20, value: '20' },
    { key: 21, value: '21' },
    { key: 22, value: '22' },
    { key: 23, value: '23' },
];

const minutes = [
    { key: 0, value: '00' },
    { key: 5, value: '05' },
    { key: 10, value: '10' },
    { key: 15, value: '15' },
    { key: 20, value: '20' },
    { key: 25, value: '25' },
    { key: 30, value: '30' },
    { key: 35, value: '35' },
    { key: 40, value: '40' },
    { key: 45, value: '45' },
    { key: 50, value: '50' },
    { key: 55, value: '55' }
];

const CalendarCreate = (props: any) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [calendar, setCalendar] = useState(new Calendar());
    const [month, setMonth] = useState("JANUARY");
    const [date, setDate] = useState(1);

    const { formState } = props;

    const monthsVar = months();

    useEffect(() => {
        if (formState && id) {
            api.calendar.get(id).then((c) => {
                setCalendar(c);
            });
        }
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();
        api.calendar.create(calendar).then(() => {
            processManager.openSnackBar(formState ? t("cal.create.successupdate") : t("cal.create.successcreate"));
            navigate('/calendars');
        });
    };

    const handleOpenChange = (index: number, value: boolean) => {
        const newCalendar = { ...calendar };
        const newBusinessDays = [...calendar.businessDays];
        newBusinessDays[index].open = value;
        newCalendar.businessDays = newBusinessDays;
        newCalendar.is247 = !(
            newBusinessDays[0].open ||
            newBusinessDays[1].open ||
            newBusinessDays[2].open ||
            newBusinessDays[3].open ||
            newBusinessDays[4].open ||
            newBusinessDays[5].open ||
            newBusinessDays[6].open);
        setCalendar(newCalendar);
    };

    const handleStartHourChange = (index: number, value: number) => {
        const newCalendar = { ...calendar };
        const newBusinessDays = [...calendar.businessDays];
        newBusinessDays[index].startHour = value;
        newCalendar.businessDays = newBusinessDays;
        setCalendar(newCalendar);
    };

    const handleStartMinuteChange = (index: number, value: number) => {
        const newCalendar = { ...calendar };
        const newBusinessDays = [...calendar.businessDays];
        newBusinessDays[index].startMinute = value;
        newCalendar.businessDays = newBusinessDays;
        setCalendar(newCalendar);
    };

    const handleEndHourChange = (index: number, value: number) => {
        const newCalendar = { ...calendar };
        const newBusinessDays = [...calendar.businessDays];
        newBusinessDays[index].endHour = value;
        newCalendar.businessDays = newBusinessDays;
        setCalendar(newCalendar);
    };

    const handleEndMinuteChange = (index: number, value: number) => {
        const newCalendar = { ...calendar };
        const newBusinessDays = [...calendar.businessDays];
        newBusinessDays[index].endMinute = value;
        newCalendar.businessDays = newBusinessDays;
        setCalendar(newCalendar);
    };

    const handleIs247Change = (value: boolean) => {
        const newCalendar = { ...calendar };
        const newBusinessDays = [...calendar.businessDays];
        newBusinessDays.forEach(bd => {
            bd.open = !value;
            bd.startHour = 0;
            bd.startMinute = 0;
            bd.endHour = 0;
            bd.endMinute = 0;
        });
        newCalendar.businessDays = newBusinessDays;
        newCalendar.is247 = value;
        setCalendar(newCalendar);
    };

    const handleAddHoliday = () => {
        if (!calendar.holidays.some(h => h.month == month && h.dayOfMonth == date)) {
            const newCalendar = { ...calendar };
            const newHolidays = [...calendar.holidays];
            newHolidays.push({ month: month, dayOfMonth: date });
            newCalendar.holidays = newHolidays;
            setCalendar(newCalendar);
        }
    };

    const handleDeleteHoliday = (index: number) => {
        const newCalendar = { ...calendar };
        const newHolidays = calendar.holidays.filter((h, i) => index != i);
        newCalendar.holidays = newHolidays;
        setCalendar(newCalendar);
    };

    const getDaysInMonth = (month: number) => {
        const date = new Date();
        const currentYear = date.getFullYear();
        return new Date(currentYear, month + 1, 0).getDate();
    };

    return (
        <Grid container spacing={2}>
            <Grid xs={12} md={8} lg={8}>
                <Toolbar>
                    <Button variant="contained" component={Link} to='/calendars' startIcon={<EventBusy />}>{t("cal.create.cancel")}</Button>
                </Toolbar>
            </Grid>
            {/* Chart */}
            <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <Grid sx={{ p: 2 }}>
                        <Typography component="h2" variant="h6">
                            {formState ? t("cal.create.calupdate") : t("cal.create.calcreate")}
                        </Typography>
                        <br />
                        <Box component="form" onSubmit={onSubmit} sx={{ width: '100%' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        size='small'
                                        label={t("cal.create.name")}
                                        placeholder={t("cal.create.namehelper") || ''}
                                        value={calendar.name}
                                        onChange={(e) =>
                                            setCalendar({ ...calendar, name: e.target.value })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>

                                </Grid>

                            </Grid>
                            <br />

                            <Card sx={{ p: 2 }}>
                                <Grid item xs={2} sm={2}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch checked={calendar.is247} onChange={(e) => handleIs247Change(e.target.checked)} />} label="24/7" />
                                    </FormGroup>
                                </Grid>
                                <Table>
                                    <TableBody>
                                        {weekDays().map((w, i) =>
                                            <TableRow key={i}>
                                                <TableCell>
                                                    <FormGroup>
                                                        <FormControlLabel control={<Switch checked={calendar.businessDays[i].open} onChange={(e) => handleOpenChange(i, e.target.checked)} />} label={w.value} />
                                                    </FormGroup>
                                                </TableCell>
                                                <TableCell>
                                                    <FormControl fullWidth size='small'>
                                                        <InputLabel id="lang-select-label">{t("cal.create.hourstart")}</InputLabel>
                                                        <Select
                                                            disabled={!calendar.businessDays[i].open}
                                                            size='small'
                                                            labelId="lang-select-label"
                                                            id="lang-select"
                                                            value={calendar.businessDays[i].startHour}
                                                            label={t("cal.create.hourstart")}
                                                            onChange={(e) => handleStartHourChange(i, e.target.value as number)}
                                                        >
                                                            {hours.map((h, hi) =>
                                                                <MenuItem key={'s' + i + hi} value={h.key}>{h.value}</MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell>
                                                    <FormControl fullWidth size='small'>
                                                        <InputLabel id="lang-select-label">{t("cal.create.minstart")}</InputLabel>
                                                        <Select
                                                            disabled={!calendar.businessDays[i].open}
                                                            size='small'
                                                            labelId="lang-select-label"
                                                            id="lang-select"
                                                            value={calendar.businessDays[i].startMinute}
                                                            label={t("cal.create.minstart")}
                                                            onChange={(e) => handleStartMinuteChange(i, e.target.value as number)}
                                                        >
                                                            {minutes.map((m, mi) =>
                                                                <MenuItem key={'s' + i + mi} value={m.key}>{m.value}</MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell>
                                                    <FormControl fullWidth size='small'>
                                                        <InputLabel id="lang-select-label">{t("cal.create.hourend")}</InputLabel>
                                                        <Select
                                                            disabled={!calendar.businessDays[i].open}
                                                            size='small'
                                                            labelId="lang-select-label"
                                                            id="lang-select"
                                                            value={calendar.businessDays[i].endHour}
                                                            label={t("cal.create.hourend")}
                                                            onChange={(e) => handleEndHourChange(i, e.target.value as number)}
                                                        >
                                                            {hours.map((h, hi) =>
                                                                <MenuItem key={'e' + i + hi} value={h.key}>{h.value}</MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell>
                                                    <FormControl fullWidth size='small'>
                                                        <InputLabel id="lang-select-label">{t("cal.create.minend")}</InputLabel>
                                                        <Select
                                                            disabled={!calendar.businessDays[i].open}
                                                            size='small'
                                                            labelId="lang-select-label"
                                                            id="lang-select"
                                                            value={calendar.businessDays[i].endMinute}
                                                            label={t("cal.create.minend")}
                                                            onChange={(e) => handleEndMinuteChange(i, e.target.value as number)}
                                                        >
                                                            {minutes.map((m, mi) =>
                                                                <MenuItem key={'e' + i + mi} value={m.key}>{m.value}</MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </Card>
                            <br />
                            <Card sx={{ p: 2 }}>
                                <Typography>
                                    {t("cal.create.holidays")}
                                </Typography>
                                <br />
                                <Stack direction="row" spacing={1}>
                                    {calendar.holidays.map((h, i) => <Chip key={i} label={`${h.dayOfMonth}, ${monthsVar.find(m => m.key == h.month)?.value}`} variant="outlined" onDelete={() => { handleDeleteHoliday(i); }} />)}
                                </Stack>
                                <br />
                                <Divider />
                                <br />
                                <Grid container spacing={2}>
                                    <Grid xs={3} sm={3} item>
                                        <FormControl fullWidth size='small'>
                                            <InputLabel id="lang-select-label">{t("cal.create.month")}</InputLabel>
                                            <Select
                                                disabled={calendar.is247}
                                                size='small'
                                                labelId="lang-select-label"
                                                id="lang-select"
                                                value={month}
                                                label={t("cal.create.month")}
                                                onChange={(e) => setMonth(e.target.value)}
                                            >
                                                {monthsVar.map((m, mi) =>
                                                    <MenuItem key={'mo' + mi} value={m.key}>{m.value}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={3} sm={3} item>
                                        <FormControl fullWidth size='small'>
                                            <InputLabel id="lang-select-label">{t("cal.create.date")}</InputLabel>
                                            <Select
                                                disabled={calendar.is247}
                                                size='small'
                                                labelId="lang-select-label"
                                                id="lang-select"
                                                value={date}
                                                label={t("cal.create.date")}
                                                onChange={(e) => setDate(e.target.value as number)}
                                            >
                                                {Array.from({ length: getDaysInMonth(monthsVar.findIndex(m => m.key == month)) }).map((v, di) =>
                                                    <MenuItem key={'d' + di} value={di + 1}>{di + 1}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={3} sm={3} item>
                                        <IconButton disabled={calendar.is247} onClick={handleAddHoliday}>
                                            <AddCircle />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Card>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {t("cal.create.save")}
                            </Button>
                        </Box>
                    </Grid>
                </Paper>
            </Grid >
        </Grid >);
};

export default CalendarCreate;
import React from "react";

import { FillialCashProps } from "./DepartmentCash.props";

import styles from "./DepartmentCash.module.css";
import { Button } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

export const DepartmentCash = ({
	kassa,
	handleOpenWindow,
	className,
	...props
}: FillialCashProps): JSX.Element => (
	<div
		className={className + " " + styles.container}
		{...props}
	>
		<div
			style={{
				display: "grid",
				gridTemplateColumns: "1fr 1fr",
				gap: "50px",
				maxWidth: "500px",
				margin: "20px auto 0",
				height: "50px"
			}}
		>
			<Button
				variant={"contained"}
				endIcon={<RemoveIcon />}
				sx={{
					background: "#da793f",
					"&:hover": {
						backgroundColor: "#b76534",
					},
				}}
				onClick={() => handleOpenWindow(kassa, true, "updateCash")}
			>
				Расход
			</Button>
			<Button
				variant={"contained"}
				startIcon={<AddIcon />}
				sx={{
					background: "#da793f",
					"&:hover": {
						backgroundColor: "#b76534",
					},
				}}
				onClick={() => handleOpenWindow(kassa, false, "updateCash")}
			>
				Приход
			</Button>
		</div>
	</div>
);
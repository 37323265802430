import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Queue, User, VariableFilter } from '@/api/CamundaController';
import { Button, Checkbox, Grid, IconButton, styled } from '@mui/material';
import pm from '@/core/helpers/ProcessManager';
import moment from 'moment';
import { Link } from '@mui/material';
import { api } from '@/api/Api';
import iassign from 'immutable-assign';
import TaskTableHead from './TaskTableHead';
import TaskTableToolbar from './TaskTableToolbar';
import TaskTableButtons from './TaskTableButtons';
import UserPicker from '../UserPicker';
import hash from "object-hash";
import { ContentCopy } from '@mui/icons-material';
import i18n from '@/i18nextConf';
import { useTranslation } from 'react-i18next';

const basics = () => [
    "taskId",
    i18n.t("task.businessKey"),
    i18n.t("task.status"),
    i18n.t("task.process"),
    i18n.t("task.createDate")
];

export interface Data {
    taskId: string;
    businessKey: number;
    status: string;
    process: string;
    date: string;
}


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: string },
    b: { [key in Key]: string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface TaskBoardProps {
    selectedUserQueue?: Queue;
    userQueue: Queue[];
    me: User;
}

const TaskBoard = (props: TaskBoardProps) => {
    const { t } = useTranslation();

    const { selectedUserQueue, me } = props;

    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<string>('task.businessKey');
    const [page, setPage] = useState(1);
    const [dense, setDense] = useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [checkSum, setCheckSum] = React.useState<string>();

    const [columnNames, setColumnNames] = useState(basics());
    const [rows, setRows] = useState<any[]>([]);
    const [count, setCount] = useState(0);
    const [searchFilter, setSearchFilter] = useState<Array<VariableFilter>>([]);

    useEffect(() => {
        console.log(orderBy + " " + order);
    }, [orderBy, order]);

    const [selected, setSelected] = React.useState<string[]>([]);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map(row => getArrayItem(row));
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleSelect = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    useEffect(() => {
        if (selectedUserQueue) {
            handleSelectQueue(selectedUserQueue);
        }
    }, [selectedUserQueue]);

    useEffect(() => {
        setSelected([]);
    }, [selectedUserQueue?.id]);

    const handleSelectQueue = (queue: Queue) => {
        const queueHash = hash(queue);
        if (queueHash != checkSum) {
            if (searchFilter.length == 0) {
                if (queue && queue.queueResponse) {
                    setRows(queue.queueResponse.queue);
                    setColumnNames(
                        queue.queueResponse?.totalResults > 0
                            ? Object.keys(queue.queueResponse?.queue[0])
                            : basics()
                    );
                    setCount(queue.queueResponse?.totalResults);
                    setRowsPerPage(5);
                    setPage(1);
                    setCheckSum(queueHash);
                }
            }

        }
    };

    const handleSearchQueue = (
        searchFilter?: Object
    ) => {
        if (selectedUserQueue) {
            setPage(1);
            setCount(0);
            setRows([]);
            pm.startLoading();
            api.camunda
                .getQueueList(selectedUserQueue?.id, 1, 5, searchFilter)
                .then(
                    queueInfo => {
                        pm.stopLoading();
                        setRows(queueInfo.queue);
                        setColumnNames(
                            queueInfo.totalResults > 0
                                ? Object.keys(queueInfo.queue[0])
                                : basics()
                        );
                        setCount(queueInfo.totalResults);
                        setRowsPerPage(5);
                    },
                    error => {
                        pm.stopLoading();
                        console.log(error);
                    }
                )
                .catch(error => {
                    pm.stopLoading();
                    console.log(error);
                });
        }
    };

    const handleChangePage = (event: unknown, index: number) => {
        if (event === null) {
            if (selectedUserQueue) {
                api.camunda
                    .getQueueList(selectedUserQueue.id, 1, index, searchFilter)
                    .then(
                        queues => {
                            setRows(queues.queue);
                            setRowsPerPage(index);
                            setPage(1);
                        },
                        error => {
                            console.log(error);
                        }
                    )
                    .catch(error => {
                        console.log(error);
                    });
            }
        } else {
            if (selectedUserQueue) {
                api.camunda
                    .getQueueList(selectedUserQueue.id, index + 1, rowsPerPage, searchFilter)
                    .then(queues => {
                        setRows(queues.queue);
                        setPage(index + 1);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (selectedUserQueue) {
            api.camunda
                .getQueueList(selectedUserQueue.id, 1, +event.target.value, searchFilter)
                .then(queues => {
                    setRows(queues.queue);
                    setRowsPerPage(+event.target.value);
                    setPage(1);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    const onClickSearch = (e: any) => {
        e.preventDefault();
        handleSearchQueue(searchFilter);
    };

    const onClearSearch = () => {
        setSearchFilter([]);
        handleSearchQueue();
    };

    const onReassignTask = (taskId: string, user: string, index: number) => {
        pm.startLoading();
        api.camunda.reassignTask(taskId, user).then(() => {
            setRows(
                iassign(
                    rows,
                    g => g[index]["task.assigned"],
                    () => user
                )
            );
        });
        pm.stopLoading();
    };
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = () =>
        page > 0 ? Math.min(Math.max(0, rowsPerPage - rows.length), 10) : 0;

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const getArrayItem = (row: any) => `${row["processInstanceId"]}:${row["taskId"]}`;

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    return (
        <Box sx={{ width: '100%' }}>
            {/* {selectedUserQueue.distributedAssign && (
                <DistributedTask count={count}
                    selectedUserQueue={selectedUserQueue}
                    onGetTask={() => {
                        rows.some(m => m) && pm.claimTask((rows[0] as any)?.taskId);
                    }} />
            )}

            {selectedUserQueue.distributedTask && (
                <DistributedTask count={count}
                    selectedUserQueue={selectedUserQueue}
                    onGetTask={() => {
                        rows.some(m => m) &&
                            pm.claimDistributedTask(selectedUserQueue.id);
                    }} />
            )} */}
            <Grid>
                <Grid>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        <TaskTableToolbar
                            onClickSearch={onClickSearch}
                            setSearchFilter={setSearchFilter}
                            onClearSearch={onClearSearch}
                            searchFilter={searchFilter}
                            items={[
                                { code: "businessKey", value: t("task.businessKey") },
                                // { code: "company", value: "ID компании" }
                            ]}
                            queueId={selectedUserQueue?.id!}
                            me={me}
                        />
                        <TaskTableButtons
                            me={me}
                            selected={selected}
                            selectedUserQueue={selectedUserQueue} />
                        <TablePagination
                            rowsPerPageOptions={[5, 50, 100]}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}

                            >
                                <TaskTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                    rowCount={rows.length}
                                    columnNames={columnNames}
                                    numSelected={selected.length}
                                    selectedUserQueue={selectedUserQueue}
                                />
                                <TableBody>
                                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                                            rows.sort(getComparator(order, orderBy)).slice() */}
                                    {stableSort(rows, getComparator(order, orderBy))
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(getArrayItem(row));
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (

                                                <StyledTableRow
                                                    key={index}
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    selected={isItemSelected}
                                                >
                                                    {selectedUserQueue?.distributedAdmin &&
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                onClick={(event) => handleSelect(event, getArrayItem(row))}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                            />
                                                        </TableCell>
                                                    }
                                                    {columnNames.filter(c => c != "taskId" && c != "processInstanceId")
                                                        ?.map((column, index) => {
                                                            var value = (row as any)[column];
                                                            var fontWeight = (row as any)['task.assigned'] ? '' : 'bold';

                                                            var momentVar = moment(
                                                                value,
                                                                moment.ISO_8601,
                                                                true
                                                            );
                                                            if (momentVar.isValid()) {
                                                                value = momentVar.format(
                                                                    "DD.MM.YYYY, HH:mm"
                                                                );
                                                            } else if (`${value}`?.startsWith("http")) {
                                                                const url = value;
                                                                value = (
                                                                    <Link>
                                                                        {url}
                                                                    </Link>
                                                                );
                                                                return (
                                                                    <TableCell
                                                                        onClick={(e) => {
                                                                            window.open(url, "_blank");
                                                                            e.stopPropagation();
                                                                        }}
                                                                        key={index}
                                                                        sx={{ fontWeight: fontWeight }}
                                                                    >
                                                                        {value}
                                                                    </TableCell>
                                                                );
                                                            } else if (
                                                                column === "task.assigned" &&
                                                                selectedUserQueue?.distributedAdmin
                                                            ) {
                                                                return (
                                                                    <TableCell key={index}>
                                                                        <UserPicker key={index} mode='colleagues' defaultValue={value} onSelectedItem={u => {
                                                                            onReassignTask(
                                                                                row["taskId"] || '',
                                                                                u.login || '',
                                                                                index
                                                                            );

                                                                        }} />
                                                                    </TableCell>
                                                                );
                                                            } else if (column === "task.businessKey") {
                                                                return (
                                                                    <TableCell key={index}>
                                                                        <Button size='small' onClick={() => selectedUserQueue?.readOnly == true ? pm.openReadOnlyTask(row.taskId!) : pm.claimTask(row.taskId!)}>
                                                                            {value}
                                                                        </Button>
                                                                        <IconButton onClick={() => navigator.clipboard.writeText(value).then(() => pm.openSnackBar(t("task.copied")))}>
                                                                            <ContentCopy fontSize='small' />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                );
                                                            }
                                                            return (
                                                                <TableCell sx={{ fontWeight: fontWeight, maxWidth: '50%' }} key={index}>{value}</TableCell>
                                                            );
                                                        })}
                                                </StyledTableRow>
                                            );
                                        })}
                                    {emptyRows() > 0 && (
                                        <TableRow
                                            style={{
                                                height: (dense ? 33 : 53) * emptyRows(),
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 50, 100]}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
                <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label={t("tight")}
                />
            </Grid>
        </Box >
    );
};

export default TaskBoard;
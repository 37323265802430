import { RateModel } from '@/interface';
import { server } from '../core/server/axios';

const wenv = process.env;

export interface UpdateRateManyVariables {
	depId: string | number;
	id: string | number;
	buy: string;
	sale: string;
	limitFrom: number;
	limitTo: number;
	initiator: string;
}

export class RateController {
	async updateRateMany(rates: UpdateRateManyVariables[]) {
		return await server.patch('/Rates/updateRateMany', rates, {
			baseURL: wenv.REACT_APP_API_URL
		});
	}

	async addRate(rates: RateModel[]) {
		return await server.post('/Rates/addRate', rates, {
			baseURL: wenv.REACT_APP_API_URL
		});
	}
}
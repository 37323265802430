import { api } from '@/api/Api';
import { Calendar } from '@/api/CalendarController';
import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CalendarPickerProps {
    onSelectedItem: (calendar: Calendar) => void;
    label?: string;
    defaultValue?: string;
}

const CalendarPicker = (props: CalendarPickerProps) => {
    const { t } = useTranslation();
    const [content, setContent] = useState<Calendar[]>([]);
    const [code, setCode] = useState('');
    const [selectedItem, setSelectedItem] = useState<Calendar>(new Calendar());
    const { onSelectedItem, label, defaultValue } = props;

    useEffect(() => {
        if (defaultValue) {
            api.calendar.get(defaultValue).then((m) => {
                setSelectedItem(m);
            });
        }
    }, [defaultValue]);

    useEffect(() => {
        api.calendar.getCalendarContains(0, 10, code, code).then((m) => {
            setContent(m.content);
        });
    }, [code]);

    return (
        <Autocomplete
            size='small'
            fullWidth
            disablePortal
            id="combo-box-demo"
            options={content || []}
            getOptionLabel={o => o.name || ''}
            value={selectedItem}
            onChange={(e, value) => {
                if (value) {
                    setSelectedItem(value);
                    onSelectedItem(value);
                }
            }}
            renderInput={(params) => <TextField {...params} label={label || t("choosecalendar")} onChange={(e) => setCode(e.target.value)} />}
        />
    );
};

export default CalendarPicker;
import { DepartmentModel } from '@/interface';
import { Dialog } from '@mui/material';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import AddIcon from '@mui/icons-material/Add';

interface FillialInfoWindowProps {
	openWindow: boolean;
	handleCloseWindow: () => void;
	activeDepartment: DepartmentModel;
	handleAddKassa: (id: string | number, cashboxNum: string) => void;
}

export const DepartmentInfoWindow = ({
	openWindow,
	handleCloseWindow,
	activeDepartment,
	handleAddKassa,
}: FillialInfoWindowProps) => (
	<Dialog
		open={openWindow}
		onClose={handleCloseWindow}
		maxWidth='md'
		fullWidth
		sx={{
			zIndex: (theme) => theme.zIndex.drawer + 1,
			"& .MuiPaper-root": {
				padding: '15px',
			},
			'& h3': {
				textAlign: 'center',
				marginBottom: '10px'
			}
		}}
	>
		<div>
			<h3>
				{activeDepartment.name}
			</h3>
		</div>
		<div style={{ textAlign: 'center' }}>
			<p>г.{activeDepartment.code}, {activeDepartment.address}</p>
			<p>{activeDepartment.bin}</p>
		</div>

		<p style={{ margin: '20px 0 10px', textAlign: 'center', fontWeight: 'bold' }}>Кассы</p>
		<div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>
			{activeDepartment.kassa.map(kassa => (
				<div
					key={kassa.id}
					style={{
						height: '70px',
						width: '70px',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						border: '1px solid #0000002e',
						borderRadius: '5px'
					}}
				>
					<PointOfSaleIcon style={{ fill: 'orange' }} />№{kassa.name}
				</div>
			))}
		</div>
		<div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0 0' }}>
			<div
				style={{
					backgroundColor: 'rgb(53, 170, 53)',
					color: 'white',
					padding: '10px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: '100%',
					transition: '.5s',
					boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
					cursor: 'pointer'
				}}
				onClick={() => {
					const cashboxNum = activeDepartment?.kassa.length ? +(activeDepartment.kassa[activeDepartment.kassa.length - 1].name) + 1 + '' : '1';
					handleAddKassa(activeDepartment.id, cashboxNum);
				}}
			>
				<AddIcon />
			</div>
		</div>

	</Dialog >
);
import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { Order } from "./TaskBoard";
import { visuallyHidden } from '@mui/utils';
import { Queue } from "@/api/CamundaController";
import { useTranslation } from "react-i18next";

interface TaskTableHeadProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    numSelected: number;
    columnNames: string[];
    onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedUserQueue?: Queue
}

const TaskTableHead = (props: TaskTableHeadProps) => {
    const { t } = useTranslation();
    const { selectedUserQueue, columnNames, numSelected, rowCount, order, orderBy, onRequestSort, onSelectAllClick } =
        props;
    const createSortHandler =
        (property: string) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {selectedUserQueue?.distributedAdmin &&
                    <TableCell
                        align="left"
                        padding='normal'
                    >
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                }
                {columnNames
                    .filter((m) => m != "taskId" && m != "processInstanceId")
                    .map((column, index) => (
                        <TableCell
                            key={index}
                            align="left"
                            padding='normal'
                            sortDirection={orderBy === column ? order : false}
                            sx={{ fontWeight: 'bold' }}
                        >
                            <TableSortLabel
                                active={orderBy === column}
                                direction={orderBy === column ? order : 'asc'}
                                onClick={createSortHandler(column)}
                            >
                                {t(column)}
                                {orderBy === column ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
            </TableRow>
        </TableHead >
    );
};

export default TaskTableHead;
import React, { useEffect, useState } from "react";
import { Delete, Edit } from "@mui/icons-material";
import {
    Box,
    Card,
    Stack,
    Typography,
    Avatar,
    Button,
    TextField,
    Link,
} from "@mui/material";
import YouTag from "./YouTag";
import { CommentModel } from "./Comment";
import { User } from "@/api/CamundaController";
import moment from "moment-timezone";
import { api } from "@/api/Api";
import { useTranslation } from "react-i18next";

interface ReplyProps {
    reply: CommentModel;
    me: User;
    readonly: boolean;
    onDelete: (id: string) => void;
}

const Reply = (props: ReplyProps) => {
    const { t } = useTranslation();
    const { me, reply, readonly, onDelete } = props;
    const itsMe = me.login == reply.who.login;
    const [clicked, setClicked] = useState(false);
    const [editingRep, setEditingRep] = useState(false);
    const [repText, setRepText] = useState(reply.text);
    const [user, setUser] = useState<User>(new User());

    const avatar =
        user?.photo?.data && `data:image/*;base64, ${user?.photo?.data}`;

    useEffect(() => {
        api.camunda.getUser(reply.who.login || '', true).then(result => {
            result.firstName = result.fullName?.split(' ')[0];
            result.lastName = result.fullName?.split(' ')[1];
            setUser(result);
        });
    }, []);

    return (
        <Card>
            <Box sx={{ p: "15px" }}>
                <Stack spacing={2} direction="row">
                    <Box sx={{ width: "100%" }}>
                        <Stack
                            spacing={2}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Stack spacing={2} direction="row" alignItems="center">
                                <Avatar src={avatar}></Avatar>
                                <Typography fontWeight="bold">
                                    {reply.who.login}
                                </Typography>
                                {itsMe && <YouTag />}
                                <Typography sx={{ fontSize: '14px' }}>
                                    {moment.tz(reply.date, me.gmt || '').toLocaleString()}
                                </Typography>
                            </Stack>
                            {itsMe && !readonly && (
                                <Stack direction="row" spacing={1}>
                                    <Button
                                        startIcon={<Delete />}
                                        sx={{
                                            color: "custom.softRed",
                                            fontWeight: 500,
                                            textTransform: "capitalize",
                                        }}
                                        onClick={() => onDelete(reply.id)}
                                    >
                                        {t("comment.reply")}
                                    </Button>
                                    <Button
                                        variant="text"
                                        disabled={clicked}
                                        sx={{
                                            fontWeight: 500,
                                            textTransform: "capitalize",
                                            color: "custom.moderateBlue",
                                        }}
                                        startIcon={<Edit />}
                                        onClick={() => {
                                            setClicked(!clicked);
                                            setEditingRep(!editingRep);
                                        }}
                                    >
                                        {t("comment.edit")}
                                    </Button>
                                </Stack>
                            )}
                        </Stack>
                        {editingRep ? (
                            <>
                                <TextField
                                    sx={{ p: "20px 0" }}
                                    multiline
                                    fullWidth
                                    minRows={4}
                                    id="outlined-multilined"
                                    placeholder={t("comment.dont") || ''}
                                    value={repText}
                                    onChange={(e) => {
                                        setRepText(e.target.value);
                                    }}
                                />
                                <Button
                                    sx={{
                                        bgcolor: "custom.moderateBlue",
                                        color: "neutral.white",
                                        p: "8px 25px",
                                        float: "right",
                                        "&:hover": {
                                            bgcolor: "custom.lightGrayishBlue",
                                        },
                                    }}
                                    onClick={() => {
                                        !repText.trim()
                                            ? alert("Read the placeholder.")
                                            : setEditingRep(!editingRep);
                                        setClicked(!clicked);
                                        console.log("check if it works");
                                    }}
                                >
                                    {t("comment.update")}
                                </Button>
                            </>
                        ) : (
                            <Typography
                                component="div"
                                sx={{ color: "neutral.grayishBlue", p: "20px 0" }}
                            >
                                <Typography
                                    sx={{
                                        color: "custom.moderateBlue",
                                        width: "fit-content",
                                        display: "inline-block",
                                        fontWeight: 500,
                                    }}
                                >
                                    <Link>{`@${reply.parentUser?.login}`}</Link>
                                </Typography>{" "}
                                {repText}
                            </Typography>
                        )}
                    </Box>
                </Stack>
            </Box>
        </Card>
    );
};

export default Reply;
export const PdfmakeFonts = {
	TimesNewRoman: {
		normal: "TimesNewRoman.ttf",
		bold: "TimesNewRomanBold.ttf",
		italics: "TimesNewRomanItalics.ttf",
		bolditalics: "TimesNewRomanBoldItalics.ttf",
	},
	Roboto: {
		normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
		bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
		italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
		bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
	},
	emptyCheckbox: {
		normal: "emptyCheckbox.ttf",
	},
};
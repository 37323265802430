import { Alert, Snackbar } from "@mui/material";


export interface PopupSnackBarProps {
    open?: boolean;
    severity?: "error" | "warning" | "success" | "info"
    message?: string | null;
    location?: {
        vertical: "top" | "bottom";
        horizontal: "left" | "center" | "right";
    };
    autoHideDuration?: number;
    onClose?: () => void;
}

export default function PopupSnackBar(props: PopupSnackBarProps) {
    const {
        open,
        message,
        severity,
        location: snackBarLocation,
        autoHideDuration,
        onClose,
    } = props;

    return (
        <Snackbar
            anchorOrigin={{
                vertical: snackBarLocation?.vertical ?? "top",
                horizontal: snackBarLocation?.horizontal ?? "right",
            }}
            open={open}
            autoHideDuration={autoHideDuration || 6000}
            onClose={onClose}
        >
            <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
}

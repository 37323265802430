import React from "react";
import ClientLayout from "@/components/ClientLayOut/ClientLayout";
import { Routes, Route } from "react-router-dom";
import SuccessReserve from "./ReserveSuccess/SuccessReserve";
import LastReservPage from "./LastReservePage/LastReservPage";
import authorizedLayout from "@/components/AuthorizedLayout";

const ClientRequest = () => (
  <Routes>
    <Route
      path="/reserve-success"
      element={authorizedLayout(() => SuccessReserve())}
    />
    <Route
      path="/reserve-success-client"
      element={ClientLayout(() => SuccessReserve())}
    />
    <Route
      path="/last-reserve-page"
      element={authorizedLayout(() => LastReservPage())}
    />
  </Routes>
);

export default ClientRequest;

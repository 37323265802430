import { api } from '@/api/Api';
import { Role } from '@/api/CamundaController';
import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface RolePickerProps {
    onSelectedItem: (role: Role) => void;
    label?: string;
}

const RolePicker = (props: RolePickerProps) => {
    const { t } = useTranslation();
    const [content, setContent] = useState<Role[]>([]);
    const [code, setCode] = useState('');
    const [selectedCode, setSelectedcode] = useState('');
    const { onSelectedItem, label } = props;

    useEffect(() => {
        api.camunda.getRoles(0, 10, code, code).then((m) => {
            setContent(m.content);
        });
    }, [code]);

    return (
        <Autocomplete
            size='small'
            fullWidth
            disablePortal
            id="combo-box-demo"
            options={content?.map(r => r.code) || []}
            value={selectedCode}
            onChange={(e, value) => {
                if (value) {
                    setSelectedcode(value);
                    var role = content.filter(c => c.code == value)[0];
                    onSelectedItem(role);
                }
            }}
            renderInput={(params) => <TextField {...params} label={label || t("chooserole")} onChange={(e) => setCode(e.target.value)} />}
        />
    );
};

export default RolePicker;
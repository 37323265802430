import React from "react";
import { ReserveCurrencyInfoProps } from './ReserveCurrencyInfo.props';
import styles from "./ReserveCurrencyInfo.module.css";
import { formatNumberRu } from '@/core/helpers/PageHelpers';

export const ReserveCurrencyInfo = ({ rateExchange, client = true, className, ...props }: ReserveCurrencyInfoProps): JSX.Element => (
	<div className={styles.reserve_info + " " + className} {...props}>
		<div className={styles.reserve_block}>
			<p className={styles.text}>{client ? "ВЫ ПРОДАЕТЕ" : "КЛИЕНТ ПРОДАЕТ"}:</p>
			<p className={styles.number}>
				{formatNumberRu(rateExchange.buyAmount)}{" "}
				<span>{rateExchange.buyCurrencyCode}</span>
			</p>
		</div>
		<div className={styles.reserve_block}>
			<p className={styles.text}>КУРС:</p>
			<p className={styles.number}>{(+rateExchange.rate).toFixed(2)}</p>
		</div>
		<div className={styles.reserve_block}>
			<p className={styles.text}>{client ? "ВЫ ПОЛУЧАЕТЕ" : "КЛИЕНТ ПОЛУЧАЕТ"}:</p>
			<p className={styles.number}>
				{formatNumberRu(rateExchange.saleAmount)}{" "}
				<span>{rateExchange.saleCurrencyCode}</span>
			</p>
		</div>
	</div>
);
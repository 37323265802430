import React from "react";
import { Routes, Route } from "react-router-dom";
import { DepartmentOpenPage } from './pages';
import { wizard } from '@/hoc';

const DepartmentOpen = () => (
	<Routes>
		<Route path="/" element={wizard(DepartmentOpenPage, 'Открытие отделений и касс')} />
	</Routes>
);

export default DepartmentOpen;

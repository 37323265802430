import { api } from '@/api/Api';
import { ReportResponse } from '@/api/ReportController';
import processManager from '@/core/helpers/ProcessManager';
import { PictureAsPdf, Search, Square } from '@mui/icons-material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Button, FormControl, FormControlLabel, Grid, IconButton, Link, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableRow, TextField, TextFieldProps, Toolbar, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Sector, Cell } from 'recharts';
import moment from 'moment-timezone';
import { ExportToExcel } from '../ExportToExcel';
import { reportTypes } from '@/pages/Reports/ReportCreate';
import { useTranslation } from 'react-i18next';


const colors = ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477", "#66aa00", "#b82e2e", "#316395", "#3366cc", "#994499", "#22aa99", "#aaaa11", "#6633cc", "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac", "#b77322", "#16d620", "#b91383", "#f4359e", "#9c5935", "#a9c413", "#2a778d", "#668d1c", "#bea413", "#0c5922", "#743411"];

const RADIAN = Math.PI / 180;

const renderActiveShape = (props: any) => {

    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {/* {payload["Сценарий обнаружения"]} */}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`${(percent * 100).toFixed(2)}%`}
            </text>
        </g>
    );
};

const renderCustomizedLabel = (props: any) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent } = props;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5 - 10;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const RePie = (props: any) => {

    const { t } = useTranslation();

    const { id, report } = props;

    const [activeIndex, setActiveIndex] = useState(0);
    const onPieEnter = (_: any, index: number) => {
        setActiveIndex(index);
    };

    const [from, setFrom] = useState<Dayjs | null>(
        dayjs().subtract(7, 'day')
    );
    const [to, setTo] = useState<Dayjs | null>(
        dayjs()
    );

    const [columnNames, setColumnNames] = useState([
        "№"
    ]);
    const [response, setResponse] = useState<ReportResponse>(new ReportResponse());
    const [count, setCount] = useState(0);

    const [dense, setDense] = useState(true);
    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };


    const print = () => {
        var w = window.open("Title");
        w?.document.write(`<head><title>${response.name}</title></head>`);
        w?.document.write(document.getElementsByClassName('report_left_inner')[0].outerHTML);
        w?.print();
        w?.close();
    };

    useEffect(() => {
        setColumnNames([
            "№"
        ]);
        setResponse(new ReportResponse());

    }, [report]);

    const load = () => {
        if (id) {
            processManager.startLoading();
            api.report
                .getReportList(id, from?.format('DD.MM.YYYY HH:mm') || '', to?.format('DD.MM.YYYY HH:mm') || '')
                .then(
                    resp => {
                        processManager.stopLoading();
                        setResponse(resp);
                        setColumnNames(
                            resp.totalResults > 0
                                ? Object.keys(resp.data[0])
                                : [
                                    "№"
                                ]
                        );
                        setCount(resp.totalResults);
                    },
                    error => {
                        processManager.stopLoading();
                        console.log(error);
                    }
                )
                .catch(error => {
                    processManager.stopLoading();
                    console.log(error);
                });
        }
    };


    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2, mt: 6 }}>
                <Grid item sx={{ p: 2 }}>
                    <Box component='form' noValidate onSubmit={e => {
                        e.preventDefault(); load();
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3} sm={3}>
                                <FormControl fullWidth size='small'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            label={t("report.view.dateTime")}
                                            value={from}
                                            onChange={(dj: Dayjs | null) => setFrom(dj)}
                                            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField size='small' {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sm={3}>
                                <FormControl fullWidth size='small'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            label={t("report.view.dateTime")}
                                            value={to}
                                            onChange={(dj: Dayjs | null) => setTo(dj)}
                                            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField size='small' {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sm={3}>
                                <Button type='submit' startIcon={<Search />}>{t("report.view.load")}</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <div className='report_left_inner'>
                    <Grid item sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <PieChart width={600} height={500}>
                                    <Pie
                                        activeIndex={activeIndex}
                                        activeShape={renderActiveShape}
                                        label={renderCustomizedLabel}
                                        data={response.data}
                                        cx="45%"
                                        cy="50%"
                                        innerRadius={70}
                                        outerRadius={140}
                                        dataKey="totalCount"
                                        onMouseEnter={onPieEnter}

                                    >
                                        {response.data.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                        ))}
                                    </Pie>
                                </PieChart>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Toolbar
                                    sx={{
                                        pl: { sm: 2 },
                                        pr: { xs: 1, sm: 1 }
                                    }}
                                >
                                    <Typography
                                        sx={{ flex: '1 1 100%' }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >
                                        {report?.name}
                                        {' '}
                                        <span style={{ color: "#999999" }}>{reportTypes.find(r => r.key == report?.type)?.value}</span>
                                    </Typography>
                                    {response?.data?.length > 0 &&
                                        <ExportToExcel
                                            fileName={response.name}
                                            tableData={response.data}
                                            headers={columnNames} />
                                    }
                                    {response?.data?.length > 0 &&
                                        <Tooltip title={t("excel.exportpdf")}>
                                            <IconButton
                                                onClick={print}
                                            >
                                                <PictureAsPdf />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </Toolbar>
                                <TableContainer>
                                    <Table

                                        aria-labelledby="tableTitle"
                                        size={dense ? 'small' : 'medium'}
                                    >
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={3}>
                                                    <Typography>
                                                        {t("report.view.summary")}: {response.totalResults}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            {response?.data
                                                .map((row, index) => (
                                                    <TableRow
                                                        key={index}
                                                        selected={index == activeIndex}
                                                    >
                                                        <TableCell key={index}>
                                                            <IconButton>
                                                                <Square sx={{
                                                                    backgroundColor: colors[index % colors.length],
                                                                    color: colors[index % colors.length]
                                                                }} />
                                                            </IconButton>
                                                        </TableCell>
                                                        {columnNames.filter(c => c != "taskId" && c != "processInstanceId")
                                                            ?.map((column, index) => {
                                                                var value = (row as any)[column];
                                                                var momentVar = moment(
                                                                    value,
                                                                    moment.ISO_8601,
                                                                    true
                                                                );
                                                                if (momentVar.isValid()) {
                                                                    value = momentVar.format(
                                                                        "DD.MM.YYYY, HH:mm"
                                                                    );
                                                                } else if (`${value}`?.startsWith("http")) {
                                                                    const url = value;
                                                                    value = (
                                                                        <Link>
                                                                            {url}
                                                                        </Link>
                                                                    );
                                                                    return (
                                                                        <TableCell
                                                                            onClick={(e) => {
                                                                                window.open(url, "_blank");
                                                                                e.stopPropagation();
                                                                            }}
                                                                            key={index}
                                                                        >
                                                                            {value}
                                                                        </TableCell>
                                                                    );
                                                                }
                                                                return (
                                                                    <TableCell key={index}>{value}</TableCell>
                                                                );
                                                            })}
                                                    </TableRow>
                                                ))}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label={t("report.view.tight")}
            />
        </Box>
    );

};

export default RePie;

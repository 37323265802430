import { Backdrop, CircularProgress, Box, Typography } from "@mui/material";

export interface PopupBackDropProps {
    open?: boolean;
    text?: string;
    disableShrink?: boolean;
    size?: number;
}

export default function PopupBackDrop(props: PopupBackDropProps) {
    const { open, text, disableShrink, size } = props;

    return (
        <Backdrop open={open || false} sx={{ zIndex: () => 10000 }}>
            <Box
                top={0}
                left={0}
                bottom={(size || 100) * 2}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="h4" gutterBottom>
                    {text}
                </Typography>
            </Box>
            <CircularProgress
                style={{ color: "#98FB98" }}
                disableShrink={disableShrink}
                size={size || 100}
            />
        </Backdrop>
    );
}

import { server } from '../core/server/axios';
import { Pagination } from './CamundaController';

const wenv = process.env;

export class MyFile {
  id?: string;
  name?: string;
  type?: string;
  username?: string;
  uploaded?: Date;
}

export class FileController {

  async upload(file: File): Promise<MyFile> {
    const formData = new FormData();
    formData.append('file', file);
    return await server.post(`/sonata/api/file/upload`, formData, {
      headers: {
        'Content-Type': `multipart/form-data;`
      },
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async download(id?: string): Promise<any> {
    return await server.get(`/sonata/api/file/download/${id}`, {
      baseURL: wenv.REACT_APP_BACKEND,
      responseType: 'blob'
    });
  }

  async delete(id?: string) {
    return await server.delete(`/sonata/api/file/${id}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async getFilesContains(
    pageNumber: number,
    pageSize: number,
    name: string
  ): Promise<Pagination<MyFile>> {
    return server.get(
      `/sonata/api/file/${pageNumber}/${pageSize}?name=${name}`,
      {
        baseURL: wenv.REACT_APP_BACKEND
      }
    );
  }
}

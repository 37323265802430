import { Button } from '@mui/material';
import { DocumentsEnum, documentsArray, useDocumentsPage } from './useDocumentsPage';
import { useMemo } from 'react';
import { Application10Document, Application14Document, DailyReportDocument, OrdersDocument } from '../../components';

const DocumentsPage = () => {
	const { activeDocumentType, handleSelectDocument } = useDocumentsPage();

	const selectedDocumentComponent = useMemo(() => {
		switch (activeDocumentType) {
			case DocumentsEnum.Order:
				return <OrdersDocument />;

			case DocumentsEnum.DailyReport:
				return <DailyReportDocument />;

			case DocumentsEnum.Application10:
				return <Application10Document />;

			case DocumentsEnum.Application14:
				return <Application14Document />;

			default:
				return <div style={{ textAlign: "center", margin: "30px 0 15px" }}>Выберите документ</div>;
		}
	}, [activeDocumentType]);

	return (
		<>
			<div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "10px" }}>
				{documentsArray.map((document) => (
					<Button
						key={document.type}
						variant={activeDocumentType === document.type ? "contained" : "outlined"}
						disabled={activeDocumentType === document.type}
						onClick={() => handleSelectDocument(document.type)}
					>
						{document.type}
					</Button>
				))}
			</div>

			{activeDocumentType && <h2 style={{ textAlign: "center", margin: "30px 0 15px" }}>{activeDocumentType}</h2>}

			{selectedDocumentComponent}
		</>
	);
};

export default DocumentsPage;
import { TaskFormComponent } from "@/api/CamundaController";
import { Grid, Typography } from "@mui/material";

interface TextGenProps {
    component: TaskFormComponent
}
const TextGen = (props: TextGenProps) => {
    const { component } = props;
    return (
        <Grid item xs={12} {...component.properties}>
            <Typography component="h2" variant="h6" gutterBottom>
                {component.text}
            </Typography>
        </Grid>);
};

export default TextGen;
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

const YouTag = () => {
    const { t } = useTranslation();
    return (
        <Chip
            label={t("you")}
            variant="filled"
            size="small"
        />
    );
};

export default YouTag;
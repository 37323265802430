import { formatNumberRu } from '@/core/helpers/PageHelpers';
import { PdfmakeFonts } from '@/core/helpers/PdfmakeFonts';
import { KassaModel, DepartmentModel } from '@/interface';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  TimesNewRoman: {
    normal: "fonts/TimesNewRoman.ttf",
    bold: "fonts/TimesNewRomanBold.ttf",
    italics: "fonts/TimesNewRomanItalics.ttf",
    bolditalics: "fonts/TimesNewRomanBoldItalics.ttf",
  },
  Roboto: {
    normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
  },
  emptyCheckbox: {
    normal: "emptyCheckbox.ttf",
  },
};

function numberToPhrase(num: number)
// сумма прописью для чисел от 0 до 999 триллионов
{
  var x = num.toFixed(2);
  if (+x < 0 || +x > 9999999999999.99) return false;

  const currency = 'KZT';

  const groups: any[] = [];

  groups[0] = [];
  groups[1] = [];
  groups[2] = [];
  groups[3] = [];
  groups[4] = [];

  groups[9] = [];

  // рубли
  // по умолчанию
  groups[0][-1] = { 'KZT': 'тенге' };
  //исключения
  groups[0][1] = { 'KZT': 'тенге' };
  groups[0][2] = { 'KZT': 'тенге' };
  groups[0][3] = { 'KZT': 'тенге' };
  groups[0][4] = { 'KZT': 'тенге' };

  // тысячи
  // по умолчанию
  groups[1][-1] = 'тысяч';
  //исключения
  groups[1][1] = 'тысяча';
  groups[1][2] = 'тысячи';
  groups[1][3] = 'тысячи';
  groups[1][4] = 'тысячи';

  // миллионы
  // по умолчанию
  groups[2][-1] = 'миллионов';
  //исключения
  groups[2][1] = 'миллион';
  groups[2][2] = 'миллиона';
  groups[2][3] = 'миллиона';
  groups[2][4] = 'миллиона';

  // миллиарды
  // по умолчанию
  groups[3][-1] = 'миллиардов';
  //исключения
  groups[3][1] = 'миллиард';
  groups[3][2] = 'миллиарда';
  groups[3][3] = 'миллиарда';
  groups[3][4] = 'миллиарда';

  // триллионы
  // по умолчанию
  groups[4][-1] = 'триллионов';
  //исключения
  groups[4][1] = 'триллион';
  groups[4][2] = 'триллиона';
  groups[4][3] = 'триллиона';
  groups[4][4] = 'триллиона';

  // копейки
  // по умолчанию
  groups[9][-1] = { 'KZT': 'тиын' };
  //исключения
  groups[9][1] = { 'KZT': 'тиын' };
  groups[9][2] = { 'KZT': 'тиын' };
  groups[9][3] = { 'KZT': 'тиын' };
  groups[9][4] = { 'KZT': 'тиын' };

  // цифры и числа
  // либо просто строка, либо 4 строки в хэше
  var names: any = [];
  names[1] = { 0: 'один', 1: 'одна', 2: 'один', 3: 'один', 4: 'один' };
  names[2] = { 0: 'два', 1: 'две', 2: 'два', 3: 'два', 4: 'два' };
  names[3] = 'три';
  names[4] = 'четыре';
  names[5] = 'пять';
  names[6] = 'шесть';
  names[7] = 'семь';
  names[8] = 'восемь';
  names[9] = 'девять';
  names[10] = 'десять';
  names[11] = 'одиннадцать';
  names[12] = 'двенадцать';
  names[13] = 'тринадцать';
  names[14] = 'четырнадцать';
  names[15] = 'пятнадцать';
  names[16] = 'шестнадцать';
  names[17] = 'семнадцать';
  names[18] = 'восемнадцать';
  names[19] = 'девятнадцать';
  names[20] = 'двадцать';
  names[30] = 'тридцать';
  names[40] = 'сорок';
  names[50] = 'пятьдесят';
  names[60] = 'шестьдесят';
  names[70] = 'семьдесят';
  names[80] = 'восемьдесят';
  names[90] = 'девяносто';
  names[100] = 'сто';
  names[200] = 'двести';
  names[300] = 'триста';
  names[400] = 'четыреста';
  names[500] = 'пятьсот';
  names[600] = 'шестьсот';
  names[700] = 'семьсот';
  names[800] = 'восемьсот';
  names[900] = 'девятьсот';

  var r = '';
  var i, j;

  var y: any = Math.floor(+x);

  // если НЕ ноль рублей
  if (y > 0) {
    // выделим тройки с руб., тыс., миллионами, миллиардами и триллионами
    var t = [];

    for (i = 0; i <= 4; i++) {
      t[i] = y % 1000;
      y = Math.floor(y / 1000);
    }

    var d: any[] = [];

    // выделим в каждой тройке сотни, десятки и единицы
    for (i = 0; i <= 4; i++) {
      d[i] = [];
      d[i][0] = t[i] % 10; // единицы
      d[i][10] = t[i] % 100 - d[i][0]; // десятки
      d[i][100] = t[i] - d[i][10] - d[i][0]; // сотни
      d[i][11] = t[i] % 100; // две правых цифры в виде числа
    }

    for (i = 4; i >= 0; i--) {
      if (t[i] > 0) {
        if (names[d[i][100]])
          r += ' ' + ((typeof (names[d[i][100]]) == 'object') ? (names[d[i][100]][i]) : (names[d[i][100]]));

        if (names[d[i][11]])
          r += ' ' + ((typeof (names[d[i][11]]) == 'object') ? (names[d[i][11]][i]) : (names[d[i][11]]));
        else {
          if (names[d[i][10]]) r += ' ' + ((typeof (names[d[i][10]]) == 'object') ? (names[d[i][10]][i]) : (names[d[i][10]]));
          if (names[d[i][0]]) r += ' ' + ((typeof (names[d[i][0]]) == 'object') ? (names[d[i][0]][i]) : (names[d[i][0]]));
        }

        if (names[d[i][11]])  // если существует числительное
          j = d[i][11];
        else
          j = d[i][0];

        if (groups[i][j]) {
          if (i == 0)
            r += ' ' + groups[i][j][currency];
          else
            r += ' ' + groups[i][j];
        }
        else {
          if (i == 0)
            r += ' ' + groups[i][-1][currency];
          else
            r += ' ' + groups[i][-1];
        }
      }
    }

    if (t[0] == 0)
      r += ' ' + groups[0][-1][currency];
  }
  else
    r = 'Ноль ' + groups[0][-1][currency];

  y = ((+x - Math.floor(+x)) * 100).toFixed();
  if (y < 10) y = '0' + y;

  r = r.trim();
  r = r.substr(0, 1).toUpperCase() + r.substr(1);
  r += ' ' + y;

  y = y * 1;

  if (names[y])  // если существует числительное
    j = y;
  else
    j = y % 10;

  if (groups[9][j])
    r += ' ' + groups[9][j][currency];
  else
    r += ' ' + groups[9][-1][currency];

  return r;
}

export const consumptionCashOrderDocument = (
  kassa: KassaModel,
  addCashBody: {
    balance: number;
    currencyCode: string;
    kassaId: number;
    currencyId: number | null;
    cashId: number | null;
    operationType: string;
  },
  number: number,
  isMinus: boolean,
  department: DepartmentModel
) => {
  const showing: any = {
    pageMargins: [60, 40, 60, 40],
    content: [
      {
        table: {
          widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
          body: [
            [
              { rowSpan: 2, colSpan: 4, border: [false, false, false, false], margin: [0, 7, 0, 0], text: department.name, fontSize: 10, bold: true, alignment: "center" },
              {},
              {},
              {},
              { border: [false, false, false, false], text: "", },
              { colSpan: 5, border: [false, false, false, false], text: "Приложение 2 к приказу", italics: true, alignment: "center" },
              {},
              {},
              {},
              {},
            ],
            [
              {},
              {},
              {},
              {},
              { border: [false, false, false, false], text: "", },
              { colSpan: 5, border: [false, false, false, false], text: "Министра финансов Республики Казахстан", italics: true, alignment: "center" },
              {},
              {},
              {},
              {},
            ],
            [
              { colSpan: 4, border: [false, false, false, false], text: "организация (индивидуальный предприниматель)", alignment: "center" },
              {},
              {},
              {},
              { border: [false, false, false, false], text: "", },
              { colSpan: 5, border: [false, false, false, false], text: "от 20 декабря 2012 года № 562", italics: true, alignment: "center" },
              {},
              {},
              {},
              {},
            ],
            [
              { colSpan: 7, border: [false, false, false, false], text: "" },
              {},
              {},
              {},
              {},
              {},
              {},
              { colSpan: 3, border: [false, false, false, true], text: "Форма КО-2" },
              {},
              {},
            ],
            [
              { colSpan: 6, border: [false, false, false, false], text: "" },
              {},
              {},
              {},
              {},
              {},
              { border: [false, false, true, true], text: "ИИН/БИН" },
              { colSpan: 3, text: department.bin, bold: true, alignment: "center" },
              {},
              {},
            ],
            [
              { rowSpan: 2, colSpan: 6, border: [false, false, true, false], margin: [0, 7, 0, 0], text: isMinus ? "РАСХОДНЫЙ КАССОВЫЙ ОРДЕР" : "ПРИХОДНЫЙ КАССОВЫЙ ОРДЕР", fontSize: 10, alignment: "center", bold: true },
              {},
              {},
              {},
              {},
              {},
              { colSpan: 2, text: "Номер документа", alignment: "center" },
              {},
              { colSpan: 2, text: "Дата составления", alignment: "center" },
              {},
            ],
            [
              {},
              {},
              {},
              {},
              {},
              {},
              { colSpan: 2, text: "01-000001", alignment: "center", bold: true },
              {},
              { colSpan: 2, text: `${new Date().toLocaleDateString()}`, alignment: "center", bold: true },
              {},
            ],
            [
              { colSpan: 10, border: [false, false, false, false], text: "" },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            [
              { text: "Дебет", bold: true, alignment: "center" },
              { rowSpan: 2, margin: [0, 7, 0, 0], text: "Кредит", bold: true, alignment: "center" },
              { rowSpan: 2, margin: [0, 7, 0, 0], text: "Валюта", bold: true, alignment: "center" },
              { rowSpan: 2, margin: [0, 7, 0, 0], text: "Курс", bold: true, alignment: "center" },
              { rowSpan: 2, colSpan: 3, margin: [0, 7, 0, 0], text: "Сумма, валюта", bold: true, alignment: "center" },
              {},
              {},
              { rowSpan: 2, colSpan: 3, margin: [0, 7, 0, 0], text: "Сумма, тенге", bold: true, alignment: "center" },
              {},
              {},
            ],
            [
              { text: "кор.счет", bold: true, alignment: "center" },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            [
              { text: "1010", alignment: "center" },
              { text: "1010", alignment: "center" },
              { text: addCashBody.currencyCode, alignment: "center" },
              { text: addCashBody.currencyCode === "KZT" ? "1" : department.currencies.find((cur) => cur.currencyId === addCashBody.currencyId)?.rates[0].buy, alignment: "right" },
              { colSpan: 3, text: formatNumberRu(number), alignment: "right" },
              {},
              {},
              { colSpan: 3, text: addCashBody.currencyCode === "KZT" ? formatNumberRu(number) : formatNumberRu(number * +(department.currencies.find(cur => cur.currencyId === addCashBody.currencyId)?.rates[0].buy || 1)), alignment: "right" },
              {},
              {},
            ],
            [
              { colSpan: 7, text: "Итого:", alignment: "right", bold: true },
              {},
              {},
              {},
              {},
              {},
              {},
              { colSpan: 3, text: addCashBody.currencyCode === "KZT" ? formatNumberRu(number) : formatNumberRu(number * +(department.currencies.find(cur => cur.currencyId === addCashBody.currencyId)?.rates[0].buy || 1)), alignment: "right" },
              {},
              {},
            ],
            [
              { colSpan: 10, border: [false, false, false, false], text: "" },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
          ]
        },
      },
      {
        layout: { defaultBorder: false },
        table: {
          widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
          body: [
            [
              { text: "Выдать" },
              { colSpan: 9, border: [false, false, false, true], text: "" },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            [
              {},
              { colSpan: 9, text: "фамилия, имя, отчество", alignment: "center", italics: true },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            [
              { colSpan: 10, border: [false, false, false, false], text: "" },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            [
              { text: "Основание" },
              { colSpan: 9, border: [false, false, false, true], text: addCashBody.operationType },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            [
              { colSpan: 10, border: [false, false, false, false], text: "" },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            [
              { text: "Сумма" },
              { colSpan: 9, border: [false, false, false, true], text: numberToPhrase(addCashBody.currencyCode === "KZT" ? number : number * +(department.currencies.find(cur => cur.currencyId === addCashBody.currencyId)?.rates[0].buy || 1)) },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            [
              {},
              { colSpan: 9, text: "прописью", alignment: "center", italics: true },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            [
              { colSpan: 10, border: [false, false, false, false], text: "" },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            [
              { colSpan: 2, text: "Руководитель" },
              {},
              { colSpan: 5, border: [false, false, false, true], text: "" },
              {},
              {},
              {},
              {},
              { colSpan: 3, text: "/" },
              {},
              {},
            ],
            [
              {},
              {},
              { colSpan: 2, text: "должность", italics: true },
              {},
              { text: "подпись", italics: true },
              {},
              { colSpan: 4, text: "расшифровка подписи", alignment: "right" },
              {},
              {},
              {},
            ],
            [
              { colSpan: 10, border: [false, false, false, false], text: "" },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            [
              { colSpan: 4, text: "Главный бухгалтер или уполномоченное лицо" },
              {},
              {},
              {},
              { colSpan: 3, border: [false, false, false, true], text: "" },
              {},
              {},
              { colSpan: 3, text: "/" },
              {},
              {},
            ],
            [
              {},
              {},
              {},
              {},
              { text: "подпись", italics: true },
              {},
              { colSpan: 4, text: "расшифровка подписи", alignment: "right" },
              {},
              {},
              {},
            ],
            [
              { colSpan: 10, border: [false, false, false, false], text: "" },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            [
              { text: "подпись" },
              { colSpan: 5, border: [false, false, false, true], text: "" },
              {},
              {},
              {},
              {},
              { colSpan: 4, text: "/" },
              {},
              {},
              {},
            ],
            [
              {},
              { colSpan: 5, text: "подпись", alignment: "center", italics: true },
              {},
              {},
              {},
              {},
              { colSpan: 4, text: "фамилия, имя, отчество", alignment: "right", italics: true },
              {},
              {},
              {},
            ],
            [
              { colSpan: 10, border: [false, false, false, false], text: "" },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            [
              { text: "по" },
              { colSpan: 9, border: [false, false, false, true], text: "" },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            [
              {},
              { colSpan: 9, text: "наименование, номер, дата и место выдачи документа, удостоверяющее личность получателя", fontSize: 7, italics: true, alignment: "center" },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            [
              { colSpan: 10, border: [false, false, false, false], text: "" },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
          ]
        }
      }
    ],
    defaultStyle: {
      fontSize: 8,
      font: "Roboto"
      // font: "TimesNewRoman"
    },
  };

  return showing;
};













export const comingCashOrderDocument = (isMinus: boolean, number: number) => {
  const showing: any = {
    pageMargins: [60, 40, 60, 40],
    content: [
      {
        text: `Пополнили на ${number}`,
      },
    ],
    defaultStyle: {
      fontSize: 8,
      font: "Roboto",
      // font: "TimesNewRoman",
      lineHeight: 1.08
    },
  };

  return showing;
};

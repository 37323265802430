import { TaskFormComponent } from "@/api/CamundaController";
import { updateTaskVariables } from "@/redux/modules/Process";
import { Box, Chip, FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

function getStyles(name: string, personName: readonly string[]) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? ''
                : 'bold',
    };
}

interface TaglistGenProps {
    component: TaskFormComponent
}

const TaglistGen = (props: TaglistGenProps) => {
    const { component } = props;
    const dispatch = useDispatch();
    const variables = useSelector((state: any) => state?.process?.taskModel?.variables) || {};

    const values = variables[component.key!] as Array<string> || [];

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event;
        dispatch(
            updateTaskVariables(
                (g) => g,
                (p) => Object.assign(p, { [component.key!]: typeof value === 'string' ? value.split(',') : value })
            ));
    };

    return (
        <Grid item xs={12} {...component.properties}>
            <FormControl fullWidth size='small'>
                <InputLabel id={`${component.id}-label`}>{component.label}</InputLabel>
                <Select
                    multiple
                    size='small'
                    labelId={`${component.id}-label`}
                    id={component.id}
                    name={component.id}
                    value={values}
                    disabled={component.disabled}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((val) => (
                                <Chip key={val} label={component.values?.find(i => i.value == val)?.label} />
                            ))}
                        </Box>
                    )}
                    MenuProps={{
                        style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                        }
                    }}
                >
                    {component.values?.map((item, index) => (
                        <MenuItem key={index} value={item.value} style={getStyles(item.value ?? '', values)}>{item.label}</MenuItem>
                    ))}
                </Select>
                <FormHelperText>{component.description}</FormHelperText>
            </FormControl>
        </Grid>);
};

export default TaglistGen;
import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import process from "./Process";
import header from "./Header";
import rateExchange from "./RateExchange";
import clientInfo from "./ClientInfo";
import { departmentReducer } from './department/departmentReducer';

export default combineReducers({
  routing: routerReducer,
  header,
  process,
  rateExchange,
  clientInfo,

  departmentReducer,
});

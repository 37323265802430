import React from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Button, TextField } from '@mui/material';

import { ModalWindow } from '@/components';

import styles from "./ModalSelectCurrency.module.css";

import { CurrencyModel } from '@/interface';
import { useModalSelectCurrency } from './useModalSelectCurrency';

export interface ModalSelectCurrencyProps {
	openWindow: boolean;
	handleCloseWindow: () => void;
	newCurrency: CurrencyModel;
	handleOpenAddCurrencyWindow: () => void;
	handleChangeNewCurrency: (currency: CurrencyModel) => void;
}

export const ModalSelectCurrency = ({
	openWindow,
	handleCloseWindow,
	newCurrency,
	handleOpenAddCurrencyWindow,
	handleChangeNewCurrency
}: ModalSelectCurrencyProps) => {
	const { currencyListForAdding } = useModalSelectCurrency();

	return (
		<ModalWindow
			title='Выберите валюту'
			open={openWindow}
			onClose={handleCloseWindow}
		>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					handleOpenAddCurrencyWindow();
				}}
			>
				<div className={styles.select_input}>
					<Autocomplete
						disableClearable
						options={currencyListForAdding}
						className={styles.autoComplete}
						getOptionLabel={(option: any) => option.code ? `${option.code}: ${option.description}` : ""}
						value={newCurrency}
						onChange={(e, newValue: any) => newValue && handleChangeNewCurrency({ ...newCurrency, code: newValue.code, description: newValue.description })}
						renderInput={(params) => <TextField required {...params} />}
					/>
				</div>
				<div className={styles.btn_group}>
					<Button
						variant="contained"
						size="large"
						startIcon={<ClearIcon />}
						sx={{
							background: "#b70000",
							"&:hover": {
								background: "#7f0101",
							},
						}}
						onClick={handleCloseWindow}
					>
						Отмена
					</Button>
					<Button
						variant="contained"
						size="large"
						startIcon={<AddCircleOutlineIcon />}
						sx={{
							background: "#037703",
							"&:hover": {
								background: "#005f00",
							},
						}}
						type={"submit"}
						disabled={newCurrency?.code === ""}
					>
						Добавить
					</Button>
				</div>
			</form>
		</ModalWindow>
	)
};
import { TaskFormComponent } from "@/api/CamundaController";
import { updateTaskVariables } from "@/redux/modules/Process";
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

interface RadioGenProps {
    component: TaskFormComponent
}
const RadioGen = (props: RadioGenProps) => {
    const { component } = props;
    const dispatch = useDispatch();
    const variables = useSelector((state: any) => state?.process?.taskModel?.variables) || {};
    useEffect(() => {
        if (variables[component.key!] == undefined && component.defaultValue && component.defaultValue != "<none>") {
            dispatch(
                updateTaskVariables(
                    (g) => g,
                    (p) => Object.assign(p, { [component.key!]: component.defaultValue })
                ));
        }
    }, []);
    const value = variables[component.key!] || '';
    return (
        <Grid item xs={12} {...component.properties}>
            <FormControl fullWidth size='small' disabled={component.disabled}>
                <FormLabel id={`${component.id}-label`}>{component.label}</FormLabel>
                <RadioGroup
                    aria-labelledby={`${component.id}-label`}
                    id={component.id}
                    name={component.id}
                    value={value}
                    onChange={(e) =>
                        dispatch(
                            updateTaskVariables(
                                (g) => g,
                                (p) => Object.assign(p, { [component.key!]: e.target.value })
                            ))
                    }
                >
                    {component.values?.map((item, index) => (
                        <FormControlLabel key={index} value={item.value} control={<Radio />} label={item.label} />
                    ))}
                </RadioGroup>
                <FormHelperText>{component.description}</FormHelperText>
            </FormControl>
        </Grid>);
};

export default RadioGen;
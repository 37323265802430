import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from './Receipt/Receipt';

const ClientExchange = () => (
  <Routes>
    <Route path="/*" element={<HomePage />} />
  </Routes>
);

export default ClientExchange;

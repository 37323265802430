import { server } from '../core/server/axios';
import { Pagination } from './CamundaController';

const wenv = process.env;

export class Calendar {
  id?: string = '';
  name?: string = '';
  is247 = false;
  businessDays: Array<BusinessDay> = [
    { open: true, dayOfWeek: "MONDAY", startHour: 8, startMinute: 0, endHour: 18, endMinute: 0 },
    { open: true, dayOfWeek: "TUESDAY", startHour: 8, startMinute: 0, endHour: 18, endMinute: 0 },
    { open: true, dayOfWeek: "WEDNESDAY", startHour: 8, startMinute: 0, endHour: 18, endMinute: 0 },
    { open: true, dayOfWeek: "THURSDAY", startHour: 8, startMinute: 0, endHour: 18, endMinute: 0 },
    { open: true, dayOfWeek: "FRIDAY", startHour: 8, startMinute: 0, endHour: 18, endMinute: 0 },
    { open: false, dayOfWeek: "SATURDAY", startHour: 8, startMinute: 0, endHour: 18, endMinute: 0 },
    { open: false, dayOfWeek: "SUNDAY", startHour: 8, startMinute: 0, endHour: 18, endMinute: 0 }
  ];
  holidays: Array<DayOfYear> = [];
}

export class BusinessDay {
  open?: boolean;
  dayOfWeek?: string;
  startHour?: number;
  startMinute?: number;
  endHour?: number;
  endMinute?: number;
}

export class DayOfYear {
  month = 'JANUARY';
  dayOfMonth = 1;
}

export class CalendarController {

  async create(calendar: Calendar): Promise<any> {
    return await server.post(`/sonata/api/calendar`, calendar, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async get(id?: string): Promise<Calendar> {
    return await server.get(`/sonata/api/calendar/${id}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async delete(id?: string) {
    return await server.delete(`/sonata/api/calendar/${id}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async getCalendarContains(
    pageNumber: number,
    pageSize: number,
    name: string,
    code: string
  ): Promise<Pagination<Calendar>> {
    return server.get(
      `/sonata/api/calendar/${pageNumber}/${pageSize}?name=${name}&code=${code}`,
      {
        baseURL: wenv.REACT_APP_BACKEND
      }
    );
  }
}

import Grid from "@mui/material/Grid";
import { TaskBoard } from "@/components/TaskBoard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { actions } from "@/redux/modules/Header";
import { User } from "@/api/CamundaController";

const TaskList = () => {
  const dispatch = useDispatch();
  const selectedUserQueue = useSelector(
    (state: any) => state?.header?.selectedQueueMenu
  );
  const userQueue = useSelector((state: any) => state?.header?.userQueue);
  const me = useSelector(
    (state: any) => state?.header?.userContext?.user as User
  );

  useEffect(() => {
    dispatch(actions.openQueueMenuBar(true));
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        {selectedUserQueue && (
          <TaskBoard
            me={me}
            selectedUserQueue={selectedUserQueue}
            userQueue={userQueue}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TaskList;

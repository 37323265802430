import { useMemo } from "react";
import { currencyArray } from '@/interface';
import { useSelector } from 'react-redux';
import { selectActiveDepartment } from '@/redux/modules/department/departmentSelectors';

export const useModalSelectCurrency = () => {
	const activeDepartment = useSelector(selectActiveDepartment);

	console.log(currencyArray.length)

	const currencyListForAdding = useMemo(() => (
		currencyArray.filter((currency) => {
			if (activeDepartment?.currencies.find((currencyItem) => currencyItem.code.indexOf(currency.code) >= 0) === undefined) {
				return currency;
			}
		})
	), [activeDepartment]);

	return {
		currencyListForAdding
	};
};

import { server } from '../core/server/axios';

const wenv = process.env;

export class ParsingController {
	async loadMigRates(): Promise<any> {
		return await server.get('/Parsing/migparse', {
			baseURL: wenv.REACT_APP_API_URL
		});
	}

	async loadBccRates(): Promise<any> {
		return await server.get('/Parsing/bck', {
			baseURL: wenv.REACT_APP_API_URL
		});
	}

	async loadNationalGoldRate(): Promise<any> {
		return await server.get('/Parsing/goldParse', {
			baseURL: wenv.REACT_APP_API_URL
		});
	}

	async loadNationalRates(): Promise<any> {
		return await server.get('/NB/xrates', {
			baseURL: wenv.REACT_APP_API_URL
		});
	}
}
import React from "react";
import { Routes, Route } from "react-router-dom";
import { TreasurePage } from './pages';
import { wizard } from '@/hoc';

const Treasure = () => (
  <Routes>
    <Route path="" element={wizard(TreasurePage, 'Операции казначея')} />
  </Routes>
);

export default Treasure;

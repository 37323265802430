import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import pm from "@/core/helpers/ProcessManager";

import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  Autocomplete,
  TextFieldProps
} from "@mui/material";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import WarningIcon from "@mui/icons-material/Warning";
import {
  ClientInfo,
  actions as clientInfoActions,
} from "@/redux/modules/ClientInfo";
import { RateExchange } from "@/redux/modules/RateExchange";

import pdfMake from "pdfmake/build/pdfmake";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import InputMask from "react-input-mask";
import { updateTaskVariables } from '@/redux/modules/Process';

const wenv = process.env;

const Document = (clientInfo: ClientInfo, rateExchange: RateExchange) => {

  const showing: any = {
    content: [
      {
        text: 'Данные для отправки в Комитет Финансового Мониторинга',
      },
      {
        text: '\n'
      },
      {
        text: 'ТОО "Ecash" ОП',
        alignment: "center",
      },
      {
        text: '\n\n'
      },
      {
        table: {
          widths: ["*", 200],
          body: [
            ["Ф.И.О.: " + clientInfo.lastName + " " + clientInfo.firstName + " " + clientInfo.middleName, ""],
            // ["Дата рождения: " + clientInfo.birthday, ""],
            ["ИИН: " + clientInfo.iin, ""],
            ["Удостоверение личности: " + "№" + clientInfo.docNumber, ""],
            ["Место рождения: ", ""],
            ["Дата выдачи / Срок действия: начало " + `${new Date(+clientInfo.issueDate).toLocaleDateString()}` + " окончание: " + `${new Date(+clientInfo.validDate).toLocaleDateString()}`, ""],
            ["Кем выдано: " + clientInfo.issuePlace, ""],
            ["Адрес места жительства: " + clientInfo.country + ", " + clientInfo.region + ", " + clientInfo.locality + ", " + clientInfo.street + ", " + clientInfo.number + ", " + clientInfo.flat, ""],
            ["Гражданство: " + clientInfo.citizen, ""],
            ["Место работы должность: ", ""],
            ["e-mail: ", ""]
          ],
        },
        layout: 'lightHorizontalLines'
      },
      {
        text: "\n",
      },
      {
        table: {
          widths: ["*", 200],
          body: [
            ["Номер сделки", "20"],
            ["Вид операции", rateExchange.buyCurrencyCode === "KZT" ? "Продажа валюты" : "Покупка валюты"],
            ["Сумма", rateExchange.buyCurrencyCode === "KZT" ? new Intl.NumberFormat("ru-RU").format(+rateExchange.saleAmount) : new Intl.NumberFormat("ru-RU").format(+rateExchange.buyAmount)],
            ["Валюта", rateExchange.buyCurrencyCode === "KZT" ? rateExchange.saleCurrencyCode : rateExchange.buyCurrencyCode],
            ["Курс", (+rateExchange.rate).toFixed(2)],
            ["Сумма в тенге", rateExchange.buyCurrencyCode === "KZT" ? new Intl.NumberFormat("ru-RU").format(+rateExchange.buyAmount) : new Intl.NumberFormat("ru-RU").format(+rateExchange.saleAmount)],
            ["Дата и время операции", `${new Date().toLocaleString()}`]
          ],
        },
      },
    ],
    defaultStyle: {
      fontSize: 14,
    },
  };

  return showing;
};

interface ClientForm {
  type: "client" | "cashier";
  isResident: boolean;
  setIsResident: (param: boolean) => void;
}

const ClientForm = ({ type, isResident, setIsResident }: ClientForm) => {
  const dispatch = useDispatch();
  const clientInfo: ClientInfo = useSelector(
    (state: any) => state?.clientInfo || {}
  );
  console.log(clientInfo)
  const rateExchange: RateExchange = useSelector(
    (state: any) => state?.rateExchange || {}
  );
  // const [isDocumentExpired, setIsDocumentExpired] = useState(false);

  const [needToPDF, setNeedToPDF] = useState(rateExchange.buyCurrencyCode === "KZT" ? +rateExchange.buyAmount >= 10000000 : +rateExchange.saleAmount >= 10000000);

  // const [checkForBlackList, setCheckForBlackList] = useState<{
  //   checked: boolean;
  //   inBlackList: boolean;
  //   resultModel: any;
  //   resultText: string;
  // }>({
  //   checked: false,
  //   inBlackList: false,
  //   resultModel: null,
  //   resultText: "",
  // });

  const [openWindow, setOpenWindow] = useState({
    open: false,
    text: "",
  });

  const iinRef = useRef<any>(null);
  const [iinErr, setIinErr] = useState({ error: false, errText: "" });
  const citizenRef = useRef<any>(null);
  const [citizenErr, setCitizenErr] = useState({ error: false, errText: "" });
  const lastNameRef = useRef<any>(null);
  const [phoneErr, setPhoneErr] = useState({ error: false, errText: "" });
  const phoneRef = useRef<any>(null);
  const [lastNameErr, setLastNameErr] = useState({ error: false, errText: "" });
  const firstNameRef = useRef<any>(null);
  const [firstNameErr, setFirstNameErr] = useState({ error: false, errText: "" });
  const middleNameRef = useRef<any>(null);
  const birthdayRef = useRef<any>(null);
  const [birthdayErr, setBirthdayErr] = useState({ error: false, errText: "" });
  const doctypeRef = useRef<any>(null);
  const [doctypeErr, setDoctypeErr] = useState({ error: false, errText: "" });
  const docNumberRef = useRef<any>(null);
  const [docNumberErr, setDocNumberErr] = useState({ error: false, errText: "" });
  const issueDateRef = useRef<any>(null);
  const [issueDateErr, setIssueDateErr] = useState({ error: false, errText: "" });
  const validDateRef = useRef<any>(null);
  const [validDateErr, setValidDateErr] = useState({ error: false, errText: "" });
  const issuePlaceRef = useRef<any>(null);
  const [issuePlaceErr, setIssuePlaceErr] = useState({ error: false, errText: "" });
  const countryRef = useRef<any>(null);
  const [countryErr, setCountryErr] = useState({ error: false, errText: "" });
  const regionRef = useRef<any>(null);
  const [regionErr, setRegionErr] = useState({ error: false, errText: "" });
  const localityRef = useRef<any>(null);
  const [localityErr, setLocalityErr] = useState({ error: false, errText: "" });
  const streetRef = useRef<any>(null);
  const [streetErr, setStreetErr] = useState({ error: false, errText: "" });
  const numberRef = useRef<any>(null);
  const [numberErr, setNumberErr] = useState({ error: false, errText: "" });
  const flatRef = useRef<any>(null);
  const [flatErr, setFlatErr] = useState({ error: false, errText: "" });

  const needToFillAddress = useMemo(() => {
    if (rateExchange.buyCurrencyCode === "KZT" && +rateExchange.buyAmount >= 500000) {
      return true;
    }

    if (rateExchange.saleCurrencyCode === "KZT" && +rateExchange.saleAmount >= 500000) {
      return true;
    }

    return false;
  }, [rateExchange]);

  // let doc = require('../../Exchange/blackList.xml')

  useEffect(() => {
    dispatch(
      clientInfoActions.updateClientInfo(
        (c) => c,
        (p) => {
          p.citizen = isResident ? "Республика Казахстан" : "";
          // p.doctype = "";
          // p.docNumber = "";
          // p.issuePlace = "";

          return p;
        }
      )
    );
  }, []);

  // useEffect(() => {
  // if (iin.length === 12 && isResident) {
  //   axios
  //     .get(`${wenv.REACT_APP_API_URL}/NB/blackListIin/$${iin}`, {
  //       headers: {
  //         accept: "*/*",
  //       },
  //     })
  //     .then((res: any) => {
  //       setCheckForBlackList({
  //         ...checkForBlackList,
  //         checked: true,
  //         inBlackList: res.data.model.isExist,
  //         resultModel: res.data.model,
  //         resultText: res.data.message,
  //       });

  //       if (res.data.model.isExist) {
  //         setOpenWindow({
  //           ...openWindow,
  //           open: true,
  //           text: "Клиент находиттся в черном списке",
  //         });
  //       }
  //     })
  //     .catch((e) => {
  //       setCheckForBlackList({
  //         ...checkForBlackList,
  //         checked: false,
  //       });

  //       setOpenWindow({
  //         ...openWindow,
  //         open: true,
  //         text: "Произошла ошибка в сервисе по проверке клиента в черном списке",
  //       });
  //     });
  //   return;
  // }
  // setCheckForBlackList({
  //   ...checkForBlackList,
  //   checked: true,
  //   inBlackList: false,
  // });
  // }, [clientInfo.iin]);

  const handleInputNames = (value: string, whichInfo: "lastName" | "firstName" | "middleName") => {
    const string = value.toLowerCase().replace(/[^а-яa-zәғқңөұүһі]/g, "");
    const newValue = string.length ? string[0].toUpperCase() + string.slice(1) : "";

    dispatch(
      clientInfoActions.updateClientInfo(
        (c) => c[whichInfo],
        () => newValue
      )
    );

    if (whichInfo === "lastName") {
      if (value.length === 0) {
        setLastNameErr({
          error: true,
          errText: "Заполните поле"
        });

        return;
      }

      setLastNameErr({
        error: false,
        errText: ""
      });
    } else if (whichInfo === "firstName") {
      if (value.length === 0) {
        setFirstNameErr({
          error: true,
          errText: "Заполните поле"
        });

        return;
      }

      setFirstNameErr({
        error: false,
        errText: ""
      });
    }
  };

  const checkPhone = (phone: string) => {
    if (
      phone.slice(1, 4) === "700" ||
      phone.slice(1, 4) === "701" ||
      phone.slice(1, 4) === "702" ||
      phone.slice(1, 4) === "703" ||
      phone.slice(1, 4) === "704" ||
      phone.slice(1, 4) === "705" ||
      phone.slice(1, 4) === "706" ||
      phone.slice(1, 4) === "707" ||
      phone.slice(1, 4) === "708" ||
      phone.slice(1, 4) === "709" ||
      phone.slice(1, 4) === "747" ||
      phone.slice(1, 4) === "750" ||
      phone.slice(1, 4) === "751" ||
      phone.slice(1, 4) === "760" ||
      phone.slice(1, 4) === "761" ||
      phone.slice(1, 4) === "762" ||
      phone.slice(1, 4) === "763" ||
      phone.slice(1, 4) === "764" ||
      phone.slice(1, 4) === "771" ||
      phone.slice(1, 4) === "775" ||
      phone.slice(1, 4) === "776" ||
      phone.slice(1, 4) === "777" ||
      phone.slice(1, 4) === "778" ||
      phone.slice(1, 4) === "700" ||
      phone.slice(1, 4) === "700" ||
      phone.slice(1, 4) === "700" ||
      phone.slice(1, 4) === "700" ||
      phone.slice(1, 4) === "700"
    ) {
      return true;
    }
    return false;
  };

  const handleSubmitForm = () => {
    let isError = false;

    if (clientInfo.iin.length === 0 && isResident) {
      setIinErr({
        error: true,
        errText: "Заполните поле"
      });

      isError = true;
    }
    if (clientInfo.citizen.length === 0) {
      setCitizenErr({
        error: true,
        errText: "Заполните поле"
      });

      isError = true;
    }
    if (clientInfo.lastName.length === 0) {
      setLastNameErr({
        error: true,
        errText: "Заполните поле"
      });

      isError = true;
    }
    if (clientInfo.firstName.length === 0) {
      setFirstNameErr({
        error: true,
        errText: "Заполните поле"
      });

      isError = true;
    }
    if (!(+clientInfo.birthday)) {
      setBirthdayErr({
        error: true,
        errText: "Заполните поле"
      });

      isError = true;
    }

    if (clientInfo.doctype === null || clientInfo.doctype.length === 0) {
      setDoctypeErr({
        error: true,
        errText: "Заполните поле"
      });

      isError = true;
    }

    if (clientInfo.docNumber.length === 0) {
      setDocNumberErr({
        error: true,
        errText: "Заполните поле"
      });

      isError = true;
    }

    if (!(+clientInfo.issueDate)) {
      setIssueDateErr({
        error: true,
        errText: "Заполните поле"
      });

      isError = true;
    }

    if (!(+clientInfo.issueDate)) {
      setValidDateErr({
        error: true,
        errText: "Заполните поле"
      });

      isError = true;
    }

    if (clientInfo.issuePlace === null || clientInfo.issuePlace.length === 0) {
      setIssuePlaceErr({
        error: true,
        errText: "Заполните поле"
      });

      isError = true;
    }

    if (needToFillAddress) {
      if (clientInfo.country.length === 0) {
        setCountryErr({
          error: true,
          errText: "Заполните поле"
        });

        isError = true;
      }

      if (clientInfo.region.length === 0) {
        setRegionErr({
          error: true,
          errText: "Заполните поле"
        });

        isError = true;
      }

      if (clientInfo.locality.length === 0) {
        setLocalityErr({
          error: true,
          errText: "Заполните поле"
        });

        isError = true;
      }

      if (clientInfo.street.length === 0) {
        setStreetErr({
          error: true,
          errText: "Заполните поле"
        });

        isError = true;
      }

      if (clientInfo.number.length === 0) {
        setNumberErr({
          error: true,
          errText: "Заполните поле"
        });

        isError = true;
      }
    }
    if (
      (
        needToFillAddress &&
        !isError &&
        !iinErr.error &&
        !citizenErr.error &&
        !phoneErr.error &&
        !lastNameErr.error &&
        !firstNameErr.error &&
        !birthdayErr.error &&
        !doctypeErr.error &&
        !docNumberErr.error &&
        !issueDateErr.error &&
        !validDateErr.error &&
        !issuePlaceErr.error &&
        !countryErr.error &&
        !regionErr.error &&
        !localityErr.error &&
        !streetErr.error &&
        !numberErr.error &&
        !flatErr.error
      )
      ||
      (
        !needToFillAddress &&
        !isError &&
        !iinErr.error &&
        !citizenErr.error &&
        !phoneErr.error &&
        !lastNameErr.error &&
        !firstNameErr.error &&
        !birthdayErr.error &&
        !doctypeErr.error &&
        !docNumberErr.error &&
        !issueDateErr.error &&
        !validDateErr.error &&
        !issuePlaceErr.error
      )
    ) {

      if (needToPDF) {
        const doc = Document(clientInfo, rateExchange);
        pdfMake.createPdf(doc).print();
        setNeedToPDF(false);
        return;
      }

      if (!clientInfo.clientId) {
        axios
          .post(
            `${wenv.REACT_APP_API_URL}/Client/NewClient`,
            {
              firstName: clientInfo.firstName,
              middleName: clientInfo.middleName,
              lastName: clientInfo.lastName,
              iin: clientInfo.iin,
              phone: clientInfo.phone,
              citizen: clientInfo.citizen,
              birthDay: clientInfo.birthday,
              documents: [
                {
                  docType: clientInfo.doctype,
                  docNumber: clientInfo.docNumber,
                  issuePlace: clientInfo.issuePlace,
                  issueDate: clientInfo.issueDate,
                  validDate: clientInfo.validDate,
                  content: clientInfo.photoImg,
                  // client: clientInfo.signImg,
                },
              ],
              clientAdress: [
                {
                  country: clientInfo.country,
                  region: clientInfo.region,
                  locality: clientInfo.locality,
                  street: clientInfo.street,
                  number: clientInfo.number,
                  flat: clientInfo.flat,
                }
              ]
            },
            {
              headers: {
                accept: "*/*",
              },
            }
          )
          .then(() => {
            if (type.indexOf("cashier") >= 0) {
              pm.start({
                processDefinitionKey: "cashier_request",
                // processDefinitionKey: "cashier_request_prod",
                variables: {
                  buyAmount: +rateExchange.buyAmount,
                  buyCurrencyCode: rateExchange.buyCurrencyCode,
                  currentRate: +rateExchange.rate,
                  saleAmount: +rateExchange.saleAmount,
                  saleCurrencyCode: rateExchange.saleCurrencyCode,
                  kassaId: rateExchange.kassaId,
                  depsId: rateExchange.depsId,
                  isIndividual: rateExchange.isIndividual,
                  phone: clientInfo.phone,
                  iin: clientInfo.iin,
                  fullName: clientInfo.lastName + " " + clientInfo.firstName,
                  requestId: "",
                  updateResponce: {},
                  requestError: "",
                  ofdError: "",
                  ofdSmsError: "",
                  completed: false,
                  returnResponse: {},
                },
              });
            } else {
              dispatch(updateTaskVariables(
                (c) => c.fullName,
                () => clientInfo.lastName + " " + clientInfo.firstName
              ));
              pm.goForth();
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        const addressFrom = clientInfo.adrId ? {
          adrId: clientInfo.adrId,
          country: clientInfo.country,
          region: clientInfo.region,
          locality: clientInfo.locality,
          street: clientInfo.street,
          number: clientInfo.number,
          flat: clientInfo.flat,
        }
          :
          {
            country: clientInfo.country,
            region: clientInfo.region,
            locality: clientInfo.locality,
            street: clientInfo.street,
            number: clientInfo.number,
            flat: clientInfo.flat,
          };

        axios
          .patch(
            `${wenv.REACT_APP_API_URL}/Client/UpdateClient`,
            {
              clientId: clientInfo.clientId,
              firstName: clientInfo.firstName,
              middleName: clientInfo.middleName,
              lastName: clientInfo.lastName,
              iin: clientInfo.iin,
              phone: clientInfo.phone,
              citizen: clientInfo.citizen,
              birthDay: clientInfo.birthday,
              documents: [
                {
                  docId: clientInfo.docId,
                  docType: clientInfo.doctype,
                  docNumber: clientInfo.docNumber,
                  issuePlace: clientInfo.issuePlace,
                  issueDate: clientInfo.issueDate,
                  validDate: clientInfo.validDate,
                  content: clientInfo.photoImg,
                  // client: clientInfo.signImg,
                },
              ],
              clientAdress: [
                addressFrom
              ]
            },
            {
              headers: {
                accept: "*/*",
              },
            }
          )
          .then(() => {
            if (type.indexOf("cashier") >= 0) {
              pm.start({
                processDefinitionKey: "cashier_request",
                // processDefinitionKey: "cashier_request_prod",
                variables: {
                  buyAmount: +rateExchange.buyAmount,
                  buyCurrencyCode: rateExchange.buyCurrencyCode,
                  currentRate: +rateExchange.rate,
                  saleAmount: +rateExchange.saleAmount,
                  saleCurrencyCode: rateExchange.saleCurrencyCode,
                  kassaId: rateExchange.kassaId,
                  depsId: rateExchange.depsId,
                  isIndividual: rateExchange.isIndividual,
                  phone: clientInfo.phone,
                  iin: clientInfo.iin,
                  fullName: clientInfo.lastName + " " + clientInfo.firstName,
                  requestId: "",
                  updateResponce: {},
                  requestError: "",
                  ofdError: "",
                  ofdSmsError: "",
                  completed: false,
                  returnResponse: {}
                },
              });
            } else {
              dispatch(updateTaskVariables(
                (c) => c.fullName,
                () => clientInfo.lastName + " " + clientInfo.firstName
              ));
              pm.goForth();
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  };

  return (
    <>
      <Dialog
        open={openWindow.open}
        onClose={() => setOpenWindow({ ...openWindow, open: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm">
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "center",
          }}>
          <WarningIcon
            sx={{
              display: "flex",
              justifyContent: "center",
              color: "	#ff9966",
              fontSize: "100px",
            }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              fontSize: "22px",
              fontWeight: "bold",
              color: "black",
              textAlign: "center",
            }}>
            {openWindow.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Button
            sx={{
              backgroundColor: "#F15A25",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#aa5e00",
              }
            }}
            fullWidth
            variant="contained"
            size="large"
            onClick={() => {
              setOpenWindow({
                ...openWindow,
                open: false,
              });
            }}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>

      <form
        onSubmit={(e) => {
          e.preventDefault();

          return;
        }}>
        <Grid
          container
          spacing={2}
          alignItems="start"
          sx={{
            padding: "20px 100px",
          }}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                textAlign: "center",
              }}>
              Реквизиты клиента
            </Typography>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}></Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!isResident}
                  onChange={() => {
                    setIsResident(!isResident);
                    dispatch(
                      clientInfoActions.updateClientInfo(
                        (c) => c,
                        (p) => {
                          p.citizen = !isResident ? "Республика Казахстан" : "";
                          p.doctype = "";
                          p.docNumber = "";
                          p.issuePlace = "";

                          return p;
                        }
                      )
                    );

                    if (!isResident && clientInfo.iin.length < 12) {
                      setIinErr({
                        error: true,
                        errText: "Длина не равна 12 цифрам"
                      });

                      return;

                    } else if (isResident && clientInfo.iin.length > 0 && clientInfo.iin.length < 12) {
                      setIinErr({
                        error: true,
                        errText: "Длина не равна 12 цифрам"
                      });

                      return;
                    }

                    setIinErr({
                      error: false,
                      errText: ""
                    });

                    return;
                  }}
                />
              }
              label="Нерезидет"
            />
          </Grid>

          {/* <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <Button
              sx={{
                backgroundColor: "#005792",
                marginTop: "10px",
                '&:hover': {
                  backgroundColor: "#13334c",
                },
              }}
              fullWidth
              variant="contained"
              size="large"
              onClick={() => {
                axios
                  .get(`${wenv.REACT_APP_API_URL}/Id2/getInfo/test_user`)
                  .then((res: any) => {

                    setIsResident(
                      res.data.client.iD2.citizen === "KAZAKHSTAN"
                    );
                    dispatch(
                      clientInfoActions.updateClientInfo(
                        (c) => c,
                        (p) => {
                          p.firstName = res.data.client.iD2.fname;
                          setFirstNameErr({
                            error: false,
                            errText: ""
                          });

                          p.middleName = res.data.client.iD2.sname;

                          p.lastName = res.data.client.iD2.lname;
                          setLastNameErr({
                            error: false,
                            errText: ""
                          });

                          p.citizen = res.data.client.iD2.citizen === "KAZAKHSTAN" ? "Республика Казахстан" : res.data.client.iD2.citizen;
                          setCitizenErr({
                            error: false,
                            errText: ""
                          });

                          p.iin = res.data.client.iD2.iin;
                          setIinErr({
                            error: false,
                            errText: ""
                          });

                          const birthday = new Date(+res.data.client.iD2.bday.slice(0, 4), +res.data.client.iD2.bday.slice(4, 6) - 1, +res.data.client.iD2.bday.slice(6));
                          p.birthday = birthday;
                          if (+birthday > +(new Date()) - 567993600000) {
                            setBirthdayErr({
                              error: true,
                              errText: "Нет 18 лет"
                            });
                          } else {
                            setBirthdayErr({
                              error: false,
                              errText: ""
                            });
                          }

                          p.doctype = "Удостоверение личности";
                          setDoctypeErr({
                            error: false,
                            errText: ""
                          });

                          p.docNumber = res.data.client.iD2.idnum;
                          setDocNumberErr({
                            error: false,
                            errText: ""
                          });

                          p.issuePlace = res.data.client.iD2.issuer;
                          setIssuePlaceErr({
                            error: false,
                            errText: ""
                          });

                          const issueDate = new Date(+res.data.client.iD2.valid_from.slice(6), +res.data.client.iD2.valid_from.slice(3, 4) - 1, +res.data.client.iD2.valid_from.slice(0, 2));
                          p.issueDate = issueDate;
                          if (+issueDate > +(new Date())) {
                            setIssueDateErr({
                              error: true,
                              errText: "Некорректная дата"
                            });
                          } else {
                            setIssueDateErr({
                              error: false,
                              errText: ""
                            });
                          }

                          const validDate = new Date(+res.data.client.iD2.valid_to.slice(6), +res.data.client.iD2.valid_to.slice(3, 4) - 1, +res.data.client.iD2.valid_to.slice(0, 2));
                          p.validDate = validDate;
                          if (+validDate < +(new Date())) {
                            setValidDateErr({
                              error: true,
                              errText: "Документ просрочен"
                            });
                          } else {
                            setValidDateErr({
                              error: false,
                              errText: ""
                            });
                          }

                          p.photoImg = res.data.client.iD2.photo;
                          p.signImg = res.data.client.iD2.sigphoto;

                          return p;
                        }
                      )
                    );
                  })
                  .catch((e) => console.error(e));
              }}
            >
              Заполнить данные
            </Button>
          </Grid> */}

          <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="ИИН"
              variant="outlined"
              value={clientInfo.iin}
              required={isResident}
              autoComplete={"off"}
              sx={{ background: "white" }}
              error={iinErr.error}
              helperText={iinErr.errText}
              inputRef={iinRef}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (isResident) {
                    phoneRef.current.focus();
                  } else {
                    citizenRef.current.focus();
                  }
                }
              }}
              onChange={(e) => {
                let string = e.target.value.replace(/\D/g, "");
                string = string.slice(0, 12);
                dispatch(
                  clientInfoActions.updateClientInfo(
                    (c) => c.iin,
                    () => string
                  )
                );

                if (isResident && string.length < 12) {
                  setIinErr({
                    error: true,
                    errText: "Длина не равна 12 цифрам"
                  });

                  return;

                } else if (!isResident && string.length > 0 && string.length < 12) {
                  setIinErr({
                    error: true,
                    errText: "Длина не равна 12 цифрам"
                  });

                  return;

                } else if (string.length === 12) {
                  const controlArr_1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
                  const controlArr_2 = [3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2];
                  const arrOfIinNumbers = [];
                  let controlNumber = 0;

                  for (let i = 0; i < 12; i++) {
                    arrOfIinNumbers[i] = parseInt(string.substring(i, i + 1));
                    if (i < 11) {
                      controlNumber += arrOfIinNumbers[i] * controlArr_1[i];
                    }
                  }
                  controlNumber = controlNumber % 11;

                  if (controlNumber === 10) {
                    controlNumber = 0;
                    for (let i = 0; i < 11; i++) {
                      controlNumber += arrOfIinNumbers[i] * controlArr_2[i];
                    }
                    controlNumber = controlNumber % 11;
                  }
                  if (controlNumber !== arrOfIinNumbers[11]) {
                    setIinErr({
                      error: true,
                      errText: "Некорректный ИИН"
                    });

                    return;
                  }
                }

                setIinErr({
                  error: false,
                  errText: ""
                });
              }}
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Гражданство"
              variant="outlined"
              value={clientInfo.citizen}
              autoComplete={"off"}
              disabled={isResident}
              sx={{ background: "white" }}
              required
              error={citizenErr.error}
              helperText={citizenErr.errText}
              inputRef={citizenRef}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  phoneRef.current.focus();
                }
              }}
              onChange={(e) => {
                dispatch(
                  clientInfoActions.updateClientInfo(
                    (c) => c.citizen,
                    () => e.target.value
                  )
                );

                if (e.target.value.length === 0) {
                  setCitizenErr({
                    error: true,
                    errText: "Заполните поле"
                  });

                  return;

                } else if (!isResident && (e.target.value === "Казахстан" || e.target.value === "Республика Казахстан")) {
                  setCitizenErr({
                    error: true,
                    errText: "Заполните иностранное государство"
                  });

                  return;
                }

                setCitizenErr({
                  error: false,
                  errText: ""
                });
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <InputMask
              mask="+7 999 999 99 99"
              value={clientInfo.phone}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  lastNameRef.current.focus();
                }
              }}
              onChange={(e) => {
                const newPhone = e.target.value.replace(/[\s_+]/g, "");

                dispatch(clientInfoActions.updateClientInfo(
                  (c) => c.phone,
                  () => newPhone
                ));

                if (newPhone.length > 1) {
                  if (!checkPhone(newPhone)) {
                    setPhoneErr({
                      error: true,
                      errText: "Некорректный код телефона"
                    });

                    return;
                  } else if (newPhone.length !== 11) {
                    setPhoneErr({
                      error: true,
                      errText: "Номер короткий"
                    });

                    return;
                  }
                }

                setPhoneErr({
                  error: false,
                  errText: ""
                });
              }}>
              <TextField
                autoComplete={"off"}
                sx={{ background: "white" }}
                fullWidth
                id="outlined-basic"
                label="Телефон"
                variant="outlined"
                error={phoneErr.error}
                helperText={phoneErr.errText}
                inputRef={phoneRef}
              />
            </InputMask>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}></Grid>

          <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
            <TextField
              fullWidth
              label="Фамилия"
              variant="outlined"
              value={clientInfo.lastName}
              required
              autoComplete={"off"}
              sx={{ background: "white" }}
              error={lastNameErr.error}
              helperText={lastNameErr.errText}
              inputRef={lastNameRef}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  firstNameRef.current.focus();
                }
              }}
              onChange={(e) => {
                handleInputNames(e.target.value, "lastName");
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Имя"
              variant="outlined"
              value={clientInfo.firstName}
              required
              autoComplete={"off"}
              sx={{ background: "white" }}
              error={firstNameErr.error}
              helperText={firstNameErr.errText}
              inputRef={firstNameRef}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  middleNameRef.current.focus();
                }
              }}
              onChange={(e) => {
                handleInputNames(e.target.value, "firstName");
              }}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Отчество"
              variant="outlined"
              value={clientInfo.middleName}
              autoComplete={"off"}
              sx={{ background: "white" }}
              inputRef={middleNameRef}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  birthdayRef.current.focus();
                }
              }}
              onChange={(e) => handleInputNames(e.target.value, "middleName")}
            />
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                <DatePicker
                  label="Дата рождения"
                  value={clientInfo.birthday}
                  onChange={(e) => {
                    if (e && typeof e === "object") {
                      dispatch(
                        clientInfoActions.updateClientInfo(
                          (c) => c.birthday,
                          () => e
                        )
                      );
                    }

                    if (e) {
                      if (!(+e)) {
                        setBirthdayErr({
                          error: true,
                          errText: "Заполните поле"
                        });

                        return;
                      } else if (+e > +(new Date()) - 567993600000) {
                        setBirthdayErr({
                          error: true,
                          errText: "Нет 18 лет"
                        });

                        return;
                      }
                    }

                    setBirthdayErr({
                      error: false,
                      errText: ""
                    });
                  }}
                  PopperProps={{
                    placement: "bottom-start",
                  }}
                  renderInput={
                    (params: JSX.IntrinsicAttributes & TextFieldProps) =>
                      <TextField {...params}
                        sx={{ background: "white" }}
                        autoComplete={"off"}
                        error={birthdayErr.error}
                        helperText={birthdayErr.errText}
                        inputRef={birthdayRef}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            doctypeRef.current.focus();
                          }
                        }}
                      />
                  }
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}></Grid>

          <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
            {
              isResident
                ? <Autocomplete
                  fullWidth
                  sx={{ background: "white" }}
                  freeSolo={!isResident}
                  value={clientInfo.doctype}
                  openOnFocus
                  options={['Удостоверение личности', 'Паспорт', 'Вид на жительство'].map((text) => text)}
                  onChange={(e, newValue) => {
                    dispatch(
                      clientInfoActions.updateClientInfo(
                        (c) => c.doctype,
                        () => newValue
                      )
                    );

                    if (newValue === null || newValue.length === 0) {
                      setDoctypeErr({
                        error: true,
                        errText: "Заполните поле"
                      });
                    } else {
                      setDoctypeErr({
                        error: false,
                        errText: ""
                      });
                    }
                  }}
                  renderInput={
                    (params) =>
                      <TextField
                        {...params}
                        error={doctypeErr.error}
                        helperText={doctypeErr.errText}
                        inputRef={doctypeRef}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            docNumberRef.current.focus();
                          }
                        }}
                        required
                        label="Тип документа"
                      />}
                />
                :
                <Autocomplete
                  fullWidth
                  sx={{ background: "white" }}
                  freeSolo={!isResident}
                  value={clientInfo.doctype}
                  openOnFocus
                  options={['Заграничный паспорт', 'Паспорт лица без гражданства', 'Вид на жительство'].map((text) => text)}
                  onChange={(e, newValue) => {
                    dispatch(
                      clientInfoActions.updateClientInfo(
                        (c) => c.doctype,
                        () => newValue
                      )
                    );

                    if (newValue === null || newValue.length === 0) {
                      setDoctypeErr({
                        error: true,
                        errText: "Заполните поле"
                      });
                    } else {
                      setDoctypeErr({
                        error: false,
                        errText: ""
                      });
                    }
                  }}
                  renderInput={
                    (params) =>
                      <TextField
                        {...params}
                        error={doctypeErr.error}
                        helperText={doctypeErr.errText}
                        inputRef={doctypeRef}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            docNumberRef.current.focus();
                          }
                        }}
                        required
                        label="Тип документа"
                      />}
                />
            }
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Номер документа"
              variant="outlined"
              value={clientInfo.docNumber}
              required
              autoComplete={"off"}
              sx={{ background: "white" }}
              error={docNumberErr.error}
              helperText={docNumberErr.errText}
              inputRef={docNumberRef}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  issueDateRef.current.focus();
                }
              }}
              onChange={(e) => {
                let string = "";

                if (isResident && clientInfo.doctype === "Удостоверение личности") {
                  string = e.target.value.replace(/\D/g, "");
                  string = string.slice(0, 9);
                } else {
                  string = e.target.value.toUpperCase().replace(/[^А-ЯA-ZӘҒҚҢӨҰҮҺІ0-9]/g, "");
                }

                dispatch(
                  clientInfoActions.updateClientInfo(
                    (c) => c.docNumber,
                    () => string
                  )
                );

                if ((isResident && string.length !== 9)) {
                  setDocNumberErr({
                    error: true,
                    errText: "Длина не равна 9 цифрам"
                  });

                  return;

                } else if (!isResident && string.length === 0) {
                  setDocNumberErr({
                    error: true,
                    errText: "Заполните поле"
                  });

                  return;
                }

                setDocNumberErr({
                  error: false,
                  errText: ""
                });
              }}
            />
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                <DatePicker
                  label="Дата выдачи"
                  value={clientInfo.issueDate}
                  onChange={(e) => {
                    if (e && typeof e === "object") {
                      dispatch(
                        clientInfoActions.updateClientInfo(
                          (c) => c.issueDate,
                          () => e
                        )
                      );
                    }

                    if (e) {
                      if (!(+e)) {
                        setIssueDateErr({
                          error: true,
                          errText: "Заполните поле"
                        });

                        return;
                      } else if (+e > +(new Date())) {
                        setIssueDateErr({
                          error: true,
                          errText: "Некорректная дата"
                        });

                        return;
                      }
                    }

                    setIssueDateErr({
                      error: false,
                      errText: ""
                    });
                  }}
                  renderInput={
                    (params: JSX.IntrinsicAttributes & TextFieldProps) =>
                      <TextField {...params}
                        sx={{ background: "white" }}
                        autoComplete={"off"}
                        error={issueDateErr.error}
                        helperText={issueDateErr.errText}
                        inputRef={issueDateRef}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            validDateRef.current.focus();
                          }
                        }}
                      />
                  }
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                <DatePicker
                  label="Срок действия"
                  value={clientInfo.validDate}
                  onChange={(e) => {
                    if (e && typeof e === "object") {
                      dispatch(
                        clientInfoActions.updateClientInfo(
                          (c) => c.validDate,
                          () => e
                        )
                      );
                    }

                    if (e) {
                      if (!(+e)) {
                        setValidDateErr({
                          error: true,
                          errText: "Заполните поле"
                        });

                        return;
                      } else if (+e < +(new Date())) {
                        setValidDateErr({
                          error: true,
                          errText: "Документ просрочен"
                        });

                        return;
                      }
                    }

                    setValidDateErr({
                      error: false,
                      errText: ""
                    });
                  }}
                  renderInput={
                    (params: JSX.IntrinsicAttributes & TextFieldProps) =>
                      <TextField {...params}
                        sx={{ background: "white" }}
                        autoComplete={"off"}
                        error={validDateErr.error}
                        helperText={validDateErr.errText}
                        inputRef={validDateRef}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            issuePlaceRef.current.focus();
                          }
                        }}
                      />
                  }
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            {isResident &&
              <Autocomplete
                fullWidth
                sx={{ background: "white" }}
                freeSolo={!isResident}
                value={clientInfo.issuePlace}
                openOnFocus
                options={['МВД РК', 'МЮ РК', 'ҚР ІІМ', 'ҚР ӘМ'].map((text) => text)}
                onChange={(e, newValue) => {
                  dispatch(
                    clientInfoActions.updateClientInfo(
                      (c) => c.issuePlace,
                      () => newValue
                    )
                  );

                  if (newValue === null || newValue.length === 0) {
                    setIssuePlaceErr({
                      error: true,
                      errText: "Заполните поле"
                    });
                  } else {
                    setIssuePlaceErr({
                      error: false,
                      errText: ""
                    });
                  }
                }}
                renderInput={
                  (params) =>
                    <TextField
                      {...params}
                      error={issuePlaceErr.error}
                      helperText={issuePlaceErr.errText}
                      inputRef={issuePlaceRef}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && needToFillAddress) {
                          countryRef.current.focus();
                        }
                      }}
                      required
                      label="Орган выдачи"
                    />}
              />
            }
            {!isResident &&
              <TextField
                fullWidth
                id="outlined-basic"
                label="Орган выдачи"
                variant="outlined"
                value={clientInfo.issuePlace}
                required
                autoComplete={"off"}
                sx={{ background: "white" }}
                error={issuePlaceErr.error}
                helperText={issuePlaceErr.errText}
                inputRef={issuePlaceRef}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && needToFillAddress) {
                    countryRef.current.focus();
                  }
                }}
                onChange={(e) => {
                  dispatch(
                    clientInfoActions.updateClientInfo(
                      (c) => c.issuePlace,
                      () => e.target.value
                    )
                  );

                  if (e.target.value.length === 0) {
                    setIssuePlaceErr({
                      error: true,
                      errText: "Заполните поле"
                    });
                  } else {
                    setIssuePlaceErr({
                      error: false,
                      errText: ""
                    });
                  }
                }}
              />
            }
          </Grid>

          {
            clientInfo.photoImg && (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} display={"flex"} sx={{ height: "200px" }}>
                <img src={`data: image / png; base64, ${clientInfo.photoImg} `} style={{ maxHeight: "200px" }} />
                <div style={{ maxWidth: "350px" }}>
                  {clientInfo.signImg && <img src={`data: image / png; base64, ${clientInfo.signImg} `} style={{ maxHeight: "200px", width: "100%" }} />}
                </div>
              </Grid>
            )
          }

          {needToFillAddress &&
            <>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "20px"
                  }}
                >
                  Юридический адрес
                </Typography>
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}></Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Страна"
                  variant="outlined"
                  value={clientInfo.country}
                  required
                  autoComplete={"off"}
                  sx={{ background: "white" }}
                  error={countryErr.error}
                  helperText={countryErr.errText}
                  inputRef={countryRef}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && needToFillAddress) {
                      regionRef.current.focus();
                    }
                  }}
                  onChange={(e) => {
                    dispatch(clientInfoActions.updateClientInfo(
                      (c) => c.country,
                      () => e.target.value
                    ));

                    if (e.target.value.length === 0) {
                      setCountryErr({
                        error: true,
                        errText: "Заполните поле"
                      });

                      return;
                    }

                    setCountryErr({
                      error: false,
                      errText: ""
                    });
                  }}
                />
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}></Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Регион"
                  variant="outlined"
                  value={clientInfo.region}
                  required
                  autoComplete={"off"}
                  sx={{ background: "white" }}
                  error={regionErr.error}
                  helperText={regionErr.errText}
                  inputRef={regionRef}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && needToFillAddress) {
                      localityRef.current.focus();
                    }
                  }}
                  onChange={(e) => {
                    dispatch(clientInfoActions.updateClientInfo(
                      (c) => c.region,
                      () => e.target.value
                    ));

                    if (e.target.value.length === 0) {
                      setRegionErr({
                        error: true,
                        errText: "Заполните поле"
                      });

                      return;
                    }

                    setRegionErr({
                      error: false,
                      errText: ""
                    });
                  }}
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Город, населенный пункт"
                  variant="outlined"
                  value={clientInfo.locality}
                  required
                  autoComplete={"off"}
                  sx={{ background: "white" }}
                  error={localityErr.error}
                  helperText={localityErr.errText}
                  inputRef={localityRef}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && needToFillAddress) {
                      streetRef.current.focus();
                    }
                  }}
                  onChange={(e) => {
                    dispatch(clientInfoActions.updateClientInfo(
                      (c) => c.locality,
                      () => e.target.value
                    ));

                    if (e.target.value.length === 0) {
                      setLocalityErr({
                        error: true,
                        errText: "Заполните поле"
                      });

                      return;
                    }

                    setLocalityErr({
                      error: false,
                      errText: ""
                    });
                  }}
                />
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}></Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Улица/мкр"
                  variant="outlined"
                  value={clientInfo.street}
                  required
                  autoComplete={"off"}
                  sx={{ background: "white" }}
                  error={streetErr.error}
                  helperText={streetErr.errText}
                  inputRef={streetRef}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && needToFillAddress) {
                      numberRef.current.focus();
                    }
                  }}
                  onChange={(e) => {
                    dispatch(clientInfoActions.updateClientInfo(
                      (c) => c.street,
                      () => e.target.value
                    ));

                    if (e.target.value.length === 0) {
                      setStreetErr({
                        error: true,
                        errText: "Заполните поле"
                      });

                      return;
                    }

                    setStreetErr({
                      error: false,
                      errText: ""
                    });
                  }}
                />
              </Grid>

              <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Дом"
                  variant="outlined"
                  value={clientInfo.number}
                  required
                  autoComplete={"off"}
                  sx={{ background: "white" }}
                  error={numberErr.error}
                  helperText={numberErr.errText}
                  inputRef={numberRef}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && needToFillAddress) {
                      flatRef.current.focus();
                    }
                  }}
                  onChange={(e) => {
                    dispatch(clientInfoActions.updateClientInfo(
                      (c) => c.number,
                      () => e.target.value
                    ));

                    if (e.target.value.length === 0) {
                      setNumberErr({
                        error: true,
                        errText: "Заполните поле"
                      });

                      return;
                    } else if (!e.target.value.match(/\d/g)) {
                      setNumberErr({
                        error: true,
                        errText: "Поле должно иметь цифру"
                      });

                      return;
                    }

                    setNumberErr({
                      error: false,
                      errText: ""
                    });
                  }}
                />
              </Grid>

              <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Квартира"
                  variant="outlined"
                  value={clientInfo.flat}
                  autoComplete={"off"}
                  sx={{ background: "white" }}
                  error={flatErr.error}
                  helperText={flatErr.errText}
                  inputRef={flatRef}
                  onChange={(e) => {
                    dispatch(clientInfoActions.updateClientInfo(
                      (c) => c.flat,
                      () => e.target.value
                    ));

                    if (e.target.value.length > 0 && !e.target.value.match(/\d/g)) {
                      setFlatErr({
                        error: true,
                        errText: "Поле должно иметь цифру"
                      });

                      return;
                    }

                    setFlatErr({
                      error: false,
                      errText: ""
                    });
                  }}
                />
              </Grid>
            </>
          }

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}></Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}></Grid>

          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center">
            <Button
              sx={{
                backgroundColor: "#F15A25",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#aa5e00",
                }
              }}
              variant="contained"
              size="large"
              type={"button"}
              onClick={handleSubmitForm}
            >
              Продолжить
            </Button>
          </Grid>
        </Grid>
      </form >
    </>
  );
};

export default ClientForm;
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Role } from '@/api/CamundaController';
import { Box, Button, TextField, Toolbar, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { CancelPresentation } from '@mui/icons-material';
import { api } from '@/api/Api';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import processManager from '@/core/helpers/ProcessManager';
import { useTranslation } from 'react-i18next';


const RoleCreate = (props: any) => {

    const { code } = useParams();
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { formState } = props;

    const [model, setModel] = useState(new Role());

    useEffect(() => {
        if (formState && code) {
            api.camunda.getRole(code).then((m) => {
                setModel(m);
            });
        }
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();
        api.camunda.registerRole(model).then(() => {
            processManager.openSnackBar(formState ? t("role.create.successupdate") : t("role.create.successcreate"));
            navigate('/roles');
        });
    };

    return (
        <Grid container spacing={2}>
            <Grid xs={12} md={8} lg={8}>
                <Toolbar>
                    <Button variant="contained" component={Link} to='/roles' startIcon={<CancelPresentation />}>{t("role.create.cancel")}</Button>
                </Toolbar>
            </Grid>
            {/* Chart */}
            <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <Grid sx={{ p: 2 }}>
                        <Typography component="h2" variant="h6">
                            {formState ? t("role.create.update") : t("role.create.create")}
                        </Typography>
                        <br />
                        <Box component="form" noValidate onSubmit={onSubmit} sx={{ width: '100%' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        disabled={formState}
                                        size='small'
                                        fullWidth
                                        placeholder="Code"
                                        label="Code"
                                        value={model.code}
                                        onChange={(e) => setModel({ ...model, code: e.target.value })} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        size='small'
                                        fullWidth
                                        placeholder="Description"
                                        label="Description"
                                        value={model.description}
                                        onChange={(e) =>
                                            setModel({ ...model, description: e.target.value })
                                        } />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        size='small'
                                        fullWidth
                                        placeholder="Comments"
                                        label="Comments"
                                        value={model.comments}
                                        onChange={(e) =>
                                            setModel({ ...model, comments: e.target.value })
                                        } />
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {t("role.create.save")}
                            </Button>
                        </Box>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>);
};

export default RoleCreate;
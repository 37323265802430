const UPDATE_RATE_MODEL = "UPDATE_RATE_MODEL";
const UPDATE_REQUEST_ID = "UPDATE_REQUEST_ID";

export interface RateExchange {
  buyCurrencyCode: string;
  saleCurrencyCode: string;
  buyAmount: number | string;
  saleAmount: number | string;
  rate: number;
  kassaId: number | string;
  depsId: number | string;
  requestId: number | null;
  isIndividual: boolean;
}

const initialState: RateExchange = {
  buyCurrencyCode: "",
  saleCurrencyCode: "KZT",
  buyAmount: "",
  saleAmount: "",
  rate: 0,
  kassaId: "",
  requestId: null,
  isIndividual: false,
  depsId: ""
};

export default function rateExchange(state = initialState, action: any = {}) {
  let _state = state;
  switch (action.type) {
    case UPDATE_RATE_MODEL:
      return {
        ...state,
        buyCurrencyCode: action.buyCurrencyCode,
        saleCurrencyCode: action.saleCurrencyCode,
        buyAmount: action.buyAmount,
        saleAmount: action.saleAmount,
        rate: action.rate,
        depsId: action.depsId,
        requestId: action.requestId,
        isIndividual: action.isIndividual,
        kassaId: action.kassaId
      };

    case UPDATE_REQUEST_ID:
      return {
        ...state,
        requestId: action.requestId
      };

    default:
      _state = state;
      break;
  }

  return _state;
}

const updateRateModel = (
  buyCurrencyCode: string,
  saleCurrencyCode: string,
  buyAmount: string | number,
  saleAmount: string | number,
  rate: number,
  kassaId: number | string,
  requestId: number | null,
  isIndividual: boolean,
  depsId: string | number
) => ({
  type: UPDATE_RATE_MODEL,
  buyCurrencyCode,
  saleCurrencyCode,
  buyAmount,
  saleAmount,
  rate,
  kassaId,
  requestId,
  isIndividual,
  depsId
});

const updateRequestId = (
  requestId: number | null
) => ({
  type: UPDATE_REQUEST_ID,
  requestId
});

export const actions = {
  updateRateModel,
  updateRequestId
};

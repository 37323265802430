import { HistoricProcessAndTaskInstances, Task, User } from "@/api/CamundaController";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import { Button, Collapse, Typography } from "@mui/material";
import moment from "moment-timezone";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface TaskHistoryProps {
    me: User;
    task?: Task;
    history?: HistoricProcessAndTaskInstances;

}

const TaskHistoryTimeline = (props: TaskHistoryProps) => {
    const { t } = useTranslation();
    const { me, history, task } = props;
    const [show, setShow] = useState(false);

    return (
        <React.Fragment>
            <Typography variant='h6' component='h4'>{t("history.title")}</Typography>
            <Typography sx={{ fontSize: '14px', color: 'grey' }}>{t("history.createDate")}: {moment.tz(history?.process?.startTime, me.gmt || '').format('DD.MM.yyyy HH:mm:ss')}</Typography>
            <Typography sx={{ fontSize: '14px', color: 'grey' }}>{t("history.user")}: {history?.process?.startUserId}</Typography>
            <Collapse collapsedSize={300} in={show} orientation='vertical'>
                <Timeline position="alternate">
                    {history?.tasks?.filter((s: any) => s.processDefinitionKey === task?.processDefinitionKey).map((t) =>
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                <Typography variant='subtitle1'>{t.assignee}</Typography>
                                <Typography variant='caption'>{t.startTime && moment.tz(t.startTime, me.gmt || '').format('DD.MM.yyyy HH:mm:ss')}</Typography><br />
                                <Typography variant='caption'>{t.endTime && moment.tz(t.endTime, me.gmt || '').format('DD.MM.yyyy HH:mm:ss')}</Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot variant="outlined" color='primary' />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography>{t.name}</Typography>
                            </TimelineContent>
                        </TimelineItem>
                    )}
                </Timeline>
            </Collapse>
            {!show && <Button sx={{ textTransform: 'none' }} onClick={() => setShow(true)}>{t("showmore")}</Button>}
            {show && <Button sx={{ textTransform: 'none' }} onClick={() => setShow(false)}>{t("showless")}</Button>}
        </React.Fragment>
    );
};

export default TaskHistoryTimeline;
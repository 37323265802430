import iassign from 'immutable-assign';

const CACHE_ADD = 'Cache/CACHE_ADD';
const CACHE_REMOVE = 'Cache/CACHE_REMOVE';

export type KObject = { [key: string]: any };

export class CacheReducer {
  items: KObject = {};
}

const initialState = new CacheReducer();

export default function cache(state: CacheReducer = initialState, action: any = {}) {
  switch (action.type) {
    case CACHE_ADD:
      return iassign(state, s => s.items, p => Object.assign(p, action.payload));
    case CACHE_REMOVE: {
      var result = {};
      for (const key in state.items) {
        if (state.items.hasOwnProperty(key)) {
          if (key != action.payload) {
            result = Object.assign(result, { [key]: state.items[key] });
          }
        }
      }
      return { ...state, items: result };
    }

    default:
      return state;
  }
}

const add = (payload: KObject) => ({
  type: CACHE_ADD,
  payload: payload
});

const remove = (key: string) => ({
  type: CACHE_ADD,
  payload: key
});

export const actions = {
  add,
  remove
};

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Role } from "@/api/CamundaController";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Input,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { AddModerator, Search } from "@mui/icons-material";
import { api } from "@/api/Api";
import React, { useEffect, useState } from "react";
import { Delete } from "@mui/icons-material";
import processManager from "@/core/helpers/ProcessManager";
import { useTranslation } from "react-i18next";

const RolesList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [pageSize, setPageSize] = useState(10);
  const showMoreSize = 10;
  const [lastPage, setLastPage] = useState(true);
  const [dense, setDense] = React.useState(true);
  const [content, setContent] = useState([] as Role[]);
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const onShowMore = () => {
    api.camunda
      .getRoles(0, pageSize + showMoreSize, code, description)
      .then((m) => {
        setContent(m.content);
        setLastPage(m.last);
        setPageSize(pageSize + showMoreSize);
      });
  };

  const deleteRole = (role: Role) => {
    processManager.openConfirm(() => {
      api.camunda.deleteRole(role.code).then(() => {
        setContent(content.filter((m) => m.code !== role.code));
        processManager.openSnackBar(t("role.list.deletesuccess"));
      });
    }, `${t("role.list.deleteconfirm")} ${role.code}?`);
  };

  useEffect(() => {
    api.camunda.getRoles(0, pageSize, code, description).then((m) => {
      setContent(m.content);
      setLastPage(m.last);
    });
  }, [code, description]);

  const onClick = (role: Role) => {
    navigate(`/roles/${role.code}`);
  };

  return (
    <Grid container spacing={2}>
      <Grid>
        <Toolbar>
          <Button
            variant="contained"
            component={Link}
            to="/roles/create"
            startIcon={<AddModerator />}>
            {t("role.list.create")}
          </Button>
        </Toolbar>
      </Grid>
      {/* Chart */}
      <Grid item xs={12} md={12} lg={12}>
        <React.Fragment>
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Input
                          type="search"
                          placeholder={t("role.list.code") || ""}
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                          size="small"
                        />
                        <Search />
                      </TableCell>
                      <TableCell>
                        <Input
                          type="search"
                          placeholder={t("role.list.code") || ""}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                        <Search />
                      </TableCell>
                      <TableCell>{t("role.list.comments")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {content.map((cont) => (
                      <TableRow key={cont.code} onClick={() => onClick(cont)}>
                        <TableCell>{cont.code}</TableCell>
                        <TableCell>{cont.description}</TableCell>
                        <TableCell>{cont.comments}</TableCell>
                        <TableCell style={{ width: "88px" }}>
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteRole(cont);
                            }}>
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              {!lastPage && (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => onShowMore()}>
                  <b>
                    <u>{t("role.list.showmore")}</u>
                  </b>
                </div>
              )}
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label={t("role.list.tight")}
            />
          </Box>
        </React.Fragment>
      </Grid>
    </Grid>
  );
};

export default RolesList;

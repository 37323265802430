import createStore from '../../redux/create';
import { history } from './history';
import throttle from "lodash/throttle";
import { saveState, loadState } from "@/redux/localStorage";

export const store = createStore(history, null, loadState());
export type RootState = ReturnType<typeof store.getState>;

store.subscribe(throttle(() => {
	saveState(
		store.getState()
	);
}, 1000));
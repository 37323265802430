import authorizedLayout from "@/components/AuthorizedLayout";
import { Routes, Route } from "react-router-dom";
import UsersCreate from "./UsersCreate";
import UsersList from "./UsersList";

const Users = () =>
    <Routes>
        <Route
            path=':id'
            element={authorizedLayout(() => UsersCreate())}
        />
        <Route
            path=''
            element={authorizedLayout(() => UsersList())}
        />
        <Route
            path='create'
            element={authorizedLayout(() => UsersCreate())}
        />
    </Routes>;

export default Users;

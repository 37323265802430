import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { PdfmakeFonts } from '@/core/helpers/PdfmakeFonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = PdfmakeFonts;

export const printDocument = () => {
	const showing: any = {
		pageOrientation: 'landscape',
		content: [
			{
				layout: "noBorders",
				table: {
					widths: [450],
					body: [
						[
							{ alignment: "center", bold: true, fontSize: 11, text: "Отчет о покупке и (или) продаже наличной иностранной валюты на сумму, равную или превышающую 50 000 (пятьдесят тысяч) долларов Соединенных Штатов Америки в эквиваленте" }
						]
					]
				},
			},
			{
				fontSize: 7,
				table: {
					widths: [225, 120],
					body: [
						[
							{ fontSize: 11, text: "Индекс:" },
							{ alignment: "center", text: "NIV_VAL" }
						],
						[
							"Отчетный месяц:",
							{ alignment: "center", text: "6" }
						],
						[
							"Отчетный год:",
							{ alignment: "center", text: "2024" }
						],
						[
							"БИН УО/УБ / филиала УО/УБ:",
							{ alignment: "center", text: "221240028699" }
						],
						[
							"Территориальный филиала НБ РК",
							{ text: "", border: [true, true, false, false] }
						],
					]
				}
			},
			{ text: "\n" },
			{ text: "\n" },
			{
				fontSize: 8.5,
				alignment: "center",
				table: {
					widths: [54.5, 54.5, 54.5, 54.5, 54.5, 54.5, 54.5, 54.5, 54.5, 54.5, 54.5,],
					body: [
						[
							{ colSpan: 7, text: "1. Информация о клиенте " },
							{},
							{},
							{},
							{},
							{},
							{},
							{ colSpan: 4, text: "2. Информация о валютной операции" },
							{},
							{},
							{},
						],
						[
							{ colSpan: 4, text: "Фамилия, имя, отчество (при наличии)" },
							{},
							{},
							{},
							{ colSpan: 2, text: "Индивидуальный идентификационный номер (иные данные)" },
							{},
							"Признак резидентства",
							"Дата",
							"Покупка (продажа)",
							"Сумма в тысячах единиц валюты",
							"Код валюты",
						],
						[
							{ colSpan: 4, text: "1.1" },
							{},
							{},
							{},
							{ colSpan: 2, text: "1.2" },
							{},
							"1.3",
							"2.1",
							"2.2",
							"2.3",
							"2.4",
						],
						[
							{ colSpan: 4, bold: true, fontSize: 6, text: "Сурапбергенов Тимур Даулеткелдыевич" },
							{},
							{},
							{},
							{ colSpan: 2, bold: true, fontSize: 6, text: "781218300039" },
							{},
							{ bold: true, fontSize: 6, text: "1" },
							{ bold: true, fontSize: 6, text: "15.09.2023" },
							{ bold: true, fontSize: 6, text: "2" },
							{ bold: true, fontSize: 6, text: "90,00" },
							{ bold: true, fontSize: 6, text: "USD" },
						],
						[
							{ colSpan: 4, bold: true, fontSize: 6, text: "Сурапбергенов Тимур Даулеткелдыевич" },
							{},
							{},
							{},
							{ colSpan: 2, bold: true, fontSize: 6, text: "781218300039" },
							{},
							{ bold: true, fontSize: 6, text: "1" },
							{ bold: true, fontSize: 6, text: "15.09.2023" },
							{ bold: true, fontSize: 6, text: "2" },
							{ bold: true, fontSize: 6, text: "90,00" },
							{ bold: true, fontSize: 6, text: "USD" },
						],
						[
							{ colSpan: 4, bold: true, fontSize: 6, text: "Сурапбергенов Тимур Даулеткелдыевич" },
							{},
							{},
							{},
							{ colSpan: 2, bold: true, fontSize: 6, text: "781218300039" },
							{},
							{ bold: true, fontSize: 6, text: "1" },
							{ bold: true, fontSize: 6, text: "15.09.2023" },
							{ bold: true, fontSize: 6, text: "2" },
							{ bold: true, fontSize: 6, text: "90,00" },
							{ bold: true, fontSize: 6, text: "USD" },
						],
						[
							{ colSpan: 4, bold: true, fontSize: 6, text: "Сурапбергенов Тимур Даулеткелдыевич" },
							{},
							{},
							{},
							{ colSpan: 2, bold: true, fontSize: 6, text: "781218300039" },
							{},
							{ bold: true, fontSize: 6, text: "1" },
							{ bold: true, fontSize: 6, text: "15.09.2023" },
							{ bold: true, fontSize: 6, text: "2" },
							{ bold: true, fontSize: 6, text: "90,00" },
							{ bold: true, fontSize: 6, text: "USD" },
						],
						[
							{ colSpan: 4, bold: true, fontSize: 6, text: "Сурапбергенов Тимур Даулеткелдыевич" },
							{},
							{},
							{},
							{ colSpan: 2, bold: true, fontSize: 6, text: "781218300039" },
							{},
							{ bold: true, fontSize: 6, text: "1" },
							{ bold: true, fontSize: 6, text: "15.09.2023" },
							{ bold: true, fontSize: 6, text: "2" },
							{ bold: true, fontSize: 6, text: "90,00" },
							{ bold: true, fontSize: 6, text: "USD" },
						],
					]
				}
			}
		],
		defaultStyle: {
			font: "Roboto"
		},
	};

	return showing;
};
import { server } from '../core/server/axios';

const wenv = process.env;

interface LoadLoagByDateVariables {
	startDate: string;
	endDate: string;
	depId: string | number;
}

export class LogsController {
	async loadLogsByDate(request: LoadLoagByDateVariables) {
		return await server.post(`/Rates/LogListByDate?startDate=${request.startDate}T00%3A00%3A00.000000&endDate=${request.endDate}T23%3A59%3A59.999999&depId=${request.depId}`, request, {
			baseURL: wenv.REACT_APP_API_URL
		});
	}
}
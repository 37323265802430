import { TaskFormComponent } from "@/api/CamundaController";
import { AddCircle } from "@mui/icons-material";
import { Button, Dialog, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface DynamicAddProps {
    components: Array<TaskFormComponent>;
    setComponents: (comps: Array<TaskFormComponent>) => void;
}

const DynamicTypes = [
    { value: 'textfield', label: 'TextField' },
    { value: 'number', label: 'Number' },
    { value: 'checkbox', label: 'Checkbox' }
];

const DynamicAdd = (props: DynamicAddProps) => {
    const { t } = useTranslation();
    const { components, setComponents } = props;
    const [newComponent, setNewComponent] = useState(false);
    const [dynamicType, setDynamicType] = useState('textfield');
    const [dynamicLabel, setDynamicLabel] = useState('');
    const [dynamicKey, setDynamicKey] = useState('');

    const onNewDynamicComponent = () => {
        setNewComponent(true);
        setDynamicType('textfield');
        setDynamicLabel('');
        setDynamicKey('');
    };

    const onCreateDynamicComponent = () => {
        var comp = {
            key: dynamicKey,
            label: dynamicLabel,
            description: dynamicLabel,
            type: dynamicType,
            dynamic: true
        };
        setNewComponent(false);
        setComponents(components.map(c => c).concat(comp));
    };

    return (
        <Container sx={{ pt: 2 }}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                <IconButton onClick={() => onNewDynamicComponent()} title={t("form.addnew") || ''}>
                    <AddCircle />
                </IconButton>
            </Grid>
            <Dialog open={newComponent} onClose={() => setNewComponent(false)}>
                <Box component="form" onSubmit={(e) => { e.preventDefault(); onCreateDynamicComponent(); }} sx={{ mt: 3, mb: 3 }}>
                    <Grid container spacing={2} sx={{ pt: 2, pl: 2, pr: 2 }}>
                        <Grid item xs={12}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id={`dynamic-type-label`}>{t("form.eltype")}</InputLabel>
                                <Select
                                    size='small'
                                    labelId={`dynamic-type-label`}
                                    value={dynamicType}
                                    label={t("form.eltype")}
                                    onChange={(e) => setDynamicType(e.target.value)}
                                >
                                    {DynamicTypes.map((item, index) => (
                                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                size='small'
                                required
                                fullWidth
                                label={t("form.title")}
                                placeholder={'A textfield'}
                                value={dynamicLabel}
                                onChange={(e) => setDynamicLabel(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                size='small'
                                required
                                fullWidth
                                label={t("form.keyval")}
                                placeholder={t("form.keyvalhelper") || ''}
                                value={dynamicKey}
                                onChange={(e) => setDynamicKey(e.target.value)}
                            />
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start" sx={{ pt: 2 }}>
                            <Button size="small" onClick={() => setNewComponent(false)}>{t("form.cancel")}</Button>
                            <Button size="small" type="submit">{t("form.create")}</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </Container>
    );
};

export default DynamicAdd;
import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import PeopleIcon from "@mui/icons-material/People";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import {
  HistoryEdu,
  Queue,
  Reorder,
  VerifiedUser,
} from "@mui/icons-material";
import { SelectedMenu } from "@/redux/modules/Header";
import { Role } from "@/api/CamundaController";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SavingsRoundedIcon from '@mui/icons-material/SavingsRounded';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ListIcon from '@mui/icons-material/List';

const menuList = [
  { title: "Открытие отделений и касс", path: "/department-open", icon: <AddBusinessIcon /> },
  { title: "Очереди", path: "/queue", icon: <Queue /> },
  { title: "История", path: "/history", icon: <HistoryEdu /> },
  { title: "Пользователи", path: "/users", icon: <PeopleIcon /> },
  { title: "Роли", path: "/roles", icon: <VerifiedUser /> },
  { title: "Настройки очередей", path: "/queues", icon: <Reorder /> },

];

const commonList = [
  { title: "Курсы", path: "/treasurer-rates", icon: <MonetizationOnIcon /> },
  { title: "Документы", path: "/documents", icon: <PictureAsPdfIcon /> },
];

const cashierMenuList = [
  { title: "Баланс касс", path: "/cash-balance", icon: <CurrencyExchangeIcon /> },
  { title: "Список операций", path: "/request-list", icon: <ListIcon /> , },
];

interface MainListItemsProps {
  selectedMenu?: SelectedMenu;
  onSelectMenu: (menu: SelectedMenu) => void;
  roles?: Role[];
}

export const MainListItems = (props: MainListItemsProps) => {
  const { onSelectMenu, roles } = props;

  return (
    <React.Fragment>
      {commonList.map((item, i) => (
        <ListItemButton
          key={i}
          onClick={() =>
            onSelectMenu({ path: item.path, title: item.title, index: i })
          }>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText secondary={item.title} />
        </ListItemButton>
      ))}

      {roles?.find((item) => item.code.indexOf("Cashier") >= 0) &&
        cashierMenuList.map((item, i) => (
          <ListItemButton
            key={i}
            onClick={() =>
              onSelectMenu({ path: item.path, title: item.title, index: i })
            }>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText secondary={item.title} />
          </ListItemButton>
        ))}

      {roles?.find((item) => item.code === "ADMIN") &&
        menuList.map((item, i) => (
          <ListItemButton
            key={i}
            onClick={() =>
              onSelectMenu({ path: item.path, title: item.title, index: i })
            }>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText secondary={item.title} />
          </ListItemButton>
        ))}
    </React.Fragment>
  );
};

export const SecondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText secondary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText secondary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText secondary="Year-end sale" />
    </ListItemButton>
  </React.Fragment>
);

import Grid from '@mui/material/Grid';
import { ProcessDefinitionInfo, User } from '@/api/CamundaController';
import { ProcessList, QueueList, TaskBoard } from '@/components/TaskBoard';
import React, { useState } from 'react';
import { ProcessFormGen } from '@/components/FormGen';
import { useSelector } from 'react-redux';


const StartProcess = () => {
    const selectedUserQueue = useSelector((state: any) => state?.header?.selectedQueueMenu);
    const userQueue = useSelector((state: any) => state?.header?.userQueue);
    const me = useSelector((state: any) => state?.header?.userContext?.user as User);

    const [process, setProcess] = useState<ProcessDefinitionInfo>();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={8}>
                <ProcessList handleProcessSelect={(process) => setProcess(process)} selectedProcess={process || {}} />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                {process && <ProcessFormGen process={process} />}
                {!process &&
                    <React.Fragment>
                        <QueueList mode='started' />
                        {selectedUserQueue && selectedUserQueue.process == 'started' && <TaskBoard me={me} selectedUserQueue={selectedUserQueue} userQueue={userQueue} />}
                    </React.Fragment>}
            </Grid>
        </Grid>);
};

export default StartProcess;
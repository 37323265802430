import React from "react";
import { ModalCashierAction } from '@/components';
import { DepartmentCashierExchange } from '../index';
import { useCashierExchangePage } from './useCashierExchangePage';
import { DepartmentModel, KassaModel } from '@/interface';

interface CashierExchangePageProps {
	activeKassa: KassaModel,
	activeDepartment: DepartmentModel,
	loadAddCash: (params: { balance: number, currencyCode: string, kassaId: string | number }) => void;
}

export const CashierExchangePage = ({ activeKassa, activeDepartment, loadAddCash }: CashierExchangePageProps) => {
	const {
		handleOpenWindow,
		showAllId,
		setShowAll,
		kztCurrency,
		openWindow,
		handleCloseWindow,
		rateExchange,
		handleChangeBuyAmount,
		handleChangeSaleAmount,
		clientInfo,
		setIsLoading,
		handleSubmitCurrencies,
		minLimitToIndivRate,
		isIndivRate,
		newIndivRate,
		handleSetIsIndivRate,
		handleSetNewIndivRate,
	} = useCashierExchangePage({ activeKassa, loadAddCash });

	return (
		<>

			{openWindow &&
				<ModalCashierAction
					openWindow={openWindow}
					handleCloseWindow={handleCloseWindow}
					rateExchange={rateExchange}
					handleChangeBuyAmount={handleChangeBuyAmount}
					handleChangeSaleAmount={handleChangeSaleAmount}
					clientInfo={clientInfo}
					setIsLoading={setIsLoading}
					handleSubmitCurrencies={handleSubmitCurrencies}
					minLimitToIndivRate={minLimitToIndivRate}
					isIndivRate={isIndivRate}
					newIndivRate={newIndivRate}
					handleSetIsIndivRate={handleSetIsIndivRate}
					handleSetNewIndivRate={handleSetNewIndivRate}
				/>
			}

			<DepartmentCashierExchange
				department={activeDepartment}
				kassa={activeKassa}
				handleOpenWindow={handleOpenWindow}
				showAllId={showAllId}
				setShowAll={setShowAll}
				kztCurrency={kztCurrency}
			/>
		</>
	);
};
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { formatNumberRu } from '@/core/helpers/PageHelpers';
import { PdfmakeFonts } from '@/core/helpers/PdfmakeFonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = PdfmakeFonts;

const CheckDocument = (
  iin: string,
  date: string,
  requestId: number | string,
  saleCurrencyCode: string,
  saleAmount: string | number,
  rate: string | number,
  buyCurrencyCode: string,
  buyAmount: string | number
) => {

  const showing: any = {
    pageSize: 'A5',
    content: [
      {
        stack: [
          {
            text: 'ТОО "Ecash*"',
          },
          {
            text: 'г.Алматы, р-н Медеуский, Достык 89, 1 этаж*',
          },
          {
            text: 'ИИН/БИН: 221240028699*',
          },
          {
            text: 'ЗНМ: CBS91836538*',
          },
          {
            text: 'РНМ: 010102664721*',
          },
          {
            text: `ИИН/БИН клиента: ${iin}`,
          },
          {
            text: "\n"
          },
          {
            text: '«Фискальный чек»',
            // bold: true
          },
          {
            text: "\n"
          },
          {
            text: `Дата: ${date.slice(0, 10)} ${date.slice(11, 16)}`
          },
          {
            text: `ИИН/БИН клиента: ${iin}`,
          },
          {
            text: 'Кассир: 30*',
          },
          {
            text: `Номер документа: ${requestId}`,
          },
          {
            text: 'Номер смены: 81*',
          },
        ],
        alignment: "center",
      },
      {
        text: 'ПРОДАЖА',
        // bold: true
      },
      {
        columns: [
          {
            width: 'auto',
            text: '1.',
          },
          {
            width: '*',
            stack: [
              {
                text: `${saleCurrencyCode}`
              },
              {
                text: `${saleAmount} х ${(+rate).toFixed(2)}`
              },
            ]
          },
          {
            width: '*',
            alignment: "right",
            stack: [
              {
                text: `${buyCurrencyCode}`
              },
              {
                text: `\n${formatNumberRu(buyAmount)}`
              }
            ]
          },
        ]
      },
      {
        text: "\n"
      },
      {
        columns: [
          {
            width: '*',
            text: ' ',
          },
          {
            width: '*',
            alignment: "center",
            text: "Наличные"
          },
          {
            width: '*',
            alignment: "right",
            text: `${formatNumberRu(buyAmount)}`
          },
        ]
      },
      {
        columns: [
          {
            width: '*',
            text: ' ',
          },
          {
            width: '*',
            alignment: "center",
            // bold: true,
            text: "ИТОГО"
          },
          {
            width: '*',
            alignment: "right",
            // bold: true,
            text: `${formatNumberRu(buyAmount)}`
          },
        ]
      },
      {
        text: "\n"
      },
      {
        stack: [
          {
            text: 'ОФД - АО "КАЗАХТЕЛЕКОМ"'
          },
          {
            text: 'Фиск. признак 2209986300'
          },
          {
            text: 'Код ККМ КГД: 010102664721'
          },
          {
            text: 'Для проверки чека зайдите на сайт:'
          },
          {
            text: 'http://consumer.oofd.kz'
          },
        ]
      },
    ],
    defaultStyle: {
      fontSize: 14,
    },
  };

  return showing;
};

export default CheckDocument;

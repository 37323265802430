import { Dialog, DialogProps } from '@mui/material';
import { ReactNode } from 'react';

interface ModalWindowProps extends DialogProps {
	children: ReactNode;
	title: string;
}

export const ModalWindow = ({
	children,
	title,
	open,
	onClose,
	maxWidth = 'xs',
	className,
	...others
}: ModalWindowProps) => (
	<Dialog
		open={open}
		onClose={onClose}
		maxWidth={maxWidth}
		className={className}
		sx={{
			'& .MuiPaper-root': {
				padding: '10px',
			},
			'& h3': {
				textAlign: 'center',
				marginBottom: '10px'
			}
		}}
		{...others}
	>
		<h3>{title}</h3>
		{children}
	</Dialog>
);
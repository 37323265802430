import moment from "moment";

export const generateUUID = () => { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
};

export const toDataURL = (url: string) => {
    var userContext = JSON.parse(localStorage.getItem("userContext") || "{}");
    return fetch(url, {
        headers: {
            Authorization: "Bearer " + (userContext.token || {}).accessToken,
        },
    })
        .then((response) => response.blob())
        .then(
            (blob) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                })
        );
};

export const downloadFile = (data: any, fileName?: string) => {
    const fileUrl = window.URL.createObjectURL(data);
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = fileName || "file";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(data);
};

export const dataURLtoFile = (dataurl: string, filename: string) => {
    var arr = dataurl.split(","),
        mime = arr?.[0].match(/:(.*?);/)?.[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
};

export const base64toBlob = (data: string, contentType: string) => {
    const out = urlB64ToUint8Array(data);

    return new Blob([out], { type: contentType });
};

function urlB64ToUint8Array(base64String: any) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
}

export function numberWithSeparator(number?: number, separator?: string) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator || "");
}

export const kz2lt = (text: string) => {
    let be = false;
    let snew = "";
    let result = "";

    Array.from(text).forEach((sold) => {
        if (sold === "А") {
            snew = "A";
            be = true;
        } else if (sold === "а") {
            snew = "a";
            be = true;
        } else if (sold === "Ә") {
            snew = "A";
            be = true;
        } else if (sold === "ә") {
            snew = "a";
            be = true;
        } else if (sold === "Б") {
            snew = "B";
            be = false;
        } else if (sold === "б") {
            snew = "b";
            be = false;
        } else if (sold === "В") {
            snew = "V";
            be = false;
        } else if (sold === "в") {
            snew = "v";
            be = false;
        } else if (sold === "Г") {
            snew = "G";
            be = false;
        } else if (sold === "г") {
            snew = "g";
            be = false;
        } else if (sold === "Ғ") {
            snew = "G";
            be = false;
        } else if (sold === "ғ") {
            snew = "g";
            be = false;
        } else if (sold === "Д") {
            snew = "D";
            be = false;
        } else if (sold === "д") {
            snew = "d";
            be = false;
        } else if (sold === "Һ") {
            snew = "H";
            be = false;
        } else if (sold === "һ") {
            snew = "h";
            be = false;
        } else if (sold === "Е" && !be) {
            snew = "E";
            be = true;
        } else if (sold === "е" && !be) {
            snew = "e";
            be = true;
        } else if (sold === "Е" && be) {
            snew = "Ye";
            be = true;
        } else if (sold === "е" && be) {
            snew = "ye";
            be = true;
        } else if (sold === "Ё" && !be) {
            snew = "E";
            be = true;
        } else if (sold === "ё" && !be) {
            snew = "e";
            be = true;
        } else if (sold === "Ё" && be) {
            snew = "Ye";
            be = true;
        } else if (sold === "ё" && be) {
            snew = "ye";
            be = true;
        } else if (sold === "Ж") {
            snew = "Zh";
            be = false;
        } else if (sold === "ж") {
            snew = "zh";
            be = false;
        } else if (sold === "З") {
            snew = "Z";
            be = false;
        } else if (sold === "з") {
            snew = "z";
            be = false;
        } else if (sold === "И") {
            snew = "I";
            be = true;
        } else if (sold === "и") {
            snew = "i";
            be = true;
        } else if (sold === "І") {
            snew = "I";
            be = true;
        } else if (sold === "і") {
            snew = "i";
            be = true;
        } else if (sold === "Й") {
            snew = "I";
            be = false;
        } else if (sold === "й") {
            snew = "i";
            be = false;
        } else if (sold === "К") {
            snew = "K";
            be = false;
        } else if (sold === "к") {
            snew = "k";
            be = false;
        } else if (sold === "Қ") {
            snew = "K";
            be = false;
        } else if (sold === "қ") {
            snew = "k";
            be = false;
        } else if (sold === "Л") {
            snew = "L";
            be = false;
        } else if (sold === "л") {
            snew = "l";
            be = false;
        } else if (sold === "М") {
            snew = "M";
            be = false;
        } else if (sold === "м") {
            snew = "m";
            be = false;
        } else if (sold === "Н") {
            snew = "N";
            be = false;
        } else if (sold === "н") {
            snew = "n";
            be = false;
        } else if (sold === "Ң") {
            snew = "N";
            be = false;
        } else if (sold === "ң") {
            snew = "n";
            be = false;
        } else if (sold === "О") {
            snew = "O";
            be = true;
        } else if (sold === "о") {
            snew = "o";
            be = true;
        } else if (sold === "Ө") {
            snew = "O";
            be = true;
        } else if (sold === "ө") {
            snew = "o";
            be = true;
        } else if (sold === "П") {
            snew = "P";
            be = false;
        } else if (sold === "п") {
            snew = "p";
            be = false;
        } else if (sold === "Р") {
            snew = "R";
            be = false;
        } else if (sold === "р") {
            snew = "r";
            be = false;
        } else if (sold === "С") {
            snew = "S";
            be = false;
        } else if (sold === "с") {
            snew = "s";
            be = false;
        } else if (sold === "Т") {
            snew = "T";
            be = false;
        } else if (sold === "т") {
            snew = "t";
            be = false;
        } else if (sold === "У") {
            snew = "U";
            be = true;
        } else if (sold === "у") {
            snew = "u";
            be = true;
        } else if (sold === "Ұ") {
            snew = "U";
            be = true;
        } else if (sold === "ұ") {
            snew = "u";
            be = true;
        } else if (sold === "Ү") {
            snew = "U";
            be = true;
        } else if (sold === "ү") {
            snew = "u";
            be = true;
        } else if (sold === "Ф") {
            snew = "F";
            be = false;
        } else if (sold === "ф") {
            snew = "f";
            be = false;
        } else if (sold === "Х") {
            snew = "Kh";
            be = false;
        } else if (sold === "х") {
            snew = "kh";
            be = false;
        } else if (sold === "Ц") {
            snew = "Ts";
            be = false;
        } else if (sold === "ц") {
            snew = "ts";
            be = false;
        } else if (sold === "Ч") {
            snew = "Ch";
            be = false;
        } else if (sold === "ч") {
            snew = "ch";
            be = false;
        } else if (sold === "Ш") {
            snew = "Sh";
            be = false;
        } else if (sold === "ш") {
            snew = "sh";
            be = false;
        } else if (sold === "Щ") {
            snew = "Shch";
            be = false;
        } else if (sold === "щ") {
            snew = "shch";
            be = false;
        } else if (sold === "Ъ") {
            snew = "";
            be = true;
        } else if (sold === "ъ") {
            snew = "";
            be = true;
        } else if (sold === "Ы") {
            snew = "Y";
            be = false;
        } else if (sold === "ы") {
            snew = "y";
            be = false;
        } else if (sold === "Ь") {
            snew = "";
            be = true;
        } else if (sold === "ь") {
            snew = "";
            be = true;
        } else if (sold === "Э") {
            snew = "E";
            be = true;
        } else if (sold === "э") {
            snew = "e";
            be = true;
        } else if (sold === "Ю") {
            snew = "Yu";
            be = true;
        } else if (sold === "ю") {
            snew = "yu";
            be = true;
        } else if (sold === "Я") {
            snew = "Ya";
            be = true;
        } else if (sold === "я") {
            snew = "ya";
            be = true;
        } else {
            snew = sold;
            be = false;
        }
        result += snew;
    });

    return result.toUpperCase();
};

export const downloadBase64File = (result: any, name: any, type: any) => {
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.setAttribute("type", "hidden");
    link.href = "data:" + type + ";base64," + result;
    link.download = name;
    link.click();
    document.body.removeChild(link);
};

export function checkIINBIN(iinbin: string): { isValid: boolean, type: string, errMsg: string } {

    var type = iinbin?.substring(4, 5);

    switch (type) {

        case "0": case "1": case "2": case "3":

            // Проверка дата рождения (0-6 digits)
            var bd = iinbin.substring(0, 6);
            var date = moment(bd, 'YYMMDD').isValid();
            if (!date) return { isValid: false, type: "", errMsg: "Неправильная дата рождения, 1 по 6 цифры" };

            // Проверка пол и век рождения (7 digit)
            var sex = iinbin.substring(6, 7);
            if (!(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].some(i => i === sex))) return { isValid: false, type: "", errMsg: "Неправильный пол и век рождения, 7 цифра" };

            // Проверяем контрольный разряд
            if (!checkControlRazryad(iinbin)) return { isValid: false, type: "", errMsg: "ИИН контрольный разряд не совпадает, 12 цифра" };

            break;

        case "4": case "5":

            // Проверка дата государственной регистрации (0-4) digits
            var regd = iinbin.substring(0, 4);
            var regdate = moment(regd, 'YYMM').isValid();
            if (!regdate) return { isValid: false, type: "", errMsg: "Неправильная дата рождения, 1 по 4 цифры" };

            // Проверка тип признака (6 digit)
            var typeDp = iinbin.substring(5, 6);
            if (!(["0", "1", "2", "4"].some(i => i === typeDp))) return { isValid: false, type: "", errMsg: "Не определился доп признак к типу, 6 цифра" };

            // Проверяем контрольный разряд 
            if (!checkControlRazryad(iinbin)) return { isValid: false, type: "", errMsg: "БИН контрольный разряд не совпадает, 12 цифра" };

            break;

        case "6":
            return { isValid: true, type: "", errMsg: "ИПС в разработке, 5 цифра" };

        default:
            return { isValid: false, type: "", errMsg: "Не найден тип клиента, 5 цифра" };
    }

    return { isValid: true, type, errMsg: "" };
}

function checkControlRazryad(iinbiin: string) {

    var b1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    var b2 = [3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2];
    var a: number[] = [];
    var controll = 0;

    for (var i = 0; i < 12; i++) {
        a[i] = parseInt(iinbiin.substring(i, i + 1));
        if (i < 11) controll += a[i] * b1[i];
    }
    controll = controll % 11;

    if (controll == 10) {
        // alert("Расчет контрольного разряда производится с другой последовательностью весов");
        controll = 0;
        for (var i = 0; i < 11; i++)
            controll += a[i] * b2[i];
        controll = controll % 11;
    }

    if (controll != a[11]) return false;

    return true;
}

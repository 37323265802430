import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Queue, Role, VariableFilter, VariableView } from '@/api/CamundaController';
import { Box, Button, Card, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import { RolePicker } from '@/components';
import { Link, useParams } from 'react-router-dom';
import { AddCircle, CancelPresentation, KeyboardArrowUp } from '@mui/icons-material';
import { api } from '@/api/Api';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Delete } from '@mui/icons-material';
import processManager from '@/core/helpers/ProcessManager';
import iassign from 'immutable-assign';
import i18n from '@/i18nextConf';
import { useTranslation } from 'react-i18next';

export const variableOperators = [
    { key: "Equals", value: "=" },
    { key: "NotEquals", value: "!=" },
    { key: "GreaterThan", value: ">" },
    { key: "GreaterThanOrEquals", value: ">=" },
    { key: "LessThan", value: "<" },
    { key: "LessThanOrEquals", value: "<=" },
    { key: "Like", value: "%" },
    { key: "NotLike", value: "!%" },
];

export const prebuiltViews = () => [
    { title: i18n.t("queue.create.status"), value: "m.taskName" },
    { title: i18n.t("queue.create.processName"), value: "m.processName" },
    { title: i18n.t("queue.create.createDate"), value: "m.created" },
    { title: i18n.t("queue.create.assignee"), value: "m.assignee" },
    { title: i18n.t("queue.create.processId"), value: "m.processInstanceId" }
];

const QueueCreate = (props: any) => {
    const { id, ord } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [model, setModel] = useState(new Queue({ ord: parseInt(ord || '0') + 1 }));
    const [dense] = React.useState(true);

    const { formState } = props;

    useEffect(() => {
        if (formState && id) {
            api.camunda.getQueue(parseInt(id || '0')).then((m) => {
                setModel(m);
            });
        }
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();
        api.camunda.registerQueue(model).then(() => {
            processManager.openSnackBar(formState ? t("queue.create.successupdate") : t("queue.create.successcreate"));
            navigate('/queues');
        });
    };

    const bindRole = (role: Role) => {
        if (!model.roles?.some(r => r.code == role.code)) {
            api.camunda.bindQueueRole(model.id, role.code).then(() => {
                processManager.openSnackBar(t("queue.create.bindsuccess"));
                setModel(
                    iassign(
                        model,
                        (c) => c.roles,
                        (p) => {
                            p?.push(role);
                            return p;
                        }
                    )
                );
            });
        }
    };

    const unbindRole = (role: Role) => {
        api.camunda.unbindQueueRole(model.id, role.code).then(() => {
            processManager.openSnackBar(t("queue.create.unbindsuccess"));
            setModel(
                iassign(
                    model,
                    (c) => c.roles,
                    (p) => p?.filter((m) => m.code !== role.code)
                )
            );
        });
    };

    const changeOrder = (index: number) => {
        const copy = [...model.view];
        const buff = copy[index - 1];
        copy[index - 1] = copy[index];
        copy[index] = buff;
        setModel({ ...model, view: copy });
    };

    // const bindUser = (user: User) => {
    //     if (!model.users?.some(r => r.login == user.login)) {
    //         api.camunda.bindQueueUser(model.id, user.login).then(() => {
    //             processManager.openSnackBar(t("queue.create.bindsuccess"));
    //             setModel(
    //                 iassign(
    //                     model,
    //                     (c) => c.users,
    //                     (p) => {
    //                         p?.push(user);
    //                         return p;
    //                     }
    //                 )
    //             );
    //         });
    //     }
    // };

    // const unbindUser = (user: User) => {
    //     api.camunda.unbindQueueUser(model.id, user.login).then(() => {
    //         processManager.openSnackBar(t("queue.create.bindsuccess"));
    //         setModel(
    //             iassign(
    //                 model,
    //                 (c) => c.users,
    //                 (p) => p?.filter((m) => m.login !== user.login)
    //             )
    //         );
    //     });
    // };

    return (
        <Grid container spacing={2}>
            <Grid xs={12} md={8} lg={8}>
                <Toolbar>
                    <Button variant='contained' component={Link} to='/queues' startIcon={<CancelPresentation />}>{t("queue.create.cancel")}</Button>
                </Toolbar>
            </Grid>
            {/* Chart */}
            <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <Grid sx={{ p: 2 }}>
                        <Typography component='h2' variant='h6'>
                            {formState ? t("queue.create.qupdate") : t("queue.create.qcreate")}
                        </Typography>
                        <br />
                        <Box component='form' noValidate onSubmit={onSubmit} sx={{ width: '100%' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={t("queue.create.name")}
                                        placeholder={t("queue.create.namehelper") || ''}
                                        value={model.name}
                                        onChange={(e) =>
                                            setModel({ ...model, name: e.target.value })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        value={model.process}
                                        label='bpmn'
                                        placeholder='client,organization'
                                        helperText='process ID'
                                        onChange={(e) =>
                                            setModel({ ...model, process: e.target.value })
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <br />
                            <Grid item xs={12} sm={12} lg={12}>
                                <Typography component='h2' variant='h6'>
                                    Filter{' '}<IconButton
                                        onClick={() =>
                                            setModel(
                                                iassign(
                                                    model,
                                                    (c: Queue) => c.filter,
                                                    (p) => {
                                                        p?.push(new VariableFilter());
                                                        return p;
                                                    }
                                                )
                                            )
                                        }><AddCircle /></IconButton>
                                </Typography>
                            </Grid>
                            <br />
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby='tableTitle'
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <TableBody>
                                        {model?.filter?.map((m, i) => (
                                            <TableRow key={i}>
                                                <TableCell >
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        required
                                                        value={m.key}
                                                        label={t("queue.create.key")}
                                                        placeholder='currentActivityId'
                                                        onChange={(e) =>
                                                            setModel(
                                                                iassign(
                                                                    model,
                                                                    (c: Queue) => c?.filter?.[i]?.key,
                                                                    () => e.target.value
                                                                )
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell >
                                                    <FormControl fullWidth size='small'>
                                                        <InputLabel id={`filter-${i}-label`}>{t("queue.create.operator")}</InputLabel>
                                                        <Select
                                                            size='small'
                                                            required
                                                            labelId={`filter-${i}-label`}
                                                            value={m.operator}
                                                            label={t("queue.create.operator")}
                                                            onChange={(e) =>
                                                                setModel(
                                                                    iassign(
                                                                        model,
                                                                        (c: Queue) => c?.filter?.[i]?.operator,
                                                                        () => e.target.value
                                                                    )
                                                                )
                                                            }
                                                        >
                                                            {variableOperators.map((item, index) => (
                                                                <MenuItem key={index} value={item.key}>{item.value}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell >
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        value={m.value}
                                                        label={t("queue.create.value")}
                                                        onChange={(e) =>
                                                            setModel(
                                                                iassign(
                                                                    model,
                                                                    (c: Queue) => c?.filter?.[i]?.value,
                                                                    () => e.target.value
                                                                )
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell valign='middle'>
                                                    <IconButton aria-label='delete'
                                                        onClick={() =>
                                                            setModel(
                                                                iassign(
                                                                    model,
                                                                    (c: Queue) => c.filter,
                                                                    (p) => {
                                                                        p?.splice(i, 1);
                                                                        return p;
                                                                    }
                                                                )
                                                            )
                                                        }
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <br />
                            <Grid item xs={12} sm={12} lg={12}>
                                <Typography component='h2' variant='h6'>
                                    View{' '}<IconButton
                                        onClick={() =>
                                            setModel(
                                                iassign(
                                                    model,
                                                    (c: Queue) => c.view,
                                                    (p) => {
                                                        p?.push(new VariableView());
                                                        return p;
                                                    }
                                                )
                                            )
                                        }><AddCircle /></IconButton>
                                </Typography>
                                <Typography component='h6'>
                                    {t("queue.create.stolbecnomer")}
                                </Typography>
                            </Grid>
                            <br />
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby='tableTitle'
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <TableBody>
                                        {model?.view?.map((m, i) => (
                                            <TableRow key={i}>
                                                <TableCell>
                                                    {!m.builtIn &&
                                                        <TextField
                                                            fullWidth
                                                            size='small'
                                                            value={m.title}
                                                            label={t("queue.create.colname")}
                                                            onChange={(e) =>
                                                                setModel(
                                                                    iassign(
                                                                        model,
                                                                        (c: Queue) => c?.view?.[i].title,
                                                                        () => e.target.value
                                                                    )
                                                                )
                                                            }
                                                        />
                                                    }
                                                    {m.builtIn &&
                                                        <FormControl size='small' fullWidth>
                                                            <InputLabel id="view-label">{t("queue.create.colname")}</InputLabel>
                                                            <Select
                                                                labelId="search-by-label"
                                                                size='small'
                                                                value={m.title}
                                                                label={t("queue.create.colname")}
                                                                required
                                                                onChange={(e) => {
                                                                    if (e.target.value === "keyval") {
                                                                        setModel(
                                                                            iassign(
                                                                                model,
                                                                                (c: Queue) => c?.view?.[i].builtIn,
                                                                                () => false
                                                                            )
                                                                        );
                                                                    } else {
                                                                        setModel(
                                                                            iassign(
                                                                                model,
                                                                                (c: Queue) => c?.view?.[i],
                                                                                () => new VariableView({ title: e.target.value, key: prebuiltViews().find(p => p.title === e.target.value)?.value || '' })
                                                                            )
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                {prebuiltViews().map((p, i) => <MenuItem key={i} value={p.title}>{p.title}</MenuItem>)}
                                                                <MenuItem value="keyval">{t("queue.create.keyval")}</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    }
                                                </TableCell>
                                                <TableCell >
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        disabled={m.builtIn}
                                                        value={m.key}
                                                        label={t("queue.create.modeldata")}
                                                        onChange={(e) =>
                                                            setModel(
                                                                iassign(
                                                                    model,
                                                                    (c: Queue) => c?.view?.[i].key,
                                                                    () => e.target.value
                                                                )
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell valign='middle'>
                                                    {i != 0 &&
                                                        <IconButton aria-label="delete"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                changeOrder(i);
                                                            }}>
                                                            <KeyboardArrowUp />
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                                <TableCell valign='middle'>
                                                    <IconButton aria-label='delete'
                                                        onClick={() =>
                                                            setModel(
                                                                iassign(
                                                                    model,
                                                                    (c: Queue) => c.view,
                                                                    (p) => {
                                                                        p?.splice(i, 1);
                                                                        return p;
                                                                    }
                                                                )
                                                            )
                                                        }
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br />
                            <Card sx={{ p: 2 }}>
                                {/* <FormGroup>
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={model.distributedAssign}
                                            onChange={(e: any) =>
                                                setModel({
                                                    ...model,
                                                    distributedAssign: !model.distributedAssign,
                                                    distributedTask: false,
                                                    distributedAdmin: false,
                                                    readOnly: false
                                                })
                                            } />} label={t("queue.create.distrassign")} />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={model.distributedTask}
                                            onChange={(e: any) =>
                                                setModel({
                                                    ...model,
                                                    distributedTask: !model.distributedTask,
                                                    distributedAssign: false,
                                                    distributedAdmin: false,
                                                    readOnly: false
                                                })
                                            } />} label={t("queue.create.distrtask")} />
                                </FormGroup> */}
                                <FormGroup>
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={model.distributedAdmin}
                                            onChange={() =>
                                                setModel({
                                                    ...model,
                                                    distributedAdmin: !model.distributedAdmin,
                                                    distributedAssign: false,
                                                    distributedTask: false,
                                                    readOnly: false
                                                })
                                            } />} label={t("queue.create.distradmin")} />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={model.readOnly}
                                            onChange={() =>
                                                setModel({
                                                    ...model,
                                                    readOnly: !model.readOnly,
                                                    distributedAdmin: false,
                                                    distributedAssign: false,
                                                    distributedTask: false,
                                                    showAssignee: !model.readOnly,
                                                    showSla: !model.readOnly,
                                                    showHistory: !model.readOnly,
                                                    showComments: !model.readOnly
                                                })
                                            } />} label={t("queue.create.readonly")} />
                                </FormGroup>
                                {model.readOnly &&
                                    <React.Fragment>
                                        <Divider />
                                        <FormGroup>
                                            <FormControlLabel control={
                                                <Checkbox
                                                    checked={model.showAssignee}
                                                    onChange={() =>
                                                        setModel({
                                                            ...model,
                                                            showAssignee: !model.showAssignee
                                                        })
                                                    } />} label={t("queue.create.showAssignee")} />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormControlLabel control={
                                                <Checkbox
                                                    checked={model.showSla}
                                                    onChange={() =>
                                                        setModel({
                                                            ...model,
                                                            showSla: !model.showSla
                                                        })
                                                    } />} label={t("queue.create.showSla")} />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormControlLabel control={
                                                <Checkbox
                                                    checked={model.showHistory}
                                                    onChange={() =>
                                                        setModel({
                                                            ...model,
                                                            showHistory: !model.showHistory
                                                        })
                                                    } />} label={t("queue.create.showSla")} />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormControlLabel control={
                                                <Checkbox
                                                    checked={model.showComments}
                                                    onChange={() =>
                                                        setModel({
                                                            ...model,
                                                            showComments: !model.showComments
                                                        })
                                                    } />} label={t("queue.create.showComments")} />
                                        </FormGroup>
                                    </React.Fragment>
                                }
                            </Card>
                            <br />
                            {formState &&
                                <React.Fragment>
                                    <Grid xs={12} sm={6} item sx={{ pt: 2, pb: 2 }}>
                                        <b>{t("queue.create.bindroles")} </b>
                                        <Stack direction='row' spacing={0} sx={{ flexWrap: 'wrap', gap: 1 }}>
                                            {model.roles?.map((r, i) => <Chip key={i} label={r?.description} variant='outlined' onDelete={() => unbindRole(r)} />)}
                                        </Stack>
                                    </Grid>
                                    <Divider />
                                    <Grid xs={12} sm={6} item sx={{ pt: 2 }}>
                                        <RolePicker onSelectedItem={(role) => bindRole(role)} />
                                    </Grid>
                                </React.Fragment>
                            }
                            <br />
                            {/* {formState &&
                                <React.Fragment>
                                    <Grid xs={12} sm={6} item sx={{ pt: 2, pb: 2 }}>
                                        <b>{t("queue.create.bindusers")} </b>
                                        <Stack direction='row' spacing={0} sx={{ flexWrap: 'wrap', gap: 1 }}>
                                            {model.users?.map((u, i) => <Chip key={i} label={u?.fullName} variant='outlined' onDelete={() => unbindUser(u)} />)}
                                        </Stack>
                                    </Grid>
                                    <Divider />
                                    <Grid xs={12} sm={6} item sx={{ pt: 2 }}>
                                        <UserPicker onSelectedItem={(user) => bindUser(user)} />
                                    </Grid>
                                </React.Fragment>
                            } */}
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {t("queue.create.save")}
                            </Button>
                        </Box>
                    </Grid>
                </Paper>
            </Grid >
        </Grid >);
};

export default QueueCreate;
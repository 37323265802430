import { Routes, Route } from "react-router-dom";
import ClientRequest from "./ClientRequest";
import { Error404 } from "..";
import CashierRequest from "./CashierRequest";
import KassaDay from './KassaDay';
import authorizedLayout from "@/components/AuthorizedLayout";

const Processes = () => (
  <Routes>
    <Route
      path="/client_request/*"
      element={<ClientRequest />}
      errorElement={<Error404 />}
    />
    <Route
      path="/cashier_request/*"
      element={authorizedLayout(CashierRequest)}
      errorElement={<Error404 />}
    />
    <Route
      path="/kassa_day/*"
      element={authorizedLayout(KassaDay)}
      errorElement={<Error404 />}
    />
  </Routes>
);

export default Processes;

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { User } from '@/api/CamundaController';
import { Box, Button, FormControlLabel, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { api } from '@/api/Api';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HistoricTaskInstance } from '@/api/HistoryController';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


const TaskHistoryList = () => {
    const { t } = useTranslation();
    const me = useSelector((state: any) => state?.header?.userContext?.user as User);
    const { businessKey } = useParams();
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([] as HistoricTaskInstance[]);
    const [dense, setDense] = React.useState(true);

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    useEffect(() => {
        if (businessKey != null) {
            api.history
                .getProcessTasks(businessKey)
                .then((m) => {
                    setTasks(m);
                });
        }
    }, []);

    const onClick = (task: HistoricTaskInstance) => {
        navigate(`/history/task/${task.id}`);
    };

    return (
        <Grid container spacing={2}>
            <Grid>
                <Toolbar>
                    <Button variant="contained" component={Link} to='/history' startIcon={<ArrowBack />}>{t("history.back")}</Button>
                </Toolbar>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("history.taskname")}</TableCell>
                                        <TableCell>{t("history.actid")}</TableCell>
                                        <TableCell>{t("history.process")}</TableCell>
                                        <TableCell>{t("history.createDate")}</TableCell>
                                        <TableCell>{t("history.donedate")}</TableCell>
                                        <TableCell>{t("assignee")}</TableCell>
                                        <TableCell>{t("history.status")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tasks.map((task, i) => (
                                        <TableRow key={i} onClick={() => onClick(task)}>
                                            <TableCell>{task.name}</TableCell>
                                            <TableCell>{task.taskDefinitionKey}</TableCell>
                                            <TableCell>{task.processDefinitionKey}</TableCell>
                                            <TableCell>{task.startTime && moment.tz(task.startTime, me.gmt || '').toLocaleString()}</TableCell>
                                            <TableCell>{task.endTime && moment.tz(task.endTime, me.gmt || '').toLocaleString()}</TableCell>
                                            <TableCell>{task.assignee}</TableCell>
                                            <TableCell>{task.deleteReason}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br />
                    </Paper>
                    <FormControlLabel
                        control={<Switch checked={dense} onChange={handleChangeDense} />}
                        label={t("history.tight")}
                    />
                </Box>
            </Grid>
        </Grid>);
};

export default TaskHistoryList;
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

export interface PopupConfirmProps {
    open?: boolean;
    handleAgree?: () => void;
    handleDisagreeOrClose?: () => void;
    title?: string;
    text?: string;
    textAgreeButton?: string;
    textCancelButton?: string;
}

const PopupConfirm = (props: PopupConfirmProps) => {
    const { t } = useTranslation();
    const { open, handleAgree, handleDisagreeOrClose, title, text, textAgreeButton, textCancelButton } = props;
    const SelfHandleAgree = () => {
        if (handleAgree) {
            handleAgree();
        }
    };
    const SelfHandleDisagreeOrClose = () => {
        if (handleDisagreeOrClose) {
            handleDisagreeOrClose();
        }
    };
    return (
        <Dialog
            open={open ?? false}
            onClose={handleDisagreeOrClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title ?? t("confirm.attention")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {text ?? t("confirm.agree")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={SelfHandleDisagreeOrClose}>{textCancelButton ?? t("confirm.cancel")}</Button>
                <Button onClick={SelfHandleAgree} autoFocus>
                    {textAgreeButton ?? t("confirm.ok")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PopupConfirm;
import { server } from '../core/server/axios';
import { Task, VariableFilter } from './CamundaController';

const wenv = process.env;

export interface ProcessInstanceRequest {
  processDefinitionKey?: string;
  businessKey?: string;
  company?: string;
  completed?: boolean;
}

export class HistoricProcessInstance {
  id?: string;
  businessKey?: string;
  processDefinitionKey?: string;
  processDefinitionId?: string;
  processDefinitionName?: string;
  processDefinitionVersion?: string;
  startTime?: string;
  endTime?: string;
  removalTime?: string;
  durationInMillis?: number;
  endActivityId?: string;
  startUserId?: string;
  startActivityId?: string;
  deleteReason?: string;
  superProcessInstanceId?: string;
  rootProcessInstanceId?: string;
  tenantId?: string;
  state?: string;
}

export class HistoricTaskInstance {
  id?: string;
  processDefinitionKey?: string;
  processDefinitionId?: string;
  rootProcessInstanceId?: string;
  processInstanceId?: string;
  executionId?: string;
  activityInstanceId?: string;
  name?: string;
  description?: string;
  deleteReason?: string;
  owner?: string;
  assignee?: string;
  startTime?: string;
  endTime?: string;
  durationInMillis?: number;
  taskDefinitionKey?: string;
  priority?: number;
  dueDate?: string;
  parentTaskId?: string;
  followUpDate?: string;
  tenantId?: string;
  removalTime?: string;
}

export class HistoryController {

  async getProcessInstances(
    pageNumber: number,
    pageSize: number,
    request: Array<VariableFilter>
  ): Promise<Array<HistoricProcessInstance>> {
    return server.post(
      `/sonata/api/history/process-instances/${pageNumber}/${pageSize}`,
      request,
      {
        baseURL: wenv.REACT_APP_BACKEND,
      }
    );
  }

  async getProcessTasks(businessKey?: string): Promise<Array<HistoricTaskInstance>> {
    return await server.get(`/sonata/api/history/process-tasks/${businessKey}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async getTask(taskId?: string): Promise<Task> {
    return await server.get(`/sonata/api/history/task/${taskId}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

}

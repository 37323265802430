import { useMemo } from "react";

import { SelectDepartments } from '@/components';
import { DepartmentTreasure, ModalSelectCurrency, ModalCurrencyAction, ModalDeleteCurrency } from '../../components';
import { useTreasurePage } from './useTreasurePage';

const TreasurePage = () => {
	const {
		activeDepartment,
		viewOptomRate,
		setViewOptomRate,
		handleOpenAddCurrencyWindow,
		handleOpenDeleteCurrencyWindow,
		clearEditCurrencies,
		editCurrencies,
		updateCurrencies,
		handleOpenEditCurrencyWindow,
		removeEditCurrency,
		migRatesList,
		bccRatesList,
		departmentStatus,
		openWindow,
		handleCloseWindow,
		activeCurrency,
		newCurrency,
		regions,
		handleOpenSelectNewCurrencyWindow,
		handleChangeNewCurrency,
		handleGetDepartmentList,
		addEditCurrencies,
		handleDeleteCurrency,
		role,
	} = useTreasurePage();

	const filtredFilialsById = useMemo(() =>
		activeDepartment &&
		<DepartmentTreasure
			department={activeDepartment}
			viewOptomRate={viewOptomRate}
			setViewOptomRate={setViewOptomRate}
			handleOpenSelectNewCurrencyWindow={handleOpenSelectNewCurrencyWindow}
			handleOpenDeleteCurrencyWindow={handleOpenDeleteCurrencyWindow}
			clearEditCurrencies={clearEditCurrencies}
			editCurrencies={editCurrencies}
			updateCurrencies={updateCurrencies}
			handleOpenEditCurrencyWindow={handleOpenEditCurrencyWindow}
			removeEditCurrency={removeEditCurrency}
			migRatesList={migRatesList}
			bccRatesList={bccRatesList}
			isAdmin={role === null}
		/>
		, [activeDepartment, viewOptomRate, editCurrencies, migRatesList, bccRatesList]);

	return (
		<>
			{openWindow === 'selectCurrency' && (
				<ModalSelectCurrency
					openWindow={openWindow === 'selectCurrency'}
					handleCloseWindow={handleCloseWindow}
					newCurrency={newCurrency}
					handleOpenAddCurrencyWindow={handleOpenAddCurrencyWindow}
					handleChangeNewCurrency={handleChangeNewCurrency}
				/>
			)}

			{openWindow === 'deleteCurrency' && activeCurrency && (
				<ModalDeleteCurrency
					openWindow={openWindow === 'deleteCurrency'}
					activeCurrency={activeCurrency}
					handleCloseWindow={handleCloseWindow}
					handleDeleteCurrency={handleDeleteCurrency}
				/>
			)}

			{
				(openWindow === "addCurrency" || openWindow === "editCurrency") &&
				<ModalCurrencyAction
					openWindow={openWindow}
					handleCloseWindow={handleCloseWindow}
					activeDepartment={activeDepartment}
					newCurrency={newCurrency}
					handleGetDepartmentList={handleGetDepartmentList}
					addEditCurrencies={addEditCurrencies}
					bccRatesList={bccRatesList}
				/>
			}

			{departmentStatus === "resolve" && (
				<>
					{editCurrencies?.length === 0 && (
						<SelectDepartments regions={regions} isSelectKassa={false} />
					)}

					{filtredFilialsById}
				</>
			)}
		</>
	);
};

export default TreasurePage;
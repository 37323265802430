import { HistoricProcessAndTaskInstances, Task } from '@/api/CamundaController';
import { PopupConfirmProps } from '@/components/PopupConfirm';
import { PopupBackDropProps } from '@/components/PopupBackDrop';
import iassign from 'immutable-assign';
import { PopupSnackBarProps } from '@/components/PopupSnackBar';

const START_LOADING = 'app/Process/START_LOADING';
const STOP_LOAIDING = 'app/Process/STOP_LOAIDING';
const UPDATE_TASK_MODEL = 'app/Process/UPDATE_TASK_MODEL';
const UPDATE_TASK_HISTORY = 'app/Process/UPDATE_TASK_HISTORY';
const UPDATE_TASK_BUTTON = 'app/Process/UPDATE_TASK_BUTTON';
const UPDATE_FLOW_BUTTON = 'app/Process/UPDATE_FLOW_BUTTON';
const OPEN_SNACKBAR = 'app/Process/OPEN_SNACKBAR';
const CLOSE_SNACKBAR = 'app/Process/CLOSE_SNACKBAR';
const UPDATE_TASK_VARIABLES = 'app/Process/UPDATE_TASK_VARIABLES';
const UPDATE_DEFINITION_KEY = 'app/Process/UPDATE_DEFINITION_KEY';
const OPEN_BACKDROP = 'app/Process/OPEN_BACKDROP';
const CLOSE_BACKDROP = 'app/Process/CLOSE_BACKDROP';
const OPEN_COMMENT = 'app/Process/OPEN_COMMENT';
const CLOSE_COMMENT = 'app/Process/CLOSE_COMMENT';
const OPEN_CONFIRM = 'app/Process/OPEN_CONFIRM';
const CLOSE_CONFIRM = 'app/Process/CLOSE_CONFIRM';

export interface ProcessReducer {
  isLoading: boolean;
  taskModel: Task;
  history: HistoricProcessAndTaskInstances;
  snackBar: PopupSnackBarProps;
  backdrop?: PopupBackDropProps;
  confirm?: PopupConfirmProps
  openComment?: boolean;
}

export interface TaskButton {
  forth?: boolean;
  back?: boolean;
  exit?: boolean;
  retry?: boolean;
  save?: boolean;
}

const wenv = (process as any).env;

const initialState: ProcessReducer = {
  isLoading: false,
  taskModel: {
    variables: {
      depCode: '',
      button: {
        forth: true,
        back: false
      },
      login: 'COLVIR',
      env: wenv,
      files: [],
      comments: []
    }
  },
  history: new HistoricProcessAndTaskInstances(),
  snackBar: {
    open: false,
    message: null,
    severity: 'error',
    location: { vertical: 'top', horizontal: 'right' }
  },
  backdrop: { open: false, text: '' }
};

export default function process(state: ProcessReducer = initialState, action: any = {}) {
  let _state = state;
  switch (action.type) {

    case START_LOADING:
      return { ...state, isLoading: action.payload };

    case STOP_LOAIDING:
      return { ...state, isLoading: action.payload };

    case UPDATE_TASK_MODEL:
      return { ...state, taskModel: action.payload };

    case UPDATE_TASK_VARIABLES:
      return {
        ...state,
        taskModel: {
          ...state.taskModel,
          variables: iassign(
            state.taskModel.variables,
            action.payload.getProp,
            action.payload.setProp,
            null
          )
        }
      };

    case UPDATE_TASK_HISTORY:
      return { ...state, history: action.payload };

    case UPDATE_FLOW_BUTTON:
      return {
        ...state,
        taskModel: {
          ...state.taskModel,
          variables: {
            ...state.taskModel.variables,
            button: action.payload
          }
        }
      };

    case UPDATE_TASK_BUTTON:
      return {
        ...state,
        taskModel: {
          ...state.taskModel,
          variables: {
            ...state.taskModel.variables,
            button: action.payload
          }
        }
      };

    case UPDATE_DEFINITION_KEY:
      return {
        ...state,
        taskModel: {
          ...state.taskModel,
          variables: {
            ...state.taskModel.variables,
            subProcessDefinitionKey: action.payload
          }
        }
      };

    case OPEN_SNACKBAR:
      return { ...state, snackBar: action.payload };

    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackBar: { ...state.snackBar, open: action.payload }
      };

    case OPEN_BACKDROP:
      return { ...state, backdrop: action.payload };

    case CLOSE_BACKDROP:
      return {
        ...state,
        backdrop: { ...state.backdrop, open: action.payload }
      };

    case OPEN_CONFIRM:
      return { ...state, confirm: action.payload };

    case CLOSE_CONFIRM:
      return {
        ...state,
        confirm: { ...state.confirm, open: action.payload }
      };



    case OPEN_COMMENT:
      return { ...state, openComment: action.payload };

    case CLOSE_COMMENT:
      return { ...state, openComment: action.payload };

    default:
      _state = state;
      break;
  }

  return _state;
}

export const startLoading = () => ({
  type: START_LOADING,
  payload: true
});

export const stopLoading = () => ({
  type: STOP_LOAIDING,
  payload: false
});

export const updateTaskModel = (taskModel?: Task) => ({
  type: UPDATE_TASK_MODEL,
  payload: taskModel
});

export const updateTaskHistory = (history: HistoricProcessAndTaskInstances) => ({
  type: UPDATE_TASK_HISTORY,
  payload: history
});

export const updateTaskButton = (taskButton: TaskButton) => ({
  type: UPDATE_TASK_BUTTON,
  payload: taskButton
});

export const updateFlowButton = (flowButton?: Object) => ({
  type: UPDATE_FLOW_BUTTON,
  payload: flowButton
});

export const updateSubProcessDefinitionKey = (processDefinitionKey: string) => ({
  type: UPDATE_DEFINITION_KEY,
  payload: processDefinitionKey
});

export const openSnackBar = (props: PopupSnackBarProps) => ({
  type: OPEN_SNACKBAR,
  payload: props
});

export const closeSnackBar = () => ({
  type: CLOSE_SNACKBAR,
  payload: false
});

export const openBackDrop = (text?: string, disableShrink?: boolean, size?: number) => ({
  type: OPEN_BACKDROP,
  payload: { open: true, text: text, disableShrink: disableShrink, size: size }
});

export const closeBackDrop = () => ({
  type: CLOSE_BACKDROP,
  payload: false
});

export const openConfirm = (props: PopupConfirmProps
) => ({
  type: OPEN_CONFIRM,
  payload: props
});

export const closeConfirm = () => ({
  type: CLOSE_CONFIRM,
  payload: false
});

export const updateTaskVariables = (getProp: (c: any) => any, setProp: (p: any) => any) => ({
  type: UPDATE_TASK_VARIABLES,
  payload: { getProp, setProp }
});

export const openComment = () => ({
  type: OPEN_COMMENT,
  payload: true
});

export const closeComment = () => ({
  type: CLOSE_COMMENT,
  payload: false
});

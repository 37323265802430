import axios, { AxiosRequestConfig } from 'axios';
import AuthUtil from '../helpers/AuthUtil';
import hash from "object-hash";
import processManager from '../helpers/ProcessManager';
import i18 from '@/i18nextConf';


const wenv = process.env;
const basePath = process.env.PUBLIC_URL;

interface Config {
  url: string | undefined;
  method: string | undefined;
  data: any;
}

export class Server {
  constructor() {

    axios.interceptors.request.use(
      function (config) {
        if (config.headers?.cache) {
          const _config: Config = {
            url: config.url,
            method: config.method,
            data: JSON.stringify(config.data)
          };
          const requestHash = hash(_config);
          const items = processManager.getCacheItems();
          if (processManager.hasCacheKey(requestHash)) {
            throw { isLocal: true, response: { data: items[requestHash] } };
          }
        }

        return config;
      },
      function (error) {
        return error?.isLocal
          ? Promise.resolve(error)
          : Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        const { config } = response;
        if (config.headers?.cache) {
          var _config: Config = {
            url: config.url,
            method: config.method,
            data: JSON.stringify(config.data)
          };
          var requestHash = hash(_config);
          processManager.setCacheItem(requestHash, response.data);
        }
        return response;
      },
      async function (error) {
        if (!error.response) {
          processManager.stopLoading();
        } else if (error.response.status === 401) {
          var auth = AuthUtil.getAuth();
          if (auth.token?.refreshToken) {
            const tokenData = {
              refreshToken: auth.token?.refreshToken
            };
            try {
              const result_1 = await axios
                .post('/sonata/api/auth/refresh', tokenData, {
                  baseURL: wenv.REACT_APP_BACKEND
                });
              localStorage.setItem('userContext', JSON.stringify(result_1.data)); const config = error.config as AxiosRequestConfig;
              config.headers = {};
              config.headers.Auth2 = 'Code ' + AuthUtil.getToken().auth2Token;
              config.headers.Authorization = 'Bearer ' + AuthUtil.getToken().accessToken;
              // config.data = JSON.parse(config.data);
              return await axios.request(config);
            } catch (e) {
              (window as any).location.href = `${basePath}/login`;
            }
          } else {
            localStorage.setItem('redirectUrlAfterLogin', window.location.pathname);
            (window as any).location.href = `${basePath}/login`;
          }
        } else if (error?.isLocal) {
          console.log("Came here", error);
          return Promise.resolve(error.response);
        }
        else {
          if (error.response && error.response.data && error.response.data.message) {
            processManager.openSnackBar(i18.t(error.response.data.message), { severity: "error" });
          } else if (error.response && error.response.data && error.response.data.Message) {
            processManager.openSnackBar(i18.t(error.response.data.Message), { severity: "error" });
          }

          processManager.stopLoading();
          processManager.closeBackDrop();
        }
        return Promise.reject(error);
      }
    );
  }

  public request(config: any) {
    return axios.request(config);
  }
  public get(url: string, config = {} as any): any {
    config = this.getConfig(config);
    return axios.get(url, config).then((r) => r.data);
  }
  public getFormData(url: string, data: any, config = {} as any): any {
    config = this.getConfig(config);
    const api = axios.create(config);
    return api.get(url, data).then((r) => r.data);
  }
  public getNoAuth(url: string, config = {} as any): any {
    config = config || {};
    config.headers = config.headers || {};
    config.baseURL = config.baseURL || wenv.SERVER_URL;
    return axios.get(url, config).then((r) => r.data);
  }
  public delete(url: string, config = {} as any): any {
    config = this.getConfig(config);
    return axios.delete(url, config).then((r) => r.data);
  }
  public head(url: string, config = {} as any): any {
    config = this.getConfig(config);
    return axios.head(url, config);
  }
  public token(url: string, data: any, config = {} as any) {
    config.baseURL = config.baseURL || wenv.SERVER_URL;
    return axios.post(url, data, config).then((r) => r.data);
  }
  public post(url: string, data: any, config = {} as any): any {
    config = this.getConfig(config);
    return axios.post(url, data, config).then((r) => r.data);
  }

  public postPublic(url: string, data: any, config = {} as any): any {
    config = config || {};
    config.headers = config.headers || {};
    config.baseURL = config.baseURL || wenv.SERVER_URL;
    config.headers.Authorization = 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJwdWJsaWMiLCJmdWxsTmFtZSI6IlB1YmxpYyBQdWJsaWMiLCJlbWFpbCI6InB1YmxpY0BxdWlxLmt6IiwibGFuZyI6IktBWiIsImdtdCI6IkFzaWEvQWxtYXR5IiwidGl0bGUiOiJRVUlRIiwiYXV0aCI6W3siY29kZSI6IlBVQkxJQyIsImRlc2NyaXB0aW9uIjoicHVibGljIiwiY29tbWVudHMiOiJwdWJsaWMiLCJhdXRob3JpdHkiOiJQVUJMSUMifV0sImFpbSI6ImFjY2VzcyIsInR5cGUiOiJTWVNURU0iLCJpYXQiOjE2ODMxMDA1NzUsImV4cCI6MTcxNDYzNjU3NX0.NxvIMBGSdgwiAAbgK-bRuxhtwqek5VeNAj5PuEzV8U4';
    return axios.post(url, data, config).then((r) => r.data);
  }

  public postNoAuth(url: string, data: any, config = {} as any): any {
    config = config || {};
    config.headers = config.headers || {};
    config.baseURL = config.baseURL || wenv.SERVER_URL;
    return axios.post(url, data, config).then((r) => r.data);
  }
  public put(url: string, data: any, config = {} as any): any {
    config = this.getConfig(config);
    return axios.put(url, data, config).then((r) => r.data);
  }
  public patch(url: string, data: any, config = {} as any): any {
    config = this.getConfig(config);
    return axios.patch(url, data, config).then((r) => r.data);
  }

  public getConfig(config = {} as any) {
    config = config || {};
    config.headers = config.headers || {};
    var userContext = JSON.parse(localStorage.getItem('userContext') || '{}');
    config.headers.Authorization = 'Bearer ' + (userContext.token || {}).accessToken;
    config.headers.Auth2 = 'Code ' + (userContext.token || {}).auth2Token;
    config.baseURL = config.baseURL || wenv.SERVER_URL;
    return config;
  }
}
export const server = new Server();

import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { api } from '@/api/Api';
import { Box, Button, Chip, Divider, IconButton, Input, MenuItem, Paper, Select, TextField } from '@mui/material';
import { HeaderReducer, actions } from '@/redux/modules/Header';
import { useDispatch, useSelector } from "react-redux";
import ModalFileUpload from '@/components/ModalFileUpload';
import { toDataURL } from '@/core/helpers/Utils';
import { Stack } from '@mui/system';
import { emailRegex, gmtList, langList, mediumRegex } from './UsersCreate';
import processManager from '@/core/helpers/ProcessManager';
import { User } from '@/api/CamundaController';
import { Done, Edit } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const UserProfile = () => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const header: HeaderReducer = useSelector((state: any) => state.header);

    const userContext = header.userContext || {};
    const user = userContext.user || {};

    const [modalOpen, setModalOpen] = useState(false);

    const avatar =
        user?.photo?.data && `data:image/*;base64, ${user?.photo?.data}`;

    const handleAvatarUpload = (file: File) => {
        api.camunda
            .uploadPhoto(file)
            .then(
                (response) => {
                    toDataURL(response?.uri || "").then(
                        (result) => {
                            const photoBase64 = (result as string)?.split(",")[1];
                            dispatch(actions.updateUserPhoto(photoBase64));
                            updateLocalStor({
                                photo: {
                                    ...userContext?.user?.photo,
                                    data: photoBase64,
                                }
                            });
                            setModalOpen(false);
                        },
                        (error) => {
                            console.log(error);
                            setModalOpen(false);
                        }
                    );
                },
                (error) => {
                    console.log(error);
                    setModalOpen(false);
                }
            )
            .catch((error) => {
                console.log(error);
                setModalOpen(false);
            });
    };

    const [newUser, setUser] = useState<User>(user);
    const [emailEdit, setEmailEdit] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [gmtEdit, setGmtEdit] = useState(false);
    const [langEdit, setLangEdit] = useState(false);
    const [isSetPassword, setIsSetPassword] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [password2Error, setPassword2Error] = useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    };

    const updateLocalStor = (user: Partial<User>) =>
        localStorage.setItem(
            "userContext",
            JSON.stringify({
                ...userContext,
                user: {
                    ...userContext?.user,
                    ...user
                },
            })
        );

    const handleSaveUser = () => {
        setEmailError(!emailRegex.test(newUser.email || ''));
        if (emailRegex.test(newUser.email || '')) {
            api.camunda.updateUser(newUser)
                .then(() => {
                    dispatch(actions.updateUser(newUser));
                    updateLocalStor(newUser);
                    setEmailEdit(false);
                    setGmtEdit(false);
                    setLangEdit(false);
                    processManager.openSnackBar(t("user.profile.updatesuccess"));
                })
                .catch(error => console.log(error));
        }
    };

    const handleSubmitPassword = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setPasswordError(!mediumRegex.test(password));
        setPassword2Error(password !== password2);
        if (mediumRegex.test(password) && password == password2) {
            api.camunda.ownNewPassword(oldPassword, password)
                .then(() => {
                    setIsSetPassword(false);
                    setPassword("");
                    setPassword2("");
                    processManager.openSnackBar(t("user.profile.resetsuccess"));
                })
                .catch(error => console.log(error));
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
                <ModalFileUpload
                    open={modalOpen}
                    text={t('user.profile.uploadava') || ''}
                    onFileUpload={handleAvatarUpload}
                    onClose={() => setModalOpen(false)}
                />
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <Grid sx={{ p: 2 }}>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                            <Grid xs={12} sm={6} item sx={{ pt: 2 }}>
                                <Avatar alt={user.fullName} src={avatar} sx={{ width: 56, height: 56 }} onClick={() => {
                                    setModalOpen(true);
                                }} />
                            </Grid>
                            <Grid xs={12} sm={6} item sx={{ pt: 2 }}>
                                <Typography component="h2" variant="h6">
                                    {user.fullName}
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={6} item sx={{ pt: 2 }}>
                                <b>{t("user.profile.email")}: </b>
                                {!emailEdit &&
                                    <Typography component="span">
                                        <Link href={'mailto:' + newUser.email}>{newUser.email}</Link>
                                        <IconButton onClick={() => setEmailEdit(true)}>
                                            <Edit />
                                        </IconButton>
                                    </Typography>}
                                {emailEdit &&
                                    <React.Fragment>
                                        <Input
                                            required
                                            id="email"
                                            name="email"
                                            autoComplete="email"
                                            value={newUser.email}
                                            onChange={(e) => setUser({ ...user, email: e.target.value })}
                                            error={emailError}
                                        />
                                        <IconButton onClick={handleSaveUser}>
                                            <Done color='success' />
                                        </IconButton>
                                    </React.Fragment>
                                }
                            </Grid>
                            <Grid xs={12} sm={6} item sx={{ pt: 2 }}>
                                <b>{t("user.profile.company")}: </b>
                                <Typography component="span">
                                    {newUser.company}
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={6} item sx={{ pt: 2 }}>
                                <b>{t("user.profile.gmt")}: </b>
                                {!gmtEdit &&
                                    <Typography component="span">
                                        {gmtList.find(g => g.code == newUser.gmt)?.value}
                                        <IconButton onClick={() => setGmtEdit(true)}>
                                            <Edit />
                                        </IconButton>
                                    </Typography>
                                }
                                {gmtEdit &&
                                    <React.Fragment>
                                        <Select
                                            variant='standard'
                                            size='small'
                                            labelId="gmt-select-label"
                                            id="gmt-select"
                                            value={newUser.gmt}
                                            label={t("user.profile.gmt")}
                                            onChange={(e) => setUser({ ...user, gmt: e.target.value })}
                                        >
                                            {gmtList.map((g, i) => <MenuItem key={i} value={g.code}>{g.value}</MenuItem>)}
                                        </Select>
                                        <IconButton onClick={handleSaveUser}>
                                            <Done color='success' />
                                        </IconButton>
                                    </React.Fragment>
                                }
                            </Grid>
                            <Grid xs={12} sm={6} item sx={{ pt: 2, pb: 2 }}>
                                <b>{t("user.profile.lang")}: </b>
                                {!langEdit &&
                                    <Typography component="span">
                                        {langList.find(l => l.code == newUser.lang)?.value}
                                        <IconButton onClick={() => setLangEdit(true)}>
                                            <Edit />
                                        </IconButton>
                                    </Typography>
                                }
                                {langEdit &&
                                    <React.Fragment>
                                        <Select
                                            variant='standard'
                                            size='small'
                                            labelId="lang-select-label"
                                            id="lang-select"
                                            value={newUser.lang}
                                            onChange={(e) => {
                                                setUser({ ...user, lang: e.target.value });
                                                i18n.changeLanguage(e.target.value);
                                            }}
                                        >
                                            {langList.map((g, i) => <MenuItem key={i} value={g.code}>{g.value}</MenuItem>)}
                                        </Select>
                                        <IconButton onClick={handleSaveUser}>
                                            <Done color='success' />
                                        </IconButton>
                                    </React.Fragment>
                                }
                            </Grid>
                        </Box>

                        <Divider />
                        <Grid xs={12} sm={6} item sx={{ pt: 2, pb: 2 }}>
                            <b>{t("user.profile.roles")}: </b>
                            <Stack direction="row" spacing={0} sx={{ flexWrap: 'wrap', gap: 1 }}>
                                {user.roles?.map((r, i) => <Chip key={i} label={r?.description} variant="outlined" />)}
                            </Stack>
                        </Grid>
                        <Divider />
                        <Box component="form" onSubmit={handleSubmitPassword} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Button onClick={() => setIsSetPassword(!isSetPassword)}>
                                        {t("user.profile.resettitle")}
                                    </Button>
                                </Grid>
                                {isSetPassword &&
                                    <React.Fragment>
                                        <Grid item xs={2}>
                                            <TextField
                                                size='small'
                                                required
                                                fullWidth
                                                name="oldPassword"
                                                label={t("user.profile.oldPassword")}
                                                type="password"
                                                id="oldPassword"
                                                value={oldPassword}
                                                onChange={(e) => setOldPassword(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                size='small'
                                                required
                                                fullWidth
                                                name="password"
                                                label={t("user.profile.resetpassword")}
                                                type="password"
                                                id="password"
                                                error={passwordError}
                                                helperText={passwordError ? t("user.profile.resetpassworderror") : ""}
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                size='small'
                                                required
                                                fullWidth
                                                name="password2"
                                                label={t("user.profile.resetpassword2")}
                                                type="password"
                                                id="password2"
                                                error={password2Error}
                                                helperText={password2Error ? t("user.profile.resetpassworderror2") : ""}
                                                value={password2}
                                                onChange={(e) => setPassword2(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                            >
                                                {t("user.profile.resetsave")}
                                            </Button>
                                        </Grid>
                                    </React.Fragment>
                                }
                            </Grid>
                        </Box>
                    </Grid>
                </Paper >
            </Grid >
        </Grid >
    );
};

export default UserProfile;
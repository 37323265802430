import { server } from '../core/server/axios';

const wenv = process.env;

export class MoveTokensTransitionVariables {
  processDefinitionKey = '';
  processInstanceIds: Array<string> = [];
  startTransition = '';
  cancelActivity = '';
  variables: any;
}

export class CancelProcessInstances {
  processInstanceIds: Array<string> = [];
}

export class BatchController {

  async batchTransitionVariables(request: MoveTokensTransitionVariables): Promise<any> {
    return await server.post(`/sonata/api/batch/process/transit/variables`, request, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async batchCancel(request: CancelProcessInstances): Promise<any> {
    return await server.post(`/sonata/api/batch/process/cancel`, request, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }
}

import { api } from "@/api/Api";
import { CodeValue, User, VariableFilter } from "@/api/CamundaController";
import { QuerySave } from "@/api/QueryController";
import { AddOutlined, Clear, Delete, Save, Search } from "@mui/icons-material";
import { Box, Chip, FormControl, Grid, IconButton, Input, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const variableOperators = [
    { key: "Equals", value: "=" },
    { key: "NotEquals", value: "!=" },
    { key: "GreaterThan", value: ">" },
    { key: "GreaterThanOrEquals", value: ">=" },
    { key: "LessThan", value: "<" },
    { key: "LessThanOrEquals", value: "<=" },
    { key: "Like", value: "%" },
    { key: "NotLike", value: "!%" },
];

interface TaskTableToolbarProps {
    onClickSearch: (e: any) => void;
    setSearchFilter: (filer: Array<VariableFilter>) => void;
    onClearSearch: () => void;
    searchFilter: Array<VariableFilter>;
    items: Array<CodeValue>;
    me?: User;
    queueId: number;
}

const TaskTableToolbar = (props: TaskTableToolbarProps) => {
    const { t } = useTranslation();
    const {
        onClickSearch,
        setSearchFilter,
        onClearSearch,
        searchFilter,
        items,
        me,
        queueId
    } = props;
    const [mode, setMode] = useState<"select" | "input">("select");
    const [key, setKey] = useState("");
    const [operator, setOperator] = useState<"Equals" | "NotEquals" | "GreaterThan" | "GreaterThanOrEquals" | "LessThan" | "LessThanOrEquals" | "Like" | "NotLike">("Equals");
    const [value, setValue] = useState("");
    const [query, setQuery] = useState<QuerySave>(new QuerySave({ username: me?.login, queueId: queueId }));
    const [userQueries, setUserQueries] = useState<Array<QuerySave>>([]);
    const [selectQueryMode, setSelectQueryMode] = useState(true);

    useEffect(() => {
        if (queueId) {
            setQuery({ ...query, queueId: queueId, id: 0 });
            setUserQueries([]);
            setSearchFilter([]);
            api.query.getUserQueries(me?.login || '', queueId || 0)
                .then(response => setUserQueries(response))
                .catch(error => console.log("TaskTableToolbar.getUserQueries", error));
        }
    }, [queueId]);

    const saveQuery = () => {
        api.query.create(query)
            .then(() =>
                api.query.getUserQueries(me?.login || '', queueId || 0)
                    .then(response => {
                        setUserQueries(response);
                        setQuery(response.find(r => r.name == query.name)!);
                        setSelectQueryMode(true);
                    }))
            .catch(error => console.log("TaskTableToolbar.create", error));
    };

    const deleteQuery = (id: number) => {
        api.query.delete(id)
            .then(() =>
                api.query.getUserQueries(me?.login || '', queueId || 0)
                    .then(response => {
                        setUserQueries(response);
                        if (response.length > 0) {
                            setQuery(response[0]);
                        } else {
                            setQuery({ ...query, queueId: queueId, id: 0 });
                        }
                        setSelectQueryMode(true);
                    }))
            .catch(error => console.log("TaskTableToolbar.delete", error));
    };

    return (
        <Box component="form" noValidate onSubmit={onClickSearch}>
            <Grid container item sx={{ p: 1 }}>

                {selectQueryMode &&
                    <FormControl fullWidth variant="standard" sx={{ mb: 1 }}>
                        <Select
                            size='small'
                            value={query.id}
                            onChange={(e) => {
                                var q = userQueries.find(u => u.id == e.target.value) || new QuerySave();
                                setQuery(q);
                                setSearchFilter(q.filter);
                            }}
                            endAdornment={
                                query.id > 0 ?
                                    <IconButton sx={{ mr: 2 }} onClick={() => deleteQuery(query.id)}>
                                        <Delete />
                                    </IconButton>
                                    :
                                    <React.Fragment></React.Fragment>
                            }
                        >
                            {userQueries.map((q, i) => <MenuItem key={i} value={q.id}>{q.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                }
                {!selectQueryMode &&
                    <FormControl fullWidth variant="standard" sx={{ mb: 1 }}>
                        <Input value={query.name}
                            onChange={
                                (e) => setQuery({ ...query, name: e.target.value })
                            }
                            endAdornment={
                                <IconButton onClick={saveQuery}>
                                    <Save />
                                </IconButton>} />
                    </FormControl>
                }


                {searchFilter.length > 0 &&
                    <FormControl fullWidth size='small'>
                        <Stack direction='row' spacing={0} sx={{ flexWrap: 'wrap', gap: 1 }}>
                            {searchFilter.map((k, i) =>
                                <Chip
                                    key={variableOperators.find(v => v.key == k.operator)?.key}
                                    label={`${k.key} ${variableOperators.find(v => v.key == k.operator)?.value} ${k.value}`}
                                    onDelete={() => {
                                        const filter = [...searchFilter];
                                        var newFilter = filter.filter((f, fi) => fi != i);
                                        setSearchFilter(newFilter);
                                        setQuery({ ...query, filter: newFilter });
                                        setSelectQueryMode(false);
                                    }} />
                            )}
                        </Stack>
                    </FormControl>
                }
            </Grid>
            <Grid container item sx={{ p: 1 }}>
                <Grid item sm={2} xs={2}>
                    {mode == "select" &&
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="search-by-label">{t("task.searchby")}</InputLabel>
                            <Select
                                labelId="search-by-label"
                                size='small'
                                value={key}
                                label={t("task.searchby")}
                                required
                                onChange={(e) => {
                                    if (e.target.value == "keyval") {
                                        setMode("input");
                                    } else {
                                        setKey(e.target.value);
                                    }
                                }}
                            >
                                {items.map((m, i) => <MenuItem key={i} value={m.code}>{m.value}</MenuItem>)}
                                <MenuItem value="keyval">{t("task.codeval")}</MenuItem>
                            </Select>
                        </FormControl>
                    }
                    {mode == "input" &&
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <TextField
                                focused
                                size='small'
                                variant="standard"
                                value={key}
                                label={t("task.searchby")}
                                required
                                onChange={(e) => setKey(e.target.value)}
                            />
                        </FormControl>
                    }
                </Grid>
                <Grid item sm={2} xs={2}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="operator-by-label">{t("task.operator")}</InputLabel>
                        <Select
                            labelId="operator-by-label"
                            size='small'
                            value={operator}
                            label={t("task.operator")}
                            required
                            onChange={(e: any) => setOperator(e.target.value)}
                        >
                            {variableOperators.map((item, index) => (
                                <MenuItem key={index} value={item.key}>{item.value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={2} xs={2}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <TextField
                            fullWidth
                            size='small'
                            value={value}
                            label={t("task.value")}
                            variant="standard"
                            required
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={2} xs={2}>
                    <Stack spacing={1} direction='row' sx={{ m: 2 }}>
                        <IconButton onClick={() => {
                            if (key.length > 0) {
                                const filter = [...searchFilter];
                                if (!filter.some(f => f.key == key)) {
                                    filter.push({ key: key, operator: operator || "Equals", value: value });
                                    setSearchFilter(filter);
                                    setQuery({ ...query, filter: filter });
                                    setSelectQueryMode(false);
                                } else {
                                    const newFilter = filter.filter(f => f.key != key);
                                    newFilter.push({ key: key, operator: operator || "Equals", value: value });
                                    setSearchFilter(newFilter);
                                    setQuery({ ...query, filter: newFilter });
                                    setSelectQueryMode(false);
                                }
                            }
                        }}>
                            <AddOutlined />
                        </IconButton>
                        <IconButton onClick={() => {
                            setKey("");
                            setValue("");
                            setOperator("Equals");
                            setMode("select");
                            onClearSearch();
                            setQuery(new QuerySave({ username: me?.login, queueId: queueId }));
                            setSelectQueryMode(true);
                        }}>
                            <Clear />
                        </IconButton>
                        <IconButton type="submit">
                            <Search />
                        </IconButton>
                    </Stack>
                </Grid>
            </Grid>
            <Grid container item sx={{ p: 1 }}>

            </Grid>
        </Box >
    );
};

export default TaskTableToolbar;
import { TaskFormComponent } from "@/api/CamundaController";
import { updateTaskVariables } from "@/redux/modules/Process";
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

interface SelectGenProps {
    component: TaskFormComponent
}
const SelectGen = (props: SelectGenProps) => {
    const { component } = props;
    const dispatch = useDispatch();
    const variables = useSelector((state: any) => state?.process?.taskModel?.variables) || {};
    useEffect(() => {
        if (variables[component.key!] == undefined && component.defaultValue && component.defaultValue != "<none>") {
            dispatch(
                updateTaskVariables(
                    (g) => g,
                    (p) => Object.assign(p, { [component.key!]: component.defaultValue })
                ));
        }
    }, []);
    const value = variables[component.key!] || '';
    return (
        <Grid item xs={12} {...component.properties} required={component.validate?.required}>
            <FormControl fullWidth size='small'>
                <InputLabel id={`${component.id}-label`}>{component.label}</InputLabel>
                <Select
                    size='small'
                    required={component.validate?.required}
                    labelId={`${component.id}-label`}
                    id={component.id}
                    name={component.id}
                    value={value}
                    label={component.label}
                    disabled={component.disabled}
                    onChange={(e) =>
                        dispatch(
                            updateTaskVariables(
                                (g) => g,
                                (p) => Object.assign(p, { [component.key!]: e.target.value })
                            ))}
                >
                    {component.values?.map((item, index) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                    ))}
                </Select>
                <FormHelperText>{component.description}</FormHelperText>
            </FormControl>
        </Grid>);
};

export default SelectGen;
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Report } from './DailyReportDocument';
import { formatNumberRu } from '@/core/helpers/PageHelpers';
import { DepartmentModel } from '@/interface';
import { PdfmakeFonts } from '@/core/helpers/PdfmakeFonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = PdfmakeFonts;

const dailyReportDocument = (report: Report, dep: DepartmentModel, openDate: string) => {
  console.log(report)
  const currenciesBalance = report.startBalance.map(cur => ({ ...cur, value: formatNumberRu(+cur.value) + ".00" })).filter(cur => cur.currencyCode !== "KZT" && cur.currencyCode && cur.currencyCode.indexOf("GOLD") < 0);
  const currenciesCloseBalance = report.closeBalance.map(cur => ({ ...cur, value: formatNumberRu(+cur.value) + ".00" })).filter(cur => cur.currencyCode !== "KZT" && cur.currencyCode && cur.currencyCode.indexOf("GOLD") < 0);

  const tengeBalance = report.startBalance.map(cur => ({ ...cur, value: formatNumberRu(+ cur.value) + ".00" })).find(cur => cur.currencyCode === "KZT");
  const tengeCloseBalance = report.closeBalance.map(cur => ({ ...cur, value: formatNumberRu(+ cur.value) + ".00" })).find(cur => cur.currencyCode === "KZT");
  const nbRatesToday = report.nbToday;
  const nbRatesYester = report.nb;
  let commonBalance = 0;

  const currencyBalanceRows = currenciesBalance.map((cur) => {
    commonBalance = commonBalance + (+(cur?.value.replace(/\s/g, "")) * +(nbRatesToday.find(nbRate => nbRate.currencyCode === cur.currencyCode)?.value || 0));
    return [
      {},
      {},
      { text: cur.currencyCode, alignment: "left", fontSize: 10 },
      { text: cur?.value, alignment: "right", fontSize: 10 },
      { text: nbRatesToday.find(nbRate => nbRate.currencyCode === cur.currencyCode)?.value || "", alignment: "right", fontSize: 10 },
      { text: `${formatNumberRu((+(cur?.value.replace(/\s/g, "")) * +(nbRatesToday.find(nbRate => nbRate.currencyCode === cur.currencyCode)?.value || 0)).toFixed(2))}`, alignment: "right", fontSize: 10 },
      {},
      {},
      {},
    ];
  });

  interface Application { currencyCode: string, value: string | number, rate: string | number, nbRate: string | number, kztValue: string | number }

  let buyApplications: Application[] = [];
  let saleApplications: Application[] = [];
  let amountOfBuyRequest = 0;
  let amountOfSaleRequest = 0;
  (function () {
    report.applicationList !== null && report.applicationList.length &&
      report.applicationList.map(application => {
        if (typeof application.currencyFrom === 'string' && application.currencyFrom.length === 3 && (application.currencyFrom.indexOf("GOLD") < 0 && application.currencyTo && application.currencyTo.indexOf("GOLD") < 0)) {
          if (application.currencyFrom !== "KZT") {
            const currentApp = buyApplications.find(app => app.currencyCode === application.currencyFrom);
            amountOfBuyRequest++;
            if (currentApp) {
              const newApp: Application = { ...currentApp, value: +(currentApp?.value || 0) + +(application.value || 0), kztValue: +(currentApp?.kztValue || 0) + +(application.amount || 0) };
              buyApplications = buyApplications.filter(app => app.currencyCode !== application.currencyFrom);
              buyApplications.push(newApp);
            } else {
              const newApp: Application = { currencyCode: application.currencyFrom || "", value: application.value || "", rate: application.rate || "", nbRate: nbRatesToday.find(rate => rate.currencyCode === application.currencyFrom)?.value || "", kztValue: application.amount || "" };
              buyApplications.push(newApp);
            }
          } else {
            const currentApp = saleApplications.find(app => app.currencyCode === application.currencyTo);
            amountOfSaleRequest++;
            if (currentApp) {
              const newApp: Application = { ...currentApp, value: +(currentApp?.value || 0) + +(application.value || 0), kztValue: +(currentApp?.kztValue || 0) + +(application.amount || 0) };
              saleApplications = saleApplications.filter(app => app.currencyCode !== application.currencyTo);
              saleApplications.push(newApp);
            } else {
              const newApp: Application = { currencyCode: application.currencyTo || "", value: application.value || "", rate: application.rate || "", nbRate: nbRatesToday.find(rate => rate.currencyCode === application.currencyTo)?.value || "", kztValue: application.amount || "" };
              saleApplications.push(newApp);
            }
          }
        }
      });
  })();

  let buyApplicationsKztNbRatesBalance = 0;
  let buyApplicationsKztBalance = 0;
  const buyApplicationsRows = buyApplications.map((cur, index) => {
    buyApplicationsKztNbRatesBalance += +cur.value * +cur.nbRate;
    buyApplicationsKztBalance += +cur.kztValue;

    return [
      index === 0 ? { rowSpan: buyApplications.length, text: '2', fontSize: 10, margin: [0, buyApplications.length * 8, 0, 0] } : {},
      index === 0 ? { rowSpan: buyApplications.length, text: 'Куплено валюты', fontSize: 10, margin: [0, buyApplications.length * 8, 0, 0] } : {},
      { text: cur.currencyCode, alignment: "left", fontSize: 10 },
      { text: formatNumberRu((+cur.value).toFixed(2)), alignment: "right", fontSize: 10 },
      { text: cur.nbRate, alignment: "right", fontSize: 10 },
      { text: formatNumberRu((+cur.value * +cur.nbRate).toFixed(2)), alignment: "right", fontSize: 10 },
      { text: cur.rate, alignment: "right", fontSize: 10 },
      { text: formatNumberRu((+cur.kztValue).toFixed(2)), alignment: "right", fontSize: 10 },
      { text: formatNumberRu((+cur.value * +cur.nbRate - +cur.kztValue).toFixed(2)), alignment: "right", fontSize: 10 },
    ];
  });

  let saleApplicationsKztNbRatesBalance = 0;
  let saleApplicationsKztBalance = 0;
  const saleApplicationsRows = saleApplications.map((cur, index) => {
    saleApplicationsKztNbRatesBalance += +cur.value * +cur.nbRate;
    saleApplicationsKztBalance += +cur.kztValue;

    return [
      index === 0 ? { rowSpan: saleApplications.length, text: '3', fontSize: 10, margin: [0, saleApplications.length * 8, 0, 0] } : {},
      index === 0 ? { rowSpan: saleApplications.length, text: 'Продано валюты', fontSize: 10, margin: [0, saleApplications.length * 8, 0, 0] } : {},
      { text: cur.currencyCode, alignment: "left", fontSize: 10 },
      { text: formatNumberRu((+cur.value).toFixed(2)), alignment: "right", fontSize: 10 },
      { text: cur.nbRate, alignment: "right", fontSize: 10 },
      { text: formatNumberRu((+cur.value * +cur.nbRate).toFixed(2)), alignment: "right", fontSize: 10 },
      { text: cur.rate, alignment: "right", fontSize: 10 },
      { text: formatNumberRu((+cur.kztValue).toFixed(2)), alignment: "right", fontSize: 10 },
      { text: formatNumberRu((+cur.value * +cur.nbRate - +cur.kztValue).toFixed(2)), alignment: "right", fontSize: 10 },
    ];
  });

  let commonCloseBalance = 0;
  const currencyCloseBalanceRows = currenciesCloseBalance.map((cur) => {
    commonCloseBalance = commonCloseBalance + (+(cur?.value.replace(/\s/g, "")) * +(nbRatesToday.find(nbRate => nbRate.currencyCode === cur.currencyCode)?.value || 0));
    return [
      {},
      {},
      { text: cur.currencyCode, alignment: "left", fontSize: 10 },
      { text: cur?.value, alignment: "right", fontSize: 10 },
      { text: nbRatesToday.find(nbRate => nbRate.currencyCode === cur.currencyCode)?.value || "", alignment: "right", fontSize: 10 },
      { text: `${formatNumberRu((+(cur?.value.replace(/\s/g, "")) * +(nbRatesToday.find(nbRate => nbRate.currencyCode === cur.currencyCode)?.value || 0)).toFixed(2))}`, alignment: "right", fontSize: 10 },
      {},
      {},
      {},
    ];
  });

  let reassessmentAmount = 0;
  const reassessment = currenciesBalance.map((cur) => {
    const yesterCount = +(cur?.value.replace(/\s/g, "")) * +(nbRatesYester.find(nbRate => nbRate.currencyCode === cur.currencyCode)?.value || 0);
    const todayCount = +(cur?.value.replace(/\s/g, "")) * +(nbRatesToday.find(nbRate => nbRate.currencyCode === cur.currencyCode)?.value || 0);
    reassessmentAmount = reassessmentAmount + (todayCount - yesterCount);
    return [
      { text: cur.currencyCode, fontSize: 10 },
      { text: formatNumberRu(cur.value), fontSize: 10 },
      { text: nbRatesYester.find(nbRate => nbRate.currencyCode === cur.currencyCode)?.value || "", fontSize: 10 },
      { text: `${formatNumberRu(yesterCount.toFixed(2))}`, fontSize: 10 },
      { text: nbRatesToday.find(nbRate => nbRate.currencyCode === cur.currencyCode)?.value || "", fontSize: 10 },
      { text: `${formatNumberRu(todayCount.toFixed(2))}`, fontSize: 10 },
      { text: formatNumberRu((todayCount - yesterCount).toFixed(2)) },
    ];
  });

  let buyBalance: { currencyCode: string, balance: number }[] = [];
  let saleBalance: { currencyCode: string, balance: number }[] = [];
  (function () {
    report.balanceUpdate !== null && report.balanceUpdate.length &&
      report.balanceUpdate.map(balanceApp => {
        if (typeof balanceApp.currencyCode === 'string' && balanceApp.currencyCode.length === 3) {
          if (+balanceApp.newBalance > +balanceApp.lastBalance) {
            const currentApp = buyBalance.find(app => app.currencyCode === balanceApp.currencyCode);
            if (currentApp) {
              const newApp: { currencyCode: string, balance: number } = { ...currentApp, balance: +currentApp.balance + (+balanceApp.newBalance - +balanceApp.lastBalance) };
              buyBalance = buyBalance.filter(app => app.currencyCode !== balanceApp.currencyCode);
              buyBalance.push(newApp);
            } else {
              const newApp: { currencyCode: string, balance: number } = { currencyCode: balanceApp.currencyCode, balance: +balanceApp.newBalance - +balanceApp.lastBalance };
              buyBalance.push(newApp);
            }
          } else {
            const currentApp = saleBalance.find(app => app.currencyCode === balanceApp.currencyCode);
            if (currentApp) {
              const newApp: { currencyCode: string, balance: number } = { ...currentApp, balance: +currentApp.balance + (+balanceApp.lastBalance - +balanceApp.newBalance) };
              saleBalance = saleBalance.filter(app => app.currencyCode !== balanceApp.currencyCode);
              saleBalance.push(newApp);
            } else {
              const newApp: { currencyCode: string, balance: number } = { currencyCode: balanceApp.currencyCode, balance: +balanceApp.lastBalance - +balanceApp.newBalance };
              saleBalance.push(newApp);
            }
          }
        }
      });
  })();

  const showing: any = {
    pageOrientation: 'landscape',
    content: [
      {
        stack: [
          {
            text: "Ежедневный отчет",
            fontSize: 14,
          },
          {
            text: `обменного пункта ТОО "${dep.name}" адрес: г.${dep.code} ${dep.address}`
          },
          {
            text: "о покупке, продаже иностранной валюты и выручке"
          },
          {
            text: `за ${openDate}`
          }
        ],
        alignment: "center"
      },
      { text: "\n" },
      { text: "\n" },
      {
        table: {
          widths: ["*", "*", "*", "*", "auto", "*", "auto", "*", "*"],
          dontBreakRows: true,
          body: [
            [
              { rowSpan: 3, text: "№п/п", margin: [0, 42, 0, 0] },
              { rowSpan: 3, text: "Операция", margin: [0, 42, 0, 0] },
              { colSpan: 4, text: "Валюта", margin: [0, 7, 0, 0] },
              {},
              {},
              {},
              { colSpan: 2, text: "Тенге", margin: [0, 7, 0, 0] },
              {},
              "Чистая выручка"
            ],
            [
              {},
              {},
              { rowSpan: 2, text: "Вид валюты", margin: [0, 21, 0, 0] },
              { rowSpan: 2, text: "Сумма валюты", margin: [0, 14, 0, 0] },
              { colSpan: 2, text: "Сумма тенге по рыночному курсу" },
              {},
              { colSpan: 2, text: "По курсу покупки / продажи" },
              {},
              "Для 2/4 = (2 - 3)"
            ],
            [
              {},
              {},
              {},
              {},
              { text: "Курс НБ", margin: [0, 7, 0, 0] },
              { text: "СуммаНБ", margin: [0, 7, 0, 0] },
              { text: "Курс", margin: [0, 7, 0, 0] },
              { text: "Сумма", margin: [0, 7, 0, 0] },
              "Для 3/4 = (3 - 2)"
            ],
            [
              "А",
              "Б",
              "В",
              "1",
              { colSpan: 2, text: "2" },
              {},
              { colSpan: 2, text: "3" },
              {},
              "4"
            ],
            [
              { rowSpan: currenciesBalance.length + 1, text: '1', fontSize: 10, margin: [0, currenciesBalance.length * 8, 0, 0] },
              { rowSpan: currenciesBalance.length + 1, text: "Получено валюты в тенге", fontSize: 10, margin: [0, currenciesBalance.length * 7.5, 0, 0] },
              { text: "Тенге", alignment: "left", fontSize: 10 },
              { text: tengeBalance?.value, alignment: "right", fontSize: 10 },
              {},
              { text: tengeBalance?.value, alignment: "right", fontSize: 10 },
              { columnSpan: 2, text: "", fontSize: 10 },
              {},
              {},
            ],
            ...currencyBalanceRows,
            [
              { border: [false], text: '' },
              { text: "Итого:", border: [false], alignment: "left", fontSize: 10 },
              { border: [false], text: '' },
              { border: [false], text: '' },
              { border: [false], text: '' },
              { text: formatNumberRu((commonBalance + +(tengeBalance ? tengeBalance.value.replace(/\s/g, "") : 0)).toFixed(2)), border: [false, true, false, false], alignment: "right", fontSize: 10 },
              { border: [false], text: '' },
              { border: [false], text: '' },
              { border: [false], text: '' },
            ],
          ],
        },
        alignment: "center"
      },
      { text: "\n" },
      {
        table: {
          widths: ["*", "*", "*", "*", 52, "*", 46, "*", "*"],
          dontBreakRows: true,
          body: [
            ...buyApplicationsRows,
            [
              { border: [false], text: '' },
              { text: "Итого:", border: [false], alignment: "left", fontSize: 10 },
              { border: [false], text: '' },
              { text: `Кол покупок = ${amountOfBuyRequest}`, border: [false], alignment: "left", fontSize: 8 },
              { border: [false], text: '' },
              { text: formatNumberRu((buyApplicationsKztNbRatesBalance).toFixed(2)), border: [false, true, false, false], alignment: "right", fontSize: 10 },
              { border: [false], text: '' },
              { text: formatNumberRu((buyApplicationsKztBalance).toFixed(2)), border: [false, true, false, false], alignment: "right", fontSize: 10 },
              { text: formatNumberRu((buyApplicationsKztNbRatesBalance - buyApplicationsKztBalance).toFixed(2)), border: [false, true, false, false], alignment: "right", fontSize: 10 },
            ],
          ]
        },
        alignment: "center"
      },
      { text: "\n" },
      {
        table: {
          widths: ["*", "*", "*", "*", 52, "*", 46, "*", "*"],
          dontBreakRows: true,
          body: [
            ...saleApplicationsRows,
            [
              { border: [false], text: '' },
              { text: "Итого:", border: [false], alignment: "left", fontSize: 10 },
              { border: [false], text: '' },
              { text: `Кол продаж = ${amountOfSaleRequest}`, border: [false], alignment: "left", fontSize: 8 },
              { border: [false], text: '' },
              { text: formatNumberRu((saleApplicationsKztNbRatesBalance).toFixed(2)), border: [false, true, false, false], alignment: "right", fontSize: 10 },
              { border: [false], text: '' },
              { text: formatNumberRu((saleApplicationsKztBalance).toFixed(2)), border: [false, true, false, false], alignment: "right", fontSize: 10 },
              { text: formatNumberRu((saleApplicationsKztNbRatesBalance - saleApplicationsKztBalance).toFixed(2)), border: [false, true, false, false], alignment: "right", fontSize: 10 },
            ],
          ]
        },
        alignment: "center"
      },
      { text: "\n" },
      {
        table: {
          widths: ["*", "*", "*", "*", 52, "*", 46, "*", "*"],
          dontBreakRows: true,
          body: [
            [
              { rowSpan: currenciesCloseBalance.length + 1, text: '4', fontSize: 10, margin: [0, currenciesCloseBalance.length * 8, 0, 0] },
              { rowSpan: currenciesCloseBalance.length + 1, text: "Сдано валюты в тенге", fontSize: 10, margin: [0, currenciesCloseBalance.length * 7.5, 0, 0] },
              { text: "Тенге", alignment: "left", fontSize: 10 },
              { text: tengeCloseBalance?.value, alignment: "right", fontSize: 10 },
              {},
              { text: tengeCloseBalance?.value, alignment: "right", fontSize: 10 },
              { columnSpan: 2, text: "", fontSize: 10 },
              {},
              {},
            ],
            ...currencyCloseBalanceRows,
            [
              { border: [false], text: '' },
              { text: "Итого:", border: [false], alignment: "left", fontSize: 10 },
              { border: [false], text: '' },
              { border: [false], text: '' },
              { border: [false], text: '' },
              { text: formatNumberRu((commonCloseBalance + +(tengeCloseBalance ? tengeCloseBalance.value.replace(/\s/g, "") : 0)).toFixed(2)), border: [false, true, false, false], alignment: "right", fontSize: 10 },
              { border: [false], text: '' },
              { border: [false], text: '' },
              { border: [false], text: '' },
            ],
          ]
        },
        alignment: "center"
      },
      { text: "\n" },
      {
        layout: 'noBorders',
        table: {
          widths: ["*", "*", "*", "*", 52, "*", 46, "*", "*"],
          body: [
            [
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: 'Итого', bold: true, alignment: "left", },
              { colSpan: 2, text: formatNumberRu((+((saleApplicationsKztNbRatesBalance - saleApplicationsKztBalance) - (buyApplicationsKztNbRatesBalance - buyApplicationsKztBalance))).toFixed(2)), fontSize: 9, bold: true, alignment: "right", },
              { text: '' },
            ],
          ]
        }
      },
      { text: "\n" },
      { text: "\n" },
      {
        layout: 'noBorders',
        table: {
          widths: ["*", "*", "*", "*", 52, "*", 46, "*", "*"],
          body: [
            [
              { text: '' },
              { colSpan: 2, text: 'Теньговая масса:', bold: true, alignment: "left" },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { colSpan: 2, text: formatNumberRu((commonCloseBalance + +(tengeCloseBalance ? tengeCloseBalance.value.replace(/\s/g, "") : 0)).toFixed(2)), bold: true, alignment: "right" },
              { text: {} },
            ],
          ]
        }
      },
      { text: "\n" },
      { text: "Переоценка валют (курсовая разница)", alignment: "center", bold: true },
      { text: "\n" },
      {
        table: {
          widths: ["*", "*", "auto", "*", "auto", "*", "*",],
          dontBreakRows: true,
          body: [
            [
              { rowSpan: 2, text: "Валюта", alignment: "center", margin: [0, 7, 0, 0] },
              { rowSpan: 2, text: "Остаток валюты на начало дня", alignment: "center" },
              { colSpan: 2, text: "За предыдущий день (на начало дня)", alignment: "center" },
              {},
              { colSpan: 2, text: "За текущий день день (на начало дня)", alignment: "center" },
              {},
              { rowSpan: 2, text: "Итого курсовая разница", alignment: "center" },
            ],
            [
              {},
              {},
              { text: "Курс НБ", alignment: "center" },
              { text: "Сумма по НБ", alignment: "center" },
              { text: "Курс НБ", alignment: "center" },
              { text: "Сумма по НБ", alignment: "center" },
              {}
            ],
            ...reassessment,
            [
              { border: [false], text: '' },
              { border: [false], text: '' },
              { border: [false, false, false, false], colSpan: 2, text: 'Итого по переоценке:', alignment: "left", bold: true, margin: [0, 14, 0, 50] },
              { border: [false, false, false, false], text: '' },
              { border: [false], text: '' },
              { border: [false], text: '' },
              { border: [false], text: formatNumberRu(reassessmentAmount.toFixed(2)), bold: true, margin: [0, 14, 0, 0] },
            ],
            [
              { border: [false], text: '' },
              { border: [false], text: '' },
              { border: [false, false, false, false], colSpan: 2, text: 'Всего:', alignment: "left", bold: true, margin: [0, 14, 0, 0] },
              { border: [false, false, false, false], text: '' },
              { border: [false], text: '' },
              { border: [false], text: '' },
              { border: [false], text: formatNumberRu(((saleApplicationsKztNbRatesBalance - saleApplicationsKztBalance) - (buyApplicationsKztNbRatesBalance - buyApplicationsKztBalance) - reassessmentAmount).toFixed(2)), bold: true, margin: [0, 14, 0, 0] },
            ],
          ]
        },
        alignment: "right"
      },
      saleBalance.length > 0 ? {
        text: "\n"
      } : {},
      saleBalance.length > 0 ? {
        text: "Выдано из кассы обменного пункта",
        bold: true
      } : {},
      saleBalance.length > 0 ? {
        text: "\n"
      } : {},
      saleBalance.length > 0 ? {
        fontSize: 10,
        table: {
          widths: [150, 75, 200],
          body: [
            [
              { text: "Операция", bold: true, italics: true },
              { text: "Валюта", bold: true, italics: true },
              { text: "Сумма", bold: true, italics: true },
            ],
            ...saleBalance.map((balance) => [
              "Выдано в подотчет",
              balance.currencyCode,
              { text: formatNumberRu(balance.balance), alignment: "right" }
            ])
          ]
        }
      } : {},
      buyBalance.length > 0 ? {
        text: "\n"
      } : {},
      buyBalance.length > 0 ? {
        text: "\n"
      } : {},
      buyBalance.length > 0 ? {
        text: "Пополнение кассы обменного пункта",
        bold: true
      } : {},
      buyBalance.length > 0 ? {
        text: "\n"
      } : {},
      buyBalance.length > 0 ? {
        fontSize: 10,
        table: {
          widths: [150, 75, 200],
          body: [
            [
              { text: "Операция", bold: true, italics: true },
              { text: "Валюта", bold: true, italics: true },
              { text: "Сумма", bold: true, italics: true },
            ],
            ...buyBalance.map((balance) => [
              "Пополнение",
              balance.currencyCode,
              { text: formatNumberRu(balance.balance), alignment: "right" }
            ])
          ]
        }
      } : {},
      {
        text: "\n"
      },
      {
        text: "\n"
      },
      {
        text: "Подпись кассира обменного пункта: ___________________________",
        fontSize: 10,
        bold: true
      }
    ],
    defaultStyle: {
      fontSize: 12,
      font: "Roboto"
    },
  };

  return showing;
};

export default dailyReportDocument;

import { server } from '../core/server/axios';
import { Content, Role, VariableFilter, VariableView } from './CamundaController';

const wenv = process.env;

export class TasksDuration {
  title = "";
  operator: "Min" | "Max" | "Avg" = "Avg";
  fromEvent = "start";
  fromTask = "";
  toEvent = "start";
  toTask = "";
}

export class Report {
  id = 0;
  name = "";
  type = "";
  process = "";
  durations: Array<TasksDuration> = [];
  filter: Array<VariableFilter> = [];
  view: Array<VariableView> = [];
  roles?: Role[] = [];
  ord = 0;
  public constructor(init?: Partial<Report>) {
    Object.assign(this, init);
  }
}

export class ReportResponse {
  name = "";
  data: any[] = [];
  totalResults = 0;
}

export class ReportController {

  async getRoleReports(role: string) {
    return await server.get(`/sonata/api/report/roleReports/${role}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async getUserReports(cache: boolean): Promise<Report[]> {
    return await server.get(`/sonata/api/report/userReports/`, {
      baseURL: wenv.REACT_APP_BACKEND,
      headers: {
        cache: cache
      }
    });
  }

  async getReports(page: number, size: number): Promise<Report[]> {
    return await server.get(`/sonata/api/report/${page}/${size}`, {
      baseURL: wenv.REACT_APP_BACKEND
    });
  }

  async getReportList(id: string, from: string, to: string): Promise<ReportResponse> {
    return await server.post(
      `/sonata/api/report/${id}/${from}/${to}`, null,
      {
        baseURL: wenv.REACT_APP_BACKEND
      }
    );
  }

  async registerReport(report: Report): Promise<void> {
    return server.post(`/sonata/api/report`, report, {
      baseURL: wenv.REACT_APP_BACKEND,
    });
  }

  async deleteReport(id: number): Promise<void> {
    return server.delete(`/sonata/api/report/${id}`, {
      baseURL: wenv.REACT_APP_BACKEND,
    });
  }

  async getReportsName(
    pageNumber: number,
    pageSize: number,
    name: string
  ): Promise<Content<Report>> {
    return server.get(
      `/sonata/api/report/${pageNumber}/${pageSize}?name=${name}`,
      {
        baseURL: wenv.REACT_APP_BACKEND,
      }
    );
  }

  async getReportsProcess(
    pageNumber: number,
    pageSize: number,
    process: string
  ): Promise<Content<Report>> {
    return server.get(
      `/sonata/api/report/${pageNumber}/${pageSize}?process=${process}`,
      {
        baseURL: wenv.REACT_APP_BACKEND,
      }
    );
  }

  async getReport(id: number) {
    return server.get(`/sonata/api/report/${id}`, {
      baseURL: wenv.REACT_APP_BACKEND,
    });
  }

  async bindReportRole(reportId: number, role: string): Promise<void> {
    return server.post(
      `/sonata/api/report/bind-role`,
      { reportId, role },
      {
        baseURL: wenv.REACT_APP_BACKEND,
      }
    );
  }
  async unbindReportRole(reportId: number, role: string): Promise<void> {
    return server.post(
      `/sonata/api/report/unbind-role`,
      { reportId, role },
      {
        baseURL: wenv.REACT_APP_BACKEND,
      }
    );
  }

  async changeOrder(reportId1: number, reportId2: number): Promise<void> {
    return server.get(
      `/sonata/api/report/change-order/${reportId1}/${reportId2}`,
      {
        baseURL: wenv.REACT_APP_BACKEND,
      }
    );
  }
}

import { TaskFormComponent } from "@/api/CamundaController";
import { updateTaskVariables } from "@/redux/modules/Process";
import { Grid, Stack, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

interface NumberGenProps {
    component: TaskFormComponent;
    iconButton?: any;
}

const NumberGen = (props: NumberGenProps) => {
    const { component, iconButton } = props;
    const dispatch = useDispatch();
    const variables = useSelector((state: any) => state?.process?.taskModel?.variables) || {};
    useEffect(() => {
        if (variables[component.key!] == undefined && component.defaultValue) {
            dispatch(
                updateTaskVariables(
                    (g) => g,
                    (p) => Object.assign(p, { [component.key!]: component.defaultValue })
                ));
        }
    }, []);
    const value = variables[component.key!] || '';
    return (
        <Grid item xs={12} {...component.properties}>
            <Stack direction='row' justifyContent="flex-start"
                alignItems="flex-start">
                <TextField
                    size='small'
                    type="number"
                    required={component.validate?.required}
                    fullWidth
                    id={component.id}
                    label={component.label}
                    placeholder={component.description}
                    name={component.id}
                    value={value}
                    onChange={(e) => dispatch(
                        updateTaskVariables(
                            (g) => g,
                            (p) => Object.assign(p, { [component.key!]: e.target.value })
                        ))}
                    error={false}
                    helperText={component.description}
                    disabled={component.disabled}
                    inputProps={{
                        ...component.properties,
                        maxLength: component.validate?.maxLength,
                        minLength: component.validate?.minLength
                    }}
                />
                {component.dynamic && iconButton}
            </Stack>
        </Grid>);
};

export default NumberGen;
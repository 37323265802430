import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { HeaderReducer, actions } from "@/redux/modules/Header";
import { connect, useDispatch } from "react-redux";
import { Drawer } from "./LeftMenu";
import { QueueList } from "./TaskBoard";
import { IconButton, ListSubheader, Typography } from "@mui/material";
import React from "react";
import { Cached, Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import processManager from "@/core/helpers/ProcessManager";
import { useTranslation } from "react-i18next";

const QueueMenu = (props: HeaderReducer) => {
  const naviagte = useNavigate();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { queueMenuBarOpen } = props;

  const onSelectMenu = () => {
    naviagte("/queue");
  };

  return (
    <React.Fragment>
      {queueMenuBarOpen && (
        <Drawer variant="permanent" open={queueMenuBarOpen}>
          <Toolbar
            variant="dense"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          />
          <Divider />
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
              position: "relative",
              overflow: "auto",
              height: "90vh",
            }}
            component="nav">
            <ListSubheader component="div" sx={{ textAlign: "right" }}>
              <Typography component="span">{t("queues")}</Typography>
              <IconButton onClick={() => processManager.fetchQueueData()}>
                <Cached />
              </IconButton>
              <IconButton
                onClick={() => dispatch(actions.openQueueMenuBar(false))}>
                <Close />
              </IconButton>
            </ListSubheader>
            <QueueList onSelectQueue={onSelectMenu} />
          </List>
        </Drawer>
      )}
    </React.Fragment>
  );
};

function mapStateToProps(state: any) {
  const header: HeaderReducer = state.header;

  return {
    userContext: header.userContext,
    queueMenuBarOpen: header.queueMenuBarOpen,
  };
}

export default connect(mapStateToProps, null)(QueueMenu);

import { Htag } from '@/components';
import styles from './styles.module.css';

const wizard = (
	Component: React.FC,
	title?: string
) => (
	<div className={styles.background_color}>
		<div className={styles.container}>
			<Htag tag={'h1'}>{title}</Htag>
			<Component />
		</div>
	</div>
);

export default wizard;
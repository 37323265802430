import React, { useEffect, useMemo, useState } from "react";

import { Autocomplete, Button, Dialog, FormControl, TextField, TextFieldProps } from '@mui/material';

import styles from "./requestListPage.module.css";
import { useDispatch, useSelector } from 'react-redux';
import InputMask from "react-input-mask";
import NumberFormat from 'react-number-format';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { searchRequest } from '@/api/MyApi';
import { formatNumberRu } from '@/core/helpers/PageHelpers';
import { DepartmentModel, currencyArray } from '@/interface';
import { selectDepartmentList } from '@/redux/modules/department/departmentSelectors';
import { loadDepartments } from '@/redux/modules/department/departmentActions';
import pm from "@/core/helpers/ProcessManager";
import { Role } from '@/api/CamundaController';

const date_input_style = {
	height: "35px",
	background: "white",
	"& .MuiFormControl-root": {
		height: "35px",
	},
	"& .MuiInputBase-root": {
		padding: 0,
		height: "35px",
	},
	"& input": {
		padding: "8px",
		fontSize: "10px"
	},
	"& button": {
		padding: "4px",
		marginRight: 0,
	},
	"& svg": {
		width: "20px",
		height: "20px"
	}
};

interface RequestModel {
	requestId: number
	clientId: number
	phone: string
	fullName: any
	currencyFrom: string
	currencyTo: string
	value: string
	rate: string
	amount: string
	isIndividual: boolean
	depId: number
	actionType: any
	date: string
	isReserve: number
	isDelivery: number
	status: number
	businessKey: any
	delivery: any[]
	applicationFiles: any
	iin: string | null,
	kassaId: number | null
}

const RequestListPage = () => {
	const [requestList, setRequestList] = useState<RequestModel[]>([]);
	const [filterBody, setFilterBody] = useState<{
		businessKey: string | null,
		status: string | null,
		iin: string | null,
		amount: string | null,
		currency: { code: string | null, description: string | null },
		actionType: { rus: string | null, value: string | null },
		lastname: string | null,
		firstname: string | null,
		middlename: string | null,
		phone: string | null,
		startDate: number | null,
		endDate: number | null,
		department: DepartmentModel | null,
		depId: string | number | null,
	}>({
		businessKey: null,
		status: null,
		iin: null,
		amount: null,
		currency: { code: null, description: null },
		actionType: { rus: null, value: null },
		lastname: null,
		firstname: null,
		middlename: null,
		phone: null,
		startDate: null,
		endDate: null,
		department: null,
		depId: null,
	});
	const [kztAmount, setKztAmount] = useState<string | null>(null);

	const [openWindow, setOpenWindow] = useState(false);
	const [currentRequest, setCurrentRequest] = useState<RequestModel | null>(null);

	const dispatch = useDispatch();

	const depsList = useSelector(selectDepartmentList);
	const roles: Role[] = useSelector((state: any) => state?.header?.userContext?.user?.roles || {});
	const activeDepartment = useMemo(() => {
		let code: number | null = null;
		for (const role of roles) {
			if (role.code === "ADMIN") {
				code = null;
				break;
			} else if (role.code === "Cashier_Astana_Aeroport") {
				return depsList.find((dep) => dep.id === 9);
			} else if (role.code === "Cashier_Almaty_GrandPark") {
				return depsList.find((dep) => dep.id === 8);
			} else if (role.code === "Cashier_Astana_Saryarka") {
				return depsList.find((dep) => dep.id === 10);
			} else if (role.code === "Cashier_Astana_Hanshatyr") {
				return depsList.find((dep) => dep.id === 7);
			} else if (role.code === "Cashier_Almaty_Rixos") {
				return depsList.find((dep) => dep.id === 11);
			}
		}
		return code;
	}, []);

	console.log(activeDepartment)

	useEffect(() => {
		dispatch(loadDepartments());
	}, []);

	const mappedRequestList = useMemo(() => (
		requestList.map(request => {
			const depName = depsList?.find((dep) => dep?.id === request?.depId)?.name || "";
			return (
				<div
					key={request.requestId}
					className={styles.table_row}
					onClick={() => {
						setCurrentRequest(request);
						setOpenWindow(true);
					}}
				>
					<p>{depName}</p>
					<p style={{ textAlign: "center" }}>{request?.iin}</p>
					<p style={{ textAlign: "center" }}>{new Date(request.date).toLocaleString()}</p>
					<p style={{ textAlign: "center" }}>{request.currencyFrom !== "KZT" ? "Продажа" : "Покупка"}</p>
					<p style={{ textAlign: "center" }}>{request.currencyFrom !== "KZT" ? request.currencyFrom : request.currencyTo}</p>
					<p style={{ textAlign: "center" }}>{(+request.rate).toFixed(2)}</p>
					<p style={{ textAlign: "center" }}>{formatNumberRu((+(request.currencyFrom !== "KZT" ? request.value : request.amount)).toFixed(2))}</p>
					<p style={{ textAlign: "center" }}>{formatNumberRu((+(request.currencyFrom !== "KZT" ? request.amount : request.value)).toFixed(2))}</p>
					<p style={{ textAlign: "center" }}>{request.phone}</p>
					<p style={{ textAlign: "center" }}>{request.status === 0 && "Введен"}{request.status === 1 && "Исполнено"}{request.status === 2 && "Возврат"}</p>
				</div>
			)
		})
	), [requestList]);

	return (
		<>
			<Dialog
				open={openWindow}
				onClose={() => setOpenWindow(false)}
				maxWidth={"md"}
				sx={{
					"& .MuiPaper-root": {
						width: "100%"
					}
				}}
			>
				<div style={{ padding: "20px", display: "flex", flexDirection: "column", gap: "14px" }}>
					<p style={{ textAlign: "center" }}>{currentRequest ? new Date(currentRequest.date).toLocaleString() : ""}</p>
					<h3 style={{ textAlign: "center" }}>{depsList.find(dep => dep.kassa.find(kassa => kassa.id == currentRequest?.kassaId))?.name}</h3>
					<p style={{ textAlign: "center" }}>{currentRequest?.iin} {currentRequest?.fullName} {currentRequest?.phone}</p>

					<div className={styles.currency_container}>
						<div className={styles.kurs_container}>
							<TextField
								disabled
								autoComplete={"off"}
								fullWidth
								value={currentRequest ? formatNumberRu(currentRequest.value) + " " + currentRequest.currencyFrom : ""}
								label={`Мы купили:`}
								variant="outlined"
								className={styles.textField}
								sx={{
									"& .Mui-disabled": {
										"-webkit-text-fill-color": "rgb(0 0 0) !important"
									}
								}}
							/>
						</div>
						<div className={styles.kurs_container}>
							<p>Курс <span>{formatNumberRu((+(currentRequest?.rate || 0)).toFixed(2))}</span> тенге</p>
						</div>
						<div className={styles.kurs_container}>
							<TextField
								disabled
								autoComplete={"off"}
								fullWidth
								value={currentRequest ? formatNumberRu(currentRequest.amount) + " " + currentRequest.currencyTo : 0}
								label={`Клиент получил:`}
								variant="outlined"
								className={styles.textField}
								sx={{
									"& .Mui-disabled": {
										"-webkit-text-fill-color": "rgb(0 0 0) !important"
									}
								}}
							/>
						</div>
					</div>
				</div>
			</Dialog>

			<div style={{ display: "grid", gap: "10px", gridTemplateColumns: "repeat(9, 1fr)", maxHeight: "35px" }}>

				{!activeDepartment &&
					<Autocomplete
						options={depsList}
						getOptionLabel={(option) => option?.name || ""}
						value={filterBody?.department || undefined}
						onChange={(e, newValue) => setFilterBody(state => ({ ...state, department: newValue }))}
						sx={{
							"& label": {
								top: filterBody?.department ? "0px" : "-7px",
								fontSize: "12px"
							},
							...date_input_style
						}}
						renderInput={(params) => <TextField label="Отделение"  {...params} />}
					/>
				}
				<InputMask
					mask="9999 9999 9999"
					value={filterBody.iin || ""}
					onChange={(e) => {
						setFilterBody((state) => ({
							...state,
							iin: e.target.value.replace(/[\s_]/g, "") || null
						}));
					}}>
					<TextField
						autoComplete={"off"}
						sx={{
							"& label": {
								top: filterBody.iin ? "0px" : "-7px",
								fontSize: "12px"
							},
							...date_input_style
						}}
						fullWidth
						id="outlined-basic"
						label="ИИН"
						variant="outlined"
					/>
				</InputMask>
				<FormControl fullWidth
					sx={{
						"& label": {
							top: filterBody.startDate ? "0px" : "-7px",
							fontSize: "12px"
						},
						...date_input_style
					}}
				>
					<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
						<DatePicker
							label="Дата"
							value={filterBody.startDate}
							onChange={(e: any) => {
								if (e && typeof e === "object") {
									setFilterBody((state) => ({
										...state,
										startDate: new Date(e["$d"]).setHours(6),
										endDate: new Date(e["$d"]).setHours(29, 59, 59)
									}));
								} else {
									setFilterBody((state) => ({
										...state,
										startDate: null,
										endDate: null
									}));
								}
							}}
							renderInput={
								(params: JSX.IntrinsicAttributes & TextFieldProps) =>
									<TextField {...params}
										sx={{ background: "white" }}
										autoComplete={"off"}
										error={false}
									/>
							}
						/>
					</LocalizationProvider>
				</FormControl>
				<Autocomplete
					options={[{ rus: "Покупка", value: "buy" }, { rus: "Продажа", value: "sell" }]}
					getOptionLabel={(option) => option?.rus || ''}
					value={filterBody.actionType}
					onChange={(e, newValue) => setFilterBody(state => ({
						...state,
						actionType: newValue || { rus: null, value: null },
					}))}
					sx={{
						"& label": {
							top: filterBody.actionType ? "0px" : "-7px",
							fontSize: "12px"
						},
						...date_input_style
					}}
					renderInput={(params) => <TextField label="Вид операции"  {...params} />}
				/>
				<Autocomplete
					options={currencyArray}
					value={filterBody.currency}
					getOptionLabel={(option) => option?.code || ''}
					onChange={(_, newValue: any) => {
						setFilterBody(state => ({
							...state,
							currency: newValue
						}));
					}}
					sx={{
						"& label": {
							top: filterBody.currency ? "0px" : "-7px",
							fontSize: "12px"
						},
						...date_input_style
					}}
					renderInput={(params) => <TextField sx={{ background: "white" }} label="Валюта" {...params} />}
				/>
				<NumberFormat
					customInput={TextField}
					//Свойства TextField Mui5
					autoComplete={"off"}
					fullWidth
					value={filterBody.amount ? filterBody.amount : ""}
					label={"Сумма в валюте"}
					variant="outlined"
					sx={{
						"& label": {
							top: filterBody.amount ? "0px" : "-7px",
							fontSize: "12px"
						},
						...date_input_style
					}}
					onChange={(e) => {
						setFilterBody(state => ({
							...state,
							amount: e.target.value.replace(/\s/g, "") || null
						}));
					}}
					inputProps={{
						inputmode: "numeric",
					}}
					//Свойства NumberFormat
					thousandSeparator={" "}
					allowNegative={false}
					// decimalScale={2}
					fixedDecimalScale={true}
				/>
				<NumberFormat
					customInput={TextField}
					//Свойства TextField Mui5
					autoComplete={"off"}
					fullWidth
					value={kztAmount ? kztAmount : ""}
					label={"Сумма в тенге"}
					variant="outlined"
					sx={{
						"& label": {
							top: kztAmount ? "0px" : "-7px",
							fontSize: "12px"
						},
						...date_input_style
					}}
					onChange={(e) => {
						setKztAmount(e.target.value.replace(/\s/g, "") || null);
					}}
					inputProps={{
						inputmode: "numeric",
					}}
					//Свойства NumberFormat
					thousandSeparator={" "}
					allowNegative={false}
					// decimalScale={2}
					fixedDecimalScale={true}
				/>
				<InputMask
					mask="+7 999 999 99 99"
					value={filterBody.phone || ""}
					onChange={(e) => {
						const newPhone = e.target.value.replace(/[\s_+]/g, "");
						setFilterBody((state) => ({
							...state,
							phone: newPhone || null
						}));
					}}>
					<TextField
						autoComplete={"off"}
						sx={{
							"& label": {
								top: filterBody.phone ? "0px" : "-7px",
								fontSize: "12px"
							},
							...date_input_style
						}}
						fullWidth
						label="Телефон"
						variant="outlined"
					/>
				</InputMask>
				<Autocomplete
					options={["Введен", "Исполнен", "Возврат"]}
					value={filterBody.status}
					onChange={(_, newValue: any) => {
						setFilterBody(state => ({
							...state,
							status: newValue
						}));
					}}
					sx={{
						"& label": {
							top: "-7px",
							fontSize: "12px"
						},
						...date_input_style
					}}
					renderInput={(params) => <TextField sx={{ background: "white" }} label="Статус" {...params} />}
				/>
			</div>

			<div>
				<Button
					variant={"contained"}
					sx={{
						display: "block",
						margin: "20px auto",

						background: "#005792",
						"&:hover": {
							backgroundColor: "#014675",
						},
					}}
					onClick={() => {
						pm.openBackDrop();
						searchRequest({ ...filterBody, status: filterBody.status === "Введен" ? "0" : filterBody.status === "Исполнен" ? "1" : filterBody.status === "Возврат" ? "2" : null, depId: activeDepartment ? activeDepartment.id : filterBody?.department?.id || null, currencyCode: filterBody.currency?.code || "", actionType: filterBody.actionType.value, startDate: filterBody.startDate ? new Date(filterBody.startDate) : null, endDate: filterBody.endDate ? new Date(filterBody.endDate) : null })
							.then((res) => {
								setRequestList(res.model);
							})
							.catch(e => {
								console.error(e);
							})
							.finally(pm.closeBackDrop);
					}}
				>
					Поиск
				</Button>
			</div>

			{requestList && requestList.length > 0
				&&
				<div
					style={{
						marginTop: "30px",
						background: "white",
						padding: "20px",
						borderRadius: "10px",
						fontSize: "13px"
					}}
				>
					<div style={{ border: "1px solid #80808096", borderRadius: "10px" }}>
						<div className={styles.table_row}>
							<p>Отделение</p>
							<p style={{ textAlign: "center" }}>ИИН</p>
							<p style={{ textAlign: "center" }}>Дата</p>
							<p style={{ textAlign: "center" }}>Вид операции</p>
							<p style={{ textAlign: "center" }}>Валюта</p>
							<p style={{ textAlign: "center" }}>Курс</p>
							<p style={{ textAlign: "center" }}>Сумма в валюте</p>
							<p style={{ textAlign: "center" }}>Сумма в тенге</p>
							<p style={{ textAlign: "center" }}>Телефон</p>
							<p style={{ textAlign: "center" }}>Статус</p>
						</div>

						{mappedRequestList}
					</div>
				</div>
			}
		</>
	);
};

export default RequestListPage;

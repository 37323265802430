import { Task, TaskFormComponent } from "@/api/CamundaController";
import { Divider, Grid, Paper, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useEffect, useState } from "react";
import DynamicAdd from "./DynamicAdd";
import ComponentsGen from "./ComponentsGen";
import AuthUtil from "@/core/helpers/AuthUtil";

interface TaskFormGenProps {
    task: Task;
    formRef: any;
}

const TaskFormGen = (props: TaskFormGenProps) => {
    const { task, formRef } = props;
    const { form } = task;
    const [components, setComponents] = useState<Array<TaskFormComponent>>(form?.components || []);

    useEffect(() => {
        setComponents(form?.components || []);
    }, [form?.components]);

    return (
        <Paper sx={{ width: '100%', mb: 2, pb: 2 }}>
            <Container sx={{ pt: 2 }}>
                <Typography component="h2" variant="h6">
                    {task.name}
                </Typography>
            </Container>
            <Container sx={{ pt: 2 }}>
                <Box component="form" ref={formRef} onSubmit={() => { }} sx={{ mt: 3, mb: 3 }}>
                    <Grid container spacing={2}>
                        <ComponentsGen components={components} setComponents={setComponents} />
                    </Grid>
                </Box>
                <Divider />
                {AuthUtil.hasRole("ADMIN") && <DynamicAdd components={components} setComponents={setComponents} />}
            </Container>
        </Paper >);
};

export default TaskFormGen;
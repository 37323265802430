import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, TextField } from '@mui/material';
import { BtnDarkBlue } from '@/components/MuiComponent';

interface AddFillialWindowInterface {
	openWindow: boolean;
	handleCloseWindow: () => void;
	city: string | null;
	handleChangeCity: (e: any, newValue: string | null) => void;
	handleChangeAddress: (e: any) => void;
	address: string;
	departmentName: string;
	handleChangeDepartmentName: (e: any) => void;
	handleAddDepartment: () => void;
	bin: string;
	handleChangeBin: (e: any) => void;
	handleUpdateDepartment: () => void;
	isEditMode: boolean;
}

export const AddDepartmentWindow = ({
	openWindow,
	handleCloseWindow,
	city,
	handleChangeCity,
	address,
	handleChangeAddress,
	departmentName,
	handleChangeDepartmentName,
	handleAddDepartment,
	bin,
	handleUpdateDepartment,
	isEditMode,
	handleChangeBin
}: AddFillialWindowInterface) => (
	<form onSubmit={(e) => {
		e.preventDefault();
	}}>
		<Dialog
			open={openWindow}
			onClose={handleCloseWindow}
			maxWidth="xs"
			fullWidth
		>
			<DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
				{isEditMode ? 'Изменить параметры отделения' : 'Добавить отделение'}
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<div style={{ padding: "20px 0", display: "grid", gap: "20px" }}>
						<Autocomplete
							disableClearable
							options={["Алматы", "Астана"]}
							value={city || ""}
							onChange={handleChangeCity}
							fullWidth
							renderInput={(params) => <TextField label="Выберите город" required {...params} />}
						/>
						<TextField
							fullWidth
							label="Адрес"
							variant="outlined"
							value={address}
							required
							autoComplete={"off"}
							sx={{ background: "white" }}
							onChange={handleChangeAddress}
						/>
						<TextField
							fullWidth
							label="Наименование"
							variant="outlined"
							value={departmentName}
							required
							autoComplete={"off"}
							sx={{ background: "white" }}
							onChange={handleChangeDepartmentName}
						/>
						<TextField
							fullWidth
							id="outlined-basic"
							label="БИН"
							variant="outlined"
							value={bin}
							required
							autoComplete={"off"}
							sx={{ background: "white" }}
							onChange={(e) => {
								handleChangeBin(e);
							}}
						/>
					</div>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<BtnDarkBlue
					fullWidth
					variant="contained"
					size="large"
					type="submit"
					onClick={() => isEditMode ? handleUpdateDepartment() : handleAddDepartment()}
				>
					{isEditMode ? 'Изменить параметры' : 'Добавить отделение'}
				</BtnDarkBlue>
			</DialogActions>
		</Dialog>
	</form>
);
import authorizedLayout from "@/components/AuthorizedLayout";
import { Routes, Route } from "react-router-dom";
import SlaList from "./SlaList";
import SlaCreate from "./SlaCreate";

const Slas = () => (
    <Routes>
        <Route
            path=''
            element={authorizedLayout(() => SlaList())}
        />
        <Route
            path='create'
            element={authorizedLayout((props: any) => SlaCreate(props))}
        />
        <Route
            path=':id'
            element={authorizedLayout((props: any) => SlaCreate(props && { formState: "read" }))}
        />
    </Routes>
);

export default Slas;

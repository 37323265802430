import iassign from "immutable-assign";

const UPDATE_CLIENT_INFO = "UPDATE_CLIENT_INFO";
const UPDATE_PHONE = "UPDATE_PHONE";

export interface ClientInfo {
  firstName: string;
  middleName: string;
  lastName: string;
  iin: string;
  phone: string;
  citizen: string;
  birthday: string;

  doctype: string;
  docNumber: string;
  issuePlace: string;
  issueDate: string;
  validDate: string;

  photoImg: string;
  signImg: string;

  clientId: number;
  docId: number;
  adrId: number;

  country: string;
  region: string;
  locality: string;
  street: string;
  number: string;
  flat: string;
}

const initialState: ClientInfo = {
  firstName: "",
  middleName: "",
  lastName: "",
  iin: "",
  phone: "",
  citizen: "",
  birthday: "",
  doctype: "",
  docNumber: "",
  issuePlace: "",
  issueDate: "",
  validDate: "",
  photoImg: "",
  signImg: "",
  clientId: 0,
  docId: 0,
  adrId: 0,
  country: "",
  region: "",
  locality: "",
  street: "",
  number: "",
  flat: "",
};

export default function clientInfo(state = initialState, action: any = {}) {
  let _state = state;
  switch (action.type) {
    case UPDATE_CLIENT_INFO:
      return {
        ...iassign(state, action.payload.getProp, action.payload.setProp, null),
      };

    case UPDATE_PHONE:
      return {
        ...state,
        phone: action.phone,
      };

    default:
      _state = state;
      break;
  }

  return _state;
}

const updateClientInfo = (
  getProp: (c: any) => any,
  setProp: (p: any) => any
) => ({
  type: UPDATE_CLIENT_INFO,
  payload: { getProp, setProp },
});

const updatePhone = (phone: string) => ({
  type: UPDATE_PHONE,
  phone,
});

export const actions = {
  updateClientInfo,
  updatePhone,
};

import { TaskFormComponent } from "@/api/CamundaController";
import { updateTaskVariables } from "@/redux/modules/Process";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

interface ChecklistGenProps {
    component: TaskFormComponent
}

interface KeyVal {
    key: string;
    val: boolean;
}

const ChecklistGen = (props: ChecklistGenProps) => {
    const { component } = props;
    const dispatch = useDispatch();
    const variables = useSelector((state: any) => state?.process?.taskModel?.variables) || {};

    useEffect(() => {
        if (variables[component.key!] == undefined) {
            dispatch(
                updateTaskVariables(
                    (g) => g,
                    (p) => Object.assign(p, { [component.key!]: component.values?.map((v) => ({ key: v.value!, val: false })) || [] })
                ));
        }
    }, []);

    const values = variables[component.key!] as KeyVal[] || [{}];

    const handleChange = (index: number, val: KeyVal) => {
        const newValues = values.map<KeyVal>((v, i) => i === index ? val : v);
        dispatch(
            updateTaskVariables(
                (g) => g,
                (p) => Object.assign(p, { [component.key!]: newValues })
            ));
    };

    return (
        <Grid item xs={12} {...component.properties}>
            <FormControl fullWidth size='small' disabled={component.disabled}>
                <FormLabel id={`${component.id}-label`}>{component.label}</FormLabel>
                <FormGroup id={component.id}>
                    {component.values?.map((item, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox checked={values[index]?.val || false} onChange={(e) => handleChange(index, { key: item.value!, val: e.target.checked })} name={item.value} />
                            }
                            label={item.label}
                        />
                    ))}
                </FormGroup>
                <FormHelperText>{component.description}</FormHelperText>
            </FormControl>
        </Grid>);
};

export default ChecklistGen;
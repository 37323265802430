import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField } from "@mui/material";
import { Htag, ReserveCurrencyInfo } from '@/components';
import pm from "@/core/helpers/ProcessManager";

import styles from "./lastReservPage.module.css";
// import CheckDocument from '../../CashierRequest/LastPage/CheckDocument/CheckDocument';
import pdfMake from "pdfmake/build/pdfmake";
import axios from 'axios';
import InputMask from 'react-input-mask';
import { BtnDarkBlue } from '@/components/MuiComponent';
import { actions as rateExchangeActions } from '@/redux/modules/RateExchange';

const LastReservPage = () => {
  const dispatch = useDispatch();

  const variables = useSelector(
    (state: any) => state?.process?.taskModel?.variables
  );

  const rateExchange: any = useSelector(
    (state: any) => state?.rateExchange || {}
  );

  const [isSendOtp, setIsSendOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpCount, setOtpCount] = useState(3);
  const [timer, setTimer] = useState(30);
  const [docCode] = useState("");

  useEffect(() => {
    const timeOut = setInterval(() => {
      if (timer !== 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    return () => clearInterval(timeOut);
  }, [timer]);

  return (
    <div className={styles.background_color}>
      <div className={styles.container}>
        <Htag tag={'h1'}>Номер брони №{variables.businessKey}</Htag>
        <ReserveCurrencyInfo
          client={false}
          rateExchange={{
            buyAmount: variables.buyAmount,
            buyCurrencyCode: variables.buyCurrencyCode,
            depsId: variables.depsId,
            rate: variables.currentRate,
            saleAmount: variables.saleAmount,
            saleCurrencyCode: variables.saleCurrencyCode,
            requestId: null,
            isIndividual: variables.isIndividual,
            kassaId: variables.kassaId
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px"
          }}
        >
          {
            !isSendOtp
            &&
            <Button
              variant="outlined"
              size="large"
              sx={{ fontWeight: "bold" }}
              onClick={() => {
                // createNewRequest(
                //   variables.kassaId,
                //   variables.phone,
                //   variables.buyCurrencyCode,
                //   variables.saleCurrencyCode,
                //   variables.buyAmount,
                //   variables.saleAmount,
                //   variables.currentRate,
                //   true,
                //   variables.isIndividual,
                //   variables.businessKey,
                //   variables.fullName,
                //   variables.depsId,
                // ).then((res: any) => {
                //   if (res.requestId >= 0) {
                //     dispatch(rateExchangeActions.updateRequestId(res.requestId));
                //     if (variables.phone.length) {
                //       const doc = CheckDocument(
                //         variables.iin,
                //         res.date,
                //         res.requestId,
                //         variables.saleCurrencyCode,
                //         variables.saleAmount,
                //         variables.currentRate,
                //         variables.buyCurrencyCode,
                //         variables.buyAmount,
                //       );
                //       const document = pdfMake.createPdf(doc);
                //       document.getBlob((blob) => {
                //         const formData = new FormData();

                //         formData.append("file", blob, "document.pdf");

                //         axios
                //           .post(
                //             `https://api.ecash.kz/Application/uploadApplication/${res.requestId}`,
                //             formData,
                //             {
                //               headers: {
                //                 "Content-Type": "multipart/form-data",
                //               },
                //             }
                //           )
                //           .then((res: any) => {

                //             if (res.data.status === 1) {
                //               axios
                //                 .post(
                //                   `https://api.ecash.kz/Otp/send`,
                //                   {
                //                     phone: variables.phone,
                //                     text: `Check https://ecash.kz/receipt/${res.data.code}`,
                //                     digits: 4
                //                   },
                //                 )
                //                 .then(() => {
                //                   setIsSendOtp(true);
                //                   setTimer(30);
                //                   setOtpCount(otpCount - 1);
                //                 })
                //                 .catch((e) => { console.log(e); });
                //             }
                //           })
                //           .catch((e) => console.log(e));
                //       });
                //     } else {
                //       axios
                //         .patch(
                //           `https://api.ecash.kz/Application/UpdateRequest`,
                //           {
                //             requestId: res.requestId,
                //             status: 1
                //           },
                //           {
                //             headers: {
                //               accept: "*/*",
                //               'Content-Type': 'application/json-patch+json'
                //             },
                //           }
                //         )
                //         .then(() => {
                //           pm.goForth();
                //         })
                //         .catch((e) => console.log(e));
                //     }
                //   }
                // });
              }}
            >
              Подтвердить заявку
            </Button>
          }
          {isSendOtp &&
            <form onSubmit={(e) => e.preventDefault()}>
              <div
                style={{
                  marginBottom: "10px",
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "black",
                  textAlign: "center",
                }}
              >
                Введите смс-код
              </div>
              <InputMask
                mask="9 9 9 9"
                value={otp}
                onChange={(e) => {
                  setOtp(e.target.value.replace(/[_\s]/g, ""));
                }}
              >
                <TextField
                  sx={{
                    textAlign: "center",
                    fontSize: "25px",
                    fontWeight: "bold",
                    background: "white"
                  }}
                  autoComplete={"off"}
                  label="Введите смс-код"
                  variant="outlined"
                  fullWidth
                />
              </InputMask>
              {
                otpCount !== 0 ?
                  timer !== 0
                    ?
                    <div> Отправить повторно смс через {timer} секунд (Осталось попыток: {otpCount})</div>
                    :
                    <div
                      className={styles.sendOtpAgain}
                      onClick={() => {
                        axios
                          .post(
                            `https://api.ecash.kz/Otp/send`,
                            {
                              phone: variables.phone,
                              text: `Check https://ecash.kz/receipt/${docCode}`,
                              digits: 4
                            },
                          )
                          .then(() => {
                            setIsSendOtp(true);
                            setTimer(30);
                            setOtpCount(otpCount - 1);
                          })
                          .catch((e) => { console.log(e); });
                      }}
                    >
                      Отправить смс повторно (Осталось попыток: {otpCount})
                    </div>
                  : <div>Попытки закончились</div>
              }
              <BtnDarkBlue
                fullWidth
                variant="contained"
                size="large"
                type={"submit"}
                disabled={otp.replace(/[_\s]/g, "").length !== 4}
                sx={{ marginTop: "10px" }}
                onClick={() => {
                  axios
                    .post(
                      `https://api.ecash.kz/Otp/confirm`,
                      {
                        phone: variables.phone,
                        otp: otp,
                      },
                    )
                    .then((res: any) => {
                      if (res.data.isCorrect) {
                        axios
                          .patch(
                            `https://api.ecash.kz/Application/UpdateRequest`,
                            {
                              requestId: rateExchange.requestId,
                              status: 1
                            },
                            {
                              headers: {
                                accept: "*/*",
                                'Content-Type': 'application/json-patch+json'
                              },
                            }
                          )
                          .then(() => {
                            pm.goForth();
                          })
                          .catch((e) => console.error(e));
                      } else {
                        pm.openSnackBar("Неверный код", { severity: "error" });
                      }
                    })
                    .catch((e) => { console.log(e); });
                }}
              >
                Исполнить
              </BtnDarkBlue>
            </form>
          }
        </div>
      </div>
    </div>
  );
};

export default LastReservPage;

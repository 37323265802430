import Grid from '@mui/material/Grid';
import { QueueList, TaskBoard } from '@/components/TaskBoard';
import { useSelector } from 'react-redux';
import { User } from '@/api/CamundaController';



const MyTasks = () => {
    const selectedUserQueue = useSelector((state: any) => state?.header?.selectedQueueMenu);
    const userQueue = useSelector((state: any) => state?.header?.userQueue);
    const me = useSelector((state: any) => state?.header?.userContext?.user as User);


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
                <QueueList mode='queue' />
                {selectedUserQueue && selectedUserQueue.process == 'queue' && <TaskBoard me={me} selectedUserQueue={selectedUserQueue} userQueue={userQueue} />}
            </Grid>
        </Grid>);
};

export default MyTasks;
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import styles from "./dailyReportDocument.module.css";
import { getReport, getReportList } from "@/api/MyApi";
import useDailyReportDocument from './useDailyReportDocument';
import pdfMake from "pdfmake/build/pdfmake";
import { Pagination } from '@mui/material';
import pm from "@/core/helpers/ProcessManager";
import { selectDepartmentList } from '@/redux/modules/department/departmentSelectors';
import { loadDepartments } from '@/redux/modules/department/departmentActions';

export interface Report {
  id: string;
  login: string;
  kassaId: number;
  isDayOpen: boolean;
  isDayClosed: boolean;
  openDate: string;
  closeDate: string;
  startBalance: StartBalance[];
  closeBalance: StartBalance[];
  nb: StartBalance[];
  nbToday: StartBalance[];
  balanceUpdate: BalanceUpdate[];
  applicationList: ApplicationList[];
}
interface ApplicationList {
  requestId: number;
  iin?: string;
  phone?: string;
  fullName?: string;
  currencyFrom?: string;
  currencyTo?: string;
  value?: string;
  rate?: string;
  amount?: string;
  isIndividual?: boolean;
  depsId: number;
  actionType?: string;
  date: string;
  isReserve?: number;
  isDelivery?: number;
  status?: number;
  businessKey?: string;
  delivery: any[];
  applicationFiles?: any;
}
interface BalanceUpdate {
  currencyCode: string;
  initiator: string;
  changeDirection: string;
  lastBalance: string;
  newBalance: string;
}
interface StartBalance {
  currencyCode: string;
  value: string;
}

export const DailyReportDocument = () => {
  const [reportsList, setReportsList] = useState<Report[]>([]);
  const [reportCount, setReportCount] = useState(0);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  const departmentList = useSelector(selectDepartmentList);

  useEffect(() => {
    pm.openBackDrop();

    getReportList(page)
      .then(res => {
        dispatch(loadDepartments());

        setReportsList(res.report ? res.report : []);
        setReportCount(res.count ? res.count : 0);
        pm.closeBackDrop();
      });
  }, []);

  const mappedReportList = useMemo(() => reportsList.map(report => {
    const currentDep = departmentList.find(dep => {
      for (const kassa of dep.kassa) {
        return kassa.id === report.kassaId;
      }
    });
    const openDate = new Date(report?.openDate).toLocaleDateString();
    const closeDate = new Date(report?.closeDate).toLocaleDateString();

    const callGetReport = (reportId: string) => {
      pm.openBackDrop();

      getReport(reportId)
        .then((res) => {
          if (currentDep) {
            const doc = useDailyReportDocument(res.report, currentDep, openDate);
            pdfMake.createPdf(doc).open();
          }
        })
        .finally(pm.closeBackDrop);
    };

    return (
      <div
        key={report.id}
        className={`${styles.table_row} ${report.isDayClosed ? "" : styles.table_row_open}`}
        onClick={() => {
          report.isDayClosed && callGetReport(report.id);
        }}
      >
        <p>{currentDep?.name}</p>
        <p style={{ justifySelf: "center" }}>{report?.login}</p>
        <p style={{ justifySelf: "center" }}>{openDate.toLocaleString()}</p>
        <p style={{ justifySelf: "center" }}>{report.isDayClosed ? closeDate.toLocaleString() : "Касса открыта"}</p>
      </div>
    );
  }), [reportsList, departmentList]);

  return (
    <>
      <div style={{
        marginTop: "30px",
        background: "white",
        padding: "20px",
        borderRadius: "10px",
      }}
      >
        <div>
          <div className={styles.table_row}>
            <p>Филиал</p>
            <p style={{ justifySelf: "center" }}>Логин</p>
            <p style={{ justifySelf: "center" }}>Время открытия</p>
            <p style={{ justifySelf: "center" }}>Время закрытия</p>
          </div>
          {
            mappedReportList
          }
        </div>
        <Pagination
          sx={{
            marginTop: "17px",
            "& ul": {
              justifyContent: "center"
            }
          }}
          count={Math.ceil(reportCount / 5)}
          page={page}
          onChange={(_, value: number) => {
            setPage(value);
            pm.openBackDrop();
            getReportList(value)
              .then(res => {
                setReportsList(res.report ? res.report : []);
                setReportCount(res.count ? res.count : 0);
                pm.closeBackDrop();
              });
          }}
        />
      </div>
    </>
  );
};
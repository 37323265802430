import { CurrencyModel, DepartmentModel, Regions } from '@/interface';
import {
	SET_ERROR,
	SET_DEPARTMENT,
	SET_LOADING,
	SET_ACTIVE_DEPARTMENT_ID,
	SET_ACTIVE_CURRENCY_ID,
	SET_ACTIVE_REGION,
	SET_NEW_CURRENCY,
	RESET_NEW_CURRENCY,
	SET_ACTIVE_KASSA_ID,
	SET_KASSA_IS_OPEN,
} from './departmentActions';

export interface InitialStateProps {
	status: 'idle' | 'loading' | 'resolve' | 'rejected',
	departmentList: DepartmentModel[];
	activeDepartmentId: number | string;
	activeCurrencyId: number | string;
	regions: Regions[];
	activeRegion: Regions | 'Все';
	newCurrency: CurrencyModel;
	activeKassaId: number | string;
	kassaIsOpen: boolean;
}

const initialState: InitialStateProps = {
	status: 'idle',
	departmentList: [],
	activeDepartmentId: "",
	activeCurrencyId: "",
	regions: ['Алматы', 'Астана'],
	activeRegion: 'Алматы',
	newCurrency: {
		code: "",
		description: "",
		country: "",
		spread: 0,
		margin: 0,
		isActive: true,
		fromParse: false,
		parseBuy: "",
		parseSale: "",
		parseSite: "",
		step: "",
		rates: [
			{
				buy: 0,
				sale: 0,
				limitFrom: 0,
				limitTo: 0,
				initiator: "Ержан",
			},
			{
				buy: 0,
				sale: 0,
				limitFrom: 0,
				limitTo: 0,
				initiator: "Ержан",
			},
		]
	},
	activeKassaId: "",
	kassaIsOpen: false,

};

export const departmentReducer = (state = initialState, { type, payload }: { type: string, payload: any }): InitialStateProps => {
	switch (type) {
		case SET_LOADING:
			return {
				...state,
				status: 'loading',
				departmentList: [],
			};
		case SET_DEPARTMENT:
			return {
				...state,
				status: 'resolve',
				departmentList: payload.departmentList
			};
		case SET_ERROR:
			return {
				...state,
				status: 'rejected',
				departmentList: [],
			};
		case SET_ACTIVE_DEPARTMENT_ID:
			return {
				...state,
				activeDepartmentId: payload.departmentId,
			};
		case SET_ACTIVE_CURRENCY_ID:
			return {
				...state,
				activeDepartmentId: payload.departmentId,
				activeCurrencyId: payload.currencyId
			};
		case SET_ACTIVE_REGION:
			return {
				...state,
				activeRegion: payload.region
			};
		case SET_NEW_CURRENCY:
			return {
				...state,
				newCurrency: payload.newCurrency
			};
		case RESET_NEW_CURRENCY:
			return {
				...state,
				newCurrency: initialState.newCurrency
			};
		case SET_ACTIVE_KASSA_ID:
			return {
				...state,
				activeKassaId: payload.kassaId
			};
		case SET_KASSA_IS_OPEN:
			return {
				...state,
				kassaIsOpen: payload.kassaIsOpen
			};
		default: {
			return state;
		}
	}
};
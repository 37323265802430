import { FlowButton } from "@/api/CamundaController";
import { Button, Grid, Stack } from "@mui/material";
import React from "react";
import processManager from '@/core/helpers/ProcessManager';
import { useTranslation } from "react-i18next";


interface ButtonsFlowProps {
    buttons?: Array<FlowButton>;
    formRef: any;
}

const ButtonsFlow = (props: ButtonsFlowProps) => {
    const { t } = useTranslation();
    const { buttons, formRef } = props;

    const handleClick = (buttonId: string, buttonLabel?: string) => {
        if (formRef.current.reportValidity())
            processManager.openConfirm(() => {
                let obj = new Object();
                buttons?.forEach((value) => {
                    obj = Object.assign(obj, { [value.id!]: value.id === buttonId });
                });
                processManager.flow(false, obj);
            }, t("form.confirm"), buttonLabel || '');
    };

    return (
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
            <Stack spacing={2} direction={'row'}>
                {buttons && buttons?.map((button, index) => (
                    <React.Fragment key={index}>
                        <Button key={index}
                            variant='contained'
                            onClick={() => handleClick(button.id!, button.label || button.id)}
                        >{button.label || button.id}</Button>
                    </React.Fragment>
                ))}
            </Stack>
        </Grid>);
};

export default ButtonsFlow;
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {
    Button, Toolbar, Typography, Box, TextField,
    FormControl, InputLabel, MenuItem, Select, TextareaAutosize, TextFieldProps
} from '@mui/material';
import { Container } from '@mui/system';
import dayjs, { Dayjs } from 'dayjs';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CancelPresentation, Send } from '@mui/icons-material';
import * as router from 'react-router-dom';


const Create = () => {

    const [lang, setLang] = React.useState('RUS');
    const [value, setValue] = React.useState<Dayjs | null>(
        dayjs('2014-08-18T21:11:54'),
    );
    const handleChange = (newValue: Dayjs | null) => {
        setValue(newValue);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
    };
    return (
        <Grid container spacing={2}>
            <Grid xs={12} md={8} lg={8}>
                <Toolbar>
                    <Button variant="contained" component={router.Link} to='/' startIcon={<CancelPresentation />}>Отменить</Button>
                </Toolbar>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <Container sx={{ pt: 2 }}>
                        <Typography component="h2" variant="h6">
                            Новое обращение
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="lang-select-label">Тип события</InputLabel>
                                        <Select
                                            labelId="lang-select-label"
                                            id="lang-select"
                                            value={lang}
                                            label="Тип события"
                                            onChange={(e) => setLang(e.target.value)}
                                            size="small"
                                        >
                                            <MenuItem value={'Подозрительное событие. Результат реагирования не получен.'}>Подозрительное событие. Результат реагирования не получен.</MenuItem>
                                            <MenuItem value={'Инцидент'}>Инцидент</MenuItem>
                                            <MenuItem value={'Подозрительная внутренняя активность, не повлекшая инцидент'}>Подозрительная внутренняя активность, не повлекшая инцидент</MenuItem>
                                            <MenuItem value={'Не инцидент - легитимная активность'}>Не инцидент - легитимная активность</MenuItem>
                                            <MenuItem value={'Подозрительная внешняя активность, не повлекшая инцидент'}>Подозрительная внешняя активность, не повлекшая инцидент</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="lang-select-label">Сценарий обнаружения (клиент)</InputLabel>
                                        <Select
                                            labelId="lang-select-label"
                                            id="lang-select"
                                            value={lang}
                                            label="Сценарий обнаружения (клиент)"
                                            onChange={(e) => setLang(e.target.value)}
                                            size="small"
                                        >
                                            <MenuItem value={'Внутреннее сканирование сети (Internal Network Scan)'}>Внутреннее сканирование сети (Internal Network Scan)</MenuItem>
                                            <MenuItem value={'Внешнее сканирование сети (External Network Scan)'}>Внешнее сканирование сети (External Network Scan)</MenuItem>
                                            <MenuItem value={'Наличие и попытка эксплуатации уязвимости'}>Наличие и попытка эксплуатации уязвимости</MenuItem>
                                            <MenuItem value={'Подозрительные попытки аутентификации'}>Подозрительные попытки аутентификации</MenuItem>
                                            <MenuItem value={'Подозрительная активность привилегированных учетных записей'}>Подозрительная активность привилегированных учетных записей</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <TextareaAutosize
                                            aria-labelledby='are-label'
                                            aria-label="Описание инцидента"
                                            minRows={5}
                                            placeholder="Описание инцидента"
                                            style={{ width: 'justify' }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                label="Дата и время возникновения"
                                                value={value}
                                                onChange={handleChange}
                                                renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <TextareaAutosize
                                            aria-label="Рекомендации по реагированию"
                                            minRows={5}
                                            placeholder="Рекомендации по реагированию"
                                        />
                                    </FormControl>
                                </Grid>

                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                size="small"
                                endIcon={<Send />}
                            >
                                Создать
                            </Button>
                        </Box>
                    </Container>
                </Paper>
            </Grid>
        </Grid>);
};

export default Create;
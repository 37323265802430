import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Queue } from '@/api/CamundaController';
import { Box, Button, FormControlLabel, IconButton, Input, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { KeyboardArrowDown, KeyboardArrowUp, LibraryAdd, Search } from '@mui/icons-material';
import { api } from '@/api/Api';
import React, { useEffect, useState } from 'react';
import { Delete } from '@mui/icons-material';
import processManager from '@/core/helpers/ProcessManager';
import { useTranslation } from 'react-i18next';


const QueuesList = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [content, setContent] = useState([] as Queue[]);
    const [pageSize, setPageSize] = useState(10);
    const [totalElements, setTotalElements] = useState(0);
    const showMoreSize = 10;
    const [lastPage, setLastPage] = useState(true);
    const [name, setName] = useState("");
    const [process, setProcess] = useState("");
    const [dense, setDense] = React.useState(true);

    const onShowMore = () => {
        if (process.length > 0)
            api.camunda.getQueuesProcess(0, pageSize + showMoreSize, process).then((m) => {
                setContent(m.content);
                setLastPage(m.last);
                setPageSize(pageSize + showMoreSize);
                setTotalElements(m.totalElements);
            });
        else
            api.camunda.getQueuesName(0, pageSize + showMoreSize, name).then((m) => {
                setContent(m.content);
                setLastPage(m.last);
                setPageSize(pageSize + showMoreSize);
                setTotalElements(m.totalElements);
            });
    };

    const deleteQueue = (queue: Queue) => {
        processManager.openConfirm(() => {
            api.camunda.deleteQueue(queue.id).then(() => {
                setContent(content.filter((m) => m.id !== queue.id));
                processManager.openSnackBar(t("queue.list.deletesuccess"));
            });
        }, `${t("queue.list.deleteconfirm")} ${queue.name}?`);
    };

    useEffect(() => {
        api.camunda.getQueuesName(0, pageSize, name).then((m) => {
            setContent(m.content);
            setLastPage(m.last);
            setTotalElements(m.totalElements);
        });
    }, [name]);

    useEffect(() => {
        api.camunda.getQueuesProcess(0, pageSize, process).then((m) => {
            setContent(m.content);
            setLastPage(m.last);
            setTotalElements(m.totalElements);
        });
    }, [process]);

    const changeOrderUp = (index: number) => {
        const q1 = content[index - 1];
        const q2 = content[index];
        api.camunda.changeOrder(q1.id, q2.id).then(() => {
            var sorted = [...content];
            var temp = sorted[index - 1];
            sorted[index - 1] = sorted[index];
            sorted[index] = temp;
            setContent(sorted);
        });
    };

    const changeOrderDown = (index: number) => {
        const q1 = content[index];
        const q2 = content[index + 1];
        api.camunda.changeOrder(q1.id, q2.id).then(() => {
            var sorted = [...content];
            var temp = sorted[index];
            sorted[index] = sorted[index + 1];
            sorted[index + 1] = temp;
            setContent(sorted);
        });
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const onClick = (queue: Queue) => {
        navigate(`/queues/${queue.id}`);
    };

    return (
        <Grid container spacing={2}>
            <Grid>
                <Toolbar>
                    <Button variant="contained" component={Link} to={`/queues/create/${totalElements}`} startIcon={<LibraryAdd />}>{t("queue.list.create")}</Button>
                </Toolbar>
            </Grid>
            {/* Chart */}
            <Grid item xs={12} md={12} lg={12}>
                <React.Fragment>
                    <Box sx={{ width: '100%' }}>
                        <Paper sx={{ width: '100%', mb: 2 }}>
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Input
                                                    type="search"
                                                    placeholder={t("queue.list.name") || ''}
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    size='small'
                                                />
                                                <Search />
                                            </TableCell>
                                            <TableCell>
                                                <Input
                                                    type="search"
                                                    placeholder={t("queue.list.process") || ''}
                                                    value={process}
                                                    onChange={(e) => setProcess(e.target.value)}
                                                    size='small'
                                                />
                                                <Search />
                                            </TableCell>
                                            <TableCell>{t("queue.list.roles")}</TableCell>
                                            <TableCell>{t("queue.list.sort")}</TableCell>
                                            <TableCell>{t("queue.list.delete")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {content.map(((cont, i) => (
                                            <TableRow key={cont.id} onClick={() => onClick(cont)}>
                                                <TableCell>{cont.name}</TableCell>
                                                <TableCell>{cont.process}</TableCell>
                                                <TableCell>{cont.roles?.map(r => r.code).join(', ')}</TableCell>
                                                <TableCell style={{ width: "88px" }}>
                                                    {i != 0 &&
                                                        <IconButton aria-label="delete"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                changeOrderUp(i);
                                                            }}>
                                                            <KeyboardArrowUp />
                                                        </IconButton>
                                                    }
                                                    {i < content.length - 1 &&
                                                        <IconButton aria-label="delete"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                changeOrderDown(i);
                                                            }}>
                                                            <KeyboardArrowDown />
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                                <TableCell style={{ width: "88px" }}>
                                                    <IconButton aria-label="delete"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            deleteQueue(cont);
                                                        }}>
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br />
                            {!lastPage && (
                                <div
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => onShowMore()}>
                                    <b>
                                        <u>{t("queue.list.showmore")}</u>
                                    </b>
                                </div>
                            )}
                        </Paper>
                        <FormControlLabel
                            control={<Switch checked={dense} onChange={handleChangeDense} />}
                            label={t("queue.list.tight")}
                        />
                    </Box>
                </React.Fragment>
            </Grid>
        </Grid>);
};

export default QueuesList;